import React, { useState, useEffect, useCallback } from "react";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Container, Row, Col, Form, Accordion, Modal } from "react-bootstrap";
import "react-datetime/css/react-datetime.css";
import FoodOrderCard from "../Components/FoodOrderCard";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Keyboard, Autoplay } from "swiper";
import { useSelector, useDispatch } from "react-redux";
import useAxiosFile from "../actions/useaxios";
import {
  DateTimeForm,
  MinuteHourFormat,
  NumberOnly,
  isEmpty,
  toLocaleStrings,
} from "../actions/common";
import { toast } from "react-toastify";
import moment from "moment";
import { TimeFormat } from "../actions/common";
import { useRef } from "react";
import Datetime from "react-datetime";

import Button from "react-bootstrap/Button";
import DateChoose from "./DateChoose";

function CartCheckout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { payload, token } = useSelector((state) => state.LoginReducer.User);
  const { DeliveryType } = useSelector((state) => state.LoginReducer);

  const navigate = useNavigate();
  const clearinput = useRef(null);
  const axiosFile = useAxiosFile();
  const [updatecart, SetUpdateCart] = useState(1);
  const [headercart, SetHeaderCart] = useState(1);

  const [tipActive, setTipActive] = useState("5");
  const [sellerActive, setSellerActive] = useState("5");
  const [showyouPickup, setShowyouPickup] = useState(false);
  const [showthirtyDelivery, setShowthirtyDelivery] = useState(false);
  const [showthirtyPickup, setShowthirtyPickup] = useState(false);
  const [showTipCustom, setShowTipCustom] = useState({ show: false, Type: "" });
  const [cartdatas, SetCartDatas] = useState([]);
  const [ShopData, SetShopData] = useState({});
  const [SelectDate, SetSelectDate] = useState([]);
  const [SelectedDay, SetSelectedDay] = useState("");
  const [ShopDayTimeCharges, SetShopDayTimeCharges] = useState([]);
  const [TimeCharges, SetTimeCharges] = useState([]);
  const [PreparationTime, SetPreparationTime] = useState(0);
  const [SelectedTime, SetSelectedTime] = useState();
  const [SelectedTimeDisplay, SetSelectedTimeDisplay] = useState({
    time: "00:00",
    date: "",
    initialtime: new Date(),
    starttime: "",
    endtime: "",
    startdate: "",
    enddate: "",
    validdays: [],
    checkedindex: undefined,
    Day: "",
    timechooseindex: undefined,
  });
  const [CustomTips, SetCustomTips] = useState("");
  const [TimeError, SetTimeError] = useState({});
  const [nextpage, SetNextPage] = useState(false);

  // modal

  const [mdShow, setMdShow] = useState(false);
  const [activeTab, setActiveTab] = useState();

  const handleCloseyouPickup = () => setShowyouPickup(false);
  const handleShowyouPickup = () => setShowyouPickup(true);

  const handleCloseThirtyDelivery = () => setShowthirtyDelivery(false);

  const handleCloseThirtyPickup = () => setShowthirtyPickup(false);

  const handleCloseTips = () => {
    setShowTipCustom(false);
  };
  const handleShowTips = (val) => setShowTipCustom({ show: true, Type: val });

  const [Load, SetLoad] = useState(false);
  const [UpdateBln, SetUpdateBln] = useState(false);
  const [emptycartconfirm, SetEmptyCartConfirm] = useState({
    _id: "",
    ProductId: "",
    type: "",
    confirmstatus: false,
  });
  const [ConfirmPopup, SetConfirmPopup] = useState(false);

  const [TotalPrices, SetTotalPrices] = useState({
    CartValue: 0,
    DeliveryTimeCharges: 0,
    Discount: 0,
    PayAmount: 0,
    SellerTips: 5,
    DriverTips: 5,
    SellerTipsInValue: 0,
    DriverTipsInValue: 0,
    Type: DeliveryType,
    Time: "",
  });

  const [total, SetTotal] = useState({ Count: 0, Price: 0 });
  const [ChoosenTime, SetChoosenTime] = useState({ time: null, day: null });
  const [HourMinuteDisplay, SetHourMinuteDisplay] = useState({
    Hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,21, 22, 23],
    Minutes: [0, 15, 30, 45],
  });
  const [ScheduleTime,SetScheduleTime] = useState([]);
  const [dispalyPopupOption, SetDisplayPoPupOption] = useState(true);


  useEffect(() => {
    if (payload?._id) {
      GetCart();
    } else {
      // handleCloseCart()
      navigate("/");
    }
  }, [payload]);
  console.log("kdjskldjsfs", TotalPrices, DeliveryType);

  useEffect(() => {
    var driver = 0,
      seller = Number(
        (
          Number(cartdatas.reduce((it, acc) => Number(it) + Number(acc.TotalPrice) * Number(acc.Quantity), 0)) *
          Number(TotalPrices?.SellerTips / 100)
        ).toFixed(2)
      );
    if (TotalPrices?.Type != "Pickup") {
      driver =
        (
          Number(cartdatas.reduce((it, acc) => Number(it) + Number(acc.TotalPrice) * Number(acc.Quantity), 0)) *
          Number(TotalPrices?.DriverTips / 100)
        ).toFixed(2);
    }

    SetTotalPrices({
      ...TotalPrices,
      ...{
        [`${"DriverTips"}InValue`]: driver,
        [`${"SellerTips"}InValue`]: seller,
        CartValue: cartdatas.reduce(
          (it, acc) => it + acc.TotalPrice * acc.Quantity,
          0
        ),
        totalItems: cartdatas.reduce((it, acc) => it + acc.Quantity, 0),
        PayAmount: Math.ceil(
          driver +
            seller +
            cartdatas.reduce((it, acc) => it + acc.TotalPrice * acc.Quantity, 0)
        ),
      },
    });
  }, [UpdateBln]);

  const GetCart = async (from) => {
    let instResp = await axiosFile.GetCarts({ UserId: payload._id });
    var Resp = { ...instResp };

    Resp.data = Resp?.data?.shop;

    //        new deliverycharge
    console.log(
      "kjfklsjfjslfs",
      from,
      instResp,
      Resp?.data?.[0]?.ShopId?.DayTimings
    );
    if (Resp?.data?.[0]?.ShopId?.DayTimings) {
      Object.keys(Resp?.data?.[0]?.ShopId?.DayTimings).map((val) => {
        if (Resp?.data?.[0]?.ShopId?.DayTimings?.[val]?.DeliveryTimeCharge) {
          Resp.data[0].ShopId.DayTimings[
            val
          ].DeliveryTimeCharge.AfterPreparation =
            instResp?.data?.deliverytimecharge?.[val];
        } else {
          Resp.data[0].ShopId.DayTimings[val].DeliveryTimeCharge = {
            AfterPreparation: instResp?.data?.deliverytimecharge?.[val],
          };
        }
      });
    }

    if (Resp?.data?.[0]) {
      Resp.data[0].ShopId.DistanceCharge =
        instResp?.data?.deliverytimecharge?.DistanceCharge;
      Resp.data[0].ShopId.DeliveryTimeCharge =
        instResp?.data?.deliverytimecharge?.DefaultCharge;
    }

    //        new deliverycharge
    if (Resp?.data?.length > 0) {
      let price = 0;
      let count = 0;
      let totalpreptime = 0;
      var tipsvaluedefault =
        Resp?.data?.reduce(
          (acc, it) => acc + Number(it.TotalPrice * it.Quantity),
          0
        ) *
        (5 / 100);
      SetTotalPrices({
        ...TotalPrices,
        ...{
          ["DriverTips"]: 5,
          [`${"DriverTips"}InValue`]:
          TotalPrices?.Type != "Pickup" ? Number(tipsvaluedefault).toFixed(2) : 0,
          ["SellerTips"]: 5,
          [`${"SellerTips"}InValue`]: Number(tipsvaluedefault).toFixed(2),
          totalItems: Resp?.data?.reduce(
            (acc, it) => acc + Number(it.Quantity),
            0
          ),
          CartValue: Resp?.data?.reduce(
            (acc, it) => acc + Number(it.TotalPrice * it.Quantity),
            0
          ),
          PayAmount: Number(
            2 * tipsvaluedefault +
              Resp?.data?.reduce(
                (acc, it) => acc + Number(it.TotalPrice * it.Quantity),
                0
              )
          ).toFixed(2),
        },
      });
      SetCartDatas(Resp?.data);
      Resp?.data?.map((val) => {
        count = count + Number(val?.Quantity);
        totalpreptime =
          totalpreptime +
          Number(
            val?.ProductId?.PreparationTime
              ? val?.ProductId?.PreparationTime
              : 0
          ) *
            (Number(val?.Quantity ? val?.Quantity : 0) /
              Number(val?.MaxCount ? val?.MaxCount : 1));
        console.log("kjsglkfjsglsg", val, price);
      });
      SetPreparationTime(totalpreptime);
      console.log("ksdjfsdklfds", Resp?.data[0]?.ShopId, count, price);
      SetShopData(Resp?.data[0]?.ShopId);
      SetTotal({ Count: count, Price: price });
      console.log("ksdjfsdklfds", count, price);
      let shoptimecharge = {};
      let days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let d = new Date();
      let getday = d.getDay();
      let dayName = days[getday];
      let validdays = [];
      if (Resp?.data[0]?.ShopId?.DayTimings) {
        if (Object.keys(Resp?.data[0]?.ShopId?.DayTimings)?.length > 0) {
          let timingarr = [];
          let instdata = Resp?.data[0]?.ShopId?.DayTimings;
          console.log("kjksjfhskjfsh", instdata);
          Object.keys(instdata).map((val) => {
            let insttiming = instdata[val]?.Timing;
            if (insttiming?.Status == true && insttiming?.timing?.length > 0) {
              let nextdatewithendtime = null;
              if (val == dayName) {
                nextdatewithendtime = new Date(
                  new Date(
                    new Date().setHours(
                      insttiming?.timing?.[insttiming?.timing?.length - 1]
                        ?.EndHour
                    )
                  ).setMinutes(
                    insttiming?.timing[insttiming?.timing?.length - 1]
                      ?.EndMinute
                  )
                );
              } else {
                nextdatewithendtime = getNextDate(days.indexOf(val));
              }

              let displaytime = "";
              insttiming?.timing?.map((currtime) => {
                let starthour =
                  Number(currtime?.StartHour) > 12
                    ? Number(currtime?.StartHour) - 12
                    : Number(currtime?.StartHour) == 0
                    ? 12
                    : Number(currtime?.StartHour);
                let startmin = MinuteHourFormat(currtime?.StartMinute);
                let startampm = Number(currtime?.StartHour) >= 12 ? "pm" : "am";
                let endhour =
                  Number(currtime?.EndHour) > 12
                    ? Number(currtime?.EndHour) - 12
                    : Number(currtime?.EndHour);
                let endmin = MinuteHourFormat(currtime?.EndMinute);
                let endampm = Number(currtime?.EndHour) >= 12 ? "pm" : "am";
                displaytime =
                  displaytime == ""
                    ? `${starthour}:${startmin} ${startampm}-${endhour}:${endmin} ${endampm} `
                    : displaytime +
                      `; ${starthour}:${startmin} ${startampm}-${endhour}:${endmin} ${endampm} `;
              });

              timingarr.push({
                ...insttiming,
                ...{
                  DayName: val,
                  Display: displaytime,
                  Day: days.indexOf(val),
                  weekdate: nextdatewithendtime,
                },
              });
              validdays.push(days.indexOf(val));
            }
            let instdaycharges = instdata[val]?.DeliveryTimeCharge;
            console.log("lkjkjglkfkl", instdaycharges, instdata, val);

            if (!isEmpty(instdaycharges)) {
              if (
                instdaycharges?.AfterPreparation?.length > 0 ||
                instdaycharges?.timing?.length > 0
              ) {
                shoptimecharge[val] = instdaycharges;
              }
            }
          });
          console.log("kjksjfhskjfshoutttt", instdata);

          var map = {
            Sunday: 1,
            Monday: 2,
            Tuesday: 3,
            Wednesday: 4,
            Thursday: 5,
            Friday: 6,
            Saturday: 7,
          };
          let mapkeys = Object.keys(map);
          let curdayindex = mapkeys.indexOf(dayName);
          map[dayName] = 1;
          let i = curdayindex >= 6 ? 0 : curdayindex + 1;
          let currordernumber = 1;

          while (i != curdayindex && i <= 8) {
            if (i >= 6) {
              map[mapkeys[i]] = ++currordernumber;
              i = 0;
            } else {
              map[mapkeys[i]] = ++currordernumber;
              i = i + 1;
            }
          }

          timingarr.sort((a, b) => {
            return map[a.DayName] - map[b.DayName];
          });

          console.log(
            "kjdsfklsjfgouttt",
            timingarr,
            dayName,
            new Date() > timingarr.weekdate,
            new Date()
          );

          if (
            timingarr?.length > 1 &&
            new Date().setHours(new Date().getHours() + 1) >
              timingarr[0].weekdate
          ) {
            dayName = timingarr[1].DayName;
            getday = days.indexOf(dayName);
            map = {
              Sunday: 1,
              Monday: 2,
              Tuesday: 3,
              Wednesday: 4,
              Thursday: 5,
              Friday: 6,
              Saturday: 7,
            };
            let mapkeys = Object.keys(map);
            let curdayindex = mapkeys.indexOf(dayName);
            map[dayName] = 1;
            i = curdayindex >= 6 ? 0 : curdayindex + 1;
            currordernumber = 1;

            while (i != curdayindex && i <= 8) {
              if (i >= 6) {
                map[mapkeys[i]] = ++currordernumber;
                i = 0;
              } else {
                map[mapkeys[i]] = ++currordernumber;
                i = i + 1;
              }
            }

            timingarr.sort((a, b) => {
              return map[a.DayName] - map[b.DayName];
            });

            console.log(
              "kjdsfklsjfg",
              i,
              currordernumber,
              curdayindex,
              dayName
            );
          }

          console.log("ksjdfkjfhksg", timingarr, dayName);

          // console.log('sjslfsjlfsfs',timingarr,map,dayName,!timingarr.some((val)=>val.Day ==  getday))
          if (
            timingarr?.length > 0 &&
            !timingarr.some((val) => val.Day == getday)
          ) {
            dayName = timingarr[0].DayName;
            getday = timingarr[0].Day;
          }
          console.log("lfkldkfljdsjfdsl", timingarr);
          SetSelectDate(timingarr);
        }
        SetShopDayTimeCharges({
          ...shoptimecharge,
          ...{
            DistanceCharge: Resp.data[0].ShopId.DistanceCharge,
            DeliveryTimeCharge: Resp.data[0].ShopId.DeliveryTimeCharge,
          }
        });

        SetSelectedDay({ DayName: dayName, Day: getday });
        SetSelectedTimeDisplay({
          ...SelectedTimeDisplay,
          ...{ validdays: validdays, Day: dayName },
        });
      }
    } else {
      // if(from=='header'){
      navigate("/");
      // }
    }
  };

  useEffect(() => {
    console.log("khfksdgksdgsd", SelectedDay);
    if (SelectedDay?.DayName) {
      CalculateTimeCharge();
    }
  }, [SelectedDay]);

  const CalculateTimeCharge = () => {
    console.log("skjflksjlfs", ShopData?.DayTimings[SelectedDay?.DayName]);
    let preptimeinhours = PreparationTime / 3600;
    let Timings = ShopData?.DayTimings[SelectedDay?.DayName]?.Timing?.timing;
    let timecharge = ShopData?.DeliveryTimeCharge;
    let daytimecharge =
      ShopDayTimeCharges[SelectedDay?.DayName]?.AfterPreparation;

    let alltiming = {
      Pickup: ShopData?.DayTimings[SelectedDay?.DayName]?.Pickup?.timing,
      Delivery: ShopData?.DayTimings[SelectedDay?.DayName]?.Delivery?.timing,
    };

    if (!isEmpty(Timings)) {
      console.log("ksdlgsglflg", ShopData, daytimecharge, ShopDayTimeCharges);

      let i = null;
      let selecteddaytime = null;
      if (SelectedDay.Day == new Date().getDay()) {
        let hourminuteadd = new Date();
        selecteddaytime = new Date();
        i = new Date().setSeconds(new Date().getSeconds() + PreparationTime);
      } 
      else {
        let nextdate = getNextDate(SelectedDay?.Day);
        let hourminuteadd = new Date(
          nextdate.setHours(Timings[0].StartHour)
        ).setMinutes(Timings[0].StartMinute);
        selecteddaytime = new Date(hourminuteadd);
        i = new Date(hourminuteadd).setSeconds(
          new Date(hourminuteadd).getSeconds() + PreparationTime
        );
        let DeliveryArray = [];
        DeliveryArray.push({
          display: "Shop is closed for delivery today, Please select Schedule for later option below",
          charge: 0,
          Timing: undefined,
          nospecialcharge: undefined,
          currency: undefined,
        })
        SetTimeCharges({
          ...TimeCharges,
          ...{
            [SelectedDay?.DayName]: {
              Pickup: [],
              Delivery: DeliveryArray,
            },
          },
        });
        SetSelectedTime(selecteddaytime);
        // console.log("lskjfkskfjs", MinuteHourFormat(selecteddaytime.getDate()));
        let time = `${MinuteHourFormat(selecteddaytime.getHours())}:${MinuteHourFormat(selecteddaytime.getMinutes())}`;
        let date = `${MinuteHourFormat(selecteddaytime.getFullYear())}-${MinuteHourFormat(selecteddaytime.getMonth() + 1)}-${MinuteHourFormat(selecteddaytime.getDate())}`;
        let endtime = selecteddaytime.getTime();
        endtime = new Date(
          new Date(endtime).setMonth(new Date(endtime).getMonth() + 3)
        );
        let enddate = `${MinuteHourFormat(endtime.getFullYear())}-${MinuteHourFormat(endtime.getMonth() + 1)}-${MinuteHourFormat(endtime.getDate())}`;
        SetSelectedTimeDisplay({
          ...SelectedTimeDisplay,
          ...{
            time,
            date,
            initialtime: selecteddaytime,
            startdate: date,
            enddate: enddate,
            starttime: time,
            endtime: Timings?.[Timings.length - 1].EndTime,
            Day: SelectedDay?.DayName,
          },
        });
        return true;
      }

      let PickupArray = [];
      let DeliveryArray = [];

      let timingindex = 0;
      let remtime = 0;

      if (daytimecharge?.length > 0) {
        daytimecharge.sort((a, b) => {
          return (
            Number(Number(a.AfterPreparation) * 3600) -
            Number(b.AfterPreparation) * 3600
          );
        });
        const CheckTime = (data, preptimesec, timingarr) => {
          if (!isEmpty(timingarr)) {
            let currclosetime = new Date(
              new Date(
                new Date(i).setHours(timingarr?.[timingindex]?.EndHour)
              ).setMinutes(timingarr?.[timingindex]?.EndMinute)
            );
            // console.log("kdfjlksjlkfs", data, currclosetime);
            console.log(
              "ldjldhddfsha",
              remtime,
              data,
              currclosetime,
              timingindex,
              timingarr.length - 1
            );
            if (data > currclosetime) {
              if (timingindex >= timingarr?.length - 1) {
                let instlastpreptime = new Date(
                  new Date(
                    new Date(i).setHours(
                      timingarr?.[timingindex]?.EndHour,
                      timingarr?.[timingindex]?.EndMinute,
                      0,
                      0
                    )
                  ).getTime() -
                    PreparationTime * 1000
                );
                console.log(
                  "jrsghfgkdshgf",
                  instlastpreptime,
                  new Date(i),
                  new Date(
                    new Date(i).setHours(
                      timingarr?.[timingarr?.length - 1]?.EndHour,
                      timingarr?.[timingarr?.length - 1]?.EndMinute,
                      0,
                      0
                    )
                  )
                );
                if (
                  new Date(
                    new Date(i).setHours(
                      timingarr?.[timingarr?.length - 1]?.EndHour,
                      timingarr?.[timingarr?.length - 1]?.EndMinute,
                      0,
                      0
                    )
                  ) >= instlastpreptime &&
                  new Date() < instlastpreptime
                ) {
                  return instlastpreptime;
                }
                return false;
              }
              timingindex = timingindex + 1;
              let difftime = data.getTime() - currclosetime.getTime();
              remtime =
                difftime / 1000 > preptimesec ? difftime / 1000 : preptimesec;
              let nextstarttime = new Date(i).setHours(
                timingarr?.[timingindex]?.StartHour,
                timingarr?.[timingindex]?.StartMinute,
                0,
                0
              );
              return CheckTime(
                new Date(
                  new Date(
                    new Date(nextstarttime).setSeconds(remtime)
                  ).setSeconds(0, 0)
                ),
                preptimesec
              );
            }
            return data;
          } else {
            return false;
          }
        };
        daytimecharge.map((val, ind) => {
          let bufferwithTime = {
            Delivery: null,
            Pickup: null,
          };
          bufferwithTime.Delivery = CheckTime(
            new Date(
              new Date(
                new Date(i).setSeconds(
                  Number(val.AfterPreparation) * 3600 + 900
                )
              ).setSeconds(0, 0)
            ),
            Number(val.AfterPreparation) * 3600 + 900,
            alltiming.Delivery
          );
          timingindex = 0;
          bufferwithTime.Pickup = CheckTime(
            new Date(
              new Date(
                new Date(i).setSeconds(
                  Number(val.AfterPreparation) * 3600 + 900
                )
              ).setSeconds(0, 0)
            ),
            Number(val.AfterPreparation) * 3600 + 900,
            alltiming.Pickup
          );
          timingindex = 0;
          console.log("ssfsfsfdfdg", bufferwithTime);

          if (bufferwithTime) {
            // console.log("kjdshgsdkgsd", bufferwithTime);
            let diffseconds = {
              Pickup:
                (bufferwithTime?.Pickup
                  ? bufferwithTime?.Pickup?.getTime()
                  : 0 - new Date()?.getTime()) / 1000,
              Delivery:
                ((bufferwithTime?.Delivery
                  ? bufferwithTime?.Delivery?.getTime()
                  : 0) -
                  new Date()?.getTime()) /
                1000,
            };

            if (false == bufferwithTime) {
              return false;
            }

            let daydisplay =
              new Date().getDay() == SelectedDay?.Day
                ? "Today"
                : new Date().getDay() + 1 == SelectedDay?.Day
                ? "Tomorrow"
                : SelectedDay?.DayName;
            let Display = {
              Pickup: bufferwithTime?.Pickup
                ? (bufferwithTime?.Pickup?.getHours() > 12
                    ? MinuteHourFormat(bufferwithTime?.Pickup?.getHours() - 12)
                    : MinuteHourFormat(bufferwithTime?.Pickup?.getHours())) +
                  ":" +
                  MinuteHourFormat(bufferwithTime?.Pickup?.getMinutes()) +
                  " " +
                  (bufferwithTime?.Pickup?.getHours() >= 12 ? "pm" : "am")
                : false,
              Delivery: bufferwithTime?.Delivery
                ? (bufferwithTime?.Delivery?.getHours() > 12
                    ? MinuteHourFormat(
                        bufferwithTime?.Delivery?.getHours() - 12
                      )
                    : MinuteHourFormat(bufferwithTime?.Delivery?.getHours())) +
                  ":" +
                  MinuteHourFormat(bufferwithTime?.Delivery?.getMinutes()) +
                  " " +
                  (bufferwithTime?.Delivery?.getHours() >= 12 ? "pm" : "am")
                : false,
            };
            // (bufferwithTime.getHours() > 12
            //   ? MinuteHourFormat(bufferwithTime.getHours() - 12)
            //   : MinuteHourFormat(bufferwithTime.getHours())) +
            // ":" +
            // MinuteHourFormat(bufferwithTime.getMinutes()) +
            // " " +
            // (bufferwithTime.getHours() >= 12 ? "pm" : "am");
            if (
              bufferwithTime?.Pickup != false &&
              (PickupArray?.length == 0 ||
                (PickupArray?.length > 0 &&
                  (Number(val?.AfterPreparation) * 3600 + 900 >
                    diffseconds?.Pickup ||
                    !PickupArray.some((val) => val.nospecialcharge == true)) &&
                  PickupArray?.[PickupArray.length - 1].Timing <
                    bufferwithTime?.Pickup))
            ) {
              PickupArray.push({
                display:
                  "Pickup Within " + daydisplay + " Before " + Display?.Pickup,
                charge:
                  Number(val?.AfterPreparation) * 3600 + 900 >
                  diffseconds?.Pickup
                    ? val.ChargeAmount
                    : ShopData?.DeliveryTimeCharge,
                Timing: bufferwithTime?.Pickup,
                nospecialcharge:
                  Number(val?.AfterPreparation) * 3600 + 900 >
                  diffseconds?.Pickup
                    ? false
                    : true,
                currency: ShopData?.CurrencySymbol,
              });
            } else if (
              bufferwithTime?.Pickup != false &&
              PickupArray?.[PickupArray.length - 1].Timing <
                bufferwithTime.Pickup
            ) {
              PickupArray[PickupArray.length - 1].Timing =
                bufferwithTime?.Pickup;
              PickupArray[PickupArray.length - 1].display =
                "Pickup Within " + daydisplay + " Before " + Display?.Pickup;
            }
            if (
              bufferwithTime?.Delivery != false &&
              (DeliveryArray?.length == 0 ||
                (DeliveryArray?.length > 0 &&
                  (Number(val?.AfterPreparation) * 3600 + 900 >
                    diffseconds?.Delivery ||
                    !DeliveryArray.some(
                      (val) => val.nospecialcharge == true
                    )) &&
                  DeliveryArray?.[DeliveryArray.length - 1].Timing <
                    bufferwithTime?.Delivery))
            ) {
              DeliveryArray.push({
                display:
                  "Delivery Within " +
                  daydisplay +
                  " Before " +
                  Display?.Delivery,
                charge:
                  Number(val?.AfterPreparation) * 3600 + 900 >
                  diffseconds?.Delivery
                    ? val.ChargeAmount
                    : ShopData?.DeliveryTimeCharge,
                Timing: bufferwithTime?.Delivery,
                nospecialcharge:
                  Number(val?.AfterPreparation) * 3600 + 900 >
                  diffseconds?.Delivery
                    ? false
                    : true,
                currency: ShopData?.CurrencySymbol,
              });
            } else if (
              bufferwithTime?.Delivery != false &&
              DeliveryArray?.[DeliveryArray.length - 1].Timing <
                bufferwithTime?.Delivery
            ) {
              DeliveryArray[DeliveryArray.length - 1].Timing =
                bufferwithTime?.Delivery;
              DeliveryArray[DeliveryArray.length - 1].display =
                "Delivery Within " +
                daydisplay +
                " Before " +
                Display?.Delivery;
            }
          }
        });
        if (isEmpty(DeliveryArray)) {
          let daydisplay =
            new Date().getDay() == SelectedDay?.Day
              ? "Today"
              : new Date().getDay() + 1 == SelectedDay?.Day
              ? "Tomorrow"
              : SelectedDay?.DayName;
          DeliveryArray[0] = {
            Timing: undefined,
            display: "Not Available " + daydisplay,
            charge: undefined,
          };
        }
        if (isEmpty(PickupArray)) {
          let daydisplay =
            new Date().getDay() == SelectedDay?.Day
              ? "Today"
              : new Date().getDay() + 1 == SelectedDay?.Day
              ? "Tomorrow"
              : SelectedDay?.DayName;
          PickupArray[0] = {
            Timing: undefined,
            display: "Not Available " + daydisplay,
            charge: undefined,
          };
        }

        // console.log(
        //   "klsjljskfsfs",
        //   PickupArray,
        //   DeliveryArray,
        //   selecteddaytime
        // );
        SetTimeCharges({
          ...TimeCharges,
          ...{
            [SelectedDay?.DayName]: {
              Pickup: PickupArray,
              Delivery: DeliveryArray,
            },
          },
        });

        SetSelectedTime(selecteddaytime);
        // console.log("lskjfkskfjs", MinuteHourFormat(selecteddaytime.getDate()));
        let time = `${MinuteHourFormat(
          selecteddaytime.getHours()
        )}:${MinuteHourFormat(selecteddaytime.getMinutes())}`;
        let date = `${MinuteHourFormat(
          selecteddaytime.getFullYear()
        )}-${MinuteHourFormat(
          selecteddaytime.getMonth() + 1
        )}-${MinuteHourFormat(selecteddaytime.getDate())}`;
        let endtime = selecteddaytime.getTime();
        endtime = new Date(
          new Date(endtime).setMonth(new Date(endtime).getMonth() + 2)
        );
        // console.log("dljfkldjsgldsg", selecteddaytime);
        let enddate = `${MinuteHourFormat(
          endtime.getFullYear()
        )}-${MinuteHourFormat(endtime.getMonth() + 1)}-${MinuteHourFormat(
          endtime.getDate()
        )}`;
        SetSelectedTimeDisplay({
          ...SelectedTimeDisplay,
          ...{
            time,
            date,
            initialtime: selecteddaytime,
            startdate: date,
            enddate: enddate,
            starttime: time,
            endtime: Timings?.[Timings.length - 1].EndTime,
            Day: SelectedDay?.DayName,
          },
        });
      } else {
        // console.log("kjkldjfdfs", new Date(i));
        let bufferwithTime = new Date(
          new Date(new Date(i).setSeconds(900)).setSeconds(0, 0)
        );
        let diffseconds =
          (bufferwithTime.getTime() - new Date().getTime()) / 1000;
        // console.log("kjdhjhdjgdsa", i, bufferwithTime);
        if (false == bufferwithTime) {
          return false;
        }

        let daydisplay =
          new Date().getDay() == SelectedDay?.Day
            ? "Today"
            : new Date().getDay() + 1 == SelectedDay?.Day
            ? "Tomorrow"
            : SelectedDay?.DayName;
        let Display =
          (bufferwithTime.getHours() > 12
            ? MinuteHourFormat(bufferwithTime.getHours() - 12)
            : MinuteHourFormat(bufferwithTime.getHours())) +
          ":" +
          MinuteHourFormat(bufferwithTime.getMinutes()) +
          " " +
          (bufferwithTime.getHours() >= 12 ? "pm" : "am");
        PickupArray.push({
          display: "Pickup Within " + daydisplay + " Before " + Display,
          charge: ShopData?.DeliveryTimeCharge,
          Timing: bufferwithTime,
          nospecialcharge: true,
        });
        DeliveryArray.push({
          display: "Delivery Within " + daydisplay + " Before " + Display,
          charge: ShopData?.DeliveryTimeCharge,
          Timing: bufferwithTime,
          nospecialcharge: true,
        });
        SetTimeCharges({
          ...TimeCharges,
          ...{
            [SelectedDay?.DayName]: {
              Pickup: PickupArray,
              Delivery: DeliveryArray,
            },
          },
        });
        SetSelectedTime(selecteddaytime);
        // console.log("lskjfkskfjs", MinuteHourFormat(selecteddaytime.getDate()));
        let time = `${MinuteHourFormat(
          selecteddaytime.getHours()
        )}:${MinuteHourFormat(selecteddaytime.getMinutes())}`;
        let date = `${MinuteHourFormat(
          selecteddaytime.getFullYear()
        )}-${MinuteHourFormat(
          selecteddaytime.getMonth() + 1
        )}-${MinuteHourFormat(selecteddaytime.getDate())}`;
        let endtime = selecteddaytime.getTime();
        endtime = new Date(
          new Date(endtime).setMonth(new Date(endtime).getMonth() + 3)
        );
        // console.log("dljfkldjsgldsg", selecteddaytime);
        let enddate = `${MinuteHourFormat(
          endtime.getFullYear()
        )}-${MinuteHourFormat(endtime.getMonth() + 1)}-${MinuteHourFormat(
          endtime.getDate()
        )}`;
        SetSelectedTimeDisplay({
          ...SelectedTimeDisplay,
          ...{
            time,
            date,
            initialtime: selecteddaytime,
            startdate: date,
            enddate: enddate,
            starttime: time,
            endtime: Timings?.[Timings.length - 1].EndTime,
            Day: SelectedDay?.DayName,
          },
        });
      }
      // console.log("klfodsfdsda", PickupArray);
      // for( i=i; i < ; i ++)
    } else {
      return false;
    }
    // SetTimeCharges()
  };

  const UpdateCart = async (type, ProductId, _id, TotalPrice) => {
    SetUpdateBln(true);
    if (type == "delete") {
      SetLoad(true);
      if (cartdatas?.length == 1 && emptycartconfirm?.confirmstatus == false) {
        SetLoad(false);
        SetConfirmPopup(true);
        SetEmptyCartConfirm({
          ...emptycartconfirm,
          ...{ type, ProductId, _id, confirmstatus: false },
        });
        return true;
      }
    }
    let senddata = {
      action: "update_from_header",
      type: type,
      UserId: payload._id,
      ProductId: ProductId,
      CartId: _id,
      Quantity: 1,
    };
    let Resp = await axiosFile.CartAction(senddata);
    // console.log("dljgksjg", _id, cartdatas, senddata, Resp);
    if (Resp?.success == "success") {
      if (type == "delete") {
        SetLoad(false);
        SetCartDatas([]);
        SetCartDatas(cartdatas.filter((it) => it._id != _id));
        if (cartdatas?.length == 1) {
          return navigate("/");
        }
        SetUpdateCart(updatecart - 1);
      } else {
        SetUpdateCart(type == "add" ? updatecart + 1 : updatecart - 1);
        let ind = cartdatas.findIndex((it) => it._id == _id);
        // console.log("cartdatas[ind]", cartdatas[ind]);
        SetCartDatas([
          ...cartdatas.slice(0, ind),
          {
            ...cartdatas[ind],
            ...{
              Quantity:
                type == "add"
                  ? Number(cartdatas[ind]?.Quantity) + 1
                  : Number(cartdatas[ind]?.Quantity) - 1,
            },
          },
          ...cartdatas.slice(ind + 1, cartdatas.length),
        ]);
      }
      GetCart();
    } else {
      toast.error(Resp?.msg, 1000);
    }
    // SetTotal(cartdatas?.)
    SetUpdateBln(false);
  };

  const CalculateTips = (type, percentage) => {
    let pricecalc = (TotalPrices?.CartValue * percentage) / 100;

    SetTotalPrices({
      ...TotalPrices,
      ...{
        [type]: percentage,
        [`${type}InValue`]: pricecalc.toFixed(2),
        PayAmount: Number(
          (Number(TotalPrices?.CartValue)-
          Number(TotalPrices.Discount))+
          Number(pricecalc)+
          Number(TotalPrices.DeliveryTimeCharges)+
          Number(
            TotalPrices?.[
              `${type === "SellerTips" ? "DriverTips" : "SellerTips"}InValue`
            ]
          )
        ).toFixed(2),
      },
    });
  };

  useEffect(()=>{
    if(TotalPrices?.Type == "Pickup"){
      
      let sellerpricecalc = (TotalPrices?.CartValue * TotalPrices?.SellerTips) / 100;
      let total = (
        (Number(TotalPrices?.CartValue)-
        Number(TotalPrices.Discount)) +
        Number(TotalPrices?.SellerTipsInValue) +
        Number(TotalPrices?.DeliveryTimeCharges)
      ).toFixed(2);
      SetTotalPrices({
        ...TotalPrices,
        ...{
          DriverTipsInValue: 0,
          SellerTipsInValue: (sellerpricecalc).toFixed(2),
          PayAmount: total,
        }
      })
    }
    else{
      let deliverypricecalc = (TotalPrices?.CartValue * TotalPrices?.DriverTips) / 100;
      let sellerpricecalc = (TotalPrices?.CartValue * TotalPrices?.SellerTips) / 100;
      let total = (
        Number(TotalPrices?.CartValue) -
        Number(TotalPrices.Discount) +
        Number(TotalPrices?.DriverTipsInValue) +
        Number(TotalPrices?.SellerTipsInValue) +
        Number(TotalPrices?.DeliveryTimeCharges)
      ).toFixed(2);
      SetTotalPrices({
        ...TotalPrices,
        ...{
          DriverTipsInValue: (deliverypricecalc).toFixed(2),
          SellerTipsInValue: sellerpricecalc.toFixed(2),
          PayAmount: total,
        }
      })
    }
  },[TotalPrices?.Type])

  const CheckOut = async () => {
    if (
      !isEmpty(TotalPrices?.DeliveryTimeCharges) ||
      !isEmpty(TotalPrices?.PickupTimeCharges)
    ) {
      navigate("/paymentselect", {
        state: {
          Products: cartdatas,
          TotalPrices: { ...TotalPrices, ...{ Time: SelectedTime } },
          ShopData: ShopData,
        },
      });
    } else {
      toast.warn(`Select ${TotalPrices?.Type} Time`);
    }
  };

  const AddDeliveryTimeCharge = (price, Timing, type, ind) => {
    let total = (
      Number(TotalPrices?.CartValue) +
      price -
      Number(TotalPrices.Discount) +
      Number(TotalPrices?.DriverTipsInValue) +
      Number(TotalPrices?.SellerTipsInValue)
    ).toFixed(2);

    console.log("lkdjfkdsjklfjksinnnn", total);
    SetTotalPrices({
      ...TotalPrices,
      ...{
        DeliveryTimeCharges: price.toFixed(2),
        PayAmount: total,
        ...(type!='choosetime'?{Type: type}:{}),
      },
    });
    if(type == 'choosetime'){
      SetChoosenTime({...ChoosenTime,...{time:Timing}});
    }
    console.log("lkdjsfkldsf", Timing);
    if (Timing) {
      SetSelectedTimeDisplay({
        ...SelectedTimeDisplay,
        ...{
          ...(type=='choosetime'?{timechooseindex:Number(ind)}:{checkedindex: Number(ind)}),
          date: `${MinuteHourFormat(Timing.getFullYear())}-${MinuteHourFormat(
            Timing.getMonth() + 1
          )}-${MinuteHourFormat(Timing.getDate())}`,
          time: `${MinuteHourFormat(Timing.getHours())}:${MinuteHourFormat(
            Timing.getMinutes()
          )}`,
        },
      });
      SetSelectedTime(Timing);
      SetNextPage(true);
    }
  };

  console.log("ksjkfskfsf", SelectedTimeDisplay, TotalPrices);

  const SelectPrice = (type) => {
    let total = (
      Number(TotalPrices?.CartValue) +
      Number(TotalPrices?.DeliveryTimeCharges) -
      Number(TotalPrices.Discount) +
      Number(TotalPrices?.SellerTipsInValue) +
      Number(TotalPrices?.DriverTipsInValue)
    ).toFixed(2);
    // else if(Tips?.DriverTips !== 0 && TotalPrices?.Type == 'Pickup'){

    // }

    SetTotalPrices({
      ...TotalPrices,
      ...{
        DeliveryTimeCharges: Number(TotalPrices?.DeliveryTimeCharges).toFixed(2),
        PayAmount: total,
        Type: type,
      },
    });
    // if (type == "Pickup") {
    //   handleCloseThirtyPickup();
    // } else {
    //   handleCloseThirtyDelivery();
    // }
  };

  function getNextDate(day) {
    const dateCopy = new Date(new Date().getTime());

    const nextdate = new Date(
      dateCopy.setDate(
        dateCopy.getDate() + ((7 - dateCopy.getDay() + Number(day)) % 7 || 7)
      )
    );

    return nextdate;
  }

  const GetDayName = (date) => {
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let d = date;
    let getday = d.getDay();
    let dayName = days[getday];
    return { getday, dayName };
  };

  const DateValidation = (e) => {
    console.log("lsjfhskljfsf", e);
    const value = e._d;
    console.log("ksdjgjdslgldsg1111", value);
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let sortdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    let choosenday = new Date(value);

    const { getday, dayName } = GetDayName(choosenday);
    let Timings = ShopData?.DayTimings?.[dayName]?.[TotalPrices?.Type]?.timing;

    if (Timings?.length > 0) {
      choosenday = new Date(
        new Date(
          choosenday.setHours(SelectedTimeDisplay?.time.split(":")[0])
        ).setMinutes(SelectedTimeDisplay?.time.split(":")[1])
      );
    }

    console.log(
      "1stifffff",
      choosenday,
      SelectedTimeDisplay?.initialtime,
      choosenday >= SelectedTimeDisplay?.initialtime
    );
    // if(Timings?.length > 0){
    console.log(
      "ksdjgjdslgldsg",
      choosenday,
      value,
      SelectedTimeDisplay?.initialtime,
      new Date(new Date().setMonth(new Date().getMonth() + 3)),
      choosenday >= SelectedTimeDisplay?.initialtime,
      choosenday <= new Date().setMonth(new Date().getMonth() + 3)
    );
    if (
      choosenday >= SelectedTimeDisplay?.initialtime &&
      choosenday <= new Date(new Date().setMonth(new Date().getMonth() + 3))
    ) {
      console.log(
        "2ndddiffff",
        SelectedTimeDisplay,
        choosenday,
        SelectedTimeDisplay.validdays.includes(choosenday.getDay())
      );
      if (SelectedTimeDisplay.validdays.includes(choosenday.getDay())) {
        choosenday = new Date(
          new Date(choosenday.setHours(Timings[0].StartHour)).setMinutes(
            Timings[0].StartMinute
          )
        );
      } else {
        console.log(
          "2nddddelseee",
          SelectedTimeDisplay.validdays.includes(choosenday.getDay()),
          choosenday,
          SelectedTime
        );
        let newtime = null;
        let prevdaydet = GetDayName(SelectedTime);
        let prevdayindex = SelectedTimeDisplay.validdays.indexOf(
          prevdaydet?.getday
        );
        var map = {
          Sunday: 1,
          Monday: 2,
          Tuesday: 3,
          Wednesday: 4,
          Thursday: 5,
          Friday: 6,
          Saturday: 7,
        };
        let mapkeys = Object.keys(map);
        let curdayindex = mapkeys.indexOf(dayName);
        map[dayName] = 1;
        let i = curdayindex >= 6 ? 0 : curdayindex + 1;
        let currordernumber = 1;

        while (i != curdayindex && i <= 8) {
          if (i >= 6) {
            map[mapkeys[i]] = ++currordernumber;
            i = 0;
          } else {
            map[mapkeys[i]] = ++currordernumber;
            i = i + 1;
          }
        }

        sortdays.sort((a, b) => {
          return map[a] - map[b];
        });

        console.log(
          "dshfjkshfssf1111",
          sortdays,
          SelectedTimeDisplay,
          prevdaydet.getday,
          choosenday,
          prevdayindex
        );

        if (choosenday > SelectedTime) {
          // if( SelectedTimeDisplay?.validdays?.length == 1 ){
          console.log(
            "3rdddifffff",
            choosenday,
            sortdays.indexOf(
              days[
                SelectedTimeDisplay?.validdays[
                  prevdayindex + 1 == SelectedTimeDisplay?.validdays?.length
                    ? SelectedTimeDisplay?.validdays?.length - 1
                    : prevdayindex + 1
                ]
              ]
            ),
            SelectedTimeDisplay?.validdays[
              prevdayindex + 1 == SelectedTimeDisplay?.validdays?.length
                ? SelectedTimeDisplay?.validdays?.length - 1
                : prevdayindex + 1
            ],
            prevdayindex,
            SelectedTimeDisplay
          );
          newtime =
            days[
              SelectedTimeDisplay?.validdays[
                prevdayindex + 1 == SelectedTimeDisplay?.validdays?.length
                  ? 0
                  : prevdayindex + 1
              ]
            ];
          Timings =
            ShopData?.DayTimings[newtime]?.[
              TotalPrices?.Type ? TotalPrices?.Type : "Delivery"
            ]?.timing;
          choosenday = new Date(
            new Date(choosenday).setDate(
              new Date(choosenday).getDate() + sortdays.indexOf(newtime)
            )
          );
        } else {
          newtime =
            days[
              SelectedTimeDisplay?.validdays[
                prevdayindex == 0
                  ? SelectedTimeDisplay?.validdays?.length - 1
                  : prevdayindex - 1
              ]
            ];
          Timings =
            ShopData?.DayTimings[newtime]?.[
              TotalPrices?.Type ? TotalPrices?.Type : "Delivery"
            ]?.timing;
          console.log(
            "3rdddifffff22222",
            sortdays,
            choosenday,
            new Date(SelectedTimeDisplay),
            prevdayindex,
            SelectedTimeDisplay,
            sortdays.indexOf(newtime)
          );
          choosenday = new Date(
            new Date(choosenday).setDate(
              new Date(choosenday).getDate() - 7 + sortdays.indexOf(newtime)
            )
          );
        }
        console.log("dshfjkshfssf2222");
        choosenday = new Date(
          new Date(choosenday.setHours(Timings[0].StartHour)).setMinutes(
            Timings[0].StartMinute
          )
        );
      }
      console.log("dshfjkshfssf", choosenday);
      SetSelectedTime(choosenday);
      let setdata = {
        date: `${MinuteHourFormat(choosenday.getFullYear())}-${MinuteHourFormat(
          choosenday.getMonth() + 1
        )}-${MinuteHourFormat(choosenday.getDate())}`,
        time: Timings[0].StartTime,
        starttime: Timings[0].StartTime,
        endtime: Timings[Timings?.length - 1].EndTime,
        Day: dayName,
      };
      TimeBasedPrice(choosenday, setdata,true);
    }
    // }
    console.log("kjdgkgksls", choosenday.getMonth(), value, choosenday);
  };

  const TimeValidation = (e) => {
    const { value } = e.target;
    let timarr = value.split(":");
    let checkselect = SelectedTime.getTime();
    let instselectedtime = new Date(checkselect);
    let choosentime = new Date(
      new Date(new Date(checkselect).setHours(timarr[0])).setMinutes(timarr[1])
    );
    const { getday, dayName } = GetDayName(choosentime);
    let Timings = ShopData?.DayTimings?.[dayName]?.[TotalPrices?.Type]?.timing;
    console.log("lsfldfkdlfd", Timings, instselectedtime);
    if (Timings?.length > 0) {
      Timings.map((val) => {
        console.log(
          "kljslfkslfsend",
          new Date(new Date(checkselect).setHours(val?.EndHour, val?.EndMinute))
        );
        console.log("kljslfkslfschoos", choosentime, new Date(checkselect));
        console.log(
          "kljslfkslfsstart",
          new Date(
            new Date(
              new Date(checkselect).setHours(val.StartHour, val.StartMinute)
            )
          )
        );
        // choosentime < new Date(new Date(instselectedtime.setHours(val.EndHour)).setMinutes(val.EndMinute)) && choosentime > new Date(new Date(instselectedtime.setHours(val.StartHour)).setMinutes(val.StartMinute))
      });

      if (
        SelectedTimeDisplay?.initialtime < choosentime &&
        Timings.some(
          (val) =>
            choosentime <
              // new Date(
              new Date(
                new Date(checkselect).setHours(val.EndHour, val.EndMinute)
              ) &&
            // .setMinutes(
            //   val.EndMinute
            // )
            choosentime >
              new Date(
                new Date(checkselect).setHours(val.StartHour, val.StartMinute)
              )
        )
      ) {
        SetSelectedTime(choosentime);
        let setdata = { time: value };
        console.log("skfhsjfhhskhfks", choosentime);
        TimeBasedPrice(choosentime, setdata,true);
      }
    }
  };

  const TimeBasedPrice = (selectedtime, setdata, setstatus) => {
    const { dayName } = GetDayName(selectedtime);
    let daytimecharge = ShopDayTimeCharges?.[dayName]?.AfterPreparation;
    let diffseconds = (selectedtime.getTime() - new Date().getTime()) / 1000;
    let price = "";
    if (daytimecharge?.length > 0) {
      daytimecharge.map((val, ind) => {
        if (
          Number(val?.AfterPreparation) * 3600 + 900 > diffseconds &&
          price == ""
        ) {
          price = Number(val?.ChargeAmount ? val?.ChargeAmount : 0);
        } else if (price == "" && ind == daytimecharge?.length - 1) {
          price =
            Number(val?.AfterPreparation) * 3600 + 900 > diffseconds
              ? Number(val?.ChargeAmount ? val?.ChargeAmount : 1)
              : Number(
                  ShopData?.DeliveryTimeCharge
                    ? ShopData?.DeliveryTimeCharge
                    : 0
                );
        }
      });
    } else {
      price = Number(
        ShopData?.DeliveryTimeCharge ? ShopData?.DeliveryTimeCharge : 1
      );
    }
    console.log("klsjlsjgs", price);
    let total = (
      Number(TotalPrices?.CartValue) +
      Number(price) -
      Number(TotalPrices.Discount) +
      Number(TotalPrices?.SellerTips) +
      Number(TotalPrices?.DriverTips)
    ).toFixed(2);
    if (TotalPrices?.DriverTips == 0 && TotalPrices?.type == "Delivery") {
      // SetTips({
      //   ...Tips,
      //   ...{
      //     DriverTips: Number(
      //       (TotalPrices?.CartValue * Tips?.DriverTipsPercentage) / 100
      //     ).toFixed(2),
      //   },
      // });
      // total = total + (TotalPrices?.CartValue * 10) / 100;
    }
    console.log("ksdhgkshdgs", total);
    if(setstatus){
      SetTotalPrices({
        ...TotalPrices,
        ...{ DeliveryTimeCharges: price.toFixed(2), PayAmount: total },
      });
      SetSelectedTimeDisplay({ ...SelectedTimeDisplay, ...setdata });
    }
    else{
      return price
    }
  };

  const SelectTiming = () => {
    console.log("kjdfkslfdsga111");
    let error = {};
    if (!SelectedTimeDisplay?.date) {
      error.date = "Enter Date";
    }
    if (!SelectedTimeDisplay?.time) {
      error.time = "Enter Time";
    }
    console.log("kjdfkslfdsga", error);
    SetTimeError(error);
    if (!isEmpty(error)) {
      return false;
    } else {
      SelectPrice(TotalPrices?.Type);
      handleCloseyouPickup();
    }
  };

  // console.log("Dsadhkjsadhksadsa",ShopData,cartdatas)

  useEffect(() => {
    if (emptycartconfirm?.confirmstatus) {
      UpdateCart(
        emptycartconfirm?.type,
        emptycartconfirm?.ProductId,
        emptycartconfirm?._id
      );
    }
  }, [emptycartconfirm?.confirmstatus]);

  const CheckValidDays = (current) => {
    console.log(
      "sljksljkfjsf",
      current,
      SelectedTimeDisplay?.initialtime,
      current?._d,
      current.date(),
      SelectedTimeDisplay?.initialtime.getDate(),
      current.month(),
      SelectedTimeDisplay?.initialtime.getMonth(),
      current.year(),
      SelectedTimeDisplay?.initialtime.getFullYear()
    );
    return (
      SelectedTimeDisplay.validdays?.some(
        (crtday) => crtday == current.day()
      ) &&
      (current?._d >= SelectedTimeDisplay?.initialtime ||
        (current.date() == SelectedTimeDisplay?.initialtime.getDate() &&
          current.month() == SelectedTimeDisplay?.initialtime.getMonth() &&
          current.year() == SelectedTimeDisplay?.initialtime.getFullYear())) &&
      current?._d <= new Date(new Date().setMonth(new Date().getMonth() + 3))
    );
  };

  const GetMinMaxTime = (dayarr) => {
    let min = undefined;
    let max = undefined;
    // dayarr.map
  };

  useEffect(() => {
    // getValidTime()
  }, [ChoosenTime?.day]);

  const MinuteRound = (minute,type) => {
    minute = Number(minute)
    if(minute==0) return (type=='start'?0:-15);
    else if(minute>0&&minute<=15) return (type=='start'?15:0);
    else if(minute>=15&&minute<=30) return (type=='start'?30:15)
    else if(minute>=30&&minute<=45) return (type=='start'?45:30)
    else if(minute>=45) return (type=='start'?60:45);
  }

  const getValidTime = (day) => {
    let { dayName, getday } = GetDayName(day);
    let curchoosentime = day.getTime()
    let alldaytiming = ShopData?.DayTimings;
    let Timings = ShopData?.DayTimings?.[dayName]?.[TotalPrices?.Type]?.timing;
    let selectedtoday = curchoosentime==new Date(new Date().setHours(0,0,0,0)).getTime() ? true : false
    console.log('kshfksjfhsf',ShopData,alldaytiming,day.getTime(),selectedtoday,new Date(new Date().setHours(0,0,0,0)).getTime());
    if(Timings?.length > 0){
      let timearr = [];
      Timings?.map((currtim)=>{
        let currtiming = {
          starttime: new Date(new Date(curchoosentime).setHours(currtim?.StartHour,Number(currtim?.StartMinute)+15)),
          starthour: currtim?.StartHour,
          startminute: currtim?.StartMinute, 
          endtime: new Date(new Date(curchoosentime).setHours(currtim?.EndHour,Number(currtim?.EndMinute)-15)),
          endhour: currtim?.EndHour,
          endminute: currtim?.EndMinute, 
        }
        if(selectedtoday&&currtiming?.starttime?.getTime()<new Date().getTime()){
          if(currtiming?.endtime?.getTime()<new Date().setMinutes(new Date().getMinutes()+15)){
            return false;
          }
          currtiming.starttime = new Date(new Date().setMinutes(new Date().getMinutes()+15));
        }
        currtiming.starttime = new Date(currtiming.starttime.setMinutes(MinuteRound(currtiming.starttime.getMinutes(),'start')))
        currtiming.endtime = new Date(currtiming.endtime.setMinutes(MinuteRound(currtiming.endtime.getMinutes(),'end')))
        currtiming.starthour = currtiming.starttime.getHours()
        currtiming.startminute = currtiming.starttime.getMinutes()
        currtiming.endhour = currtiming.endtime.getHours()
        currtiming.endminute = currtiming.endtime.getMinutes()
        let loop = true;
        console.log('ksjhjskhfsf',currtiming)
        HourMinuteDisplay.Hours.map((hour,hourind)=>{
          HourMinuteDisplay.Minutes.map((mins,minind)=>{
            if(new Date(new Date(curchoosentime).setHours(hour,mins,0,0))>currtiming.starttime&&new Date(new Date(curchoosentime).setHours(hour,mins,0,0))<=currtiming.endtime){
              let nexttime = null;
              if(minind==3){
                if(new Date(new Date(curchoosentime).setHours(HourMinuteDisplay?.Hours?.[hourind+1],HourMinuteDisplay?.Minutes?.[0],0,0))>currtiming.starttime&&new Date(new Date(curchoosentime).setHours(HourMinuteDisplay?.Hours?.[hourind+1],HourMinuteDisplay?.Minutes?.[0],0,0))<=currtiming.endtime){
                  nexttime = {
                    hour: HourMinuteDisplay.Hours[hourind+1],
                    minute: HourMinuteDisplay.Minutes[0],
                  }
                }
                else{
                  return false;
                }
              }
              else{
                if(new Date(new Date(curchoosentime).setHours(hour,HourMinuteDisplay?.Minutes?.[minind+1],0,0))>currtiming.starttime&&new Date(new Date(curchoosentime).setHours(hour,HourMinuteDisplay?.Minutes?.[minind+1],0,0))<=currtiming?.endtime){
                  nexttime = {
                    hour: hour,
                    minute: HourMinuteDisplay.Minutes[minind+1],
                  }
                }
                else{
                  return false;
                }
              }
              timearr.push({
                charge: TimeBasedPrice(new Date(new Date(curchoosentime).setHours(nexttime.hour,nexttime.minute)),false,false),
                display: MinuteHourFormat(hour>12?hour-12:hour)+':'+MinuteHourFormat(mins)+" "+(hour>=12?"PM":"AM")+' - '+MinuteHourFormat(nexttime.hour>12?nexttime.hour-12:nexttime.hour)+':'+MinuteHourFormat(nexttime.minute)+" "+(nexttime.hour>=12?"PM":"AM"),
                Timing: new Date(new Date(curchoosentime).setHours(nexttime.hour,nexttime.minute)),
              })
              return true;
            }
            else{
              return false
            }
          })
        })
        if(timearr?.length == 0){
          timearr.push({
            charge: undefined,
            display: "Not Available for "+TotalPrices?.Type,
            Timing: undefined,
          })
        }
        // new Date(curchoosentime).setHours
        
        console.log('kdlshdlsf',currtiming.endtime.getHours()-currtiming.starttime.getHours(),currtiming.endtime,currtiming.starttime)
      })
      console.log('skjhdsfkjshf',timearr);
      if(timearr?.length>0){
        SetScheduleTime(timearr);
      }
      else{
        SetScheduleTime([
          {
            display: "Not Available for "+TotalPrices?.Type
          }
        ]);
      }
    }
    else{
      SetScheduleTime([
        {
          display: "Not Available for "+TotalPrices?.Type
        }
      ]);
    }
    // ShopData
  }

  useEffect(()=>{
    if(mdShow&&ChoosenTime?.day){
      getValidTime(ChoosenTime?.day)
    }
  },[ChoosenTime?.day,TotalPrices?.Type])

  const ScheduleDone = () => {
    if(ChoosenTime?.day&&ChoosenTime?.time){
      SelectPrice(TotalPrices?.Type)
      setMdShow(false);
      SetDisplayPoPupOption(true)
      SetSelectedTimeDisplay({
        ...SelectedTimeDisplay,
        ...{
          checkedindex: undefined
        }
      })
    }
    else{
      toast.error("Choose Time and Date for "+TotalPrices?.Type)
    }
  }

  const ScheduleClose = (closepopup) => {
    SetSelectedTimeDisplay({
      ...SelectedTimeDisplay,
      ...{
        time:"00:00",
        date: "",
        timechooseindex: undefined,
      }
    });
    SetSelectedTime();
    SetChoosenTime({ time: null, day: null })
    let total = (
      Number(TotalPrices?.CartValue) -
      Number(TotalPrices.Discount) +
      Number(TotalPrices?.SellerTipsInValue) +
      Number(TotalPrices?.DriverTipsInValue)
    ).toFixed(2);

    SetTotalPrices({
      ...TotalPrices,
      ...{
        DeliveryTimeCharges: 0,
        PayAmount: total,
      },
    });
    if(closepopup){
      SetScheduleTime([])
      setMdShow(false);
    }
    
  }

  return (
    <>
      <Header
        Getcart={updatecart == 1 ? false : updatecart}
        CartUpdate={() => GetCart()}
      />
      <Container className="my-5">
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
            <Row className="cco_toprow">
              <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                <p className="cco_tableth">Item</p>
              </Col>
              <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
                <p className="cco_tableth">Description</p>
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                <p className="cco_tableth text-end">Quantity</p>
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                <p className="cco_tableth text-end">Total Price</p>
              </Col>
            </Row>

            <Row className="cco_card_mobpad">
              {!Load && cartdatas?.length > 0 ? (
                cartdatas?.map((val) => {
                  return (
                    <Col xs={12} className="mt-4">
                      <FoodOrderCard
                        TotalPrices={TotalPrices}
                        SetTotalPrices={SetTotalPrices}
                        data={val}
                        UpdateCart={UpdateCart}
                        GetCart={GetCart}
                      />
                    </Col>
                  );
                })
              ) : (
                <p>No Data in Cart</p>
              )}
            </Row>
          </Col>

          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
            {SelectDate?.length > 0 && (
              <>
                <p className="cco_selectdate cco_slctdate_mobtop">
                  Store Timing
                </p>
                <Swiper
                  spaceBetween={15}
                  grabCursor={true}
                  keyboard={{
                    enabled: true,
                  }}
                  autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                  }}
                  modules={[Keyboard, Autoplay, Mousewheel]}
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                    },
                    400: {
                      slidesPerView: 2.5,
                    },
                    500: {
                      slidesPerView: 3.5,
                    },
                    600: {
                      slidesPerView: 3.5,
                    },
                    992: {
                      slidesPerView: 2,
                    },
                  }}
                  className="mySwiper mt-3"
                >
                  {console.log(
                    "kjdshfjdkhskgs",
                    ShopData,
                    SelectDate,
                    SelectedTime,
                    SelectedTimeDisplay,
                    SelectedDay
                  )}
                  {/* {ShopData?.DayTimings?.map((val) => { */}
                  {SelectDate?.map((val) => {
                    return (
                      // <SwiperSlide>
                      <div className="cco_daydate">
                        <p className="cco_day">{val?.day} :</p>
                        <p className="cco_date">{val?.Display} </p>
                      </div>
                      // </SwiperSlide>
                    );
                  })}
                </Swiper>
              </>
            )}

            <p className="cco_selectdate mt-3">Shipping Method</p>

            <Accordion flush className="faq_accordion cco_accordion mt-3">
              {/* {
TotalPrices?.Type != "Delivery" ? */}

              {/* <Accordion.Item eventKey="0">
                <Accordion.Header>You Pickup</Accordion.Header>
                <Accordion.Body>
                  <div className="cco_accordion_subdiv">
                    <Form>
                      <div className="ps_radio cco-radio">
                        {TimeCharges[SelectedDay?.DayName]?.Pickup?.length >
                          0 &&
                          TimeCharges[SelectedDay?.DayName]?.Pickup?.map(
                            (val, ind) => {
                              return val?.Timing ? (
                                <div
                                  className="cco_radiocheck_pad"
                                  onClick={() => {
                                    // handleShowyouPickup();
                                    AddDeliveryTimeCharge(
                                      val?.charge ? Number(val.charge) : 0,
                                      val?.Timing,
                                      "Pickup",
                                      ind
                                    );
                                  }}
                                >
                                  <Form.Check>
                                    {console.log("kdfjkdsjfksd", val)}
                                    <Form.Check.Input
                                      type="radio"
                                      name="deliverytimeprice"
                                      isValid
                                      checked={
                                        ind ==
                                          SelectedTimeDisplay?.checkedindex &&
                                        TotalPrices?.Type == "Pickup"
                                      }
                                    />
                                    <Form.Check.Label className="cco_radiolable_whole">
                                      <p className="cco_accordion_label">
                                        {val.display}
                                      </p>
                                      <p className="cco_accordion_bluetxt text-end">
                                        {val?.currency} {val.charge}{" "}
                                      </p>
                                    </Form.Check.Label>
                                  </Form.Check>
                                </div>
                              ) : (
                                <div className="cco_radiocheck_pad">
                                  <Form.Check>
                                    <Form.Check.Label className="cco_radiolable_whole">
                                      <p className="cco_accordion_label">
                                        {val.display}
                                      </p>
                                    </Form.Check.Label>
                                  </Form.Check>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </Form>
                  </div>
                </Accordion.Body>
              </Accordion.Item> */}
              {/* : */}
              <Button onClick={() => {setMdShow(true);SetDisplayPoPupOption(false);AddDeliveryTimeCharge(0,undefined,'Pickup')}} className="me-2 schd_btn mb-2">
              You Pickup
            </Button>

              <Accordion.Item eventKey="1">
                <Accordion.Header>We Deliver</Accordion.Header>
                <Accordion.Body>
                  <div className="cco_accordion_subdiv"></div>
                  <div className="cco_accordion_subdiv">
                    <Form>
                      <div className="ps_radio cco-radio">
                        {TimeCharges[SelectedDay?.DayName]?.Delivery?.length >
                          0 &&
                          TimeCharges[SelectedDay?.DayName]?.Delivery?.map(
                            (val, ind) => {
                              return (
                                val?.Timing ? <div
                                  className="cco_radiocheck_pad"
                                  onClick={() => {
                                    // handleShowyouPickup();
                                    AddDeliveryTimeCharge(
                                      val?.charge ? Number(val.charge) : 0,
                                      val?.Timing,
                                      "Delivery",
                                      ind
                                    );
                                  }}
                                >
                                  <Form.Check >
                                    <Form.Check.Input
                                      type="radio"
                                      name="deliverytimeprice"
                                      isValid
                                      checked={
                                        ind ==
                                          SelectedTimeDisplay?.checkedindex &&
                                        TotalPrices?.Type == "Delivery"
                                      }
                                    />
                                    <Form.Check.Label className="cco_radiolable_whole">
                                      <p className="cco_accordion_label">
                                        {val.display}
                                      </p>
                                      <p className="cco_accordion_bluetxt text-end">
                                        {val?.currency} {val.charge}
                                      </p>
                                    </Form.Check.Label>
                                  </Form.Check>
                                </div>
                                :
                                <div className="cco_radiocheck_pad">
                                  <Form.Check className="check_hider">
                                    <Form.Check.Label className="cco_radiolable_whole">
                                      <p className="cco_accordion_label">
                                        {val.display}
                                      </p>
                                    </Form.Check.Label>
                                  </Form.Check>
                                </div>
                              );
                            }
                          )}
                        {/* <p className='cco_dangertxt' onClick={handleShowThirtyDelivery}>OR 30 Mins Delivery Window</p> */}
                      </div>
                    </Form>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {/* } */}
            </Accordion>
{console.log('dlsljjfskflskf',TotalPrices)}
            {/* modal */}

            <Button onClick={() => setMdShow(true)} className="me-2 schd_btn ">
              Schedule For Later
            </Button>

            <p className="cco_summary cco_summary_new">Summary</p>
            {console.log("lsljdlskfjsfs", TotalPrices)}
            <Row>
              {TotalPrices?.Type == "Delivery" && (
                <Col
                  className="mt-3"
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <div className="cco_drivertip">
                    <p>Driver Tip</p>

                    <p>
                      ${toLocaleStrings(TotalPrices?.["DriverTipsInValue"])}
                    </p>
                  </div>
                  <ul class="nav cco_tipnav">
                    {[5, 10, 15].map((it) => {
                      return (
                        <li class="nav-item cco_tipnavitem">
                          <a
                            class={
                              tipActive == it ? "nav-link active" : "nav-link"
                            }
                            onClick={() => {
                              setTipActive(it);
                              CalculateTips("DriverTips", it);
                            }}
                            aria-current="page"
                          >
                            <span>{it}%</span>
                          </a>
                        </li>
                      );
                    })}

                    <li class="nav-item cco_tipnavitem ">
                      <a
                        class={
                          tipActive == 4
                            ? "nav-link active"
                            : "nav-link cco_tipbg"
                        }
                        onClick={() => {
                          setTipActive("4");
                          handleShowTips("DriverTips");
                        }}
                      >
                        <span>{"Custom"}</span>
                      </a>
                    </li>
                  </ul>
                  <p className="cco_tippercent">
                    100% of this tip will go to the Driver
                  </p>
                </Col>
              )}

              <Col
                className="mt-3"
                xxl={12}
                xl={12}
                lg={12}
                md={6}
                sm={12}
                xs={12}
              >
                <div className="cco_drivertip">
                  <p>Seller Tip</p>
                  <p>${toLocaleStrings(TotalPrices?.["SellerTipsInValue"])}</p>
                </div>

                <ul class="nav cco_tipnav">
                  {[5, 10, 15].map((it) => {
                    return (
                      <li class="nav-item cco_tipnavitem">
                        <a
                          class={
                            sellerActive == it ? "nav-link active" : "nav-link"
                          }
                          onClick={() => {
                            setSellerActive(it);
                            CalculateTips("SellerTips", it);
                          }}
                          aria-current="page"
                        >
                          <span>{it}%</span>
                        </a>
                      </li>
                    );
                  })}

                  <li class="nav-item cco_tipnavitem ">
                    <a
                      class={
                        sellerActive == "4"
                          ? "nav-link active"
                          : "nav-link cco_tipbg"
                      }
                      onClick={() => {
                        setSellerActive("4");
                        handleShowTips("SellerTips");
                      }}
                    >
                      <span>{"Custom"}</span>
                    </a>
                  </li>
                </ul>
                <p className="cco_tippercent">
                  100% of this tip will go to the Seller
                </p>
              </Col>
            </Row>
            <hr />
            {console.log('lsjfklsjss',SelectedTime,TotalPrices?.CartValue.toFixed(2))}
            {SelectedTime > new Date() &&
              TotalPrices?.Type &&
              ((SelectedTimeDisplay?.checkedindex ||
                SelectedTimeDisplay?.checkedindex == 0)||
                (SelectedTimeDisplay?.timechooseindex ||
                SelectedTimeDisplay?.timechooseindex == 0)
                ) && (
                <div className="cco_summarydtls">
                  <p className="cco_summaryblur">
                    {TotalPrices?.Type} time before
                  </p>
                  <p className="cco_summarybold">
                    {DateTimeForm(SelectedTime, false, false, true)}
                    {/* {moment(SelectedTime).format("DD:MM:yyyy, HH:MM A")} */}
                  </p>
                </div>
              )}
            <div className="cco_summarydtls">
              <p className="cco_summaryblur">
                Cart Value ({TotalPrices?.totalItems} Items)
              </p>
              <p className="cco_summarybold">
                ${TotalPrices?.CartValue.toFixed(2)}
              </p>
            </div>
            <div className="cco_summarydtls">
              <p className="cco_summaryblur">
                {TotalPrices?.Type} time charges
              </p>
              <p className="cco_summarybold">
                ${Number(TotalPrices?.DeliveryTimeCharges).toFixed(2)}
              </p>
            </div>

            <hr />
                  {console.log('dskdklsfsfs',TotalPrices)}
            <div className="cco_summarydtls">
              <p className="cco_summarybold">Payable Amount</p>
              <p className="cco_summarybold">
                ${TotalPrices?.PayAmount}
              </p>
            </div>

            {/* <Link > */}
            <button
              className="blue_btn cco_proceedcheck"
              onClick={() => CheckOut()}
            >
              Proceed To Checkout
            </button>
            {/* </Link> */}
          </Col>
        </Row>
      </Container>

      {/* start of pickup window */}
      <Modal
        show={showyouPickup}
        onHide={handleCloseyouPickup}
        backdrop="static"
        keyboard={false}
        className="cco_ordermodal"
        centered
      >
        <Modal.Header closeButton>
          Set Your {TotalPrices?.Type} Window
        </Modal.Header>
        <Modal.Body className="pb-4 pt-0 px-5">
          {/* <input
            className="cco_dateinp mt-3"
            type="date"
            min={SelectedTimeDisplay.startdate}
            max={SelectedTimeDisplay?.enddate}
            value={SelectedTimeDisplay?.date}
            onChange={(e) => DateValidation(e)}
          /> */}
          <Datetime
            viewMode={"Date"}
            timeFormat={false}
            isValidDate={CheckValidDays}
            value={SelectedTimeDisplay?.date}
            onChange={(e) => DateValidation(e)}
            closeOnSelect={true}
          />
          <br />

          <input
            className="cco_dateinp mt-3"
            type="time"
            min={SelectedTimeDisplay.starttime}
            max={SelectedTimeDisplay?.endtime}
            value={SelectedTimeDisplay?.time}
            onChange={(e) => {
              TimeValidation(e);
            }}
          />

          {/* <Datetime 
            viewMode={'time'}
            dateFormat={false}
            // value={SelectedTimeDisplay?.time}
            onChange={(e) => TimeValidation(e)}
            timeConstraints={
              { 
                hours: { min: 7, max: 18 },
                minutes:{ min: 10,max: 30}
              }
            }
          /> */}

          <div className="cco_modal_packagecharge">
            <p className="cco_modal_packagetxt">Package Charge</p>
            <p className="cco_modal_packagetxt">
              ${TotalPrices?.DeliveryTimeCharges}
            </p>
          </div>
          <button
            className="blue_btn cco_modalsave_btn"
            onClick={(e) => SelectTiming()}
          >
            Set {TotalPrices?.Type}
          </button>
        </Modal.Body>
      </Modal>

      {/* end of pickup window */}

      {/* start of delivery window */}
      <Modal
        show={showTipCustom?.show}
        onHide={handleCloseTips}
        backdrop="static"
        keyboard={false}
        className="cco_ordermodal"
        centered
      >
        <Modal.Header closeButton>Select Tips</Modal.Header>
        <Modal.Body className="pb-4 pt-0 px-5">
          <p className="cco_tipmodal_txt">Enter {showTipCustom.Type} %</p>
          <input
            ref={clearinput}
            type="text"
            maxLength={3}
            className="cco_dateinp mt-3"
            value={TotalPrices?.[`${showTipCustom?.Type}`]}
            onChange={(e) =>
              Number(e?.target?.value?.toString()?.trim()) <= 100
                ? CalculateTips(showTipCustom?.Type, e?.target?.value?.trim())
                : null
            }
          />

          <button
            className="blue_btn cco_modalsave_btn"
            onClick={handleCloseTips}
          >
            SetTip
          </button>
        </Modal.Body>
      </Modal>
      {/* end of delivery window */}

      {/* start of 30min pickup window */}

      <Modal
        show={showthirtyPickup}
        onHide={handleCloseThirtyPickup}
        backdrop="static"
        keyboard={false}
        className="cco_ordermodal"
        centered
      >
        <Modal.Header closeButton>Choose Pick up Window</Modal.Header>
        <Modal.Body className="pb-4 pt-0 px-5">
          <input
            type="date"
            className="cco_dateinp mt-3"
            min={SelectedTimeDisplay.startdate}
            max={SelectedTimeDisplay?.enddate}
            value={SelectedTimeDisplay?.date}
            onChange={(e) => DateValidation(e)}
          />
          {TimeError?.date && (
            <span className="error-msg">{TimeError.date}</span>
          )}
          <br />
          <input
            className="cco_dateinp mt-3"
            type="time"
            min={SelectedTimeDisplay.starttime}
            max={SelectedTimeDisplay?.endtime}
            value={SelectedTimeDisplay?.time}
            onChange={(e) => {
              TimeValidation(e);
            }}
          />
          {TimeError?.time && (
            <span className="error-msg">{TimeError.time}</span>
          )}

          <div className="cco_modal_packagecharge">
            <p className="cco_modal_packagetxt">Package Charge</p>
            <p className="cco_modal_packagetxt">
              ${Number(ShopData?.DeliveryTimeCharge)?.toFixed(2)}
            </p>
          </div>
          <button
            className="blue_btn cco_modalsave_btn"
            onClick={() => SelectTiming()}
          >
            Set Pick up
          </button>
        </Modal.Body>
      </Modal>
      {/* end of 30min pickup window */}

      {/* start of 30min delivery widnow */}

      <Modal
        show={showthirtyDelivery}
        onHide={handleCloseThirtyDelivery}
        backdrop="static"
        keyboard={false}
        className="cco_ordermodal"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="pb-4 pt-0 px-5">
          <h3 className="cco_modal_title">Choose Delivery Window</h3>
          <input
            type="date"
            min="2023-08-19"
            onChange={(e) => console.log("timeeeedate", e.target.value)}
          />
          <br />
          <input
            type="time"
            min="12:30"
            max="18:00"
            onChange={(e) => {
              TimeValidation(e);
              console.log("timeeee", e.target.value);
            }}
          />

          <div className="cco_modal_packagecharge">
            <p className="cco_modal_packagetxt">Packing + Delivery Charge</p>
            <p className="cco_modal_packagetxt">
              ${Number(ShopData?.DeliveryTimeCharge)?.toFixed(2)}
            </p>
          </div>
          <button
            className="blue_btn cco_modalsave_btn"
            onClick={() => SelectPrice("Delivery")}
          >
            Set Delivery
          </button>
        </Modal.Body>
      </Modal>
      {/* Confirm Popup */}
      <Modal
        show={ConfirmPopup}
        onHide={() => SetConfirmPopup(false)}
        backdrop="static"
        keyboard={false}
        className="cco_ordermodal"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="pb-4 pt-0 px-5">
          <h3 className="cco_modal_title">Confirm to Empty Cart</h3>

          <button
            className="blue_btn cco_modalsave_btn"
            onClick={() => {
              SetEmptyCartConfirm({
                ...emptycartconfirm,
                ...{ confirmstatus: true },
              });
              SetConfirmPopup(false);
            }}
          >
            Confirm
          </button>
          <button
            className="blue_btn cco_modalsave_btn"
            onClick={() => SetConfirmPopup(false)}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
      {/* Confirm Popup */}

      {/* schedule popup */}
      {console.log("khfdjkfhkdf")}
      <Modal
        size="md"
        show={mdShow}
        onHide={()=>{ScheduleClose(true);SetDisplayPoPupOption(true)}}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <p className="mb-0 modal_tlt"> When do you want your {dispalyPopupOption ? "delivery/pickup":"pickup"}?</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <div className="slt_dat pb-3 d-none">
              <p className="slt_date_tle">Select Date</p>
              <div className="week_days d-flex align-items-center pb-2">
                <div className="date_choose d-flex flex-column align-items-center active">
                  <p className="mb-0">Today</p>
                  <p className="mb-0">1</p>
                </div>
                <div className="date_choose d-flex flex-column align-items-center">
                  <p className="mb-0">Sun</p>
                  <p className="mb-0">2</p>
                </div>
                <div className="date_choose d-flex flex-column align-items-center">
                  <p className="mb-0">Mon</p>
                  <p className="mb-0">3</p>
                </div>
                <div className="date_choose d-flex flex-column align-items-center">
                  <p className="mb-0">Tue</p>
                  <p className="mb-0">4</p>
                </div>
                <div className="date_choose d-flex flex-column align-items-center">
                  <p className="mb-0">Thrs</p>
                  <p className="mb-0">5</p>
                </div>
                <div className="date_choose d-flex flex-column align-items-center">
                  <p className="mb-0">Fri</p>
                  <p className="mb-0">6</p>
                </div>
                <div className="date_choose d-flex flex-column align-items-center">
                  <p className="mb-0">Sat</p>
                  <p className="mb-0">7</p>
                </div>
              </div>
            </div> */}

<div className="checkprice_holder">
            {dispalyPopupOption ?
            <div className="d-flex align-items-center">
            <Form.Check>
              <Form.Check.Input
                type="radio"
                name="deliverytimeprice"
                isValid

                checked={
                  TotalPrices?.Type == "Pickup"
                } 
                onChange={()=>{console.log('shfkhsfsfs',);AddDeliveryTimeCharge(0,undefined,'Pickup')}}

              />
              <Form.Check.Label className="cco_radiolable_whole">
                <p className="cco_accordion_label">Pickup</p>
              </Form.Check.Label>
            </Form.Check>
            <Form.Check  className="ms-2">
              <Form.Check.Input
                type="radio"
                name="deliverytimeprice"
                isValid

                checked={
                  TotalPrices?.Type == "Delivery"
                }
                onChange={()=>AddDeliveryTimeCharge(0,undefined,'Delivery')}
                // onClick={()=>console.log('shfkhsfsfs111',)}

              />
              <Form.Check.Label className="cco_radiolable_whole">
                <p className="cco_accordion_label">Delivery</p>
              </Form.Check.Label>
            </Form.Check>
            </div> :
            <>
            <p className="none_textt">none</p>
            </>
            }
            <p className="prizeee">$ {Number(TotalPrices?.DeliveryTimeCharges).toFixed(2)}</p>
            </div>

            <div className="horizontal_date_selector">
              <DateChoose
                Validdays={SelectedTimeDisplay.validdays}
                SetChoosenTime={SetChoosenTime}
                ChoosenTime={ChoosenTime}
                GetTime={(e) => getValidTime(e)}
                clearTime={()=>ScheduleClose()}
              />
            </div>
            <div className="slt_dat pb-3 pt-3 ">
              <div className="d-flex align-items-center justify-content-between">
                <p className="slt_date_tle txt_gry">ASAP</p>
                <p className="slt_date_tle txt_blue">Schedule Time for Later</p>
              </div>
              {console.log('msfmshfshfkd',)}
              {/* <div className="week_days pb-2">
                {scheduleTime.map((value) => {
                  return (
                    <div className="row">
                      <div className="col-3">
                        <div className="date_choose d-flex flex-column align-items-center active">
                          <p className="mb-0">{value.time}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> */}

              <div className="time_sch">
                <div className="row mx-0 ">
                  {console.log('sdjglsj',ScheduleTime)}
                  {ScheduleTime.map((value,ind) => {
                    console.log('skfhkjshfksf',value)
                    return (
                      <>
                      {
                        value?.Timing ? 
                          <div className="col-6 col-sm-3 d-flex mb-3 flex-column">
                            <div
                              className={ SelectedTimeDisplay?.timechooseindex == ind ? "tme_choose d-flex flex-wrap active" : "tme_choose d-flex flex-wrap" }
                              onClick={()=>AddDeliveryTimeCharge(value?.charge?value?.charge:1,value?.Timing,'choosetime',ind)}
                            >
                              {value.display}
                            </div>
                          </div>
                        :
                        <div className="flex-column">
                          {value.display}
                      </div>
                      }
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <div className="pt-3 d-flex justify-content-center">
              <button className="blue_btn done_btn" onClick={()=>ScheduleClose()}>Clear</button>
            </div> */}
            <div className="pt-3 d-flex justify-content-center">
              <button className="blue_btn done_btn" onClick={()=>ScheduleDone()}>Done</button>
            </div>
            
          </div>
        </Modal.Body>
      </Modal>
      {/* schedule popup */}

      <Footer />
    </>
  );
}

export default CartCheckout;
