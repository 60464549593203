import * as CryptoJs from "crypto-js";
import Config from "../actions/config";
import { toast } from "react-toastify";
export const isEmpty = (value) =>
  value === undefined ||
  value == "undefined" ||
  value === null ||
  value == false ||
  value == "false" ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0) ||
  (typeof value === "string" && value === "0") ||
  (typeof value === "number" && value === 0);

// Address cut function like 0x123...345
export const address_showing = (item) => {
  if (item && item.toString().length > 30) {
    var slice_front = item.slice(0, 8);
    var slice_end = item.slice(item.length - 6, item.length - 1);
    return slice_front + "..." + slice_end;
  } else return item;
};

// Copy Text
export const copydata = (data) => {
  var copyText = data;
  navigator?.clipboard?.writeText(copyText);
  //toast.success("Copied Successfully")
};
export const EncryptData = (data) => {
  if (typeof data == "string") {
    return CryptoJs.AES.encrypt(data, Config.KEY).toString();
  } else {
    return CryptoJs.AES.encrypt(JSON.stringify(data), Config.KEY).toString();
  }
};

export const DecryptData = (data) => {
  try {
    var bytes = CryptoJs.AES.decrypt(data, Config.KEY);

    var decryptedData;
    try {
      decryptedData = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
    } catch (err) {
      try {
        decryptedData = bytes.toString(CryptoJs.enc.Utf8);
      } catch (dcpterr) {
        return undefined;
      }
    }
    return decryptedData;
  } catch (error) {
    return undefined;
  }
};

export const NumANdDotOnly = (data) => {
  var data = data.toString();
  var str = data
    ? data.includes(".")
      ? data.split(".").length >= 3
        ? (data.split(".")[0] + "." + data.split(".")[1]).toString()
        : data
      : data
    : data;
  return str.toString().replace(Config.NumDigitOnly, "");
};
export const NumberOnly = (data) => {
  return data.toString().replace(Config.NumberOnly, "");
};

// return two digit number for single digit

export const MinuteHourFormat = (data) => {
  data = Number(isEmpty(data) ? 0 : data);
  return (data < 10 ? "0" : "") + data;
};

export const TimeFormat = (time) =>
  `${MinuteHourFormat(new Date(time).getDate())}/${MinuteHourFormat(
    new Date(time).getMonth() + 1
  )}/${new Date(time).getFullYear()}  ${MinuteHourFormat(
    new Date(time).getHours()
  )} : ${MinuteHourFormat(new Date(time).getMinutes())}`;

export const LoadToast = (data) => {
  return toast.loading(data ?? "Loading.....");
};
export const updateToast = (id, data, type) => {
  try {
    if (id) {
      if (data == "fix") {
        data = "Fix All Validation";
        type = "error";
      }
      if (type == "error") type = toast.TYPE.ERROR;
      if (type == "success") type = toast.TYPE.SUCCESS;
      if (type == "info") type = toast.TYPE.INFO;
      console.log("===========", type, data, id);
      return toast.update(id, {
        render: data,
        type: type,
        isLoading: false,
        autoClose: 1000,
      });
      // console.log("===========", type, data, id)
      // return toast.update(id, { render: data, type: type, autoClose: 1000 })
    } else {
      // console.log('ksjgldkfjgkldf',type,data)
      return toast[type](data, { autoClose: 1000 });
    }
  } catch (e) {
    console.error("tpast update", e);
    return false;
  }
};

export const toLocaleStrings = (data) => Number(data).toLocaleString();

export const DateTimeForm = (date, datealone, timealone, ampm) => {
  try {
    if (datealone) {
      return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(
        new Date(date)?.getMonth() + 1
      )}/${MinuteHourFormat(new Date(date)?.getFullYear())}`;
    } else if (timealone) {
      if (ampm) {
        return `${MinuteHourFormat(
          new Date(date)?.getHours() > 12
            ? new Date(date)?.getHours() - 12
            : new Date(date)?.getHours()
        )}:${MinuteHourFormat(new Date(date)?.getMinutes())} ${
          new Date(date)?.getHours() >= 12 ? "PM" : "AM"
        }`;
      } else {
        return `${MinuteHourFormat(
          new Date(date)?.getHours()
        )}:${MinuteHourFormat(new Date(date)?.getMinutes())} `;
      }
    } else if (ampm) {
      return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(
        new Date(date)?.getMonth() + 1
      )}/${MinuteHourFormat(new Date(date)?.getFullYear())}, ${MinuteHourFormat(
        new Date(date)?.getHours() > 12
          ? new Date(date)?.getHours() - 12
          : new Date(date)?.getHours()
      )}:${MinuteHourFormat(new Date(date)?.getMinutes())} ${
        new Date(date)?.getHours() >= 12 ? "PM" : "AM"
      } `;
    }
    return `${MinuteHourFormat(new Date(date)?.getDate())}:${MinuteHourFormat(
      new Date(date)?.getMonth() + 1
    )}:${new Date(date)?.getFullYear()},${MinuteHourFormat(
      new Date(date)?.getHours()
    )}:${MinuteHourFormat(new Date(date)?.getMinutes())} `;
  } catch (err) {
    console.log("dskhfskfs", err);
    return "No Date";
  }
};

export const SplitString = (data, splitkey, dataalone) => {
  if (dataalone) {
    let crtdata = [];
    data?.split(splitkey).map((val) => {
      if (!isEmpty(val)) crtdata.push(crtdata);
    });
  }
  return data?.split(splitkey);
};

export const Number_Decimal = (data, decimal) => {
  try {
    return (isEmpty(data) ? 0 : Number(data) == NaN ? 0 : Number(data)).toFixed(
      decimal ? decimal : 2
    );
  } catch (err) {
    return "0.00";
  }
};

export function calcDistance(startingCoords, destinationCoords, type) {
  try {
    let startingLat = degreesToRadians(startingCoords?.[0]);
    let startingLong = degreesToRadians(startingCoords?.[1]);
    let destinationLat = degreesToRadians(destinationCoords?.[0]);
    let destinationLong = degreesToRadians(destinationCoords?.[1]);

    console.log(
      "startingLat",
      startingLat,
      startingLong,
      destinationLat,
      destinationLong
    );
    // Radius of the Earth in kilometers
    let radius = 6571;
    // Haversine equation
    let distanceInKilometers =
      Math.acos(
        Math.sin(startingLat) * Math.sin(destinationLat) +
          Math.cos(startingLat) *
            Math.cos(destinationLat) *
            Math.cos(startingLong - destinationLong)
      ) * radius;

    if (type == "miles") {
      return (distanceInKilometers * 0.621371).toFixed(1);
    } else {
      return distanceInKilometers.toFixed(1);
    }
  } catch (err) {
    console.log("calcDistance error", err);
    return undefined;
  }
}

function degreesToRadians(degrees) {
  var radians = (degrees * Math.PI) / 180;
  return radians;
}
