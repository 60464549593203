import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Col,
  Container,
  Row,
  Nav,
  Tab,
  Form,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import Footer from "../Components/Footer";
import Header from "../Components/Header";
import OrdersCard from "../Components/OrdersCard";
import Select from "react-select";
import WishlistCard from "../Components/WishlistCard";
import useAxiosFile from "../actions/useaxios";
import { useDispatch, useSelector } from "react-redux";
import {
  isEmpty,
  LoadToast,
  SplitString,
  updateToast,
} from "../actions/common";
import config from "../actions/config";
import { Data_Section, LogOut } from "../redux/constants";
import CancelOrder from "../Components/CancelOrderModal";
import {
  GetAddressBasedOnLatLng,
  GetLatLngBasedOnAddress,
} from "../Components/Map/geocode";
import GMap from "../Components/Map/GMap";

// svg imports

import addressIcon from "../assets/svg/address.svg";
import orderIcon from "../assets/svg/order.svg";
import logoutIcon from "../assets/svg/logout.svg";
import profileIcon from "../assets/svg/profile.svg";
import wishlistIcon from "../assets/svg/wishlist.svg";

const optionsGender = [
  { value: "male", label: "male" },
  { value: "female", label: "Female" },
  { value: "others", label: "Others" },
];

const stylesgraybg = {
  option: (styles, { isFocused, isSelected, isHovered }) => ({
    ...styles,
    color: "#6C6A81",
    background: isFocused
      ? "#F5F6F7"
      : isSelected
      ? "#F5F6F7"
      : isHovered
      ? "red"
      : "#F5F6F7",

    zIndex: 1,
    cursor: "pointer",
    fontSize: "13px",
  }),

  option: (styles, { isFocused, isSelected, isHovered }) => {
    // const color = chroma(data.color);

    return {
      ...styles,
      backgroundColor: isHovered
        ? "#A71D24"
        : isSelected
        ? "#A71D24"
        : isFocused
        ? "#A71D24"
        : "",
      cursor: "pointer",
      color: isHovered ? "#fff" : isSelected ? "#fff" : isFocused ? "#fff" : "",
      fontSize: "13px",
    };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    height: "47px",
    padding: "0 20px",
    backgroundColor: "#fff ",
    border: "1px solid rgba(34, 34, 34, 0.3)",
    borderRadius: 30,
    fontSize: "13px",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "47px",
    borderRadius: 10,
    backgroundColor: "#fff",
    border: "none",
    outline: "none",
    boxShadow: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "47px",
    position: "absolute",
    right: 0,
    top: 0,
    color: "#6C6A81",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#6C6A81",
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
};

const stylesprofileinp = {
  option: (styles, { isFocused, isSelected, isHovered }) => ({
    ...styles,
    color: "#6C6A81",
    background: isFocused
      ? "#F5F6F7"
      : isSelected
      ? "#F5F6F7"
      : isHovered
      ? "red"
      : "#F5F6F7",

    zIndex: 1,
    cursor: "pointer",
    fontSize: "13px",
  }),

  option: (styles, { isFocused, isSelected, isHovered }) => {
    // const color = chroma(data.color);

    return {
      ...styles,
      backgroundColor: isHovered
        ? "#A71D24"
        : isSelected
        ? "#A71D24"
        : isFocused
        ? "#A71D24"
        : "",
      cursor: "pointer",
      color: isHovered ? "#fff" : isSelected ? "#fff" : isFocused ? "#fff" : "",
      fontSize: "13px",
    };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    height: "43px",
    padding: "0 20px",
    backgroundColor: "#fff ",
    borderBottom: "1px solid rgba(34, 34, 34, 0.32)",
    // borderRadius: 30,
    // marginTop:"10px",
    fontSize: "13px",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "43px",
    borderRadius: 30,
    backgroundColor: "#fff",
    border: "none",
    outline: "none",
    boxShadow: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "43px",
    position: "absolute",
    right: 0,
    top: 0,
    color: "#6C6A81",
    marginTop: "10px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#6C6A81",
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
};
function OrderDetailsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const { token, payload } = useSelector((state) => state.LoginReducer.User);
  const { state, pathname } = useLocation();
  const dispatch = useDispatch();

  const axiosFile = useAxiosFile();

  const [tabname, SetTabname] = useState(pathname.split("/")[2]);
  const [profiledetail, SetProfildetail] = useState({});
  const [filter, SetFilter] = useState("all");
  const [arrfilter, SetArrFilter] = useState([]);
  const [Addresstype, SetAddresstype] = useState("");
  const [showMobMenu, setShowMobMenu] = useState(false);
  const [cancelorderpopup, SetCancelOrderPopup] = useState(false);
  const [currentorder, SetCurrentOrder] = useState({});
  var [headerupdate, SetHeaderUpdate] = useState(0);
  console.log("tabname", tabname);
  const handleShowMobMenu = () => setShowMobMenu(true);
  const handleCloseMobMenu = () => setShowMobMenu(false);

  const changePositiion = ()=>
  {
    var addressInputElement =document.getElementById("parent_comple");

setTimeout(() => {
  var pacContainer =document.querySelectorAll('.pac-container');
  for(var i=0;i<pacContainer.length;i++)
  {
    addressInputElement.appendChild(pacContainer[i]);

  }
  console.log(addressInputElement,pacContainer,"e;e");
  // addressInputElement.parentElement.append(pacContainer);
}, 100);


  }

  const initform = {
    FirstName: "",
    LastName: "",
    EmailId: "",
    Mobile: "",
    DateofBirth: "",
    Gender: "",
  };
  const initname = {
    FirstName: "First Name",
    LastName: "Last Name",
    EmailId: "Email-Id",
    Mobile: "Mobile Number",
    DateofBirth: "Date of Birth",
    Gender: "Gender",
  };
  const initAddress = {
    FullName: "",
    EmailId: "",
    Mobile: "",
    type: "",
    Street: "",
    Street2: "",
    Area: "",
    City: "",
    Zipcode: "",
    defaultAddress: false,
    FullAddress: "",
  };

  const AddressName = {
    FullName: "Full Name",
    EmailId: "Emai-Id",
    Mobile: "Mobile Number",
    type: "Address Type",
    FullAddress: "Full Address"
    // Street: "Address ",
    // Area: "Country",
    // City: "City",
    // Zipcode: "Zipcode",
  };

  const [formdata, SetFormdata] = useState(initform);
  const [addressform, SetAddressForm] = useState(initAddress);
  const [newemail, SetNewemail] = useState("");
  const [GenderType, SetGenderType] = useState("Gender");

  const [Error, SetError] = useState({});
  console.log("errrrrrr", Error, addressform);

  const [show_map, setShow_map] = useState(false);
  const [Address, SetAddress] = useState({});
  const [addressselectstatus,SetAddressSelectStatus] = useState(true)

  console.log("pathname", pathname);

  useEffect(() => {
    if (isEmpty(profiledetail[tabname])) {
      GetTabdatas(1);
    }
    if (tabname == "address") {
      SetProfildetail({
        ...profiledetail,
        ...{
          [tabname]: {
            loader: false,
            page: 1,
            list: payload?.DeliveryAddress,
          },
        },
      });
    }
    if (tabname == "profile") {
      SetFormdata({
        ...formdata,
        ...{
          FirstName: payload?.FirstName,
          LastName: payload?.LastName,
          EmailId: payload?.EmailId,
          Mobile: payload?.Mobile,
          DateofBirth: payload?.DateofBirth,
          Gender: payload?.Gender,
        },
      });
      SetNewemail(payload?.EmailId);
    }
    if(isEmpty(addressform?.FullName) && !isEmpty(payload?.FirstName)){
      SetAddressForm({
        ...addressform,
        ...{
          FullName: payload?.FirstName,
          EmailId: payload?.EmailId,
          Mobile: payload?.Mobile,
        }
      });
    }
  }, [tabname]);

  useEffect(() => {
    var paths = pathname.split("/")[2];
    console.log(
      " ",
      paths,
      payload,
      [
        "order",
        "address",
        "payment",
        "wishlist",
        "profile",
        "editaddress",
        "addaddress",
      ].includes(paths)
    );
    if (
      [
        "order",
        "address",
        "payment",
        "wishlist",
        "profile",
        "addaddress",
        "editaddress",
      ].includes(paths) &&
      payload
    ) {
      console.log("ldkskjlksjfs", paths?.includes("address"));
      SetTabname(paths?.includes("address") ? "address" : paths);
      if (paths?.includes("editaddress")) {
        EditClick(state?.editaddress);
      } else if (paths?.includes("addaddress")) {
        SetAddresstype("add");
      } else if (paths == "address") {
        SetAddresstype("");
      }
      console.log("dascdgkjmnsabhg", paths);
    }
  }, [pathname, payload]);

  console.log("payload>>>", payload);

  const Logouts = () => {
    const id = LoadToast();
    console.log("dsahdkasdkasjahsdk");
    let text = "Do You Want to Log-Out?";
    if (window.confirm(text)) {
      updateToast(id, `Bye ${payload?.FirstName}`, "success");
      localStorage.removeItem("_id");
      localStorage.removeItem("Address");
      document.cookie =
        "token" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      dispatch({
        type: LogOut,
      });
    } else {
      updateToast(id, `Cancelled`, "success");
      navigate("/detail/order");
      SetTabname("order");
    }
  };

  console.log("state?.from", payload);
  const WishlistFilter = async (e) => {
    const { value, checked } = e.target;
    console.log("kjdgkjsg", value, checked);
    let array = arrfilter;
    let outputarr = [];
    if (checked) {
      outputarr = [...array, ...[value]];
    } else {
      array.map((val) => {
        if (value == val) {
        } else {
          outputarr.push(val);
        }
      });
    }
    console.log("djkjgdgs", outputarr);
    let sendfilter =
      outputarr?.length == 0 || outputarr?.length == 2 ? "all" : outputarr[0];
    let Resp = await axiosFile.GetMyWishList({
      UserId: payload?._id,
      Type: sendfilter,
      page: 1,
      limit: 5,
    });
    console.log("jsjfsksfjkf", Resp, sendfilter);
    if (Resp?.data?.length > 0) {
      SetProfildetail({
        ...profiledetail,
        ...{
          [tabname]: {
            loader: true,
            page: 1,
            list: Resp.data,
            count: Resp.count,
          },
        },
      });
    } else {
      SetProfildetail({
        ...profiledetail,
        ...{
          [tabname]: {
            loader: false,
            page: 1,
            list: [],
            count: Resp.count ? Resp.count : 0,
          },
        },
      });
    }
    SetFilter(sendfilter);
    SetArrFilter(outputarr);
  };

  const GetTabdatas = async (page) => {
    console.log("llskfslfs", payload);
    page = page
      ? page
      : profiledetail[tabname]?.page
      ? profiledetail[tabname]?.page
      : 1;

    if (tabname == "order") {
      let Resp = await axiosFile.GetOrders({
        UserId: payload?._id,
        page: page,
        limit: 6,
        Type: "buyer",
      });
      console.log("dlkdgsdgs", Resp);
      if (Resp?.data?.length > 0) {
        SetProfildetail({
          ...profiledetail,
          ...{
            [tabname]: {
              loader: true,
              page: page,
              list: profiledetail[tabname]?.list
                ? page == 1
                  ? Resp.data
                  : [...profiledetail[tabname]?.list, ...Resp.data]
                : Resp.data,
              count: Resp.count,
            },
          },
        });
      } else {
      }
      console.log("ldkflsdfsd", Resp);
    } else if (tabname == "wishlist") {
      let Resp = await axiosFile.GetMyWishList({
        UserId: payload?._id,
        Type: filter,
        page: page,
        limit: 5,
      });
      console.log("ksfksfs", Resp);
      if (Resp?.data?.length > 0) {
        SetProfildetail({
          ...profiledetail,
          ...{
            [tabname]: {
              loader: true,
              page: page,
              list:
                page == 1
                  ? Resp.data
                  : profiledetail[tabname]?.list
                  ? [...profiledetail[tabname]?.list, ...Resp.data]
                  : Resp.data,
              count: Resp?.count,
            },
          },
        });
      }
    }
  };

  const Validate = (type) => {
    let error = {};
    if (type == "address") {
      Object.keys(addressform).map((val) => {
        console.log("dkdskgjdsk",isEmpty(addressform[val]) && !isEmpty(AddressName[val]),val);
        if (val != "FullName" && val != "EmailId" && val != "Mobile") {
          if (isEmpty(addressform[val]) && !isEmpty(AddressName[val])) {
            error[val] = AddressName[val] + " is Required";
          }
          else {
            if (val == "EmailId") {
              if (!config.EMAIL.test(addressform[val])) {
                error[val] = "Enter Valid EmailId";
              }
            }
          }
        }
      });
    } 
    else {
      Object.keys(formdata).map((val) => {
        if (val != "EmailId") {
          if (isEmpty(formdata[val])) {
            error[val] = initname[val] + " is Required";
          } else {
            if (val == "Mobile") {
              console.log(
                "kjskfjhsjklhslfs",
                isValidPhoneNumber(formdata[val])
              );
              if (!isValidPhoneNumber(formdata[val])) {
                error[val] = "Enter Valid Mobile Number";
              }
              // if (config.NumOnly.test(formdata[val])) {
              //   error[val] = "Mobile Number Must be a Number";
              // }
            }
            if (val == "Gender") {
              if (formdata[val] == "others") {
                error[val] = initname[val] + " is Required";
              }
            }
            // if (val == "EmailId") {
            //   if (!config.EMAIL.test(formdata[val])) {
            //     error[val] = "Enter Valid EmailId";
            //   }
            // }
          }
        }
      });
    }

    return error;
  };
  console.log("dsahdjhsadjkashdasdas", payload);

  const OnChange = useCallback(
    (e, ids, data) => {
      SetError({});
      if (data && ids) {
        SetAddressForm({ ...addressform, ...{ [ids]: e } });
      }
      if (ids) {
        SetFormdata({ ...formdata, ...{ [ids]: e } });
      } else {
        const { id, value, name } = e.target;
        console.log("namename", name, value, id, e.target);
        if (name == "address") {
          if (!isEmpty(Error[id])) {
            console.log("revert error", Error[id], id);
            SetError({ ...Error, ...{ [id]: "" } });
          }
          SetAddressForm({ ...addressform, ...{ [id]: value } });
        } else {
          if (!isEmpty(Error[id])) {
            console.log("revert error", Error[id], id);
            SetError({ ...Error, ...{ [id]: "" } });
          }
          SetFormdata({ ...formdata, ...{ [id]: value } });
        }
      }
    },
    [addressform, formdata]
  );

  const SelectAddress = useCallback(
    (place, Type) => {
      console.log('kdklajdjajjdalfada',place,'dfdgfdfdh',place?.geometry?.location?.lat)
      console.log(
        "dsjadkasjdasdasdasd 1",
        addressform,
        "op",
        place.address_components,
        place,
        Type,
      );
      SetAddressForm({
        ...addressform,
        ...((place?.geometry?.location)
          ? { latlng: { type: "Point", coordinates: [place?.geometry?.location?.lat(),place?.geometry?.location?.lng()] } }
          : { latlng: { type: "Point", coordinates: [0, 0] } }),
        ...{FullAddress:place?.formatted_address}
      });
      // if (place?.address_components?.length > 0) {
      //   var add = {},
      //     stret = {};
      //   var data = place.address_components.map((it) => {
      //     console.log("add>>>>>>>>>>>>>", it);
      //     if (it.types.includes("street_number")) {
      //       stret.StreetNumber = it.long_name;
      //     }
      //     if (it.types.includes("route")) {
      //       stret.route = it.long_name;
      //     }
      //     if (it.types.includes("locality")) {
      //       stret.locality = it.long_name;
      //     }
      //     if (it.types.includes("administrative_area_level_2")) {
      //       add.City = it.long_name;
      //     }
      //     if (it.types.includes("administrative_area_level_3")) {
      //       add.City = it.long_name;
      //     }
      //     if (it.types.includes("administrative_area_level_1")) {
      //       add.State = it.long_name;
      //     }
      //     if (it.types.includes("country")) {
      //       add.Country = it.long_name;
      //     }
      //     if (it.types.includes("postal_code")) {
      //       add.Zipcode = it.long_name;
      //     }
      //     add.Street = `${stret?.StreetNumber ?? ""} , ${stret?.route ?? ""}, ${
      //       stret?.locality ?? ""
      //     }`;

      //     return add;
      //   });

      //   console.log("addressformaddressform", addressform, add, addressform);
      //   SetAddressForm({
      //     ...addressform,
      //     ...add,
      //   });
      // }
    },
    [addressform]
  );
  console.log("addressformaddressform 1",addressform)

  const SelectChange = (e, name, from) => {
    console.log("fhldfhdfjd", e);
    if (from == "address") {
      if (!isEmpty(Error[name])) {
        console.log("revert error", Error[name], e.value);
        SetError({ ...Error, ...{ [name]: "" } });
      }
      SetAddressForm({ ...addressform, ...{ [name]: e.value } });
    } else {
      if (!isEmpty(Error[name])) {
        SetError({ ...Error, ...{ [name]: "" } });
      }
      if (name == "Gender") {
        if (e.value == "others") {
          SetGenderType("input");
          SetFormdata({ ...formdata, ...{ [name]: e.value } });
        } else {
          SetFormdata({ ...formdata, ...{ [name]: e.value } });
        }
      } else {
        SetFormdata({ ...formdata, ...{ [name]: e.value } });
      }
    }
  };

  const SelectDown = (e, name) => {
    console.log("lfsjfsfs", e);
    if (e) {
      SetAddressForm({ ...addressform, ...{ [name]: e } });
    }
  };

  console.log("kjsjkgsgs", profiledetail, addressform);

  const SaveProfile = async () => {
    let error = Validate();
    console.log("errorerror", formdata, error, isEmpty(error));
    if (isEmpty(error)) {
      let senddata = formdata;
      senddata.Type = "profile";
      senddata.from = "buyer";
      var resp = await axiosFile.LoginReg(senddata);
      console.log("resp>>>>", resp);
      toast.success("Updated Successfully", 1000);
    } else {
      SetError(error);
      toast.error("Error Occured", 1000);
    }
  };

  const AddressSubmit = async () => {
    let error = Validate("address");
    console.log("errorerror", error, isEmpty(error));
    if (isEmpty(error)) {
      let testlan = await GetLatLngBasedOnAddress(addressform.FullAddress);
      if(isEmpty(testlan?.coordinates)||(!isEmpty(testlan?.coordinates)&&testlan?.coordinates?.every((val)=>val==0))){
        toast.error("Enter Valid Address");
        return SetError({
          FullAddress: "Enter Valid Address"
        })
      }
      console.log('kjssfksjfsfss',testlan)
      let senddata = {};
      senddata.Address = addressform;
      senddata.Address.user_id = payload._id;
      senddata.Mobile = payload?.Mobile;
      senddata._id = payload?._id;
      senddata.Type = Addresstype == "add" ? "saveaddress" : "editaddress";
      senddata.from = "buyer";
      console.log("sendat", senddata);
      let Update = await axiosFile.LoginReg(senddata);
      console.log("lsfskfsfs", Update);
      if (Update.success == "success") {
        toast.success("Updated Successfully", 1000);
        dispatch({
          type: "Register_Section",
          Register_Section: {
            User: {
              payload: Update.data,
              // token: Update.token ? Update.token : token,
            },
          },
        });
        SetAddressForm(initAddress);
        navigate("/detail/address");
      } else toast.error(Update?.msg ?? "Try-Again", 1000);
    } 
    else {
      SetError(error);
      toast.error("Error Occured", 1000);
    }
  };

  const EditClick = (address) => {
    SetAddresstype("edit");
    SetAddressForm({
      ...addressform,
      ...{
        FullName: address.FullName,
        Street: address.Street,
        Area: address.Area,
        type: address.type,
        City: address.City,
        Zipcode: address.Zipcode,
        EmailId: address.EmailId,
        Mobile: address.Mobile,
        Street2: address.Street2,
        defaultAddress: address.defaultAddress,
        State: address.State,
        _id: address._id,
        Addresslabel: address.Addresslabel,
        Instructions: address.Instructions,
        latlng: address?.latlng,
        FullAddress: address?.FullAddress,
      }
    });
  };

  const Save = async () => {
    console.log(
      "payload.EmailId == formdata.EmailId ",
      payload.EmailId,
      newemail,
      isEmpty(newemail),
      formdata.EmailId
    );
    if (payload.EmailId == newemail) {
      toast.warn("Enter new gmail to change");
      return;
    }
    if (isEmpty(newemail)) {
      console.log("enter thoseee");
      SetError({ EmailId: "Enter Email-Id" });
      toast.error("Error-Occured", 1000);
    } else {
      if (config.EMAIL.test(newemail)) {
        let senddata = {};
        senddata.EmailId = newemail;
        senddata.Mobile = payload?.Mobile;
        senddata._id = payload?._id;
        senddata.Type = "newmail_send_otp";
        senddata.from = "buyer";
        let Update = await axiosFile.LoginReg(senddata);
        console.log("lsfskfsfs", Update);
        if (Update.success == "success") {
          navigate("/changepw", {
            state: {
              ...payload,
              ...{ from: "email-change", newmail: newemail },
            },
          });
        } else toast.error(Update.msg ?? "Try-Again", 1000);
      } else {
        SetError({ EmailId: "Enter valid email" });
        toast.error("Error-Occured", 1000);
      }
    }
  };

  const cancelOrder = (status, currorder) => {
    SetCancelOrderPopup(status);
    SetCurrentOrder(currorder);
  };

  const RemoveAddress = async (data) => {
    console.log("kdksjkfjksf", data._id);
    let text = "Do You Want to Delete this Address?";
    if (window.confirm(text)) {
      let senddata = {
        Type: "remove",
        from: "buyer",
        Mobile: payload.Mobile,
        _id: payload._id,
        Address: { _id: data._id, defaultAddress: data.defaultAddress },
      };
      let Resp = await axiosFile.LoginReg(senddata);
      window.alert("Address Removed !");
    } else {
      window.alert("Cancelled !");
    }
  };

  const Add = async (newadd, LatLng) => {
    if (newadd || (Address?.Address && isEmpty(Address?.Latlng))) {
      var Latlngs = await GetLatLngBasedOnAddress(Address?.Address ?? newadd);
      var Latlng = Latlngs?.coordinates;
    } else Latlng = Address?.Latlng ?? LatLng;
    if (LatLng || (Address?.Latlng && isEmpty(Address?.Address))) {
      var Addresses = await GetAddressBasedOnLatLng(
        Address?.Latlng?.[0] ?? LatLng?.[0],
        Address?.Latlng?.[1] ?? LatLng?.[1]
      );
    } else Addresses = Address?.Address ? Address?.Address : newadd;
    console.log("AddressAddress", Addresses, Address, LatLng);
  };

  console.log("dskjfklsfsfs", Address);

  const order = useMemo(() => {
    return (
      <>
        <p className="odp_lit_title">Orders</p>
        <p className="odp_lit_hint mt-2">
          see how your orders are managed and check the latest status on your{" "}
        </p>

        {profiledetail[tabname]?.list?.length > 0 ? (
          <>
            {profiledetail[tabname]?.list?.map((val) => {
              return (
                <OrdersCard
                  details={val}
                  updatecart={() => SetHeaderUpdate(headerupdate - 1)}
                  cancelOrder={cancelOrder}
                />
              );
            })}
          </>
        ) : (
          <div className="odp_emptyorder_whole mb-5">
            <img
              className="odp_emptyorder_img"
              src={require("../assets/images/ordersempty.png")}
            />
            <p className="odp_lit_title text-center mt-4">
              You don't have any orders yet
            </p>
            <p className="odp_lit_hint text-center mt-2">
              What are you waiting for? Start shopping!
            </p>
            <Link to="/">
              <button className="blue_btn odp_continue_shopbtn">
                Continue Shopping
              </button>{" "}
            </Link>
          </div>
        )}
        {console.log("ldkflsdfsdincomponent", profiledetail?.[tabname]?.count)}
        {profiledetail?.[tabname]?.count >
          profiledetail[tabname]?.list?.length && (
          <div className="text-center">
            <button
              className="blue_btn odp_savebtn mb-4"
              onClick={() =>
                GetTabdatas(
                  (profiledetail?.[tabname]?.page
                    ? profiledetail?.[tabname]?.page
                    : 0) + 1
                )
              }
            >
              Load More
            </button>
          </div>
        )}
      </>
    );
  }, [tabname, profiledetail]);
  console.log("dssofsuifss", Addresstype, tabname);
  const address = useMemo(() => {
    return (
      <>
        <p className="odp_lit_title">Address</p>
        {!Addresstype ? (
          <Row className="h-100">
            {payload?.DeliveryAddress?.length > 0 ? (
              <>
                {payload?.DeliveryAddress?.map((val) => {
                  return (
                    <Col
                      className="my-4"
                      xxl={4}
                      xl={4}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                    >
                      <div className="ps_homeaddress">
                        <div className="odp_vertaligned">
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="ps_homettl">{val.title}</p>
                              {val.defaultAddress && (
                                <p className="ps_defaddress">Default Address</p>
                              )}
                            </div>
                            <div className="ps_addresstxt">
                              <p>Name:</p>
                              <b>{val?.FullName}</b>
                            </div>
                            <div className="ps_addresstxt">
                              <p>Address:</p>
                              <div className="addresses_aligned">
                              {
                                  isEmpty(val?.FullAddress) ?
                                <>
                                {!isEmpty(val?.Street) &&
                                  val?.Street?.split(",").map((addr) => {
                                    return (
                                      <>
                                        {!isEmpty(addr) && (
                                          <b>
                                            {addr} ,{"\n"}
                                          </b>
                                        )}
                                      </>
                                    );
                                  })}
                                {!isEmpty(val?.Street2) &&
                                  val?.Street2?.split(",").map((addr) => {
                                    return (
                                      <>
                                        {!isEmpty(addr) && (
                                          <b>
                                            {addr} ,{"\n"}
                                          </b>
                                        )}
                                      </>
                                    );
                                  })}
                                {!isEmpty(val?.City) &&
                                  val?.City?.split(",").map((addr) => {
                                    return (
                                      <>
                                        {!isEmpty(addr) && (
                                          <b>
                                            {addr} ,{"\n"}
                                          </b>
                                        )}
                                      </>
                                    );
                                  })}
                                {!isEmpty(val?.State) &&
                                  val?.State?.split(",").map((addr) => {
                                    return (
                                      <>
                                        {!isEmpty(addr) && (
                                          <b>
                                            {addr} ,{"\n"}
                                          </b>
                                        )}
                                      </>
                                    );
                                  })}
                                <b>{`${val.Area}-${val.Zipcode}`}</b>
                                </>
                                :
                                <b>{val.FullAddress}</b>
                              }
                                {/* <b>{`${val.Street},\n`}</b>
                                <b>{`${val.Street2 ? val.Street2 + ",\n" : ""}`}</b>
                                <b>{`${val.City},\n`}</b>
                                <b>{`${val.Area}-${val.Zipcode}`}</b> */}
                              </div>
                            </div>
                            <div className="ps_addresstxt">
                              <p>Phone Number:</p>
                              <b>{val?.Mobile}</b>
                            </div>
                            <div className="ps_addresstxt">
                              <p>Email Address:</p>
                              <b>{val?.EmailId}</b>
                            </div>
                          </div>
                          <div className="ps_editremovedef">
                            <div className="ps_editremove">
                              <p
                                onClick={() => {
                                  navigate(`/detail/editaddress`, {
                                    state: {
                                      ...state,
                                      ...{ editaddress: val },
                                    },
                                  });
                                  // EditClick(val);
                                }}
                              >
                                Edit
                              </p>
                              <p
                                className="ps_removetxt"
                                onClick={() => RemoveAddress(val)}
                              >
                                Remove
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}

                <Col
                  className="my-4"
                  xxl={4}
                  xl={4}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <div
                    className="odp_addnewaddrs_box"
                    onClick={() => {
                      navigate(`/detail/addaddress`, { state: { ...state } });
                      // SetAddresstype("add");
                    }}
                  >
                    <img src={require("../assets/images/icon/add.png")} />
                    <p className="ps_homettl">Add New Address</p>
                  </div>
                </Col>
              </>
            ) : (
              <Col
                className="my-4"
                xxl={4}
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
              >
                <div
                  className="odp_addnewaddrs_box"
                  onClick={() => {
                    SetAddresstype("add");
                  }}
                >
                  <img src={require("../assets/images/icon/add.png")} />
                  <p className="ps_homettl">Add New Address</p>
                </div>
              </Col>
            )}
          </Row>
        ) : (
          <Row className="mt-4" id="add_address">
            {/* <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">
                Full Name <span>*</span>
              </p>
              <input
                className="regpopy_cmninput"
                type="text"
                placeholder="Ex. john doe"
                name="address"
                id="FullName"
                value={addressform?.FullName || ""}
                onChange={(e) => OnChange(e)}
              />
              {Error?.FullName && (
                <span className="error-msg">{Error.FullName}</span>
              )}
            </Col>
            <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">Address Type</p>
              {console.log(
                "config.AddressType",
                addressform?.type,
                config.AddressType
              )}
              <Select
                options={config.AddressType}
                // styles={stylesgraybg}
                className="border_blue_select mt_seelc_new custom_rct_slt"
                classNamePrefix="react-select"
                placeholder="Ex. home"
                styles={stylesgraybg}
                value={{
                  label: addressform?.type ?? "",
                  value: addressform?.type ?? "",
                }}
                clearable={true}
                id="type"
                name="address"
                onChange={(e) => {
                  console.log("dskkflds", e);
                  SelectDown(e.value, "type");
                }}
              />
              {Error?.type && <span className="error-msg">{Error.type}</span>}
            </Col>
            <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">
                Email Address <span>*</span>
              </p>
              <input
                className="regpopy_cmninput"
                type="text"
                placeholder="Ex. johndoe@gmail.com"
                name="address"
                id="EmailId"
                value={addressform?.EmailId || ""}
                onChange={(e) => OnChange(e)}
              />
              {Error?.EmailId && (
                <span className="error-msg">{Error.EmailId}</span>
              )}
            </Col>
            <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">
                Phone Number <span>*</span>
              </p>
              <PhoneInput
                className="sigin_inputs new_rephoneinp sigin_inputs_borde"
                placeholder="Enter phone number"
                id="Mobile"
                value={payload?.Mobile ? addressform?.Mobile : ""}
                onChange={(val) => OnChange(val, "Mobile", "address")}
                defaultCountry="US"
                country="US"
                // onCountryChange={(val)=>OnChange(val,"Mobile","country")}
              />

              {Error?.Mobile && (
                <span className="error-msg">{Error.Mobile}</span>
              )}
            </Col>
            <Col
              className="mb-3 mt-2"
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <p className="regpopy_lable addnew_addr">
                Choose Location by Clicking Here
              </p>
              <div
                className="header_locateme_btn locate_just_start"
                onClick={() => setShow_map(true)}
              >
                <img src={require("../assets/images/icon/Icon.png")} />
                <p>Locate me</p>
              </div>
            </Col> */}
            <Col className="mb-3" xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">
                Enter Full Address <span>*</span>
              </p>
              <div id="parent_comple" className="parent_comple parent_comple_payment">

                <Autocomplete
                  autoCorrect={true}
                  className="regpopy_cmninput"
                  type="text"
                  placeholder="Enter your house name and street name"
                  name="address"
                  id="FullAddress"
                  value={addressform?.FullAddress || ""}
                  onChange={(e) => {changePositiion();OnChange(e)}}
                  apiKey={
                    process.env.geoapikey
                  }
                  onPlaceSelected={(place) =>{
                    SetAddressSelectStatus(!addressselectstatus);
                    console.log('lkljdjskkfjsfs',place)
                    SelectAddress(place, "FullAddress")
                  }
                  }
                  // types
                  options={{
                    types: ["address"],
                  }}
                />
                </div>
              {Error?.FullAddress && (
                <span className="error-msg">{Error.FullAddress}</span>
              )}
            </Col>
            <Col
              className="mb-3 mt-2"
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <p className="regpopy_lable addnew_addr">
                Choose Location by Clicking Here
              </p>
              <div
                className="header_locateme_btn locate_just_start"
                onClick={() => setShow_map(true)}
              >
                <img src={require("../assets/images/icon/Icon.png")} />
                <p>Locate me</p>
              </div>
            </Col>
            <Col className="mb-3" xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">Address Type</p>
              <Select
                options={config.AddressType}
                // styles={stylesgraybg}
                className="border_blue_select mt_seelc_new"
                classNamePrefix="react-select"
                placeholder="Ex. home"
                styles={stylesgraybg}
                value={{
                  label: addressform?.type ?? "",
                  value: addressform?.type ?? "",
                }}
                clearable={true}
                id="type"
                name="address"
                onChange={(e) => {
                  console.log("dskkflds", e);
                  SelectDown(e.value, "type");
                }}
              />
              {Error?.type && <span className="error-msg">{Error.type}</span>}
            </Col>
            {/* <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">
                City <span>*</span>
              </p>
              <input
                className="regpopy_cmninput"
                type="text"
                placeholder="Ex. New york"
                id="City"
                name="address"
                value={addressform?.City || ""}
                onChange={(e) => OnChange(e)}
              />

              {Error?.City && <span className="error-msg">{Error.City}</span>}
            </Col>
            <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">
                State <span>*</span>
              </p>
              <input
                className="regpopy_cmninput"
                type="text"
                placeholder="Ex. New york"
                id="State"
                name="address"
                value={addressform?.State || ""}
                onChange={(e) => OnChange(e)}
              />
              {Error?.State && <span className="error-msg">{Error.State}</span>}
            </Col>
            <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">
                Country <span>*</span>
              </p>
              <input
                className="regpopy_cmninput"
                type="text"
                placeholder="Ex. New york"
                id="Area"
                name="address"
                value={addressform?.Area || ""}
                onChange={(e) => OnChange(e)}
              />

              {Error?.Area && <span className="error-msg">{Error.Area}</span>}
            </Col>
            <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">Zipcode</p>

              <input
                className="regpopy_cmninput"
                type="text"
                placeholder="Ex. New york"
                id="Zipcode"
                name="address"
                value={addressform?.Zipcode || ""}
                onChange={(e) => OnChange(e)}
              />

              {Error?.Zipcode && (
                <span className="error-msg">{Error.Zipcode}</span>
              )}
            </Col>*/}
            <Col className="mb-3" xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">Instructions for delivery person</p>
              <textarea
                className="regpopy_cmninput"
                placeholder="Ex. Please knock instead of using the doorbell"
                id="Instructions"
                name="address"
                value={addressform?.Instructions || ""}
                onChange={(e) => OnChange(e)}
              />
             
              {Error?.Instructions && <span className="error-msg">{Error.Instructions}</span>}
            </Col>


            <Col className="mb-3" xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">Address label</p>
              <input
                className="regpopy_cmninput"
                placeholder="Add a label Ex. school"
                id="Addresslabel"
                name="address"
                value={addressform?.Addresslabel || ""}
                onChange={(e) => OnChange(e)}
              />
             
              {Error?.Addresslabel && <span className="error-msg">{Error.Addresslabel}</span>}
            </Col>
            <Col
              className="mb-3"
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <div className="modal_checkoptions ps_defadcheck">
                <Form.Check type="checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    checked={addressform?.defaultAddress}
                    onChange={() =>
                      SetAddressForm({
                        ...addressform,
                        ...{
                          defaultAddress: !addressform.defaultAddress,
                        },
                      })
                    }
                  />
                  <Form.Check.Label className="ps_defaultaddr">
                    Default Address
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Col>

            <Col xs={12} className="ps_saveaddrsbtnal">
              <button
                className="blue_btn ps_saveaddrsbtn mb-5"
                onClick={() => AddressSubmit()}
              >
                Save Address
              </button>
            </Col>
          </Row>
        )}
      </>
    );
  }, [addressform, tabname, Error, Addresstype,addressselectstatus]);

  const wishlist = useMemo(() => {
    return (
      <>
        <p className="odp_lit_title">My Wishlist</p>
        <p className="odp_lit_hint mt-2">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
        <div className="odp_chekcers_align">
          <div className="modal_checkoptions ps_defadcheck me-3">
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                checked={arrfilter.some((val) => val == "Shop")}
                onChange={(e) => WishlistFilter(e)}
                value={"Shop"}
                className="cursor_pointer_hand"
              />
              <Form.Check.Label className="ps_defaultaddr">
                {" "}
                Shop
              </Form.Check.Label>
            </Form.Check>
          </div>
          <div className="modal_checkoptions ps_defadcheck">
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                checked={arrfilter.some((val) => val == "Product")}
                onChange={(e) => WishlistFilter(e)}
                value={"Product"}
                className="cursor_pointer_hand"
              />
              <Form.Check.Label className="ps_defaultaddr">
                {" "}
                Products
              </Form.Check.Label>
            </Form.Check>
          </div>
        </div>
        <Row className="mt-4">
          {profiledetail[tabname]?.list?.length > 0 ? (
            profiledetail[tabname]?.list?.map((val) => {
              return (
                <Col
                  className="mb-4"
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <WishlistCard
                    wishlistDetails={val}
                    GetWishList={() => GetTabdatas()}
                  />
                </Col>
              );
            })
          ) : (
            <div className="odp_emptyorder_whole mb-5">
              <img
                className="odp_emptyorder_img"
                src={require("../assets/images/ordersempty.png")}
              />
              <p className="odp_lit_title text-center mt-4">
                You don't have any Wishlist yet
              </p>
              <p className="odp_lit_hint text-center mt-2">
                What are you waiting for? Start shopping!
              </p>
              <Link to="/">
                <button className="blue_btn odp_continue_shopbtn">
                  Continue Shopping
                </button>{" "}
              </Link>
            </div>
          )}
        </Row>
        {profiledetail?.[tabname]?.count >
          profiledetail[tabname]?.list?.length && (
          <div className="text-center">
            <button
              className="blue_btn odp_savebtn"
              onClick={() =>
                GetTabdatas(
                  (profiledetail?.[tabname]?.page
                    ? profiledetail?.[tabname]?.page
                    : 0) + 1
                )
              }
            >
              Load More
            </button>
          </div>
        )}
      </>
    );
  }, [tabname, arrfilter, profiledetail]);

  const profile = useMemo(() => {
    return (
      <>
        {console.log("sjdsadsadasdasdas", formdata)}
        <p className="odp_lit_title">Profile</p>
        <p className="odp_lit_hint mt-2">
          Lorem Ipsum is simply dummy text of the printing and typesetting in
        </p>

        <Row className="odp_profile_border">
          <Col xs={12} className="odp_profilettlbg">
            <p className="odp_profilettl">General Information</p>
          </Col>
          <Row className="p-4">
            <Col className="mb-3" xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
              <p className="odp_input_title">First Name</p>
              <input
                type="text"
                className="odp_profile_inp"
                placeholder="Enter First Name"
                id="FirstName"
                value={formdata?.FirstName || ""}
                onChange={(e) => OnChange(e)}
              />
              {Error?.FirstName && (
                <span className="error-msg">{Error.FirstName}</span>
              )}
            </Col>
            <Col className="mb-3" xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
              <p className="odp_input_title">Last Name</p>
              <input
                type="text"
                className="odp_profile_inp"
                placeholder="Enter Last Name"
                id="LastName"
                value={formdata?.LastName || ""}
                onChange={(e) => OnChange(e)}
              />
              {Error?.LastName && (
                <span className="error-msg">{Error.LastName}</span>
              )}
            </Col>
            <Col className="mb-3" xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
              <p className="odp_input_title">Mobile Number</p>
              <PhoneInput
                className="sigin_inputs new_rephoneinp"
                placeholder="Enter phone number"
                id="Mobile"
                value={payload?.Mobile ? formdata.Mobile : ""}
                onChange={(e) => OnChange(e, "Mobile")}
                defaultCountry="US"
                country="US"
                countries={["US","IN"]}
                // onCountryChange={(e)=>OnChange(e,"Mobile","country")}
              />
              {/* <input
                type="string"
                className="odp_profile_inp"
                placeholder="+ 515 516 0624"
                id="Mobile"
                value={formdata?.Mobile?formdata?.Mobile:payload?.Mobile}
                onChange={(e) => OnChange(e)}
              /> */}
              {Error?.Mobile && (
                <span className="error-msg">{Error.Mobile}</span>
              )}
            </Col>
            <Col className="mb-3" xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
              <p className="odp_input_title">Date Of Birth</p>
              <input
                type="date"
                className="odp_profile_inp"
                placeholder="Choose Date of Birth"
                id="DateofBirth"
                value={formdata?.DateofBirth || ""}
                onChange={(e) => OnChange(e)}
                max={
                  new Date().getFullYear() +
                  "-" +
                  (new Date().getMonth() + 1) +
                  "-" +
                  (new Date().getDate() - 1)
                }
              />
              {Error?.DateofBirth && (
                <span className="error-msg">{Error.DateofBirth}</span>
              )}
            </Col>
            <Col className="mb-3" xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
              <p className="odp_input_title">Gender</p>
              <Select
                onChange={(e) => {
                  SelectChange(e, "Gender");
                }}
                options={optionsGender}
                value={{
                  label: formdata?.Gender == "others" ? "" : formdata.Gender,
                  value: formdata?.Gender == "others" ? "" : formdata.Gender,
                }}
                styles={stylesprofileinp}
                // menuIsOpen={true}
                className="border_blue_select custom_rct_slt"
                classNamePrefix="react-select"
                placeholder="Male"
                id="Gender"
              />
            </Col>
            <Col className="mb-3" xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
              {GenderType == "input" && (
                <>
                  <p className="odp_input_title">Enter Gender</p>

                  <input
                    className="odp_profile_inp"
                    id="Gender"
                    type="text"
                    placeholder="Enter Gender"
                    value={
                      (formdata?.Gender == "others" ? "" : formdata.Gender) ||
                      ""
                    }
                    onChange={(e) => OnChange(e)}
                  />
                </>
              )}

              {Error?.Gender && (
                <span className="error-msg">{Error.Gender}</span>
              )}
            </Col>
            <button
              className="blue_btn odp_savebtn"
              onClick={() => SaveProfile()}
            >
              Save
            </button>
          </Row>
        </Row>

        <Row className="odp_profile_border mt-4">
          <Col xs={12} className="odp_profilettlbg">
            <p className="odp_profilettl">Security</p>
          </Col>
          <Row className="p-4">
            <Col className="mb-3" xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
              <p className="odp_input_title">Email Address</p>
              <input
                type="email"
                className="odp_profile_inp"
                placeholder="Enter Your Email"
                id="EmailId"
                value={newemail}
                onChange={(e) => SetNewemail(e.target.value)}
              />
              {Error?.NewMail && (
                <span className="error-msg">{Error.NewMail}</span>
              )}
              <p>
                {/* Enter new email address you would like to associate with your
                account above. We will send a One Time Password(OTP) to that
                address */}
              </p>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="odb_security_btns mt-2">
                <button className="odp_security_btns" onClick={() => Save()}>
                  Change Email
                </button>
              </div>
            </Col>
          </Row>
        </Row>
      </>
    );
  }, [tabname, Error, newemail, payload, formdata]);

  const Images = (it) => {
    return it == "order" ? (
      // <img
      //   className="odp_listlogos"
      //   src={require(`../assets/images/icon/orders.png`)}
      // />
      <img className="odp_listlogos" src={orderIcon} />
    ) : it == "address" ? (
      // <img
      //   className="odp_listlogos"
      //   src={require("../assets/images/icon/address.png")}
      // />
      <img className="odp_listlogos" src={addressIcon} />
    ) : it == "wishlist" ? (
      // <img
      //   className="odp_listlogos"
      //   src={require("../assets/images/icon/wishlist.png")}
      // />
      <img className="odp_listlogos" src={wishlistIcon} />
    ) : // <img className="odp_listlogos" src={wishlistIcon} />
    it == "profile" ? (
      // <img
      //   className="odp_listlogos"
      //   src={require("../assets/images/icon/profile.png")}
      // />
      <img className="odp_listlogos" src={profileIcon} />
    ) : (
      // <img
      //   className="odp_listlogos"
      //   src={require(`../assets/images/icon/Logout.png`)}
      // />
      <img className="odp_listlogos" src={logoutIcon} />
    );
  };

  console.log("lkdllfsss", addressform);

  return (
    <>
      <Header Getcart={() => headerupdate} />
      <Container className="mt-5 pb-4">
        {tabname && (
          // <Tab.Container id="left-tabs-example" defaultActiveKey={tabname}>
          <Row>
            <Col sm={1} className="odp_mobshow">
              <button className="odp_mobmenu_btn" onClick={handleShowMobMenu}>
                Menu
              </button>
            </Col>
            <Col lg={3} className="odp_mobhide">
              <Nav variant="pills" className="flex-column odp_nav nav_padBot">
                {["order", "address", "wishlist", "profile", "logout"].map(
                  (it) => {
                    return (
                      <Nav.Item
                        id={it + "check"}
                        onClick={() => {
                          SetTabname(it);
                          it == "logout"
                            ? Logouts()
                            : navigate(`/detail/${it}`);
                        }}
                      >
                        <Nav.Link
                          className={
                            tabname == it
                              ? "activated_naved active"
                              : "nav-link"
                          }
                          // href="#order"
                          eventKey={it}
                          aria-selected={tabname == it}
                          tabIndex={tabname == it ? 0 : -1}
                        >
                          {Images(it)}

                          <>
                            {" "}
                            {it?.toString()?.substr(0, 1)?.toUpperCase() +
                              it?.toString()?.substr(1, it.length - 1)}
                          </>
                        </Nav.Link>
                        {/* </LinkContainer> */}
                      </Nav.Item>
                    );
                  }
                )}
              </Nav>
            </Col>
            <Col lg={9} md={12} sm={12}>
              {/* <Tab.Content className="odp_mobhidecnt"> */}
              {console.log("Dsadsadsadasdas", tabname)}
              {/* <Tab.Pane eventKey={tabname}> */}
              {tabname == "order"
                ? order
                : tabname == "address"
                ? address
                : tabname == "wishlist"
                ? wishlist
                : tabname == "profile" && profile}
              {/* </Tab.Pane> */}
              {/* </Tab.Content> */}
            </Col>
          </Row>
          // </Tab.Container>
        )}

        <Offcanvas
          show={showMobMenu}
          className="odp_mobmenu_offcanva"
          onHide={handleCloseMobMenu}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Offcanvas</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="odp_mobmenu_ul">
              {["order", "address", "wishlist", "profile", "logout"].map(
                (it) => {
                  return (
                    <li
                      className={tabname == it ? "active" : ""}
                      onClick={() => {
                        SetTabname(it);
                        setShowMobMenu(!showMobMenu);
                        it != "logout" ? navigate(`/detail/${it}`) : Logouts();
                      }}
                    >
                      {Images(it)}{" "}
                      {it?.toString()?.substr(0, 1)?.toUpperCase() +
                        it?.toString()?.substr(1, it.length - 1)}
                    </li>
                  );
                }
              )}
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
        {/* end of mobile offcanva menu */}
      </Container>
      {cancelorderpopup && (
        <CancelOrder
          Detail={currentorder}
          ClosePopup={() => SetCancelOrderPopup(false)}
          cancelorderpopup={cancelorderpopup}
          GetTabdatas={GetTabdatas}
        />
      )}
      <Footer />
      {show_map && (
        <GMap
          show_map={show_map}
          Add={Add}
          setShow_map={setShow_map}
          SetAddress={(e) => {
            console.log('doifpsjfdsjgds',e)
            SetAddressForm({
              ...addressform,
              ...{FullAddress:e?.Address?.display},
              ...(e?.Latlng
                ? { latlng: { type: "Point", coordinates: e?.Latlng } }
                : { latlng: { type: "Point", coordinates: [0, 0] } }),
            });
          }}
          Address={Address}
        />
      )}
    </>
  );
}

export default OrderDetailsPage;
