import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DecryptData, isEmpty } from "../actions/common";
import useAxiosFile from "../actions/useaxios";

function SignIn() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { token, payload } = useSelector((state) => state.LoginReducer.User);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosFile = useAxiosFile();
  const [passwordText, setPasswordText] = useState("password");

  const location = useLocation();

  const { state } = location;

  console.log("ksjflksjfsf", location);

  const initform = {
    Mobile: "",
    Password: "",
    Rememberme: false,
  };
  const initname = {
    Password: "Password",
    Mobile: "Mobile Number",
  };
  const [formdata, SetFormdata] = useState(initform);
  const [displayname, SetDisplayName] = useState(initname);
  const [Error, SetError] = useState({});
  const [IpAddress, SetIpAddress] = useState("");
  const [RememeberUsers, SetRememberUsers] = useState([]);
  const [showmobile, SetShowMobile] = useState(false);

  const OnChange = (e) => {
    SetError({});
    const { id, value } = e.target;
    SetFormdata({ ...formdata, ...{ [id]: value } });
  };
  const Validate = () => {
    let error = {};

    Object.keys(displayname).map((val) => {
      if (isEmpty(formdata[val])) {
        error[val] = displayname[val] + " Required";
      }
    });
    return error;
  };
  const Loginclick = async () => {
    let error = Validate();

    if (isEmpty(error)) {
      let senddata = formdata;
      senddata.Type = "login";
      senddata.from = "buyer";

      senddata.IpAddress = IpAddress;
      var Resp = await axiosFile.LoginReg(senddata);

      if (Resp?.success == "success") {
        dispatch({
          type: "Register_Section",
          Register_Section: {
            User: {
              payload: Resp?.data[0],
              token: Resp?.token ? Resp.token : token,
            },
          },
        });
        toast.success("Login Successfully");
        navigate(state?.previouspage ? state?.previouspage : "/detail/profile");
      } else {
        toast.error(Resp?.msg);
      }
    } else {
      toast.error(Resp?.msg);
      SetError(error);
    }
  };

  useEffect(() => {
    DefaultLogin();
  }, []);

  const DefaultLogin = async () => {
    let ipaddress = await axiosFile.GetIpAddress();
    SetIpAddress(ipaddress?.ip);
    let GetUsers = await axiosFile.GetUserlist({ IpAddress: ipaddress?.ip });

    let savedpass = [];
    if (GetUsers?.data?.length > 0) {
      GetUsers?.data?.map((val) => {
        savedpass.push(DecryptData(val.Rememberme));
      });
    }

    SetRememberUsers(savedpass);
  };

  return (
    <>
      <div className="singin_bg">
        <img
          className="singin_abs"
          src={require("../assets/images/jamskew.png")}
          alt=""
        />
        <div className="sigin_form">
          <p className="sigin_skip">
            <Link to="/">Skip</Link>
          </p>
          <div className="centering_sigin">
            <div className="single_center">
              <label className="signin_labels" for="mobile">
                Mobile Number
              </label>
              <br />
              <input
                className="sigin_inputs"
                type="number"
                placeholder="Enter your mobile number"
                id="Mobile"
                value={formdata?.Mobile || ""}
                onChange={(e) => OnChange(e)}
                name="mobile"
                onFocus={() => SetShowMobile(true)}
              />

              {RememeberUsers?.length > 0 &&
                showmobile &&
                isEmpty(formdata?.Mobile) &&
                RememeberUsers?.map((val) => {
                  return (
                    <>
                      <label className="signin_labels" for="mobile" />
                    </>
                  );
                })}
              {Error?.Mobile && (
                <span className="error-msg">{Error.Mobile}</span>
              )}
            </div>
            <div className="single_center">
              <label className="signin_labels" for="password">
                password
              </label>
              <br />
              <div className="reg_passwordinps">
                <input
                  className=""
                  type={passwordText == "password" ? "password" : "text"}
                  placeholder="Enter your password"
                  id="Password"
                  value={formdata?.Password || ""}
                  onChange={(e) => OnChange(e)}
                  name="password"
                />
                <i
                  onClick={() =>
                    setPasswordText(
                      passwordText == "text" ? "password" : "text"
                    )
                  }
                  className={
                    passwordText == "password"
                      ? "fa-solid fa-eye"
                      : "fa-solid fa-eye-slash"
                  }
                ></i>
              </div>
              {Error?.Password && (
                <span className="error-msg">{Error.Password}</span>
              )}
            </div>
          </div>
          <div className="remember_dtls">
            {/* <div>
  <input type="checkbox" id="Rememberme" name="Rememberme" checked={formdata?.Rememberme} onClick={()=>SetFormdata({...formdata,...{Rememberme:!formdata.Rememberme}})}/>
  <label for="vehicle1" className='remember_lable'>Remember me</label>
  </div> */}
            <Link to="/forgotpw" className="signin_frgtpwt">
              Forgot password?
            </Link>
          </div>
          <div className="siginin_btn_dtl">
            <button
              type="button"
              className="blue_btn signin_btn"
              onClick={() => Loginclick()}
            >
              Sign In
            </button>
          </div>
          <p className="sigin_acnt_txt">
            Don't have an account?{" "}
            <span className="reg_link">
              <Link to="/register">Register</Link>
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
export default SignIn;
