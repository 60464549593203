import axios from 'axios';
import config from './config';
import { DecryptData, EncryptData, isEmpty } from './common';
import { useSelector, useDispatch } from 'react-redux';
import { data } from 'jquery';
import { handleCallback } from './errorHandler';
import { Initial_Register } from '../redux/constants';



const useAxiosFile = () => {


    const usertoken = ("; " + document.cookie)
        .split(`; token=`)
        .pop()
        .split(";")[0];
    const authToken = useSelector((state) => state.LoginReducer?.User?.token);
    
    var 
    token = usertoken ? usertoken : authToken;

    axios.defaults.baseURL = config.BACK_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.post["Authorization"] = token;
    const dispatch = useDispatch();

    const axiosFunc    =   async   (data)  =>  {
        
        try{
            let Resp    =   await axios(data)
            
            if(Resp?.data){
                Resp.data = isEmpty(DecryptData(Resp.data)) ? Resp.data : DecryptData(Resp.data)
            }
            

            return Resp.data
        }
        catch (e) {
            
            return { success: 'error', msg: null }
        }
    }

    const AppenData = (data) => {
        var formdata = new FormData()
        var SendDta = Object.entries(data).map((item) => {
            if (Array.isArray(item[1])) {
                var come = item[1].map(data => {
                    if (data?.type && data?.size) {
                        //file
                        formdata.append(item[0], data)
                    }
                    else {
                        formdata.append(item[0], EncryptData(data))

                    }
                    return formdata
                })
                return come

            }
            else {
                if (item[1]?.type && item[1]?.size) {
                    //file type
                    formdata.append(item[0], item[1])
                }
                else {
                    formdata.append(item[0], EncryptData(item[1]))

                }
                return formdata
            }
        })
        return SendDta
    }

    

  const LoginReg = handleCallback(async (data) => {
    

    var formdata = AppenData(data);
     var senddata = {
        method: "post",
        url: `/login/create`,
        
        data: formdata[0],
      };
      var Resp = await axiosFunc(senddata);
      console.log("Dasdasdsadasdasdsad",Resp)
      let _id =Array.isArray(Resp.data)?Resp.data[0]?._id:Resp.data?._id
      ,
      type = data.Type
        if(Resp?.token){
          document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
          }
        if(_id){
          localStorage.setItem("_id",_id)
          await AlreadyExits({ data: EncryptData({ _id :_id }) })
        }
        // if(Resp?.type == "otp"){
        //   localStorage.setItem("WcZyumloQ",encr(Resp.data))
        // }
  
    return Resp;
  });





  const GetOrders = handleCallback(async (data) => {
    var encdata = EncryptData(JSON.stringify(data));

    var senddata = {
      method: "get",
      url: `/user/getorders`,
      params: { data: encdata },
    };
    let Resp = await axiosFunc(senddata);
    return Resp;
  });


    const getcategory = async (data) => {
        try {


            var senddata = {
                method: 'GET',
                url: `${config.BACK_URL}/user/getcategory`,
                params: {data:EncryptData(data)},
            }
            let Resp = await axiosFunc(senddata)
            return Resp;
        }
        catch (err) { }
    }
    const getSubcategory = async (data) => {
        
        try {

            var senddata = {
                method: 'get',
                url: `${config.BACK_URL}/user/getsubcategory`,
                params: { data: EncryptData(data) }
            }
            let Resp = await axiosFunc(senddata)
            
            return Resp;
        }
        catch (err) { 
    }
    }

    const getshop = async (data) => {
        
        try {

            var senddata = {
                method: 'get',
                url: `${config.BACK_URL}/user/getshops`,
                params: { data: EncryptData(data) }
            }
            let Resp = await axiosFunc(senddata)
            return Resp;
        }
        catch (err) { 
        }
    }

    const GetMyWishList = async (data) => {
        try {
            var encdata = EncryptData(JSON.stringify(data))

            var senddata = {
                method: 'post',
                url: `${config.BACK_URL}/user/getwishlist`,
                data: { data: encdata },
                'headers': {
                    'Authorization': token
                }
            }
            let Resp = await axiosFunc(senddata)
            return Resp
        }
        catch (err) {
            
            return undefined
        }
    }

    const WishListAction = async (data) => {
        try {
            var encdata = EncryptData(JSON.stringify(data))


            var senddata = {
                method: 'post',
                url: `${config.BACK_URL}/user/wishlistaction`,
                data: { data: encdata },
                'headers': {
                    'Authorization': token
                }

            }
            let Resp = await axiosFunc(senddata)
            return Resp
        }
        catch (err) {
            
            return undefined
        }
    }

    const OrderAction = async (data) => {
        try {
            var encdata = EncryptData(JSON.stringify(data))

            var senddata = {
                method: 'post',
                url: `${config.BACK_URL}/user/orderaction`,
                data: { data: encdata },
                'headers': {
                    'Authorization': token
                }
            }
            let Resp = await axiosFunc(senddata)
            return Resp
        }
        catch (err) {
            
            return undefined
        }
    }

    const GetOrderDetail = async (data) => {
        try {
            var encdata = EncryptData(JSON.stringify(data))
            console.log('lkkgksdkgdsjlkg',DecryptData(encdata))
            var senddata = {
                method: 'get',
                url: `${config.BACK_URL}/user/getorderdetail`,
                params: { data: encdata },
                'headers': {
                    'Authorization': token
                }
            }
            let Resp = await axiosFunc(senddata)
            return Resp
        }
        catch (err) {
            
            return undefined
        }
    }



const getwishlist = async(data)=>{
    
    try {

        var senddata    =   {
            method  :   'get',
            url     :   `${config.BACK_URL}/user/wishlist`,
            params  :   {data:EncryptData(data)}
            }
    let Resp    =   await axiosFunc(senddata) 
        return Resp;
    }
    catch (err) { }

}
const addwishlist = async(data)=>{
    
    try {

        var senddata    =   {
            method  :   'post',
            url     :   `${config.BACK_URL}/user/wishlistaction`,
            'headers': {
                'Authorization': token
              },
            data  :   {data:EncryptData(data)}
            }
    let Resp    =   await axiosFunc(senddata) 
        return Resp;
    }
    catch (err) { }

}


const getsellerCategory = async (data) =>{
    var senddata ={
        method  :   'get',
        url     :   `/user/getsellerCategory`,
        params  :   {data:EncryptData(data)}
    }
    
    let Resp = await axiosFunc(senddata);
    return Resp;
  };
  const sociallinks = async () => {
    var senddata = {
      method: "get",
      url: `/user/getsociallink`,
    };

    let Resp = await axiosFunc(senddata);
    console.log("resposnse",Resp);
    return Resp;
}

const GetIpAddress = async () => {
    try{
        var senddata = {
            method: 'get',
            url: `https://api.ipify.org?format=json`,
        }
        let Resp = await axios(senddata);
        
        return Resp.data;
    }
    catch(err){
        
    }
}


const GetUserlist = async (data) => {
    try{
        var encdata = EncryptData(JSON.stringify(data))
        var senddata = {
            method: 'get',
            url: `${config.BACK_URL}/login/getuser`,
            params: {data: encdata},
        }
        let Resp = await axiosFunc(senddata);
        
        return Resp;
    }
    catch(err){
        
    }
}




const getproducts = async (data) =>{
    var senddata ={
        method  :   'get',
        url     :   `/user/getsellerproducts`,
        params  : data
    }
    
    let Resp = await axiosFunc(senddata);
    
    return Resp;
}

const getshopproducts = async (data) =>{
    
    var senddata ={
        method  :   'get',
        url     :   `/user/getshopproduct`,
       
        params    :    {data:EncryptData(data)}
    }
    
    let Resp = await axiosFunc(senddata);
    
    return Resp;
}



const ReviewAction = async(data) => {
    try {
        var encdata = EncryptData(JSON.stringify(data))

        var senddata = {
            method: 'post',
            url: `/user/reviewaction`,
            data: { data: encdata },
            'headers': {
                'Authorization': token
            }
        }
        let Resp = await axiosFunc(senddata)
        return Resp
    }
    catch (err) {
        
        return undefined
    }
    
}
const getsellerproducts = async (data) =>{
    
    var senddata ={
        method  :   'get',
        url     :   `/user/getproductview`,
        params  : data
    }
    
    let Resp = await axiosFunc(senddata);
    

    return Resp;
}
const getFaqList = async() =>{
  try {

      var senddata    =   {
          method  :   'get',
          url     :   `/user/getfaq`,
          }
  let Resp    =   await axiosFunc(senddata) 
      return Resp;
  }
  catch (err) { }

}


const getCmsList = async(data)=>{
 
  try {

      var senddata    =   {
          method  :   'post',
          url     :   `/user/getCmsList`,
          data:{data:EncryptData(data)}

          }
  let Resp    =   await axiosFunc(senddata) 
      return Resp;
  }
  catch (err) { }

}

const Getreview = async(data)=>{
  
  try {

      var senddata    =   {
          method  :   'get',
          url     :   `/user/Getreview`,
          params :data
          }
  let Resp    =   await axiosFunc(senddata) 
      return Resp.data;
  }
  catch (err) { }

}
const NewsletterS = async (data) =>{
    console.log("NewsletterS_datat", data);
  var senddata ={
      method  :   'POST',
      url     :   `/user/newsletter`,
      'headers': {
          'Authorization': token
        },
      data    :    {data:EncryptData(data)}
  }
  
  let Resp = await axiosFunc(senddata);

  
  return Resp;
}
const Contactus = async (data) =>{
  var senddata ={
      method  :   'POST',
      url     :   `/user/Contactus`,
      'headers': {
          'Authorization': token
        },
      data    :    data
  }
  
  let Resp = await axiosFunc(senddata);
  
  return Resp.data;
}


    const AlreadyExits = handleCallback(async (data) => {
        var senddata = {
            method: "get",
            url: `/user/AlreadyExits`,
            params: data,
            headers: { 'Authorization': token ? token : 'skip' }
        };

        let Resp = await axiosFunc(senddata);
        console.log("Resp>>>>>>",Resp)
        if (Resp?.token) {
            document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
        }
        dispatch({
            type: 'Register_Section',
            Register_Section: {
                User: {
                    payload: Resp?.Data[0],
                    token: Resp?.token ? Resp?.token : token
                },
                App_Inside: true,
                initialRegister: {
                    initialRegister: {
                        show: false
                    },
                }
            }
        });
    });

const GetProductDetail = async (data) =>{
    
    let encdata = EncryptData(data)

    var senddata ={
        method  :   'get',
        url     :   `${config.BACK_URL}/user/getproductdetail`,
        params  :    {data:encdata}
    }
    
    let Resp = await axiosFunc(senddata);
    
    return Resp;
}

const CartAction = async (data) => {
    let encdata = EncryptData(data)

    var senddata ={
        method  :   'post',
        url     :   `/user/cartaction`,
        data    :    {data:encdata},
    }
    
    let Resp = await axiosFunc(senddata);
    
    return Resp;
}


const GetCartLength = async (data) => {
    let encdata = EncryptData(data)

    var senddata ={
        method  :   'get',
        url     :   `/user/getcartlength`,
        params  :    {data:encdata},
        headers:{
            'Authorization' : token
        }
    }
    
    let Resp = await axiosFunc(senddata);
    
    return Resp;
}
const GetCarts = async (data) => {
    let encdata = EncryptData(data)

    var senddata ={
        method  :   'get',
        url     :   `/user/getcart`,
        params  :    {data:encdata},
        headers:{
            'Authorization' : token
        }
    }
    
    let Resp = await axiosFunc(senddata);
    
    return Resp;
}


const TestApi = async(data) => {
    // var encdata = EncryptData(JSON.stringify(data))
    let testdata = AppenData({test:'111',result:'sdfsdfs'})
    var senddata    =   {
        method  :   'post',
        url     :   `/user/test`,
        data    :   testdata[0],
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

const Payment_Intent = async(data)=>{
    var encdata = EncryptData(JSON.stringify(data))
    
    var senddata    =   {
        method  :   'post',
        url     :   `/stripe/create_payment_intent`,
        data    :   {data:encdata}
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}

const LoginUser = async(data) => {
    var encdata = EncryptData(JSON.stringify(data))

    var senddata    =   {
        method  :   'post',
        url     :   `/user/sendotp`,
        data    :   {data:encdata}
        }
    let Resp    =   await axiosFunc(senddata)
    console.log("Resp>>>>",Resp)
    if(Resp?.token){
        document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";

        localStorage.setItem("_id",Resp?.data?._id)
    dispatch({
        type: 'Register_Section',
        Register_Section: {
            User: {
                payload: Resp?.data,
                token: Resp?.token 
            },
          }
    });}
    return Resp
}


const showuserpanel = (async(data)=>{
    try {
        console.log('gfwehjre', data)
        var userlistdata = {
            method: 'get',
            url: `/admin/showuserpanel`,
            params:data

        }
        let Resp = await axiosFunc(userlistdata);
        console.log("tdhutfjh",Resp);
        return Resp;
    }
    catch (err) { }
 });

 //coupon
 const coupondetails = (async(data)=>{
    try {
        console.log('rgegerg', data)
        var couponlistdata = {
            method: 'get',
            url: `/admin/coupondetails`,
            params:data

        }
        let Resp = await axiosFunc(couponlistdata);
        console.log("ytjgers",Resp);
        return Resp;
    }
    catch (err) { }
 });

    const GetSearchData = async(data) => {
        let senddata = {
            method  :   'get',
            url     :   '/user/searchdata',
            params  :   {data:EncryptData(data)}
        }
        let Resp    =   await axiosFunc(senddata)
        return Resp;
    }

    const Reorder = async(data) => {
        let senddata = {
            method  :   'post',
            url     :   '/user/reorder',
            data    :   {data:EncryptData(data)}
        }
        let Resp    =   await axiosFunc(senddata)
        return Resp;
    }


    return(
        

        {
            LoginUser,
            LoginReg,
            GetMyWishList,
            GetOrders,
            sociallinks,
            getcategory,
            getSubcategory,
            getshop,
            ReviewAction,
            getwishlist,
            addwishlist,
            getsellerproducts,

            GetCarts,

            getFaqList,
            getCmsList,
            Getreview,
            
            NewsletterS,
            Contactus,
            getsellerCategory,
            addwishlist,
            GetIpAddress,
            GetUserlist,
            GetProductDetail,
            
            getproducts,
            getshopproducts,
            
            

            WishListAction,
            GetOrderDetail,
            OrderAction,
            CartAction,
            
            
            
            TestApi,
            
            AlreadyExits,
            showuserpanel,
            coupondetails,
            StripeApi:{
                Payment_Intent,
            },
            
            GetCartLength,
            GetSearchData,
            Reorder,
        }
    )




}



 

export default useAxiosFile;

