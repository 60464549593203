import React, { Component, useState, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container, Tab, Row, Nav, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { useDispatch, useSelector } from "react-redux";

import FoodCard from "../Components/FoodCard";
import Footer from "../Components/Footer";
import config from "../actions/config";
import useraxios from "../actions/useaxios";

import { DecryptData, EncryptData, isEmpty } from "../actions/common";
import Header from "../Components/Header";

// import { useDispatch, useSelector } from 'react-redux';

function GroceryCategory() {
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const { Address, DeliveryType } = useSelector((state) => state.LoginReducer);
  const location = useLocation();
  const navigate = useNavigate();

  const axiosfile = useraxios();
  // console.log("detailspay",payload);
  // const {DeliveryType} = useSelector((state) => state.LoginReducer);

  const [wishlist, setwishlist] = useState();
  const [Id, Setid] = useState("");
  const [address, SetAddress] = useState("");
  const [TabName, SetTabName] = useState("");
  const [List, setList] = useState({});
  const [Subcat, setSubcat] = useState("");
  const [AllCat, SetAllCat] = useState([]);
  console.log("location", location, location?.state?.Address);

  const outsider = document.getElementById("outsider");
  const distance = 130;
  const scrollingNavsRight = () => {
    outsider.scrollBy({
      left: distance,
      behavior: "smooth",
    });
  };
  const scrollingNavsLeft = () => {
    outsider.scrollBy({
      left: -distance,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (localStorage?.Address || Address) {
      SetAddress(Address ? Address : localStorage?.Address);
    }
  }, [localStorage?.Address, Address]);

  useEffect(() => {
    if (location?.search) {
      let paramdata = location.search.split("?Id=")[1];
      console.log("llsflklfk", DecryptData(paramdata));
      // SetTabName(
      //   isEmpty(DecryptData(paramdata)) ? paramdata : DecryptData(paramdata)
      // );
      let ifd = isEmpty(DecryptData(paramdata))
        ? paramdata
        : DecryptData(paramdata);
      Setid(ifd);
      getCategory(ifd);
    }
  }, [location?.search]);

  useEffect(() => {
    console.log(
      "typeof List?.[TabName]?.[Subcat]",
      typeof List?.[TabName]?.[Subcat]
    );
    if (typeof List?.[TabName]?.[Subcat] == "undefined") {
      getshop();
    }
  }, [TabName, Subcat, Address]);

  useEffect(() => {
    if (Address) setList({});
    if (DeliveryType) {
      setList({});
    }
    getCategory();
  }, [Id, Address, DeliveryType]);
  console.log("setList", List);
  const getCategory = async () => {
    var resp = await axiosfile.getcategory({
      from: "grocerycategory",
      Id: Id,
    });

    if (resp?.success == "success") {
      if (location?.search && resp?.data?.length > 0) {
        let paramdata = location.search.split("?Id=")[1];
        let defdata = resp?.data?.find(
          (it) => it._id == DecryptData(paramdata)
        );
        resp.data.map((cat,catind)=>{
          if(!isEmpty(cat?.SubCategory)){
            cat.SubCategory.unshift({
              _id: String(Date.now())+catind+'all',
              Categoryname: "All",
            })
          }
          else{
            cat.SubCategory = [{
              _id: String(Date.now())+'all',
              Categoryname: "All",
            }]
          }
        })
        if (defdata?._id) {
          SetTabName(defdata?._id);
          setSubcat(defdata?.SubCategory?.[0]?._id);
          // if()
        } else {
          SetTabName(resp.data?.[0]?._id);
          setSubcat(resp.data?.[0]?.SubCategory?.[0]?._id);
        }
      } else {
        SetTabName(resp.data?.[0]?._id);
        setSubcat(resp.data?.[0]?.SubCategory?.[0]?._id);
      }
      SetAllCat(resp?.data);
    }
  };
  console.log("Address=====", Address, address);
  const getshop = async (page, wish) => {
    var SendData = {
      Category: TabName,
      SubCategory: Subcat?.includes('all') ? 'all' : Subcat,
      page: page ? page : 1,
      Address: Address ? Address : address,
      DeliveryType,
      UserId: payload?._id,
    };
    var resp = await axiosfile.getshop(SendData);
    console.log("kjhgsjkhgs", resp, wish);
    if (resp?.success == "success") {
      setList(
        wish
          ? {
              ...List,
              ...{
                [TabName]: {
                  ...List?.[TabName],
                  ...{
                    [Subcat]: {
                      ...{
                        list: resp?.data,
                        loader: resp?.loader,
                        page: page ?? 1,
                      },
                    },
                  },
                },
              },
            }
          : {
              ...List,
              ...{
                [TabName]: {
                  ...List?.[TabName],
                  ...{
                    [Subcat]: {
                      ...List?.[TabName]?.[Subcat],
                      ...{
                        list: List?.[TabName]?.[Subcat]
                          ? [...List?.[TabName]?.[Subcat]?.list, ...resp?.data]
                          : resp?.data,
                        loader: resp?.loader,
                        page: page ?? 1,
                      },
                    },
                  },
                },
              },

            }
      );
    }
  };
  console.log("dsadsadsadasdasds", List);
  const LoadMore = (page) => {
    getshop(page + 1);
  };

  console.log("TabNameaaaaa", TabName);
  return useMemo(
    () => (
      <>
        <Header />
        <Container fluid>
          {TabName && (
            <Tab.Container id="left-tabs-example" defaultActiveKey={TabName}>
              <Row>
                <div className="gro_tab_bg">
                  <img
                    onClick={() => scrollingNavsLeft()}
                    className="cd_menualt_img rotate_left gc_left"
                    src={require("../assets/images/icon/right_arrow.png")}
                  />
                  <Col sm={12}>
                    <Nav
                      variant="pills"
                      id="outsider"
                      className="gro_scrolling_aligned"
                    >
                      {AllCat.length > 0 ? (
                        AllCat?.map((item, index) => {
                          return (
                            <>
                              <Nav.Item className="gro_top_tabs" id={item?._id}>
                                <Nav.Link
                                  className="gro_top_tabslink"
                                  eventKey={item?._id}
                                  onClick={() => {
                                    SetTabName(item?._id);
                                    navigate(
                                      `/store?Id=${EncryptData(item?._id)}`
                                    );
                                    setSubcat(item?.SubCategory?.[0]?._id);
                                  }}
                                >
                                  <div className="gro_tab_dtls">
                                    <img
                                      className="gro_tab_dtlsimg"
                                      src={
                                        item.CategoryImage
                                          ? `${config.IMG_URL}/admin/Category/${item.CategoryImage}`
                                          : require("../assets/images/Ellipse51.png")
                                      }
                                    />
                                    <p className="gro_tab_dtlstxt">
                                      {item?.Categoryname}
                                    </p>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        })
                      ) : (
                        <>Nodata</>
                      )}
                    </Nav>
                  </Col>
                  <img
                    className="cd_menualt_img gr_right"
                    onClick={() => scrollingNavsRight()}
                    src={require("../assets/images/icon/right_arrow.png")}
                  />
                </div>

                {Subcat ? (
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey={TabName}>
                        <Container fluid className="gro_swiper_pad gro_swiper_dtls_slider">
                          {AllCat?.length > 0 ? (
                            <Swiper
                              spaceBetween={30}
                              pagination={false}
                              breakpoints={{
                                320: {
                                  slidesPerView: 2,
                                },
                                576: {
                                  slidesPerView: 3,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                992: {
                                  slidesPerView: 12,
                                },
                              }}
                              navigation={true}
                              modules={[Pagination, Navigation]}
                              className="mySwiper"
                            >
                              {AllCat?.find(
                                (it) => it._id == TabName
                              )?.SubCategory?.map((item) => {
                                // console.log("dsdasdjsajdksa1",item,Subcat)
                                return (
                                  <div className="">
                                    <SwiperSlide>
                                      <div className={ item?._id == Subcat ? "gro_swiper_dtls active" : "gro_swiper_dtls"} onClick={() => {setSubcat(item?._id)}}>
                                        <img
                                          className="fro_swiper_img cursor_pointer_hand"
                                          src={
                                            item?.CategoryImage
                                              ? `${config.IMG_URL}/admin/subCategory/${item?.CategoryImage}`
                                              : (
                                                item?.Categoryname == 'All'
                                                ? require("../assets/images/menu/all_icon.svg").default 
                                                : require("../assets/images/menu/fast-food.png")
                                              )
                                              
                                          }
                                        />
                                        <p className="fro_swiper_txt cursor_pointer_hand">
                                          {item?.Categoryname}
                                        </p>
                                      </div>
                                    </SwiperSlide>
                                  </div>
                                );
                              })}{" "}
                            </Swiper>
                          ) : (
                            <>Nodata</>
                          )}
                          <Container>
                            <Row className="mt-5">
                              {List?.[TabName]?.[Subcat]?.list?.length > 0 ? (
                                List?.[TabName]?.[Subcat]?.list?.map((val) => {
                                  console.log("gfsfs", val);
                                  return (
                                    <Col
                                      xxl={4}
                                      xl={4}
                                      lg={4}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                    >
                                      <FoodCard
                                        from="cat"
                                        details={val}
                                        getshop={getshop}
                                        // list={wishlist}
                                        // getwishlist={() => getwishlist()}
                                      />
                                    </Col>
                                  );
                                })
                              ) : (
                                <>Nodata</>
                              )}
                            </Row>
                          </Container>

                          {List?.[TabName]?.[Subcat]?.loader ? (

                            <div className="w-100 text-center">
                              <button
                                className="blue_btn send_btn pad_albtn"
                                onClick={() =>
                                  LoadMore(List?.[TabName]?.[Subcat]?.page)
                                }
                              >
                                LoadMore
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Container>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                    </Tab.Content>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Tab.Container>
          )}
        </Container>
        <Footer />
      </>
    ),
    [TabName, Subcat, List]
  );
}

export default GroceryCategory;
