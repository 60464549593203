import React,{useEffect, useState} from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import {Link,useLocation,useNavigate} from 'react-router-dom'
import moment from 'moment'


import { DecryptData } from '../actions/common'
import Useaxiosfile from '../actions/useaxios';
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import config from '../actions/config';


function TrackOrder() {

    useEffect(() =>{
        window.scrollTo(0,0);
    },[])


    const location = useLocation();
    const navigate = useNavigate();


    const axiosfile = Useaxiosfile();


    const [detailid,SetDetailid] = useState(location.search.split('?order=')[1])
    const [Detail,SetDetail] = useState({});


    useEffect(()=>{
        if(detailid){
            OrderDetail();
        }
        
    },[detailid])

    const OrderDetail = async() => {
        
        let Resp = await axiosfile.GetOrderDetail({id:DecryptData(detailid)});
        
        SetDetail(Resp?.data?.Order)
    }


  return (
    <>
    <Header/>
    <Container>
        <Row className='h-100 my-5'>
        <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12} className="mb-4">
            <p className='cco_summary to_ordersum_ttl'>Order Summary</p>
            {console.log('ksfslgsfsljfsl',Detail)}
            { 
            Detail?.Product?.length > 0 && 
            Detail?.Product.map((val)=>{
                return(
                <>
                <Row className='pc_rightwholedtls'>
                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                    <div className='pc_rightimg'>
                        <img className='img-fluid pc_rightbur' src={`${config.IMG_URL}/Product/${val?.ProductId?.ProductImage}`}/>
                    </div>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                    <div className='pc_rightttlprice'>
                        <p className='pc_rightttl'>{val?.ProductId?.ProductName}</p>
                        <p className='pc_rightprice'>${val?.ProductId?.Price}</p>
                    </div>
                
          <div className='pc_rightfddtl'>
            <p className='me-3'>Size: Small</p>
            <p>Item: {val.Quantity}</p>
          </div>


                </Col>
            </Row>
            <hr className='pc_righthr'/></>)})}
         
           
            <div className='pc_wholerightprice'>
            <div className='pc_rightpricingdtls'>
                <p>Subtotal</p>
                <p className='pc_rightpriced'>+${Number(Detail?.Price?.CartValue).toFixed(2)}</p>
            </div>
            <div className='pc_rightpricingdtls'>
                <p>taxes</p>
                <p className='pc_rightpriced'>${Number(Detail?.Price?.Taxes).toFixed(2)}</p>
            </div>
            <div className='pc_rightpricingdtls'>
                <p>{Detail.OrderType} {moment(Detail?.DeliveryTime).format('DD/MM/yyyy')} by {moment(Detail?.DeliveryTime).format('hh:MM A')}</p>
                <p className='pc_rightpriced'>${Number(Detail?.Price?.DeliveryTimeCharges).toFixed(2)}</p>
            </div>
            {/* <div className='pc_rightpricingdtls'>
                <p>Coupon Code</p>
                <p className='pc_rightpriced pc_greenvalue'>-${Number(Detail?.Price?.Discount).toFixed(2)}</p>
            </div> */}
            <div className='pc_rightpricingdtls'>
                <p>Service Charge</p>
                <p className='pc_rightpriced'>${(Number(Detail?.Price?.SellerTipsInValue)+Number(Detail?.Price?.DriverTipsInValue)).toFixed(2)}</p>
            </div>
            </div>
            <hr/>
            <div className='pc_rightpricingdtls'>
                <p className='pc_payabaled'>Payable Amount</p>
                <p className='pc_rightpriced pc_totalpriced'>${Number(Detail?.Price?.PayAmount).toFixed(2)}</p>
            </div>

            <div className='to_status_time'>
                <div className='to_status_dtls'>
                    <div className='to_status_imgbg'>
                        <img className='to_status_img' src={require('../assets/images/icon/bike.png')}/>
                    </div>
                    {console.log('kjhfsfjkssf',Detail)}
                    <p className='to_ontheway_txt'>{Detail?.Status == 'completed' ? 'Completed' : (Detail?.Status == 'dispatch' ? 'Not-Ready' : (Detail?.Status == "shipping" ? 'In-transit' : 'Not-Ready') )}</p>
                </div>

                <div className='to_status_dtls'>
                    <div className='to_time_imgbg'>
                    <img className='to_status_img' src={require('../assets/images/icon/Time.png')}/>
                    </div>
                    {console.log('kjdkjsfsfs',Detail)}
                    <p className='to_ontheway_txt'>{Detail?.Status == 'started' ? 'waiting for approval' : ( (Detail?.Status == 'cancelbyuser' || Detail?.Status == 'cancelbyseller') ? 'Cancelled' : ( Detail?.Status == 'approvebyseller' ? 'Order Approved' : (Detail?.Status == 'dispatch' ? 'Order is Ready' : ( Detail?.Status == 'picked' ? 'Out-for-delivery' : (Detail?.Status == 'preparing' ? 'Preparing' : ( Detail?.Status == 'completed' ? 'Delivered' : 'paid' ) ) ))))}</p>
                </div>
            </div>

            <div className='to_dual_btns'>
            <Link className='to_continueshop_link' to="/store">
                <button className='blue_btn to_continueorder'>Continue Shopping</button></Link>
                <Link className='to_continueshop_link' to={`/revieworder?order=${detailid}`}>
                <button className='secondary_btn to_review_order'>Review Order</button></Link>

            </div>

            
           
            

            </Col>
            <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                <img className='img-fluid' src={require('../assets/images/map.png')}/>
            </Col>
        </Row>
    </Container>
    <Footer/>
    </>
  )
}

export default TrackOrder