import React,{useState,useEffect} from 'react'
import useAxiosFile from '../actions/useaxios';
import { isEmpty } from '../actions/common';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

export default function CancelOrder(props){

  console.log('dlksjfkljslfks',props)
    const axiosfile = useAxiosFile()


    const [showCancelOrder, setShowCancelOrder] = useState(true);
    const [Reason, SetReason] = useState('');
    const [error,SetError] = useState('');


    const { Detail, ClosePopup, cancelorderpopup, GetTabdatas } = props;

    const handleCloseCancelOrder = () => setShowCancelOrder(false);
    const handleShowCancelOrder = () => setShowCancelOrder(true);


    const Cancel = async() => {
        if(isEmpty(Reason)){
            SetError('Enter Reason');
        }
        else{
            let Resp = await axiosfile.OrderAction({action:'cancel',OrderId:Detail._id})
            console.log('kslkslfss',Resp,Detail._id);
            if(Resp?.success == 'success'){
                toast.success('Cancelled Successfully',1000)
                handleCloseCancelOrder()
                GetTabdatas(1)
                ClosePopup()
            }
            else{
                toast.error('Try-Again',1000)
            }
        }
        
    }

    return(
        <Modal
        show={cancelorderpopup}
        onHide={handleCloseCancelOrder}
        backdrop="static"
        keyboard={false}
        centered
      >
        
        <Modal.Body>
          <div className='cancer_order_tops'>
          <p className='vd_cancel_modalttl'>Cancel Order</p>
         
          <img onClick={()=>ClosePopup()} className='cancelorder_closeimg' src={require('../assets/images/icon/close.png')}/>
          </div>
          <div className='vd_mdl_imgtxt'>
            <img  className='vd_mdl_cancelimg' src={require('../assets/images/icon/Cancel.png')}/>
            <p className='vd_modal_cntnt'>Are you sure you want to cancel this order?</p>
            <p className='vd_modal_lowercntnt'>Your order is on the way. you cannot cancel now</p>
          </div>
          <p className='vd_mdl_smalltxt'>Tell us Why</p>
          <textarea className='contact_textarea vd_modal_textarea' placeholder='Write a short suggestion ' id="Reason" name="w3review" rows="3" cols="50" onChange={(e)=>SetReason(e.target.value)}></textarea>
            {error && <span>{error}</span>}
          <div className='to_dual_btns vd_dual_btns'>
            
                <button className='blue_btn to_continueorder me-3' onClick={()=>Cancel()}>Cancel Item</button>
                 </div>

        </Modal.Body>
        
      </Modal>
    )

}

    