import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";

import useAxiosFile from "../actions/useaxios";
import { isEmpty } from "../actions/common";
import config from "../actions/config";

function ChangePassword() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { state } = useLocation();
  const navigate = useNavigate();

  const axiosFile = useAxiosFile();

  const [otp, SetOtp] = useState("");
  const [form, setForm] = useState({
    Password: "",
    ConfirmPassword: "",
    Mobile: state?.Mobile,
  });
  const [otpVerified, SetOtpVerified] = useState(false);
  const [error, SetError] = useState({});

  const OnChange = (e) => {
    
    const { id, value } = e.target;
    setForm({
      ...form,
      ...{
        [id]: value,
      },
    });
  };

  const Verify = async () => {
    if (otp.length == 6) {
      // let senddata = {
      //   Type: "newmail",
      //   otp: otp,
      //   from: "buyer",
      // };
      let senddata = {};
      senddata.EmailId = state.newmail;
      senddata.Mobile = state?.Mobile;
      senddata._id = state?._id;
      senddata.Type = "newmail";
      senddata.from = "buyer";
     
        let Resp = await axiosFile.LoginReg(senddata);
        
        if (Resp.success == "success") {
          toast.success("OTP verified successfully");
          if(state.from == "email-change"){
           
            navigate('/detail/profile',{state:{from:'otp'}})
          }
          else  SetOtpVerified(true);
        } else {
          toast.error(Resp.msg);
          SetError({ OTP: Resp.msg });
        }
     } else {
      SetError({ OTP: "OTP Must Contain 6 Numbers" });
    }
  };

  const Validate = () => {
    let Error = {};
    if (isEmpty(form.Password)) {
      Error.Password = "Enter Password";
    } else if (config.PASSWORD.test(form.Password)) {
      Error.Password =
        "Password must contain 1 small letter, 1 capital letter, 1 special character, 1 number.";
    }
    if (form.Password !== form.ConfirmPassword) {
      Error.ConfirmPassword = "Password and Confirm Password must be same";
    }
    return Error;
  };

  const ChangePassword = async () => {
    let check = Validate();
    
    if (isEmpty(check)) {
      let senddata = {
        Type: "changepassword",
        Password: form.Password,
        from: "buyer",
      };
      let Resp = await axiosFile.LoginReg(senddata);
      
      if (Resp.success == "success") {
        toast.success("Password Changed Sucessfully");
        navigate("/signin");
      } else {
        toast.error(Resp.msg);
      }
    } else {
      SetError(check);
    }
  };

  

  return (
    <>
      <div className="regwhole_bg">
        <img
          className="singin_abs reg_abs"
          src={require("../assets/images/jamskew.png")}
          alt=""
        />

        <Container>
          <Row className="justify-content-center">
            <Col
              xxl={5}
              xl={5}
              lg={5}
              md={6}
              sm={12}
              xs={12}
              className="reg_whole_dtls"
            >
              <div className="reg_top_dtls">
                <h6 className="reg_top_title">Verification</h6>
                {/* <p className="reg_skip_txt">skip</p> */}
              </div>
              <p className="reg_welcome_txt">
                We sent a confirmation number at <br />
                <b>{ state.from == "email-change"? state.newmail: state.Mobile}</b>
              </p>

              <div className="cp_inputs_padding">
                {/* <form action="" className="my-4 confirm">
              <input className="otp" type="text" onKeyUp={(e) => {onTextOtp(e.target.value,0);if (e.target.value.length === 1){tabChange(1);}}} maxlength="1" />
              <input className="otp" type="text" onKeyUp={(e) => {onTextOtp(e.target.value,1);if (e.target.value.length === 1){tabChange(2);}}} maxlength="1" />
              <input className="otp" type="text" onKeyUp={(e) => {onTextOtp(e.target.value,2);if (e.target.value.length === 1){tabChange(3);}}} maxlength="1" />
              <input className="otp" type="text" onKeyUp={(e) => {onTextOtp(e.target.value,3)}} maxlength="1" />
            </form> */}

                {otpVerified ? (
                  <>
                    <Row className="">
                      <Col xxl={12} xsl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="">
                          <label className="signin_labels" for="mobile">
                            Password
                          </label>
                          <br />
                          <input
                            className="sigin_inputs"
                            type="password"
                            placeholder="Enter Password"
                            id="Password"
                            name="mobile"
                            onChange={(e) => OnChange(e)}
                          />
                          {error?.Password && <span>{error.Password}</span>}
                        </div>
                      </Col>

                      <Col xxl={12} xsl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="">
                          <label className="signin_labels" for="mobile">
                            Confirm Password
                          </label>
                          <br />
                          <input
                            className="sigin_inputs"
                            type="password"
                            placeholder="Confirm Password"
                            id="ConfirmPassword"
                            name="mobile"
                            onChange={(e) => OnChange(e)}
                          />
                          {error?.ConfirmPassword && (
                            <span>{error.ConfirmPassword}</span>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="w-100 text-center">
                      <button
                        className="blue_btn"
                        onClick={() => ChangePassword()}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <form action="" className="cp_otpform">
                      {/* {[0,1,2,3,4,5].map((val)=>{
    return(
      <input className="cp_otpinput" type="text" maxlength="1" id="otp" onChange={(e)=>OnChange(e)}/>
    )
  })} */}
  <div className="otp-inputs">
                      <OtpInput
                        value={otp}
                        onChange={SetOtp}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                      />
                      </div>
                      
                    </form>
                    
                    {error?.OTP && <p className="error-msg">{error.OTP}</p>}
                   
                    <div className="w-100 text-center">
                      <button
                        className="blue_btn mt-4"
                        onClick={() => Verify()}
                      >
                        Verify-OTP
                      </button>
                    </div>
                  </>
                )}
  {state.from == "email-change"? 
 <p className="reg_bottomtxt">
 Is  Email Id Wrong?
 <span>
   <Link to="/detail/profile" state={{from:'otp'}}>Change Email</Link>
 </span>
</p>
:
                <p className="reg_bottomtxt">
                  Did you remember it?
                  <span>
                    <Link to="/signin">Sign In</Link>
                  </span>
                </p>
}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ChangePassword;
