import React, { useEffect } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { Container, Row, Col } from "react-bootstrap";
import Newsletter from "../Components/Newsletter";

function JoinAJamske() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Container fluid className="joinjamske_banner">
        <Container className="h-100">
          <Row className="h-100">
            <Col
              className="jj_bannerdtls"
              xxl={4}
              xl={4}
              lg={4}
              md={10}
              sm={12}
              xs={12}
            >
              <h1 className="jj_bannerttl">
                Reopen Your Bussiness With Delpick
              </h1>
              <button className="blue_btn joina_jamske">Join A Delpick</button>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col xxl={7} xl={7} lg={7} md={10} sm={12} xs={12}>
            <p className="fm_banner_ttl jj_mobttl">
              Not just for restaurants, Delpick helps you win business in your
              neighborhood
            </p>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col className="mb-3" xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
            <div className="jj_smcardwhole">
              <img
                className="jj_smcardimg"
                src={require("../assets/images/apple.png")}
              />
              <p className="jj_smcardttl">Grocery Store</p>
              <p className="jj_smcardhint">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
              <button className="lightblue_btn">
                Grocery <i class="fa-solid fa-arrow-right-long ms-1" />
              </button>
            </div>
          </Col>
          <Col className="mb-3" xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
            <div className="jj_smcardwhole">
              <img
                className="jj_smcardimg"
                src={require("../assets/images/flower-pot 1.png")}
              />
              <p className="jj_smcardttl">Grocery Store</p>
              <p className="jj_smcardhint">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
              <button className="lightblue_btn">
                Grocery <i class="fa-solid fa-arrow-right-long ms-1" />
              </button>
            </div>
          </Col>
          <Col className="mb-3" xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
            <div className="jj_smcardwhole">
              <img
                className="jj_smcardimg"
                src={require("../assets/images/fast-food.png")}
              />
              <p className="jj_smcardttl">Grocery Store</p>
              <p className="jj_smcardhint">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
              <button className="lightblue_btn">
                Grocery <i class="fa-solid fa-arrow-right-long ms-1" />
              </button>
            </div>
          </Col>
          <Col className="mb-3" xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
            <div className="jj_smcardwhole">
              <img
                className="jj_smcardimg"
                src={require("../assets/images/mechanic.png")}
              />
              <p className="jj_smcardttl">Grocery Store</p>
              <p className="jj_smcardhint">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
              <button className="lightblue_btn">
                Grocery <i class="fa-solid fa-arrow-right-long ms-1" />
              </button>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="py-4 mt-5 ab_reviewdtls">
        <p className="fm_banner_ttl jj_mobttl">
          Joined the Many businesses already on Delpick
        </p>
        <Row className="mt-4">
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="jj_griddtls_whole">
              <div className="jj_griddtls">
                <div className="jj_gridsingle">
                  <img src={require("../assets/images/Breakfast.png")} />
                  <p>Wegmans</p>
                </div>
                <div className="jj_gridsingle">
                  <img src={require("../assets/images/Salads.png")} />
                  <p>Wegmans</p>
                </div>
                <div className="jj_gridsingle">
                  <img src={require("../assets/images/lucky.png")} />
                  <p>Wegmans</p>
                </div>
                <div className="jj_gridsingle">
                  <img src={require("../assets/images/napkin.png")} />
                  <p>Wegmans</p>
                </div>
                <div className="jj_gridsingle">
                  <img src={require("../assets/images/Sides.png")} />
                  <p>Wegmans</p>
                </div>
                <div className="jj_gridsingle">
                  <img src={require("../assets/images/snippers.png")} />
                  <p>Wegmans</p>
                </div>
                <div className="jj_gridsingle">
                  <img src={require("../assets/images/Salads1.png")} />
                  <p>Wegmans</p>
                </div>
                <div className="jj_gridsingle">
                  <img src={require("../assets/images/Breakfast1.png")} />
                  <p>Wegmans</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5">
        <Row className="d-flex align-items-center">
          <Col
            className="fm_withlove_center"
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <div className="fm_withlove_left">
              <h5 className="ab_serve_subttl">Vendors</h5>
              <p className="fm_whatwe_hint">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>

              <p className="fm_whatwe_hint mt-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.{" "}
              </p>

              {/* <button className='cmn_button'>Visit Us</button> */}
            </div>
          </Col>
          <Col
            className="fm_withlove_center"
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <img
              className="img-fluid"
              src={require("../assets/images/shopBig.jpg")}
            />
          </Col>
        </Row>
      </Container>

      <Container fluid className="contact_wholedtlsban py-4">
        <Container>
          <Row className="align-items-center">
            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
              <h3 className="jj_graphleftttl jj_mobttl">
                Insights you can use
              </h3>
              <p className="jj_grphlefthint">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
              <button className="lightblue_btn jj_lightblue">
                Merchant Portal
              </button>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
              <img
                className="img-fluid"
                src={require("../assets/images/graphpad.png")}
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
            <h3 className="jj_graphleftttl jj_mobttl text-center">
              Why Should you partner with delpick?
            </h3>
            <p className="jj_grphlefthint text-center">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
            <div className="jj_bottomtri_whole">
              <div className="jj_bottomsingle">
                <img
                  className="jj_bottomtri_img"
                  src={require("../assets/images/icon/globe.png")}
                />
                <div className="jj_bottomtri_txt">
                  <p className="jj_cities">500+ Cities</p>
                  <p className="jj_hints">In World</p>
                </div>
              </div>
              <div className="jj_bottomsingle">
                <img
                  className="jj_bottomtri_img"
                  src={require("../assets/images/icon/globe.png")}
                />
                <div className="jj_bottomtri_txt">
                  <p className="jj_cities">500+ Cities</p>
                  <p className="jj_hints">In World</p>
                </div>
              </div>
              <div className="jj_bottomsingle">
                <img
                  className="jj_bottomtri_img"
                  src={require("../assets/images/icon/globe.png")}
                />
                <div className="jj_bottomtri_txt">
                  <p className="jj_cities">500+ Cities</p>
                  <p className="jj_hints">In World</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Newsletter />
      <Footer />
    </>
  );
}

export default JoinAJamske;
