import React, { Component, useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Tab, Row, Nav, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { useDispatch, useSelector } from "react-redux";

import FoodCard from "../Components/FoodCard";
import Footer from "../Components/Footer";
import config from "../actions/config";
import useraxios from "../actions/useaxios";

import { DecryptData, EncryptData, isEmpty } from "../actions/common";
import Header from "../Components/Header";
import FoodDetailCard from "../Components/FoodDetailCard";

// import { useDispatch, useSelector } from 'react-redux';

function Search() {
  const { payload, token } = useSelector((state) => state.LoginReducer.User);
  const location = useLocation();

  const axiosfile = useraxios();
  // console.log("detailspay",payload);

  const [shopList, setshopList] = useState({});
  const [wishlist, setwishlist] = useState();

  const [Id, Setid] = useState({});
  const [Address, SetAddress] = useState("");

  const [TabName, SetTabName] = useState("");
  const [List, setList] = useState({});

  useEffect(() => {
    if (location?.search) {
      let paramdata = location.search.split("?keyword=")[1];
      Setid(
        isEmpty(DecryptData(paramdata)) ? paramdata : DecryptData(paramdata)
      );
    }
  }, [location.search]);

  useEffect(() => {
    if(Id)
    GetSearchData(1);
  }, [Id]);

  

  const GetSearchData = async(page,tab) => {
    
    let insttab = ''
    if(Id?.defaulttab&&TabName==''){
        insttab = Id?.defaulttab
    }
    else{
        if(TabName==''&&Id?.keyword){
            insttab = 'product'
        }
    }
    insttab = tab? tab : insttab;
    console.log('sljkfskjfkslfs',Id,TabName,insttab)
    let senddata = {
        keyword: Id?.keyword,
        limit:6,
        page:page,
        type: insttab,
    }
    if(Id?.keyword){
            SetTabName(insttab)
        let Resp = await axiosfile.GetSearchData(senddata);
        if(page==1){
            setList({...List,...{[insttab]:{list:Resp.data,page:page,count:Resp?.count}}})
        }
        else{
            setList({...List,...{[insttab]:{list:[...List[insttab].list,...Resp.data],page:page,count:Resp?.count}}})
        }
        
        console.log('sljkfskjfkslfsresp',Resp)
    }
    
  }


console.log("sljkfskjfkslfsopennn",List,TabName)
  const LoadMore = () => {
    };

    useEffect(()=>{
        if(payload){
            getwishlist()
        }
    },[payload])

  const getwishlist = async () => {
    var SendData = {
      Type: "Shop",
      UserId: payload?._id,
    };
    var resp = await axiosfile.getwishlist(SendData);
    console.log("shoffffp", resp);
    setwishlist(resp);
  };
  console.log("TabName", TabName,List);
  return useMemo(
    () => (
      <>
        <Header />
        <Container fluid>
          {TabName?
            <Tab.Container id="left-tabs-example" defaultActiveKey={TabName}>
              <Row>
                <div className="gro_tab_bg">
                  <Col sm={12}>
                    <Nav variant="pills" className="gro_scrolling_aligned">
                      
                        {['shop','product','category']?.map((item, index) => {
                          return (
                            <>
                              <Nav.Item
                                className="gro_top_tabs"
                                id={item?._id}
                              >
                                <Nav.Link
                                  className="gro_top_tabslink"
                                  eventKey={item}
                                  onClick={() => {GetSearchData((List?.[TabName]?.page?List?.[TabName]?.page:1),item)}}
                                >
                                  <div className="gro_tab_dtls">
                                    <img
                                      className="gro_tab_dtlsimg"
                                      src={
                                        item.CategoryImage
                                          ? `${config.IMG_URL}/admin/Category/${item.CategoryImage}`
                                          : require("../assets/images/Ellipse51.png")
                                      }
                                    />
                                    <p className="gro_tab_dtlstxt">
                                      {item}
                                    </p>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          );
                        })}
                    </Nav>
                  </Col>
                </div>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey={TabName}>
                        <Container fluid className="gro_swiper_pad">
                          <Container>
                            <Row className="mt-5">
                              {
                              TabName == 'shop' ? 
                              List?.[TabName]?.list?.map(
                                  (val) => {
                                    console.log("gfsfs", val);
                                    return (
                                      <Col
                                        xxl={4}
                                        xl={4}
                                        lg={4}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        className="mb-3"
                                      >
                                        <FoodCard
                                          details={val}
                                          list={wishlist}
                                          getwishlist={() => getwishlist()}
                                        />
                                      </Col>
                                    );
                                  }
                                )
                                :
                                (TabName == 'product'?
                                List?.[TabName]?.list?.map(
                                    (val) => {
                                      console.log("gfsfs", val);
                                      return (
                                        <Col
                                          xxl={6}
                                          xl={6}
                                          lg={6}
                                          md={6}
                                          sm={12}
                                          xs={12}
                                          className="mb-3"
                                        >
                                          <FoodDetailCard
                                            detail={val}
                                            list={wishlist}
                                            getwishlist={() => getwishlist()}
                                          />
                                        </Col>
                                      );
                                    }
                                  )
                                :
                                List?.[TabName]?.list?.map(
                                    (val) => {
                                      console.log("gfsfs", val);
                                      return (
                                        <Col
                                          xxl={3}
                                          xl={3}
                                          lg={3}
                                          md={6}
                                          sm={12}
                                          xs={12}
                                          className="mb-3"
                                        >
                                          <div className='fm_banner_separate'>
                                            <Link to={`/store?Id=${EncryptData(val._id)}`}><img className='fm_banner_sep_img' src={val.CategoryImage ? `${config.IMG_URL}/admin/Category/${val.CategoryImage}` : require('../assets/images/Ellipse51.png')}/></Link>
                                            <p className='fm_banner_plttxt'>{val.Categoryname}</p>
                                        </div>
                                        </Col>
                                      );
                                    }
                                  )
                                )
                               }
                            </Row>
                          </Container>

                          {
                            List?.[TabName]?.count > List?.[TabName]?.list?.length &&
                            <div className="w-100 text-center">
                              <button
                                className="blue_btn send_btn pad_albtn"
                                onClick={()=>GetSearchData(List?.[TabName]?.page+1,TabName)}
                              >
                                Load More
                              </button>
                            </div>
                          }
                        </Container>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
              </Row>
            </Tab.Container>
          :
          <div className="stire_nid_hwi">
            <div className="saved_bg_nine">
          <p className="mb-0">No Results Found</p>
          </div>
          </div>
           
        
        }
        </Container>
        <Footer />
      </>
    ),
    [TabName,List]
  );
}

export default Search;
