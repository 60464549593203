import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { EncryptData, isEmpty } from "./actions/common";
import useAxiosFile from "./actions/useaxios";
import { LoginRoutes } from "./routes/LoginRoutes";

import { PublicRoutes } from "./routes/PublicRoutes";
import { UserRoutes } from "./routes/UserRoutes";
import { loadStripe } from "@stripe/stripe-js";
import { useMemo } from "react";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SingInPopup from "./Components/SingInPopup";

/**
 *
 * isA means Register or login
 * isA = "XyZ" Register
 * isA = XYZ login
 *
 */

function App() {
  const usertoken = ("; " + document.cookie)
    .split(`; token=`)
    .pop()
    .split(";")[0]
    ? ("; " + document.cookie).split(`; token=`).pop().split(";")[0]
    : "";
  const { token, payload } = useSelector((state) => state.LoginReducer.User);
  const { _id, userAuth } = localStorage;
  const HavAccess = !isEmpty(_id) ? _id : payload?._id ? payload?._id : _id;
  const dispatch = useDispatch();

  const [Auth, SetAuth] = useState(usertoken ? usertoken : token);
  const [load, Setload] = useState(true);
  console.log("sakdlasjdaskdas", _id, Auth);
  console.log("sakdlasjdaskdas");
  useEffect(() => {
    Setload(true);
    SetAuth(usertoken ? usertoken : token);
    if (!isEmpty(HavAccess) && !isEmpty(Auth)) {
      AlreadyExits(HavAccess);
    } else if (HavAccess == "undefined") {
      localStorage.clear();
      document.cookie =
        "token" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      Setload(false);
    } else {
      dispatch({
        type: "Register_Section",
        Register_Section: { App_Inside: true },
      });
      Setload(false);
    }
  }, [token, _id]);
  const axiosfile = useAxiosFile();

  const AlreadyExits = async () => {
    await axiosfile.AlreadyExits({ data: EncryptData({ _id: HavAccess }) });
    Setload(false);
  };

  useEffect(() => {
    Getlink();
  }, []);

  const Getlink = async () => {
    let link_res = await axiosfile.sociallinks();
    console.log("linkres", link_res);
    dispatch({
      type: "Register_Section",
      Register_Section: { links: link_res?.data },
    });
  };

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

  return useMemo(
    () =>
      !load && (
        <BrowserRouter basename="/">
          <Routes>
            <Route path="*" element={<Navigate to="/" replace />} />
            {/* 
          {UserRoutes((Auth),stripePromise)}
          {LoginRoutes(HavAccess,Auth)} */}

            {PublicRoutes(null)}
            {Auth && HavAccess
              ? UserRoutes(true, stripePromise)
              : LoginRoutes(true, true)}
          </Routes>
          {/* <ToastContainer /> */}
        </BrowserRouter>
      ),

    [Auth, load, token]
  );
}

export default memo(App);
