import React, { useState, useEffect } from "react";

import useAxiosFile from "../actions/useaxios";
import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import config from '../actions/config'
import { useSelector } from "react-redux";
import { EncryptData, isEmpty, updateToast } from "../actions/common";
function Payment({ Setsubmitcall, submitcall, Datas, SetDisabledstatus }) {

  const axiosFile = useAxiosFile();


  const { payload, token } = useSelector((state) => state.LoginReducer.User);

console.log("Datas",Datas)
  
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    SetDisabledstatus({paynow:true})
    setMessage("");
    Setsubmitcall(false)
console.log("DeliveryAddress",Datas?.DeliveryAddress)
    try {
      if(isEmpty(Datas?.DeliveryAddress?.EmailId))
      {
        SetDisabledstatus({paynow:false})
        return updateToast(null,"Required EmailId For Choosen Address")
      }
      if(isEmpty(Datas?.DeliveryAddress?.Mobile))
      {
        SetDisabledstatus({paynow:false})
        return updateToast(null,"Required Mobile For Choosen Address","error")
      }
      if(isEmpty(Datas?.DeliveryAddress?.Zipcode))
      {
        SetDisabledstatus({paynow:false})
        return updateToast(null,"Required Zipcode For Choosen Address","error")
      }
      if(isEmpty(Datas?.DeliveryAddress?.FullName))
      {
        SetDisabledstatus({paynow:false})
        return updateToast(null,"Required FullName For Choosen Address","error")
      }
      console.log("Dsadasdasdsadsadas",stripe,elements)
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        SetDisabledstatus({paynow:false})
        return updateToast(null,"Stripe Fields are required","error")

      }
     

      const {error} = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${config.FRONT_URL}/orderconfirm`,
          shipping:{
            name:Datas.DeliveryAddress?.FullName,
            phone:Datas.DeliveryAddress?.Mobile,
            address:{
              city:Datas.DeliveryAddress?.City,
              line1:`${Datas.DeliveryAddress?.Doornumber},${Datas.DeliveryAddress?.Street}`,
              line2:Datas.DeliveryAddress?.Street2,
              country:Datas.DeliveryAddress.Area,
              state:Datas.DeliveryAddress.State,
              postal_code:Datas.DeliveryAddress.Zipcode
            }
          }
        },
      });

      console.log("error====",error)

      
      
      
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      
      if (error) {
        setMessage(error.message);
        
        
      } 
      else {
        

        
       
      }
      SetDisabledstatus({paynow:false})
      setIsLoading(false);
      Setsubmitcall(false)
    }
    catch (e) {
      console.error("!!!!!!!!Error stripe",e)
    }
  }

  useEffect(() => {
    if (submitcall) {
      handleSubmit()
    }
  }, [submitcall])


  
  /**lp */

  return (
    <>
      <LinkAuthenticationElement id="link-authentication-element"
      />
      <PaymentElement id="payment-element" />
      {/* <button disabled={isLoading || !stripe || !elements} id="submit">
        
      </button> */}
      <span id="button-text">
        {<div className="spinner" id="spinner">{message}</div>}
      </span>
    </>
  )
}

export default Payment;
