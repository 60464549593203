import { Route } from "react-router-dom"
import SignIn from "../Views/SignIn"
import Register from "../Views/Register"
import Protected from "./Protected"
import ForgotPassword from '../Views/ForgotPassword';
import ChangePassword from '../Views/ChangePassword';
import { isEmpty } from "../actions/common";


export const LoginRoutes=(Auths,Token)=>{

  const Auth = ( (Auths) && isEmpty(Token) ) ?
   true 
   : false  
   const Auth_sign = (Token && Auths) ? false : true
  console.log("<><><><><>",Auth,Auth_sign)
    return(
        <>
        
        <Route
        path="/signin"
        element={
          <Protected isSignedIn={true}>
            <SignIn/>
          </Protected>
        }
      />
       <Route
        path="/register"
        element={
          <Protected isSignedIn={true}>
            <Register/>
          </Protected>
        }
      />
    
      <Route
        path="/forgotpw"
        element={
          <Protected isSignedIn={true}>
            <ForgotPassword/>
          </Protected>
        }
      />
     
      

      </>
    )
}

