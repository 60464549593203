import React,{useState} from 'react'
import {Card,Modal,Form ,Row,Col} from 'react-bootstrap'
import config from "../actions/config"
import useAxiosFile from '../actions/useaxios';
import { useNavigate } from 'react-router-dom';
import { EncryptData, isEmpty } from '../actions/common';
function FoodDetailCard(props) {
  console.log("gsgsgsg",props);



  const navigate = useNavigate();


  return (
    <>

    <Card onClick={()=>navigate(`/detail/?Id=${String(EncryptData(props?.detail?._id))}`)} className='h-100'>
      <Card.Body>
        <div className='fooddtlcard_dtls'>
            <div className='fooddtlcard_prices'>
                <h5>{props?.detail?.ProductName}</h5>
                <div className='fooddtlcard_lists'>
                  {console.log("props?.detail?.AdditionalInfo.find(it=>it?.type == ",props?.detail?.AdditionalInfo?.find(it=>it?.type == "property"))}
                {!isEmpty(props?.detail?.AdditionalInfo?.find(it=>it?.type == "property"))&&
                props?.detail?.AdditionalInfo?.find(it=>it?.type == "property")?.values?.slice(0,3)?.map(it=>{return(
                <ul>
                    <li>{it.value}</li>
                    <li>{it.price}</li>
                </ul>
                
                )})}
               
                </div>
                <h5 className='fooddtlcard_dollar mt-2'>${props?.detail?.Price}</h5>
            </div>

            <img className='fooddtlcard_product' src={props?.detail?.ProductImage ? `${config.IMG_URL}/Product/${props?.detail?.ProductImage}` :require('../assets/images/items/image52.png')}/>

        </div>
        
      </Card.Body>
    </Card>

    </>
  )
}

export default FoodDetailCard