import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Header from '../Components/Header'
import { Container, Tab, Row, Nav, Col,Accordion } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import jQuery from 'jquery'
import FoodCard from '../Components/FoodCard';
import Footer from '../Components/Footer';
import config from "../actions/config"
import useraxios from "../actions/useaxios"
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from "../actions/common";
import { toast } from "react-toastify";
import Newsletter from "../Components/Newsletter";
function Faq() {
  const axiosfile = useraxios();
  const [faq,setFAq]=useState();
  var [email,setEmail] = useState('');

  useEffect(() => {
    getFaqList();
  }, []);


  const getFaqList = async () => {
    var resp = await axiosfile.getFaqList();
    if (resp?.success == 'success') {
      setFAq(resp.data)
    }
  };

  const NewsLetter = async() =>{
    const id = toast.loading("Subscribing...");
    
    let err = {};
    if(email=='') err.email="Email Id Required";
    if(email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
    // SetError(err);
    // console.log('fhbdfhbdf',email)
    if(isEmpty(err)){
        let resp = await axiosfile.Newsletter({email : email});
        if(resp.success === 'success'){
            toast.update(id ,{render:"Successfully Subscribed for NewsLetter", type: "success", isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
            setEmail('');
        }
        else{
            toast.update(id ,{ render : "Already User", type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
            // SetError({email:resp.msg});
        }
    }
    else{
        toast.update(id ,{ render : err.email, type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
    }
  }

return (
    <>
    <Header/>
    <Container fluid className='faq_bannerbg '>
        <h3 className='ab_bannerttl'>FAQ</h3> 
    </Container>
    <Container fluid>

    <Accordion defaultActiveKey="0" flush className='faq_accordion'>

    { faq?.length > 0 && faq?.map((data,key) => {
      return(
      <Accordion.Item eventKey={JSON.stringify(key)}>
        <Accordion.Header>{data?.question}</Accordion.Header>
        <Accordion.Body>
        <div dangerouslySetInnerHTML={{__html:data?.answer}}></div> 
        </Accordion.Body>
      </Accordion.Item>
        )})}
    </Accordion>
    </Container>


    <Newsletter/>

    <Footer/>
    </>
  )
}

export default Faq