
var EnvName = "demoserver";


var key = {};
key.KEY = "CardBo@rD1290%6Fine3";
key.ONEDAYINSECONDS = 0;
key.BLOCKS_PER_YEAR = 0;
key.RPAD_ADDRESS = "";
key.ROUTER = "";
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
key.MOBILE = /^\d{10}$/;
key.NumOnly = /^\d+$/;
key.PASSWORD =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
key.OnlyAlbhabets = /^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _]*)$/;
key.notSpecil = /^[a-zA-Z0-9]+$/;
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/;

key.limit = 50;
key.NumDigitOnly = /[^0-9\.]/g;
key.NumberOnly = /[^0-9]/g;

if (EnvName === "local") {
  key.Seller = "http://localhost:3000";
  key.Deliver = "http://localhost:3000";
  key.FRONT_URL = "http://localhost:3000";
  key.BACK_URL = "http://localhost:2000/v1";
  key.IMG_URL = "http://localhost:2000";

  // key.ADMIN_URL       =   'http://localhost:2000/'
} else if (EnvName === "demo") {
  key.Seller = "http://localhost:3000";
  key.Deliver = "http://localhost:3000";

  key.FRONT_URL = "http://localhost:3000";
  key.BACK_URL = "http://192.53.121.26:7053/v1";
  key.IMG_URL = "http://192.53.121.26:7053";
}
else if (EnvName === "demoserver") {
  key.Seller = "https://jamskedemosellerpanel.maticz.in";
  key.Deliver = "https://jamskedemo.maticz.in";
  key.FRONT_URL = "https://jamskedemo.maticz.in";
  key.BACK_URL = "https://jamskedemoapi.maticz.in/v1";
  key.IMG_URL = "https://jamskedemoapi.maticz.in";
}
else if (EnvName === "demossl") {
  key.Seller = "https://jamske.maticz.in/seller";
  key.Deliver = "https://jamske.maticz.in/Deliver";

  key.FRONT_URL = "https://jamske.maticz.in";
  key.BACK_URL = "https://backend-jamske.maticz.in/v1";
  key.IMG_URL = "https://backend-jamske.maticz.in";
} else if (EnvName === "production") {
  key.Seller = "https://seller-panel.delpick.com";
  // key.Deliver = "https://jamske.maticz.in/Deliver";

  key.FRONT_URL = "https://user.delpick.com";
  key.BACK_URL = "https://api.delpick.com/v1";
  key.IMG_URL = "https://api.delpick.com";
}

key.Keys = process.env.Decryptkey;
key.register = "XyZ";
key.login = "XYZ";
key.AddressType = [
  { label: "Home", value: "Home" },
  { label: "Office", value: "Office" },
  { label: "Other", value: "Other" },
];
export default key;
