
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import '../css/style.css'

import config from '../actions/config';
import { EncryptData, isEmpty, NumberOnly } from '../actions/common';
import Useaxiosfile from '../actions/useaxios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Initial_Register } from '../redux/constants';
import Newsletter from '../Components/Newsletter';
// import noimage from '../assets/images/noimage.jpg'

function Home() {



    const { token } = useSelector(state => state.LoginReducer.User)
    const { show } = useSelector(state => state.LoginReducer.initialRegister)
    const { AddressInName } = useSelector((state) => state.LoginReducer);

    const navigate = useNavigate();
    const dispatch = useDispatch();



    const [buttonActive, setButtonActive] = useState(1);

    const initform = {
        FirstName: '',
        LastName: '',
        EmailId: '',
        Mobile: '',
        Password: '',
        Address: {
            type: 'Home',
            Area: '',
            City: '',
            Street2: "",
            Street: "",

            FullName: "",
            EmailId: "",
            Mobile: "",
            Zipcode: "",
            State: "",
            defaultAddress: true,

        }
    };
    const initname = {
        FirstName: 'First Name',
        LastName: 'Last Name',
        EmailId: 'Email-Id',
        Mobile: 'Mobile Number',
        Password: 'Password',
        Street2: 'Society/Street name',
        Street: 'House no/ Flat no/Floor'
    };
    const [formdata, SetFormdata] = useState(initform);
    const [displayname, SetDisplayName] = useState(initname);
    const [Error, SetError] = useState({});
    const [Category, SetCategory] = useState([]);
    const [userpaneldata, setUserpaneldata] = useState()
    const [placechoosepopup, setplacechoosepopup] = useState(false);

    console.log("SDgf", userpaneldata);
    //coupon
    const [coupondata, setCoupondata] = useState()
    console.log("tyu", coupondata);

    const axiosfile = Useaxiosfile();

    useEffect(() => {
        showuserpanel()
    }, [])
    // const showuserpanel = async () => {

    //     var res = await axiosfile.showuserpanel({ page: "home" })
    //     console.log("qwrtw", res?.data.data);
    //     setUserpaneldata(res?.data.data)
    // }
    const showuserpanel = async () => {
        var obj = {}
        var res = await axiosfile.showuserpanel({ page: "home" })
        console.log("rgnzdfsbg", res);
        res?.data?.data?.map((mappeddata) => {
            console.log("mappeddata", mappeddata);
            obj[mappeddata?.question] = mappeddata

        })
        setUserpaneldata(obj)
    }
    console.log("rtyhrtdhuytju", userpaneldata);
    // useEffect(() => {	
    //     const usertoken = ("; " + document.cookie).split(`; token=`).pop().split(";")[0]
    //     ? ("; " + document.cookie).split(`; token=`).pop().split(";")[0]

    //     : "";
    //     if(token||usertoken){	
    //         RegisterFormClose(false)
    //     }
    // }, [token])
    //coupon
    useEffect(() => {
        coupondetails()
    }, [])
    const coupondetails = async () => {
        // var obj = {}
        var res = await axiosfile.coupondetails({ status: true })
        console.log("edgheess", res);
        var dd= res?.data?.data
        setCoupondata(dd)
    }
    console.log("rgrtfgbrtfhgbt", coupondata);

    const OnChange = (e) => {
        if (e?.target) {
            const { name, value, id } = e.target;

            if (!isEmpty(Error[id])) {
                SetError({ ...Error, ...{ [id]: '' } });
            }

            if (name == 'Address') {
                if (!isEmpty(Error[id])) {
                    SetError({ ...Error, ...{ [id]: '' } });
                }
                SetFormdata({ ...formdata, ...{ Address: { ...formdata.Address, ...{ [id]: value } } } })
            }
            else {
                if (['FirstName', 'LastName', 'EmailId', 'Mobile'].includes(id)) {
                    SetFormdata({ ...formdata, ...{ [id]: value, ['Address']: { ...formdata['Address'], ...{ [(id == "LastName" || id == "FirstName") ? "FullName" : id]: (id == "LastName") ? formdata.FirstName + " " + value : (id == "FirstName") ? value + " " + formdata.LastName : value } } } });
                }
                else SetFormdata({ ...formdata, ...{ [id]: value } });
            }
        }
        else if (typeof (e) == 'string') {
            SetFormdata({ ...formdata, ...{ Address: { ...formdata.Address, ...{ type: e } } } })
        }
    }

    const Validate = () => {
        let error = {};
        (Object.keys(formdata)).map((val) => {
            console.log('lsjflksjfs', val, displayname[val])
            if (isEmpty(formdata[val])) {
                error[val] = displayname[val] + " is Required"
            }
            else {
                if (val == 'Address') {
                    (Object.keys(formdata?.Address)).map((addr) => {
                        if (isEmpty(formdata.Address[addr])) {
                            error[addr] = (displayname?.[addr] ? displayname?.[addr] : addr) + " is Required"
                        }
                    })
                }
                if (val == 'Mobile') {
                    if (!config.NumOnly.test(formdata[val])) {
                        error[val] = "Mobile Number Must be a Number"
                    }
                }
                if (val == 'EmailId') {
                    if (!config.EMAIL.test(formdata[val])) {
                        error[val] = "Enter Valid EmailId"
                    }
                }
                if (val == 'Password') {
                    if (!config.PASSWORD.test(formdata[val])) {
                        error[val] = "Password must contain alphanumeric, must contain 8 to 15 letters."
                    }
                }
            }
            console.log('lsjflksjfs222', error)
        })
        return error
    }

    const OnClick = (e) => {

    }

    const OnRegister = async () => {
        console.log('lskkslfs')
        let error = Validate();
        console.log('lsjksjfslfs', error)
        if (isEmpty(error)) {
            let senddata = formdata;
            senddata.Type = 'register';
            senddata.from = 'buyer';
            var Resp = await axiosfile.LoginReg(formdata);

            if (Resp?.success == 'success') {

                RegisterFormClose(false)
                //   setTimeout(() => {
                //   if(localStorage._id)
                navigate('/signin')

                //   }, 2000);
                toast.success('Registered Successfully', 1000)
            }
            else {
                toast.error(Resp.msg, 1000)
                if (Resp?.error) {
                    SetError(Resp?.error)
                }

            }
        }
        else {
            SetError(error)
        }
    }

    useEffect(() => {
        Getcategory();
    }, [])

    const Getcategory = async () => {
        let Resp = await axiosfile.getcategory({ from: 'home' });
        SetCategory(Resp?.data)
    }

    const RegisterFormClose = (data) => {
        dispatch({
            type: Initial_Register,
            initialRegister: {
                initialRegister: {
                    show: data
                },
            }
        })
    }

    const CatChoose = (val) => {
        console.log("AddressInName", AddressInName)
        if (AddressInName) { navigate(`/store?Id=${EncryptData(val?._id)}`) }
        else {
            toast.warn("Choose Address to Explore shops")
            setplacechoosepopup(true);
        }
        
        
    }

    return (
        <>
            <Header 
                placechoosepopup = {placechoosepopup}
                setplacechoosepopup = {setplacechoosepopup}
            />
            <Container fluid className='hm_banner_bg'>
                <Container>
                    <p className='fm_banner_ttl'>Find Things You'll Love</p>
                    <p className='fm_banner_hint'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

                    <div className='fm_banner_five'>
                    {
                    Category?.length > 0 &&
                    Category?.map((val)=>{
                        return(
                        <div className='fm_banner_separate' onClick={()=>CatChoose(val)}>
                       <img className='fm_banner_sep_img' src={val.CategoryImage ? `${config.IMG_URL}/admin/Category/${val.CategoryImage}` : require('../assets/images/Ellipse51.png')}/>
                        <p className='fm_banner_plttxt'>{val.Categoryname}</p>
                        </div>
                        )
                    })
                }

                        {/* <div className='fm_banner_separate'>
                    <img className='fm_banner_sep_img' src={require('../assets/images/Ellipse52.png')}/>
                    <p className='fm_banner_plttxt'>Cafe/Restaurant</p>
                </div>
                <div className='fm_banner_separate'>
                    <img className='fm_banner_sep_img' src={require('../assets/images/Ellipse53.png')}/>
                    <p className='fm_banner_plttxt'>Flower Shop</p>
                </div>
                <div className='fm_banner_separate'>
                    <img className='fm_banner_sep_img' src={require('../assets/images/Ellipse54.png')}/>
                    <p className='fm_banner_plttxt'>Mechanic</p>
                </div>
                <div className='fm_banner_separate'>
                    <img className='fm_banner_sep_img' src={require('../assets/images/Ellipse55.png')}/>
                    <p className='fm_banner_plttxt'>Gym</p>
                </div> */}
                    </div>


                </Container>
            </Container>

            <Container fluid className='fm_whatwe_serve'>
                <Row>
                    <Col className='fm_serve_left' xxl={5} xl={5} lg={5} md={5} sm={5} xs={12}>
                        <img className='fm_serve_left_img' src={(userpaneldata?.homemiddle?.Image?.[0]) ? `${config.IMG_URL}/cms_images/${userpaneldata?.homemiddle?.Image?.[0]}` : require('../assets/images/flowers.jpg')} />
                        <img className='fm_serve_left_absimg' src={(userpaneldata?.homemiddle?.Image?.[1]) ? `${config.IMG_URL}/cms_images/${userpaneldata?.homemiddle?.Image?.[1]}`:  require('../assets/images/orange.jpg')} />

                        {/* {userpaneldata?.Image?.map((immagess) => (
                            <>
                                {console.log("immagess", immagess)}
                                {
                                    <img className='fm_serve_left_absimg' src={`http://localhost:2000/cms_images/${immagess[0]}`} />} 
                              {  <img className='fm_serve_left_absimg' src={`http://localhost:2000/cms_images/${immagess[1]}`} />} 

                             </>
                        ))}  */}

                    </Col>
                    <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={0}></Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="fm_whatwe_whole">
                        <h4 className='fm_serve_rightttl'>{userpaneldata?.homemiddle?.question ? userpaneldata?.homemiddle?.question :"What We Serve"} </h4>
                        <p className='fm_whatwe_hint'><div dangerouslySetInnerHTML={{ __html: userpaneldata?.homemiddle?.answer ? userpaneldata?.homemiddle?.answer : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s," }}></div>  </p>
                        <p className='fm_whatwe_hint mt-3'><div dangerouslySetInnerHTML={{ __html: userpaneldata?.homemiddle?.answer ? userpaneldata?.homemiddle?.answer : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s," }}></div> </p>
                        <h5 className='fm_moreabt_link'><Link to="/about">{userpaneldata?.homemiddle?.linkurl ? userpaneldata?.homemiddle?.linkurl : "MORE ABOUT US "}<i class="fa-solid fa-arrow-right-long ms-3" /></Link></h5>

                    </Col>
                </Row>
            </Container>
            <Container className='fm_withlove'>
                <p className='fm_banner_ttl'>{userpaneldata?.homefooter?.question ? userpaneldata?.homefooter?.question : "We Made With Love"}</p>
                <Row className='d-flex align-items-center mt-5'>
                    <Col className='fm_withlove_center mb-3' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='fm_withlove_left'>
                            <p className='fm_johndoe'>{userpaneldata?.homefooter?.name ? userpaneldata?.homefooter?.name : "Mr.John Doe"}</p>
                            <p className='fm_whatwe_hint'><div dangerouslySetInnerHTML={{ __html: userpaneldata?.homefooter?.answer ? userpaneldata?.homefooter?.answer : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."}}></div> </p>

                            <p className='fm_whatwe_hint mt-3'><div dangerouslySetInnerHTML={{ __html: userpaneldata?.homefooter?.answer ? userpaneldata?.homefooter?.answer : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged." }}></div> </p>

                            <Link to="/store"><button className='cmn_button'>{userpaneldata?.homefooter?.linkurl ? userpaneldata?.homefooter?.linkurl : "Visit Us"}</button></Link>
                        </div>
                    </Col>
                    <Col className='fm_withlove_center' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <img className='img-fluid' src={(userpaneldata?.homefooter?.Image?.[0]) ? `${config.IMG_URL}/cms_images/${userpaneldata?.homefooter?.Image?.[0]}` : require('../assets/images/shopBig.jpg')} />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='mt-5 fm_blurdtls'>

                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='fm_blursupport fm_blursupport_res'>
                            {console.log("vrgzrgth",coupondata,coupondata?.[0]?.ProductId?.[0]?.ProductImage)}
                            <img className='img-fluid img_percent' src={(coupondata?.[0]?.ProductId?.[0]?.ProductImage)?`${config.IMG_URL}/Product/${coupondata?.[0]?.ProductId?.[0]?.ProductImage}`: require('../assets/images/Offer1.png')} />
                            <div className='fm_blur'>
                                <h5 className='fm_blur_blue'>{coupondata?.[0]?.DiscountInPercentange ? coupondata?.[0]?.DiscountInPercentange : "50% OFF"}</h5>
                                <p className='fm_blut_sub'>{coupondata?.[0]?.ProductId?.[0]?.ProductName ? coupondata?.[0]?.ProductId?.[0]?.ProductName : "COCO STRAWBERRY PANCAKE"}</p>
                                <p className='fm_blut_hint'><div dangerouslySetInnerHTML={{ __html: coupondata?.[0]?.ProductId?.[0]?.ProductDescription ? coupondata?.[0]?.ProductId?.[0]?.ProductDescription : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard." }}></div></p>
                                <Link to="/store"><button className='shopnow_btn'>SHOP NOW</button></Link>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                    {console.log("trhrtg",coupondata,`${config.IMG_URL}/Product/${coupondata?.[1]?.ProductId?.[0]?.ProductImage}`,`${config.IMG_URL}/Product/${coupondata?.[0]?.ProductId?.[0]?.ProductImage}`)}

                        <div className='fm_blursupport'>
                            <img className='img-fluid img_percent' src={(coupondata?.[1]?.ProductId?.[0]?.ProductImage)?`${config.IMG_URL}/Product/${coupondata?.[1]?.ProductId?.[0]?.ProductImage}`: require('../assets/images/offer2.jpg')}  />
                            <div className='fm_blur'>
                                <h5 className='fm_blur_blue'>{coupondata?.[1]?.DiscountInPercentange ? coupondata?.[1]?.DiscountInPercentange : "20% OFF"}</h5>
                                <p className='fm_blut_sub'>{coupondata?.[1]?.ProductId?.[0]?.ProductName ? coupondata?.[1]?.ProductId?.[0]?.ProductName : "SWEET PRAWN NOODLE"}</p>
                                <p className='fm_blut_hint'><div dangerouslySetInnerHTML={{ __html: coupondata?.[1]?.ProductId?.[0]?.ProductDescription ? coupondata?.[1]?.ProductId?.[0]?.ProductDescription : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard." }}></div></p>
                                <Link to="/store"><button className='shopnow_btn'>SHOP NOW</button></Link>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>

            <Newsletter />
            {/* <Button variant="primary" onClick={handleShowModal}>
        Launch static backdrop modal
      </Button> */}


            <Footer />


        </>
    )
}

export default Home