import React, { useRef, useState } from "react";
import { Row, Col, Container, Form, InputGroup, Modal } from "react-bootstrap";
import Select from "react-select";
import useAxiosFile from "../actions/useaxios";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isEmpty, LoadToast, updateToast } from "../actions/common";
import OTPInput from "react-otp-input";
import { GetAddressBasedOnLatLng, GetLatLngBasedOnAddress } from "./Map/geocode";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import config from "../actions/config";
import GMap from "./Map/GMap";
import { useCallback } from "react";

const optionsGender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "others", label: "Others" },
];

export default function SingInPopup({ show_sign, setShow_sign, Addtocart }) {
  console.log("show", show_sign);

  const navigate = useNavigate();

  const axiosFile = useAxiosFile();
  const { Address } = useSelector((state) => state.LoginReducer);

  const changePositiion = ()=>
  {
    var addressInputElement =document.getElementById("parent_comple");

setTimeout(() => {
  var pacContainer =document.querySelectorAll('.pac-container');
  for(var i=0;i<pacContainer.length;i++)
  {
    addressInputElement.appendChild(pacContainer[i]);

  }
  console.log(addressInputElement,pacContainer,"e;e");
  // addressInputElement.parentElement.append(pacContainer);
}, 100);


  }

  // const otpInputRefs = Array(digits)
  //   .fill(0)
  //   .map((_, index) => useRef(null));
  const otpInputRefs = useRef([0, 0, 0, 0, 0, 0]);

  console.log("lslhdshlsfs", otpInputRefs);
  const initform = {
    Mobile: "",
    Rememberme: false,
  };

  const initregform = {
    FirstName: "",
    LastName: "",
    EmailId: "",
    Mobile: "",
    // Password: '',
    Gender: "",
    // ConfirmPassword: '',
    Address: {
      type: "Home",
      Area: "",
      City: "",
      Street2: "",
      Street: "",
      FullName: "",
      EmailId: "",
      Mobile: "",
      Zipcode: "",
      State: "",
      defaultAddress: true,
      FullAddress:"",
    },
  };
  const initname = {
    FirstName: "First Name",
    LastName: "Last Name",
    EmailId: "Email-Id",
    Mobile: "Mobile Number",
    // Password: 'Password',
    ConfirmPassword: "Confirm-Password",
    Gender: "Gender",
  };

  const AddressName = {
    FullName: "Full Name",
    EmailId: "Emai-Id",
    Mobile: "Mobile Number",
    type: "Address Type",
    // Street: "House Name / Flat no",
    // Area: "Area",
    // City: "City",
    // Zipcode: "Zipcode",
    // State: "State",
    FullAddress: "Full Address"
  };

  const [formdata, SetFormdata] = useState(initform);
  const [displayname, SetDisplayName] = useState(initname);
  const [addressname, SetAddressName] = useState(AddressName);
  const [Error, SetError] = useState({});
  const [otp, setotp] = useState("");
  const [ShowScreen, SetShowScreen] = useState("");
  const [showGender, setShowGender] = useState(false);
  const [showDOB, setShowDOB] = useState(false);
  const [passwordText, setPasswordText] = useState("password");
  const [buttonActive, setButtonActive] = useState(1);
  const [OTPInputLength, SetOTPInputLength] = useState([1, 2, 3, 4, 5, 6]);
  const digits = 6;
  const [GenderType, SetGenderType] = useState("Gender");
  const [disabledstatus,SetDisabledstatus] = useState({});
  const [addressselectstatus,SetAddressSelectStatus] = useState(true)
  const [show_map, setShow_map] = useState(false);

  // const otpInputRefs = useRef(null)
  // const [otparr,SetOTPArr] = useState(['','','','','',''])

  const handleCloseGender = () => setShowGender(false);
  const handleShowGender = () => setShowGender(true);

  const handleCloseDOB = () => setShowDOB(false);
  const handleShowDOB = () => setShowDOB(true);

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#A71D24"
          : isSelected
          ? "#A71D24"
          : isFocused
          ? "#A71D24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid #232222 ",
      borderRadius: 30,
      marginTop: "10px",
      cursor: "pointer",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "44px",
      borderRadius: 30,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      marginTop: "10px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  console.log("dsfhjkshfs", formdata);

  const OnChange = (e, ids) => {
    SetError({});
    console.log("fkdjgksdjgjsdg", e);
    if (ShowScreen == "register") {
      if (e?.target) {
        const { name, value, id } = e.target;

        if (!isEmpty(Error[id])) {
          SetError({ ...Error, ...{ [id]: "" } });
        }

        if (name == "Address") {
          if (!isEmpty(Error[id])) {
            SetError({ ...Error, ...{ [id]: "" } });
          }
          SetFormdata({
            ...formdata,
            ...{ Address: { ...formdata.Address, ...{ [id]: value } } },
          });
        } else {
          if (["FirstName", "LastName", "EmailId", "Mobile"].includes(id)) {
            SetFormdata({
              ...formdata,
              ...{
                [id]: value,
                ["Address"]: {
                  ...formdata["Address"],
                  ...{
                    [id == "LastName" || id == "FirstName" ? "FullName" : id]:
                      id == "LastName"
                        ? formdata.FirstName + " " + value
                        : id == "FirstName"
                        ? value + " " + formdata.LastName
                        : value,
                  },
                },
              },
            });
          } else SetFormdata({ ...formdata, ...{ [id]: value } });
        }
      } else if (typeof e == "string") {
        if (ids == "Mobile") {
          return SetFormdata({
            ...formdata,
            ...{
              [ids]: e,
              ["Address"]: { ...formdata["Address"], ...{ [ids]: e } },
            },
          });
        }
        return SetFormdata({
          ...formdata,
          ...{ Address: { ...formdata.Address, ...{ type: e } } },
        });
      }
    } else {
      const id = e?.target?.id,
        value = e?.target?.value;
      SetFormdata({ ...formdata, ...{ [id ?? ids]: value ?? e } });
    }
  };
  const Validate = () => {
    let error = {};
    if (ShowScreen == "") {
      if (isEmpty(formdata?.Mobile)) error.Mobile = "Mobile Number required";
      if (formdata.Mobile && !isValidPhoneNumber(formdata.Mobile)) {
        error.Mobile = "Enter Valid Mobile number";
      }
    } else if (ShowScreen == "otp") {
      if (isEmpty(otp)) error.otp = "OTP required";
      if (!isEmpty(otp) && otp.length != 6)
        error.otp = "OTP Length Must have 6";
    } else if (ShowScreen == "register") {
      Object.keys(formdata).map((val) => {
        if (isEmpty(formdata[val])) {
          error[val] = displayname[val] + " is Required";
        } else {
          if (val == "Address") {
            Object.keys(formdata?.Address).map((addr) => {
              if (isEmpty(formdata.Address[addr]) && addressname[addr]) {
                error[addr] = addressname[addr] + " is Required";
              }
            });
          }
          if (val == "Mobile") {
            if (!isValidPhoneNumber(formdata[val])) {
              error[val] = "Enter Valid Mobile Number";
            }
          }
          if (val == "EmailId") {
            if (!config.EMAIL.test(formdata[val])) {
              error[val] = "Enter Valid EmailId";
            }
          }
          if (val == "Password") {
            if (!config.PASSWORD.test(formdata[val])) {
              error[val] =
                "Password must contain alphanumeric, must contain 8 to 15 letters.";
            }
          }
        }
      });
    }
    return error;
  };
  const Loginclick = async (from) => {
    SetDisabledstatus({ [from]: true });
    setotp("");
    const id = LoadToast();
    let error = Validate();
    console.log("error", error);
    if (isEmpty(error) || from == "resend") {
      let senddata = formdata;
      senddata.Type = "sendotp";
      senddata.from = "buyer";
      var Resp = await axiosFile.LoginUser(senddata);
      console.log("Resp", Resp);
      updateToast(id, Resp?.msg, Resp?.success);
      if (Resp?.success == "success") {
        SetShowScreen("otp");
      } else {
        SetError(Resp?.error);
      }
    } else {
      updateToast(id, "fix");
      SetError(error);
    }
    SetDisabledstatus({ resend: false });
  };

  const OTPLOGIN = async () => {
    SetDisabledstatus({ login: true });
    const id = LoadToast();
    let error = Validate();
    console.log("error", error);
    if (isEmpty(error)) {
      let senddata = formdata;

      senddata.otp = otp;
      // if (!isEmpty(localStorage.getItem("Address"))) {
      //   let add = JSON.parse(localStorage.getItem("Address"));
      //   if (add) {
      //     var DeliveryAddress = await GetAddressBasedOnLatLng(
      //       add[0],
      //       add[1],
      //       "sign"
      //     );
      //     senddata.DeliveryAddress = {
      //       ...DeliveryAddress,
      //       Mobile: formdata.Mobile,
      //     };
      //   }
      // }
      senddata.Type = "validateotp";
      senddata.from = "buyer";
      var Resp = await axiosFile.LoginUser(senddata);
      console.log("dsahgdjasdsa", Resp);
      updateToast(id, Resp?.msg, Resp?.success);
      if (Resp?.success == "success") {
        SetShowScreen("");
        setShow_sign(false);
        Addtocart(true);

        // setTimeout(() => {

        // }, 200);
      } else {
        SetError(Resp?.error);
      }
    } else {
      updateToast(id, "fix");
      SetError(error);
    }
    SetDisabledstatus({ login: false });
  };

  useEffect(() => {
    if (ShowScreen == "register") {
      SetFormdata(initregform);
    }
    SetError({});
  }, [ShowScreen]);

  const Register = async () => {
    let error = Validate();

    if (isEmpty(error)) {
      let testlan = await GetLatLngBasedOnAddress(formdata?.Address.FullAddress);
      if(isEmpty(testlan?.coordinates)||(!isEmpty(testlan?.coordinates)&&testlan?.coordinates?.every((val)=>val==0))){
        toast.error("Enter Valid Address");
        return SetError({
          FullAddress: "Enter Valid Address"
        })
      }
      let senddata = formdata;
      senddata.Type = "register";
      senddata.from = "buyer";
      var Resp = await axiosFile.LoginReg(formdata);
      console.log("lsjfkslflsf", Resp);
      if (Resp.success == "success") {
        // navigate('/signin')
        //    siva
        SetError({});
        SetShowScreen("");

        toast.success("Registered Successfully", 1000);
      } else {
        toast.error(Resp?.msg, 1000);
        SetError(Resp?.error);
      }
      // handleCloseModal()
    } else {
      SetError(error);
    }
  };

  const OnChangeOtp = (e, index) => {
    let { name, id, value } = e.target;
    console.log("lsjlskjfssfsf", value, index, otp);
    let currpos = index;
    setotp((prevOTP) => {
      const newOTP = prevOTP.split("");
      // if(value[0]){
      let j = 0;
      console.log("lskjfksjfls", value?.length);
      if (value?.length > 0) {
        currpos = index + 1;
        for (let i = index; i < 6; i++) {
          if (value[j]) {
            newOTP[i] = value[j];
            j++;
            currpos = i;
          }
        }
      } else {
        newOTP[index] = "";
        currpos = index > 0 ? index - 1 : index;
      }
      // }
      console.log(
        "lsjlskjfssfsf11123223",
        currpos,
        newOTP,
        newOTP.join(""),
        value[0]
      );
      if (value !== "") {
        // Move focus to the next input
        if (currpos < 5) {
          currpos = currpos + 1;
        }
        if (index < digits - 1) {
          if (document.getElementById("otpinput" + currpos)) {
            document.getElementById("otpinput" + currpos).focus();
          }
          // otpInputRefs[index + 1].current.focus();
        }
      } else {
        if (document.getElementById("otpinput" + currpos)) {
          document.getElementById("otpinput" + currpos).focus();
        }
      }
      return newOTP.join("");
    });
  };

  // const handleKeyPress = ({ nativeEvent }, index, event) => {
  //   console.log('lsjlskjfssfsfkeyyy',nativeEvent.key,index, event.keyCode, String.fromCharCode(event.keyCode),event)
  //   if (nativeEvent.key == "Backspace") {
  //     // Move focus to the previous input when backspace is pressed
  //     if(index > 0){
  //       document.getElementById("otpinput"+(index-1)).focus();
  //     }

  //     setotp((prevOTP) => {
  //       // prevOTP[index]
  //       const newOTP = prevOTP.split("");
  //       newOTP[index] = "";
  //       // newOTP = value[0] ? value[0] : '';
  //       // console.log('lsjklsfsf',newOTP,value[0])
  //       return newOTP.join("");
  //     });
  //   }
  //   console.log("index", index, nativeEvent.key, typeof nativeEvent.key);
  //   // if (
  //   //   ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(
  //   //     nativeEvent.key
  //   //   ) &&
  //   //   index < 5
  //   // ) {
  //   //   document.getElementById("otpinput"+(index+1)).focus();
  //   // }
  // };

  const SelectChange = (e, name, from) => {
    console.log("fhldfhdfjd", e);
    if (!isEmpty(Error[name])) {
      SetError({ ...Error, ...{ [name]: "" } });
    }
    if (name == "Gender") {
      if (e.value == "others") {
        SetGenderType("input");
        SetFormdata({ ...formdata, ...{ [name]: e.value } });
      } else {
        SetGenderType("Gender");
        SetFormdata({ ...formdata, ...{ [name]: e.value } });
      }
    } else {
      SetFormdata({ ...formdata, ...{ [name]: e.value } });
    }
  };

  const SelectAddress = useCallback(
    (place, Type) => {
      SetFormdata({
        ...formdata,
        ...{
          Address:{
            ...formdata.Address,
            ...{FullAddress:place?.Address?.display},
            ...((place?.geometry?.location)
            ? { latlng: { type: "Point", coordinates: [place?.geometry?.location?.lat(), place?.geometry?.location?.lng()] } }
            : { latlng: { type: "Point", coordinates: [0, 0] } }),
            ...{ FullAddress: place?.formatted_address },
          }
        }
      });
      // SetFormdata({
      //   ...addressform,
      //   ...((place?.geometry?.location)
      //     ? { latlng: { type: "Point", coordinates: [place?.geometry?.location?.lat(), place?.geometry?.location?.lng()] } }
      //     : { latlng: { type: "Point", coordinates: [0, 0] } }),
      //   ...{ FullAddress: place?.formatted_address }
      // });
    }, [formdata]);

  const Add = async (newadd, LatLng) => {
    if (newadd || (Address?.Address && isEmpty(Address?.Latlng))) {
      var Latlngs = await GetLatLngBasedOnAddress(Address?.Address ?? newadd);
      var Latlng = Latlngs?.coordinates;
    } else Latlng = Address?.Latlng ?? LatLng;
    if (LatLng || (Address?.Latlng && isEmpty(Address?.Address))) {
      var Addresses = await GetAddressBasedOnLatLng(
        Address?.Latlng?.[0] ?? LatLng?.[0],
        Address?.Latlng?.[1] ?? LatLng?.[1]
      );
    } else Addresses = Address?.Address ? Address?.Address : newadd;
    console.log("AddressAddress", Addresses, Address, LatLng);
  };

  console.log("lsjffsfsg", formdata);

  return (
    <Modal
      size={ShowScreen == "register" ? "lg" : "md"}
      show={show_sign}
      onHide={() => setShow_sign(!show_sign)}
      backdrop="static"
      keyboard={false}
      centered
      className="regpopy_modal"
    >
      {console.log("fojflkslfsf", otp)}
      <Modal.Header className="regpopy_header" closeButton></Modal.Header>

      <Modal.Body className="d-flex align-items-center justify-content-center">
        {ShowScreen == "otp" ? (
          <div className="centering_signinform">
            <div className="sigin_form widt_unset">
              <div className="centering_sigin">
                <div className="single_center wid_fll">
                  <label className="signin_labels" for="mobile">
                    We sent a text with your OTP code to {formdata?.Mobile}
                  </label>
                  <br />

                  <label className="signin_labels" for="mobile">
                    Enter OTP
                  </label>

                  <br />
                  <div className="otp-inputs new_optinputs otp_fld_wid otp_spc_btw">
                    {/* <OTPInput
                      value={otp}
                      onChange={(e) => setotp(e)}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    /> */}

                    {OTPInputLength.map((val, ind) => {
                      return (
                        <input
                          id={"otpinput" + ind}
                          type="text"
                          value={otp[ind] || ""}
                          onChange={(e) => OnChangeOtp(e, ind)}
                          // onKeyDown={(event) => handleKeyPress(event, ind,event)}
                          // ref={otpInputRefs[ind]}
                        />
                      );
                    })}
                  </div>

                  {Error?.otp && <span className="error-msg">{Error.otp}</span>}
                </div>
              </div>

              <div className="siginin_btn_dtl">
                <button
                  type="button"
                  className="blue_btn signin_btn"
                  onClick={() => OTPLOGIN()}
                  disabled={disabledstatus.login}
                >
                  Login
                </button>
              </div>
              <div className="siginin_btn_dtl">
                <button
                  type="button"
                  className="blue_btn signin_btn"
                  onClick={() => Loginclick("resend")}
                  disabled={disabledstatus.resend}
                >
                  Resend
                </button>
                <p
                  className="change_mobnum_txt"
                  onClick={() => {
                    setotp("");
                    SetShowScreen("");
                    SetFormdata(initform);
                  }}
                >
                  Change <span>{formdata?.Mobile}</span>
                </p>
              </div>
            </div>
          </div>
        ) : ShowScreen == "register" ? (
          <div className="sigin_form widt_unset wth_none">
            <div className="centering_sigin">
              <Container>
                <Row className="justify-content-center">
                  {/* <Col xxl={7} xsl={7} lg={7} md={10} sm={12} xs={12} className="reg_whole_dtls"> */}
                  <Col md={10} sm={12} xs={12} className="reg_whole_dtls">
                    <div className="reg_top_dtls">
                      <h6 className="reg_top_title">Register</h6>
                    </div>
                    <p className="reg_welcome_txt">
                      Welcome. Let's Create Your <b>delpick</b> Account.
                    </p>
                    <Row>
                      <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            First Name
                          </label>
                          <br />
                          <input
                            className="sigin_inputs"
                            type="text"
                            placeholder="Ex.John"
                            id="FirstName"
                            name="mobile"
                            onChange={(e) => OnChange(e)}
                          />
                          {Error?.FirstName && (
                            <span className="error-msg">{Error.FirstName}</span>
                          )}
                        </div>
                      </Col>
                      <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            Last Name
                          </label>
                          <br />
                          <input
                            className="sigin_inputs"
                            type="text"
                            placeholder="Ex.Deo"
                            id="LastName"
                            name="LastName"
                            onChange={(e) => OnChange(e)}
                          />
                          {Error?.LastName && (
                            <span className="error-msg">{Error.LastName}</span>
                          )}
                        </div>
                      </Col>

                      <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            Email Address
                          </label>
                          <br />
                          <input
                            className="sigin_inputs"
                            type="email"
                            placeholder="Ex.johndeo@gmail.com"
                            id="EmailId"
                            name="EmailId"
                            onChange={(e) => OnChange(e)}
                          />
                          {Error?.EmailId && (
                            <span className="error-msg">{Error.EmailId}</span>
                          )}
                        </div>
                      </Col>

                      {/* <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            Password
                          </label>
                          <br />
                          <div className="reg_passwordinps">
                            <input
                              className=""
                              type={
                                passwordText == "password" ? "password" : "text"
                              }
                              placeholder="Enter Password"
                              id="Password"
                              name="Password"
                              onChange={(e) => OnChange(e)}
                            />
                            <i
                              onClick={() =>
                                setPasswordText(
                                  passwordText == "text" ? "password" : "text"
                                )
                              }
                              className={
                                passwordText == "password"
                                  ? "fa-solid fa-eye-slash"
                                  : "fa-solid fa-eye"
                              }
                            ></i>
                          </div>
                          {Error?.Password && (
                            <span className="error-msg">{Error.Password}</span>
                          )}
                        </div>
                      </Col> */}

                      <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            Gender{" "}
                            <i
                              class="fa-solid fa-circle-exclamation reg_excla"
                              onClick={handleShowGender}
                            />
                          </label>
                          <br />
                          <Select
                            options={optionsGender}
                            onChange={(e) => {
                              SelectChange(e, "Gender");
                              // SetError({});
                              // SetFormdata({ ...formdata, ["Gender"]: e.value });
                            }}
                            value={{
                              label:
                                formdata?.Gender == "others"
                                  ? ""
                                  : formdata.Gender,
                              value:
                                formdata?.Gender == "others"
                                  ? ""
                                  : formdata.Gender,
                            }}
                            styles={stylesgraybg}
                            className="border_blue_select custom_rct_slt"
                            classNamePrefix="react-select"
                            placeholder="Select a gender"
                            autoFocus={false}
                            autoComplete={false}
                            isSearchable={false}
                          />
                          {Error?.Gender && (
                            <span className="error-msg reg_gendertop">
                              {Error.Gender}
                            </span>
                          )}
                        </div>
                      </Col>
                      {console.log("lsjdklsdfa", formdata)}
                      {GenderType == "input" && (
                        <Col
                          className="mb-3"
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                        >
                          <div className="single_center">
                            <label className="signin_labels" for="mobile">
                              Enter Gender
                            </label>
                            <br />
                            <input
                              className="sigin_inputs"
                              type="text"
                              placeholder="Enter Gender"
                              id="Gender"
                              name="Gender"
                              value={
                                (formdata?.Gender == "others"
                                  ? ""
                                  : formdata.Gender) || ""
                              }
                              onChange={(e) => OnChange(e)}
                            />
                            {Error?.EmailId && (
                              <span className="error-msg">{Error.EmailId}</span>
                            )}
                          </div>
                        </Col>
                      )}
                      <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        {/* <div className='single_center'>

          <label className='signin_labels' for="mobile">Date of Birth <i class="fa-solid fa-circle-exclamation reg_excla" onClick={handleShowDOB} /></label><br />
          <input className='sigin_inputs' type="date" placeholder='Choose DOB' id="DateofBirth" name="DateofBirth" onChange={(e)=>OnChange(e)}/>
          {/* <DatePicker onChange={onChange} value={value} /> */}
                        {/* { Error?.DateofBirth && <span>{Error.DateofBirth}</span>} */}
                        {/* </div> */}
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            Phone Number
                          </label>
                          <br />
                          {/* <input className='sigin_inputs' type="number" placeholder='123 456 7890' id="mobile" name="mobile"/> */}

                          <PhoneInput
                            className="new_rephoneinp"
                            placeholder="Enter phone number"
                            id="Mobile"
                            onChange={(val) => OnChange(val, "Mobile")}
                            onCountryChange={(val) =>
                              OnChange(val, "Mobile", "country")
                            }
                            defaultCountry="US"
                            country="US"
                            countries={["US","IN"]}
                          />
                          {/* <InputGroup className="sigin_inputs reg_inpgrp">
            <InputGroup.Text id="basic-addon1">+1 |</InputGroup.Text>
            <Form.Control
              placeholder="123 456 7890"
              aria-label="Username"
              aria-describedby="basic-addon1"
              type='number'
              id='Mobile'
              onChange={(e)=>OnChange(e)}
            />
          </InputGroup> */}
                          {Error?.Mobile && (
                            <span className="error-msg">{Error.Mobile}</span>
                          )}
                        </div>
                      </Col>

                      <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            Enter Full Address
                          </label>
                          <br />
                          {console.log('dlkjfsdklfkdsgkjsdgsd',formdata?.Address?.FullAddress)}
                          <div id="parent_comple" className="parent_comple signin">
                            <Autocomplete
                              autoCorrect={true}
                              className="regpopy_cmninput"
                              type="text"
                              placeholder="Enter your house name and street name"
                              name="Address"
                              id="FullAddress"
                              value={formdata?.Address?.FullAddress || ""}
                              onChange={(e) => {changePositiion();OnChange(e,"Address")}}
                              apiKey={
                                process.env.geoapikey
                              }
                              onPlaceSelected={(place) => {
                                SetAddressSelectStatus(!addressselectstatus);
                                SelectAddress(place, "FullAddress")
                              }
                              }
                              // types
                              options={{
                                types: ["address"],
                              }}
                            />
                            </div>
                          {Error?.FullAddress && (
                            <span className="error-msg">{Error.FullAddress}</span>
                          )}
                        </div>
                      </Col>

                      {/* <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            Society/Street name
                          </label>
                          <br />
                          <input
                            className="sigin_inputs"
                            type="text"
                            placeholder="Enter Street"
                            id="Street2"
                            name="Address"
                            onChange={(e) => OnChange(e)}
                          />
                          {Error?.Street2 && (
                            <span className="error-msg">{Error.Street2}</span>
                          )}
                        </div>
                      </Col>

                      <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            City
                          </label>
                          <br />
                          <input
                            className="sigin_inputs"
                            type="text"
                            placeholder="Enter City"
                            id="City"
                            name="Address"
                            onChange={(e) => OnChange(e)}
                          />
                          {Error?.City && (
                            <span className="error-msg">{Error.City}</span>
                          )}
                        </div>
                      </Col>

                      <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            Country{" "}
                          </label>
                          <br />
                          <input
                            className="sigin_inputs"
                            type="text"
                            placeholder="Enter Area"
                            id="Area"
                            name="Address"
                            onChange={(e) => OnChange(e)}
                          />
                          {Error?.Area && (
                            <span className="error-msg">{Error.Area}</span>
                          )}
                        </div>
                      </Col>

                      <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            State
                          </label>
                          <br />
                          <input
                            className="sigin_inputs"
                            type="text"
                            placeholder="Enter State"
                            id="State"
                            name="Address"
                            onChange={(e) => OnChange(e)}
                          />
                          {Error?.State && (
                            <span className="error-msg">{Error.State}</span>
                          )}
                        </div>
                      </Col>

                      <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="single_center">
                          <label className="signin_labels" for="mobile">
                            Zipcode
                          </label>
                          <br />
                          <input
                            className="sigin_inputs"
                            type="text"
                            placeholder="Enter Zipcode"
                            id="Zipcode"
                            name="Address"
                            onChange={(e) => OnChange(e)}
                          />
                          {Error?.Zipcode && (
                            <span className="error-msg">{Error.Zipcode}</span>
                          )}
                        </div>
                      </Col> */}

<Col
                    className="mb-3 mt-2"
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <p className="regpopy_lable addnew_addr">
                      Choose Location by Clicking Here
                    </p>
                    <div
                      className="header_locateme_btn locate_just_start"
                      onClick={() => setShow_map(true)}
                    >
                      <img src={require("../assets/images/icon/Icon.png")} />
                      <p>Locate me</p>
                    </div>
                  </Col>

                      <Col
                        xxl={6}
                        xsl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="mt-4"
                      ></Col>
                      <div className="single_center registr_bot mt-3">
                        <button
                          className={
                            buttonActive == 1
                              ? "regpopy_buttons active"
                              : "regpopy_buttons"
                          }
                          onClick={() => {
                            setButtonActive(1);
                            OnChange("Home");
                          }}
                        >
                          Home
                        </button>
                        <button
                          className={
                            buttonActive == 2
                              ? "regpopy_buttons active"
                              : "regpopy_buttons"
                          }
                          onClick={() => {
                            setButtonActive(2);
                            OnChange("Office");
                          }}
                        >
                          Office
                        </button>
                        <button
                          className={
                            buttonActive == 3
                              ? "regpopy_buttons active"
                              : "regpopy_buttons"
                          }
                          onClick={() => {
                            setButtonActive(3);
                            OnChange("Other");
                          }}
                        >
                          Other
                        </button>
                      </div>
                    </Row>
                    <div className="w-100 text-center">
                      {/* <Link to='/'> */}
                      <button
                        className="blue_btn register_btn"
                        onClick={() => Register()}
                      >
                        Register
                      </button>
                      {/* </Link> */}
                    </div>
                    <p className="reg_bottomtxt">
                      Already have an account?
                      <span
                        className="cursor_pointer_hand"
                        onClick={() => SetShowScreen("")}
                      >
                        Sign In
                      </span>
                    </p>
                  </Col>
                </Row>

                {/* start of gender modal */}
                <Modal
                  show={showGender}
                  onHide={handleCloseGender}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="reg_gender_modal"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <p className="reg_modal_hintntxt">
                      “We use your gender to make delpick services more
                      personal. Additionally, we can provide more relevant,
                      tailored content you might be interested in, like ads as
                      well as promotions, including but not limited to ,highly
                      discounted and/or free products/services".
                    </p>
                  </Modal.Body>
                </Modal>
                {/* end of gender modal */}

                {/* start of DOB modal */}

                <Modal
                  show={showDOB}
                  onHide={handleCloseDOB}
                  backdrop="static"
                  keyboard={false}
                  centered
                  className="reg_gender_modal"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <p className="reg_modal_hintntxt reg_dobmodal_txt">
                      1. Account security and personalization across delpick
                      services.
                    </p>

                    <p className="reg_modal_hintntxt reg_dobmodal_txt">
                      2. Make sure you're old enough to buy certain products and
                      services. For example, show/buy alcoholic beverages.
                    </p>

                    <p className="reg_modal_hintntxt reg_dobmodal_txt">
                      3. Offer you highly discounted or free products and
                      services on your birthday.
                    </p>

                    <p className="reg_modal_hintntxt reg_dobmodal_txt">
                      4. Determine your age group for personalized
                      recommendations and ads.
                    </p>
                  </Modal.Body>
                </Modal>
                {/* end of DOB modal */}
              </Container>
            </div>
          </div>
        ) : (
          <div className="sigin_form widt_unset">
            <div className="centering_sigin">
              {/* <div className="single_center">
            <label className="signin_labels" for="mobile">
              Mobile Number 
            </label>
            <br />

            <PhoneInput
className="new_rephoneinp"
placeholder="Enter phone number"
id="Mobile"
onChange={(val)=>OnChange(val,"Mobile")}
onCountryChange={(val)=>OnChange(val,"Mobile","country")}

/>
            <PhoneInput
              className="sigin_inputs new_rephoneinp"
              placeholder="Enter phone number"
              id="Mobile"
              onChange={(val)=>OnChange(val,"Mobile")}
              onCountryChange={(val)=>OnChange(val,"Mobile","country")}

              />
            <input
              className="sigin_inputs"
              type="number"
              placeholder="Enter your mobile number"
              id="Mobile"
              value={formdata?.Mobile || ""}
              onChange={(e) => OnChange(e)}
              name="mobile"
             
            />

            {Error?.Mobile && (
              <span className="error-msg">{Error.Mobile}</span>
            )}
          </div> */}

              <div className="single_center">
                <label className="signin_labels" for="mobile">
                  Phone Number
                </label>
                <br />

                <PhoneInput
                  className="new_rephoneinp"
                  placeholder="Enter phone number"
                  id="Mobile"
                  onChange={(val) => OnChange(val, "Mobile")}
                  onCountryChange={(val) => OnChange(val, "Mobile", "country")}
                  defaultCountry="US"
                  country="US"
                  countries={["US","IN"]}
                />
                {Error?.Mobile && (
                  <span className="error-msg">{Error.Mobile}</span>
                )}
              </div>
            </div>

            <div className="siginin_btn_dtl">
              <button
                type="button"
                className="blue_btn signin_btn"
                onClick={() => Loginclick("getotp")}
                disabled={disabledstatus?.getotp}
              >
                Get OTP
              </button>
            </div>

            <p
              className="change_mobnum_txt"
              // onClick={() => {
              //   setotp("");
              //   SetShowScreen("");
              //   SetFormdata(initform);
              // }}
            >
              Click Here to{" "}
              <span onClick={() => SetShowScreen("register")}>Register</span>
            </p>
          </div>
        )}
      </Modal.Body>
      {show_map && (
        <GMap
          show_map={show_map}
          Add={Add}
          setShow_map={setShow_map}
          SetAddress={(e) => {
            SetFormdata({
              ...formdata,
              ...{
                Address:{
                  ...formdata.Address,
                  ...{FullAddress:e?.Address?.display},
                  ...(e?.Latlng
                    ? { latlng: { type: "Point", coordinates: e?.Latlng } }
                    : { latlng: { type: "Point", coordinates: [0, 0] } }),
                }
              }
            });
          }}
        />
      )}
    </Modal>
  );
}
