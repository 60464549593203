import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import Header from "../Components/Header";
import { Container } from "react-bootstrap";
import { Dropdown, Nav, Col, Tab, Row, Modal, Button } from "react-bootstrap";
import FoodDetailCard from "../Components/FoodDetailCard";
import Footer from "../Components/Footer";
import useraxios from "../../src/actions/useaxios";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import config from "../actions/config";
import { data } from "jquery";
import { DecryptData, isEmpty } from "../actions/common";
function CategoryDetails() {
  const location = useLocation();
  const { payload, token } = useSelector((state) => state.LoginReducer.User);

  const axiosfile = useraxios();

  const [sellercategory, setsellercategory] = useState();
  const [product, setproduct] = useState({});
  const [category, setcategory] = useState();
  //   const [sellercategory, setsellercategory] = useState()
  const [shop, setshop] = useState({});
  const [shopname, setshopname] = useState();
  const [CurrentId, SetCurrentId] = useState({});

  console.log("wrewrwgggr", category);
  console.log("productfff", product);
  console.log("wrewrwr", sellercategory);

  useEffect(() => {
    if (location?.search) {
      let paramdata = location.search.split("?Id=")[1];
      SetCurrentId(DecryptData(paramdata));
    }
  }, [location.search]);

  useEffect(() => {
    if (!isEmpty(CurrentId)) {
      getCategory();
    }
  }, [CurrentId]);

  useEffect(() => {
    console.log(
      "calleduseeffect",
      typeof product[sellercategory] == "undefined" && sellercategory
    );

    if (
      typeof product[sellercategory?.id] == "undefined" &&
      !isEmpty(sellercategory)
    ) {
      console.log("sadfasd");
      product[sellercategory?.id] = { loader: false, page: 1, list: [] };
      console.log("kjsfkjskf", sellercategory?.id);
      setproduct(product);

      getshop(sellercategory, 1);
    }
    console.log("tabname", sellercategory);
  }, [sellercategory]);

  const getCategory = async () => {
    var resp = await axiosfile.getsellerCategory({
      SellerId: CurrentId?.SellerId,
    });
    if (resp?.success == "success") {
      console.log("responseeee", resp);
      resp?.data?.map((val) => {
        if (val?._id == CurrentId?.SellerCategory) {
          setsellercategory({ id: val?._id, name: val?.CategoryName });
        }
      });

      setcategory(resp?.data);
    }
  };

  const getshop = async (data, page) => {
    console.log("sdsdsd", data);
    setsellercategory(data);
    var sendata = {
      SellerId: CurrentId?.SellerId,
      ShopId: CurrentId?.ShopId,
      filter: data.id,
      page: page ?? 1,
      limit: 2,
    };
    var resp = await axiosfile.getsellerproducts(sendata);
    console.log("viewpage", resp);

    if (resp?.data?.length > 0) {
      console.log("fdfdf", sendata);
      setshop(resp?.data[0]?.ShopId);
      setproduct({
        ...product,
        ...{
          [sellercategory?.id]: {
            list: [...product[sellercategory?.id].list, ...resp?.data],
            loader: resp?.data?.length == 0 ? false : true,
            page: product[sellercategory?.id]?.page,
          },
        },
      });
    } else {
      console.log("dgdgdg");
      setproduct({
        ...product,
        ...{
          [sellercategory?.id]: {
            list: product[sellercategory?.id].list,
            loader: false,
            page: product[sellercategory?.id].page,
          },
        },
      });
    }
  };

  const LoadMore = () => {
    product[sellercategory?.id].page = product[sellercategory?.id].page + 1;
    setproduct(product);
    getshop(sellercategory, product[sellercategory?.id].page);
  };

  return (
    <>
      <Header />
      <Container fluid className="carousel_container">
        <Swiper
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="mySwiper_catdtl"
        >
          {console.log("dddddgs", shop)}
          {shop &&
            shop?.StoreImages?.length > 0 &&
            shop?.StoreImages?.map((item) => {
              {
                console.log("image", item);
              }
              return (
                <SwiperSlide>
                  <img
                    className="img-fluid catdtl_swiperimg"
                    src={
                      item?.Name
                        ? `${config?.IMG_URL}/shop/${item?.Name}`
                        : require("../assets/images/menu/c8.png")
                    }
                  />
                </SwiperSlide>
              );
            })}
          {/* <SwiperSlide>
            <img className='img-fluid catdtl_swiperimg' src={require('../assets/images/menu/c8.png')}/>
        </SwiperSlide>
        <SwiperSlide>
            <img className='img-fluid catdtl_swiperimg' src={require('../assets/images/menu/c8.png')}/>
        </SwiperSlide>
        <SwiperSlide>
            <img className='img-fluid catdtl_swiperimg' src={require('../assets/images/menu/c8.png')}/>
        </SwiperSlide> */}
        </Swiper>
      </Container>

      <Container className="mb-5 mt-5">
        {/* {shop && shop?.length > 0 && shop?.map((item) => {
          console.log("return( ", item);
          return ( */}
        <div className="catdtl_postdiv">
          <img
            className="catdtl_mechdo"
            src={
              shop?.BrandImage
                ? `${config.IMG_URL}/shop/${shop?.BrandImage}`
                : require("../assets/images/mechdo.png")
            }
          />
        </div>
        {/* )
        })
        } */}

        {/* {shop && shop?.length > 0 && shop?.map((item) => {
          return ( */}
        <div className="cd_details">
          <h2>{shop?.BrandName}</h2>
          <div className="cd_flextxt">
            <p className="cd_ratingtxt me-1">Food Shop 4.5 </p>
            <img
              className="cd_greenstar"
              src={require("../assets/images/icon/star_green.png")}
            />
            <p className="cd_ratingtxt ms-1">742 Ratings Free Delivery</p>
          </div>

          <div className="cd_boxdtls">
            <div className="cd_dollardtls">
              <b className="cd_bolddtl">$0.00</b>
              <p className="cd_deliveryfee">Delivery Fee, First Order</p>
            </div>
            <div className="cd_dollardtls cd_dollardtls_two">
              <b className="cd_bolddtl">10-20</b>
              <p className="cd_deliveryfee">Minute</p>
            </div>
          </div>

          <hr />

          <Dropdown className="dinnermenu_drpdwn">
            {/* <Dropdown.Toggle id="dropdown-basic">
                  Dinner Menu <i class="fa-solid fa-angle-down" />
                </Dropdown.Toggle> */}

            <Dropdown.Menu>
              <Dropdown.Item href="#">Action</Dropdown.Item>
              <Dropdown.Item href="#">Another action</Dropdown.Item>
              <Dropdown.Item href="#">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {console.log("lkldkllsljglsf", shop)}
        <div className="cdmenu_tabs_whole">
          {/* <img className='cd_menualt_img' src={require('../assets/images/icon/menu_alt.png')} /> */}

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={12}>
                <Nav className="flex cd_tabs">
                  {category &&
                    category?.length > 0 &&
                    category?.map((item) => {
                      console.log("item", item);
                      return (
                        <Nav.Item className="cd_navitem">
                          <Nav.Link
                            className="cd_navlink"
                            eventKey="first"
                            onClick={() => {
                              setsellercategory({
                                id: item?._id,
                                name: item?.CategoryName,
                              });
                            }}
                          >
                            {item?.CategoryName}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  {/* <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="second">Combo Meals</Nav.Link>
            </Nav.Item>
            <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="third">Shareables</Nav.Link>
            </Nav.Item>
            <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="four">Sweets & Treats</Nav.Link>
            </Nav.Item>
            <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="five">Happy Meal</Nav.Link>
            </Nav.Item>
            <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="six">Condiments</Nav.Link>
            </Nav.Item>
            <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="seven">Fries, Sides & More</Nav.Link>
            </Nav.Item>
            <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="eight">McCafé</Nav.Link>
            </Nav.Item>
            <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="nine">McCafé Bakery</Nav.Link>
            </Nav.Item>
            <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="ten">Beverages</Nav.Link>
            </Nav.Item>
            <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="eleven">McCafé Bakery</Nav.Link>
            </Nav.Item>
            <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="twelve">Individual Items</Nav.Link>
            </Nav.Item>
            <Nav.Item className='cd_navitem'>
              <Nav.Link className='cd_navlink' eventKey="thirteen">Shareables</Nav.Link>
            </Nav.Item> */}
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content className="cd_tabcontent">
                  <Tab.Pane eventKey="first">
                    <h4 className="cd_mostpop">{sellercategory?.name}</h4>
                    <Row>
                      {console.log(
                        "shopopppppp",
                        product[sellercategory?.id]?.list
                      )}
                      {product[sellercategory?.id] &&
                        product[sellercategory?.id]?.list?.length > 0 &&
                        product[sellercategory?.id]?.list?.map(
                          (item, index) => {
                            console.log("hghsddghgd", item);
                            return (
                              <Col
                                className="mb-4"
                                xxl={6}
                                xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                              >
                                <FoodDetailCard detail={item} />
                              </Col>
                            );
                          }
                        )}
                      <div className="text-center">
                        {product[sellercategory?.id] &&
                          product[sellercategory?.id]?.loader && (
                            <button
                              className="blue_btn odp_savebtn"
                              onClick={() => LoadMore()}
                            >
                              Load More
                            </button>
                          )}{" "}
                      </div>
                    </Row>
                    {/* <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FoodDetailCard/>
                </Col>
                <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FoodDetailCard/>
                </Col>

                <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FoodDetailCard/>
                </Col>
                <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FoodDetailCard/>
                </Col>
                <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FoodDetailCard/>
                </Col> */}

                    {/* <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FoodDetailCard/>
                </Col>
                <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FoodDetailCard/>
                </Col>
                <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FoodDetailCard/>
                </Col>

                <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FoodDetailCard/>
                </Col>
                <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FoodDetailCard/>
                </Col>
                <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <FoodDetailCard/>
                </Col> */}
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <img
            className="cd_menualt_img"
            src={require("../assets/images/icon/right_arrow.png")}
          />
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default CategoryDetails;
