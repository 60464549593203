import React, { useState, useEffect } from "react";
export function CartData({
  val,
  cartdatas,
  SetCartDatas,
  UpdateCart,
  ShowTotal,
  SetShowTotal,
}) {
  const [Quantity, SetQuantity] = useState(val?.Quantity);
  console.log("val?.TotalPrice", val?.TotalPrice, Quantity);
  const [Status, SetStatus] = useState("");
  // useEffect(()=>{
  // if(Status){
  //    UpdateCart(Status, val?.ProductId?._id,val?._id)
  //   }},[Status])

  useEffect(() => {
    SetQuantity(val?.Quantity);
  }, [val?.Quantity]);
  return (
    <>
      <hr />

      <div className="cartoffcanva_deltettl">
        <h6>{val?.ProductId?.ProductName}</h6>
        <img
          className="cartoffcanva_delteicn"
          src={require("../assets/images/icon/delete.png")}
          onClick={() => {
            // SetStatus('delete')
            UpdateCart("delete", val?.ProductId?._id, val?._id);
          }}
        />
      </div>
      <div className="cartoffcanva_spoons d_bloc">
        {val?.AdditionalInfo.map((it) => {
          return it.value ? (
            <>
              {/* <div className='cartoffcanva_spooning'> */}
              <div className="cartoffcanva_fooddtl">
                <img
                  className="cartoffcanva_spponicn"
                  src={require("../assets/images/icon/Vector.png")}
                />
                <p>
                  {it?.value.length > 25
                    ? it?.value.slice(0, 25).concat("...")
                    : it?.value}
                  {/* {it?.value} */}
                </p>
                {console.log(it.value, "itvalue")}
                <p>
                  {" "}
                  {it?.price ? (
                    <>
                      {" "}
                      -{val?.ShopId?.CurrencySymbol} {it?.price}
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
              {/* </div> */}
            </>
          ) : (
            <></>
          );
        })}
      </div>
      <div className="cartoffcanva_incdecwhole">
        <div className="cartoffcanva_incdec">
          <button
            className="cartoffcanva_incdecbtn me-3"
            disabled={Number(Quantity) == 1}
            onClick={() => {
              UpdateCart("remove", val?.ProductId?._id, val?._id);
              // SetStatus('remove')
              // SetQuantity(Quantity-1)
            }}
          >
            <p aria-disabled={Number(val?.Quantity) == 1}>-</p>
          </button>
          <p className="cartoffcanva_incdeccount">{Quantity}</p>
          <button
            className="cartoffcanva_incdecbtn ms-3"
            disabled={Number(Quantity) == Number(val?.ProductId?.Quantity)}
            onClick={() => {
              //   if(val?.ProductId?.Quantity > val?.Quantity){
              // //  SetQuantity(Quantity+1)
              //  SetStatus('add')
              // }
              UpdateCart("add", val?.ProductId?._id, val?._id);
            }}
          >
            <p
              aria-disabled={
                Number(val?.Quantity) == Number(val?.ProductId?.Quantity)
              }
            >
              +
            </p>
          </button>
        </div>
        <p className="cartoffcanva_wholeprice">
          {val.ShopId?.CurrencySymbol} {val?.TotalPrice * Quantity}
        </p>
      </div>
    </>
  );
}
