import React,{useState} from 'react'
import {Card} from 'react-bootstrap';
import {Form,Row,Col,Modal} from 'react-bootstrap'
import { useSelector } from 'react-redux';

import useAxiosFile from '../actions/useaxios';

import config from '../actions/config';
import { EncryptData, isEmpty } from '../actions/common';
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify'


function WishlistCard(props) {
  

  const { token, payload } = useSelector(state => state.LoginReducer.User)
  const navigate = useNavigate()


  const axiosFile =  useAxiosFile()

  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log('lsjkfslfsfs',props,props.wishlistDetails)
  const LikeAction = async(data) => {
    let senddata = {
      Type:data?.Type,
      SellerId: data?.SellerId,
      ProductId: isEmpty(data?.ProductId) ? undefined : data.ProductId,
      ShopId: isEmpty(data?.ShopId) ? undefined : data.ShopId,
      UserId: payload._id,
      _id:data?._id
    }
    let Resp = await axiosFile.WishListAction(senddata)
    console.log('dsjldjsjfksfs',Resp)
    if(Resp.success='success'){
      toast.success(Resp?.msg,1000)
      await props.GetWishList(1);
    }
    
  }

  return (
    <>
    {console.log('lsjfksjfss',props?.wishlistDetails)}
    {
      props?.wishlistDetails?.Type == "Product"
      ?
      <Card className='wccard_whole' >
        <div className='wccard_imgwhole' onClick={()=>navigate('/detail/?Id='+(EncryptData(props?.wishlistDetails?.ProductId?._id)))}>
          <img className='wccard_dishimg' src={props.wishlistDetails.ProductId.ProductImage ? `${config.IMG_URL}/Product/${props.wishlistDetails.ProductId.ProductImage}` : ''}/>
        </div>
        <Card.Body>
          <p className='wc_title'>{props?.wishlistDetails?.ProductId.ProductName}</p>
          <div className='wc_price_remove'>
              {/* <p class="fooddtlcard_dollar">{props?.wishlistDetails?.ProductId?.Price}</p> */}
              <p className='wc_removelist' onClick={()=>LikeAction(props?.wishlistDetails)}>Remove from wishlist</p>
          </div>
        </Card.Body>
      </Card>
      :
      <Card className='wccard_whole' >
        <div className='wccard_imgwhole' onClick={()=>navigate('/categoryDetail/?Id='+(EncryptData(props?.wishlistDetails?.ShopId?._id)))}>
          <img className='wccard_dishimg' src={ props?.wishlistDetails?.ShopId?.BrandImage ? `${config.IMG_URL}/shop/${props?.wishlistDetails?.ShopId?.BrandImage}` : ''}/>
        </div>
        <Card.Body>
          <p className='wc_title'>{props?.wishlistDetails?.ShopId?.BrandName}</p>
          <div className='wc_price_remove'>
              {/* <p class="fooddtlcard_dollar">{props.wishlistDetails.Price}</p> */}
              <p className='wc_removelist' onClick={()=>LikeAction(props?.wishlistDetails)}>Remove from wishlist</p>
          </div>
        </Card.Body>
      </Card>
    }
    

    

         {/* Details Modal */}

         <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
        className='foodorder_modal'
      >
       
        <Modal.Body>
          <Row>
            <div className='text-end mb-4'>
            <img className='foodmodal_closeimg'  onClick={handleClose} src={require('../assets/images/icon/close.png')}/>
            </div>
            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
              <img className='img-fluid' src={require('../assets/images/ModalBurger.jpg')}/>
              <div className='foodmodal_demoimg_small'>
                <img className='foodmodal_imgsmall me-2' src={require('../assets/images/Rectangle 5219.png')}/>
                <img className='foodmodal_imgsmall me-2' src={require('../assets/images/Rectangle 5221.png')}/>
                <img className='foodmodal_imgsmall' src={require('../assets/images/Rectangle 5220.png')}/>
              </div>

            </Col>
            <Col className='foodmodal_closerel' xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
              
              <h5 className='foodmodal_title'>McAloo Tikki Burger®</h5>
              <p className='foodmodal_caltxt'>334 kCal</p>
              <p className='foodmodal_paratxt'>Mouthwatering perfection starts with two 100% pure beef patties and Big Mac® sauce sandwiched between a sesame seed bun. It’s topped off with pickles, crisp shredded lettuce, finely chopped onion, and American cheese for a 100% beef burger with a taste like no other. It contains no artificial flavors, preservatives, or added colors from artificial sources.</p>

              <div className='modal_checkoptions'>
              {/* <input type="checkbox" id="mac" name="mac" value="mac"/>
  <label for="mac"> Best Mac Small Size </label><br/>

  <input type="checkbox" id="macmedium" name="macmedium" value="macmedium"/>
  <label for="macmedium"> Best Mac Medium Size With Extra Cheez </label><br/>

  <input type="checkbox" id="maclarge" name="maclarge" value="maclarge"/>
  <label for="maclarge"> Best Mac Large Size With Extra Cheez </label><br/> */}

<Form>
      
        <div className="mb-3">
        <p className='foodmodal_subbold'>Options</p>
          <Form.Check type="checkbox">
            <Form.Check.Input type="checkbox" isValid />
            <Form.Check.Label>Best Mac Small Size</Form.Check.Label>
          </Form.Check>
          <Form.Check type="checkbox">
            <Form.Check.Input type="checkbox" isValid />
            <Form.Check.Label>Best Mac Medium Size With Extra Cheez</Form.Check.Label>
          </Form.Check>
          <Form.Check type="checkbox">
            <Form.Check.Input type="checkbox" isValid />
            <Form.Check.Label>Best Mac Large Size With Extra Cheez</Form.Check.Label>
          </Form.Check>
        </div>
      
    </Form>
              </div>

              <p className='foodmodal_subbold'>Ingredients</p>
              <p className='foodmodal_paratxt margin_less'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p>
              <p className='foodmodal_subbold add_note'>Add Note</p>
            <input className='foodmodal_input' type="text" placeholder="Write instructions here.You may be charged for extras."/>
            <div className='foodmodal_price'>
              <h6>Total:</h6>
              <h6>$38.00</h6>
            </div>
            <div className='text-end'>
            <button className='foodmodal_cartbtn' onClick={handleClose}>Add To Cart</button>
            </div>
            </Col>
           
          </Row>
         
        </Modal.Body>
        
      </Modal>
      {/* End of Details Modal */}
    </>
  )
}

export default WishlistCard