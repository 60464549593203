import React, { Component, useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import config from "../actions/config";
import { Link } from "react-router-dom";
import useraxios from "../actions/useaxios";
import { useDispatch, useSelector } from "react-redux";
import { EncryptData, calcDistance, isEmpty } from "../actions/common";

import { toast } from "react-toastify";

import bookmarkOne from "../assets/svg/bookmarkOne.svg";
import bookmarkTwo from "../assets/svg/bookmarkTwo.svg";

function FoodCard({ details, list, getshop, getwishlist, from, _id }) {
  const { payload, token } = useSelector((state) => state.LoginReducer.User);
  const axiosfile = useraxios();
  const Val_id = _id
    ? _id
    : details?.Wishlist?.find((item) => item?.ShopId == details?._id)?._id;

  const [encryptid, setEncryptid] = useState("");
  const [Distance, SetDistance] = useState("");

  console.log("klsjslkfs", details, Distance);

  const wishlist = async () => {
    var SendData = {
      Type: "Shop",
      SellerId: details?.SellerId,
      UserId: payload?._id,
      ShopId: from == "cat" ? details?._id : details?.ShopId?._id,
      _id: Val_id,
    };

    var resp = await axiosfile.addwishlist(SendData);
    console.log("kdjsfksdlkfsdfs", SendData, resp);
    if (resp?.success == "success") {
      toast.success(resp?.msg, 1000);
      if (from == "cat") getshop("", true);
      if (from == "savedshop") getwishlist();
    }
  };

  useEffect(() => {
    if (details?._id || details?.ShopId?._id) {
      setEncryptid(
        String(
          EncryptData(from == "savedshop" ? details?.ShopId?._id : details?._id)
        )
      );
      let curshoplatlan =
        details?.ShopId?.latlng?.coordinates ?? details?.latlng?.coordinates;
      if (isEmpty(curshoplatlan)) {
        SetDistance("");
      } else {
        let LatLng = localStorage.getItem("Address");
        LatLng = JSON.parse(LatLng);
        SetDistance(calcDistance(LatLng, curshoplatlan, "miles"));
      }

    }
  }, [details?.SellerId]);

  return (
    <>
      <Card className="foodcard_whole">
        <Link className="common_link" to={`/categoryDetail/?Id=${encryptid}`}>
          <Card.Img
            variant="top"
            className="foodcard_prodimg"
            src={
              details?.ShopId?.BrandImage || details?.BrandImage
                ? `${config?.IMG_URL}/shop/${
                    from == "savedshop"
                      ? details?.ShopId?.BrandImage
                      : details?.BrandImage
                  }`
                : require("../assets/images/shopdummy.png")
            }
          />
        </Link>
        <Card.Body>
          <Card.Title className="foodcard_ttl">{details?.Discount}</Card.Title>
          <div className="foodcard_brandbook">
            <Link
              className="common_link"
              to={`/categoryDetail/?Id=${encryptid}`}
            >
              <p className="foodcard_brandname">
                {from == "savedshop"
                  ? details?.ShopId?.BrandName
                  : details?.BrandName}
              </p>
            </Link>
            {console.log(
              "Val_id",
              Val_id,
              details?.Wishlist?.find((item) => item?.ShopId == details?._id)
                ?._id
            )}
            {Val_id ? (
              <img
                className="foodcard_bkmark"
                src={bookmarkOne}
                onClick={() => wishlist()}
              />
            ) : (
              <img
                className="foodcard_bkmark"
                src={bookmarkTwo}
                onClick={() => wishlist()}
              />
            )}
          </div>
          <div className="foodcard_brandbook">
            <p className="foodcard_shopname">{details?.Mobile}</p>
            <div className="foodcard_milestone">
              <img
                className="foodcard_walkimg"
                src={require("../assets/images/icon/directions_walk.png")}
              />
              <p className="foodcard_shopname">
                {isEmpty(Distance)
                  ? "Not Detected"
                  : isNaN(Number(Distance))
                  ? "Not Detected"
                  : Distance + " Miles"}
              </p>

            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default FoodCard;
