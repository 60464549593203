import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
import Header from "../Components/Header";
import { Container } from "react-bootstrap";
import { Dropdown, Nav, Col, Tab, Row } from "react-bootstrap";
import FoodDetailCard from "../Components/FoodDetailCard";
import Footer from "../Components/Footer";
import useraxios from "../../src/actions/useaxios";
import { useParams, useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import config from "../actions/config";
import "swiper/css/navigation";
import { DecryptData, EncryptData, isEmpty } from "../actions/common";
function CategoryDetails() {
  const axiosfile = useraxios();
  const navigate = useNavigate();
  const [shop, setshop] = useState({ loader: true, page: 1 });
  const [Products, SetProducts] = useState({});
  const [CurrentSubId, setCurrentSubId] = useState("");

  const location = useLocation();

  const [Id, Setid] = useState();

  // console.log("ssww",Object.keys(shop));
  useEffect(() => {
    if (Id) {
      getshop();
    }
  }, [Id]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    console.log("kjsfsjfs", location.search.split("?Id="));
    if (location?.search) {
      let paramdata = location.search.split("?Id=")[1];
      console.log("llsflklfk", paramdata, DecryptData(paramdata));
      Setid(
        isEmpty(DecryptData(paramdata)) ? paramdata : DecryptData(paramdata)
      );
    }
  }, [location.search]);
  useEffect(() => {
    if (!isEmpty(Products)) {
      if (isEmpty(Products[CurrentSubId])) {
        console.log("Dsadasd>>>>>>> new");
        getshop(CurrentSubId);
      }
    }
  }, [CurrentSubId]);

  const outsider = document.getElementById("outsider");
  const distance = 80;
  const scrollingNavsRight = () => {
    outsider.scrollBy({
      left: distance,
      behavior: "smooth",
    });
  };
  const scrollingNavsLeft = () => {
    outsider.scrollBy({
      left: -distance,
      behavior: "smooth",
    });
  };
  const getshop = async (data) => {
    var SendData = {
      ShopId: isEmpty(DecryptData(Id)) ? Id : DecryptData(Id),
      from: "shop",
      Seldata: data,
    };
    var resp = await axiosfile.getshopproducts(SendData);
    console.log("resp", resp);
    if (!data) {
      setshop(resp?.data?.[0]);
      setCurrentSubId(resp?.data?.[0]?.SellerCategory?.[0]?._id);
    }
    if (resp?.data?.[0]?.SellerCategory.length > 0) {
      SetProducts(
        data
          ? {
              ...Products,
              ...{
                [resp?.data[0]?.SellerCategory.filter((it) => it._id == data)[0]
                  ?._id]: {
                  list: resp?.data[0]?.SellerCategory.filter(
                    (it) => it._id == data
                  )[0].ProductId,
                  loader: true,
                  page: 1,
                },
              },
            }
          : {
              ...Products,
              ...{
                [resp?.data[0]?.SellerCategory[0]?._id]: {
                  list: resp?.data[0]?.SellerCategory[0].ProductId,
                  loader: true,
                  page: 1,
                },
              },
            }
      );
    }
  };

  return (
    <>
      <Header />
      <Container fluid className="carousel_container">
        <Swiper
          pagination={{ clickable: true }}
          modules={[Pagination, Autoplay, Navigation]}
          className="mySwiper_catdtl"
          navigation={true}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        >
          {console.log("dddddgs", shop)}
          {shop &&
            shop?.StoreImages?.length > 0 &&
            shop?.StoreImages?.map((item) => {
              {
                console.log("image111111", item);
              }
              return (
                <SwiperSlide>
                  <img
                    className="img-fluid catdtl_swiperimg"
                    src={
                      item?.Name
                        ? `${config?.IMG_URL}/shop/${item?.Name}`
                        : require("../assets/images/menu/c8.png")
                    }
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Container>
      <Container className="mb-5 ">
        {shop && shop?.BrandImage && (
          <div className="catdtl_postdiv">
            <img
              className="catdtl_mechdo"
              src={
                shop?.BrandImage
                  ? `${config.IMG_URL}/shop/${shop?.BrandImage}`
                  : require("../assets/images/mechdo.png")
              }
            />
          </div>
        )}
        {/* <div className="bt_sec_fav">
          <div className="lke_btn">
            <i class="fa-regular fa-heart"></i>
          </div>
          <div className="shr_btn">
            <i class="fa-solid fa-share-nodes"></i>
          </div>
        </div> */}
        <div className="cd_details ">
          <h2>{shop?.BrandName}</h2>
          <div className="cd_flextxt">
            <p className="cd_ratingtxt me-1">
              {shop?.Category?.Categoryname} Shop 4.5{" "}
            </p>
            <img
              className="cd_greenstar"
              src={require("../assets/images/icon/star_green.png")}
            />
            <p className="cd_ratingtxt ms-1">742 Ratings Free Delivery</p>
          </div>

          <div className="cd_boxdtls">
            <div className="cd_dollardtls">
              <b className="cd_bolddtl">$0.00</b>
              <p className="cd_deliveryfee">Delivery Fee, First Order</p>
            </div>
            <div className="cd_dollardtls cd_dollardtls_two">
              <b className="cd_bolddtl">10-20</b>
              <p className="cd_deliveryfee">Minute</p>
            </div>
          </div>

          <hr />
        </div>

        <div className="cdmenu_tabs_whole">
          <img
            onClick={() => scrollingNavsLeft()}
            className="cd_menualt_img rotate_left"
            src={require("../assets/images/icon/right_arrow.png")}
          />

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="w-100">
              <Col sm={12}>
                <Nav className="flex cd_tabs" id="outsider">
                  {shop?.SellerCategory?.length > 0 &&
                    shop?.SellerCategory?.map((item) => {
                      return (
                        <Nav.Item
                          className="cd_navitem"
                          onClick={() => {
                            console.log("Dsadasd>>>>>>>", item._id);
                            setCurrentSubId(item._id);
                          }}
                        >
                          <Nav.Link className="cd_navlink" eventKey="first">
                            {item?.CategoryName}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content className="cd_tabcontent">
                  <>
                    {
                      <Tab.Pane eventKey="first">
                        <Row>
                          {Products[CurrentSubId]?.list?.length > 0 ? (
                            Products[CurrentSubId]?.list?.map((item) => {
                              return (
                                <Col
                                  id={item._id}
                                  className="mb-4"
                                  xxl={6}
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  xs={12}
                                >
                                  <FoodDetailCard detail={item} />
                                </Col>
                              );
                            })
                          ) : (
                            <>No Data</>
                          )}
                          {/* <div className='text-center'>
                              <button className='blue_btn odp_savebtn' onClick={() => navigate(`/viewcategory/?Id=${String(EncryptData({ShopId:Id,SellerId:item?.SellerId,SellerCategory:item?._id}))}`)}>View More</button>
                              </div> */}
                        </Row>
                      </Tab.Pane>
                    }
                  </>

                  <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <img
            className="cd_menualt_img"
            onClick={() => scrollingNavsRight()}
            src={require("../assets/images/icon/right_arrow.png")}
          />
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default CategoryDetails;
