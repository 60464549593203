import React,{useEffect, useState} from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import {Link, useLocation, useParams} from 'react-router-dom'
import { DecryptData } from '../actions/common'

function OrderConfirmed() {


  var location = useLocation();
  var Time = useParams()
  
  var [ OrderTime,  SetOrderTime] = useState('');
  
  useEffect(() =>{
    window.scrollTo(0,0);
},[])


  useEffect(()=>{
    if(Time?.Time){
      SetOrderTime(DecryptData(Time?.Time))
    }
  },[Time])

  return (
    
    <>
    <Header/>
    <Container>
        <Row className='justify-content-center'> 
            <Col xxl={5} xl={5} lg={6} md={7} sm={12} xs={12}>

        
        <div className='co_wholedtls'>
            <img src={require('../assets/images/icon/check-circle.png')}/>
            <p className='co_title'>Congratulations your order is successfully placed</p>
            <p className='co_hint'>Your order is confirmed. You will receive an order confimation email/SMS shortly with the expected delivery date for your items.</p>
            <div className='co_dulabtns'>
            <Link to="/detail/order">
                <button className='blue_btn co_vieworder'>View Order</button></Link>
                <Link to="/">
                <button className='secondary_btn co_secondarybtn_mob'>Continue Shopping</button></Link>
            </div>
        </div>
        </Col>
        </Row>
    </Container>
    <Footer/>
    </>
  )
}

export default OrderConfirmed