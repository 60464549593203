import React, { useState, useEffect, useMemo, useCallback } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { Col, Container, Row, Form, Offcanvas } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useSelector } from "react-redux";
import useAxiosFile from "../actions/useaxios";
import { toast } from "react-toastify";
import { EncryptData, Number_Decimal, isEmpty } from "../actions/common";
import config from "./../actions/config";
import { Elements } from "@stripe/react-stripe-js";
import Payment from "./pyament";
import Autocomplete from "react-google-autocomplete";
import GMap from "../Components/Map/GMap";
import { GetAddressBasedOnLatLng, GetLatLngBasedOnAddress } from "../Components/Map/geocode";
function PaymentSelect(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showToken, setShowToken] = useState(false);

  const handleCloseToken = () => setShowToken(false);
  const handleShowToken = () => setShowToken(true);


  const changePositiion = ()=>
  {
    var addressInputElement =document.getElementById("parent_comple");

setTimeout(() => {
  var pacContainer =document.querySelectorAll('.pac-container');
  for(var i=0;i<pacContainer.length;i++)
  {
    addressInputElement.appendChild(pacContainer[i]);

  }
  console.log(addressInputElement,pacContainer,"e;e");
  // addressInputElement.parentElement.append(pacContainer);
}, 100);


  }


  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#A71D24"
          : isSelected
          ? "#A71D24"
          : isFocused
          ? "#A71D24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "46px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 30,
      marginTop: "10px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "46px",
      borderRadius: 30,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "46px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      marginTop: "10px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  /**lp */

  const { payload } = useSelector((state) => state?.LoginReducer.User);
  const axiosFile = useAxiosFile();
  const navigate = useNavigate();
  const { state } = useLocation();

  const initAddress = {
    FullName: "",
    EmailId: "",
    Mobile: "",
    type: "",
    Street: "",
    Street2: "",
    Area: "",
    City: "",
    Zipcode: "",
    defaultAddress: false,
  };

  /**
   * paymentype should be CARD ,  COD , GPAY - By default CARD
   **/
  const [openAddress, setopenAddress] = useState("");

  const errordisplay = {
    EmailId: "Email-Id",
    FullName: "Full-Name",
    Mobile: "Mobile",
    type: "Address Type",
    Street: "Street Name",
    Area: "Country",
    City: "City",
    Zipcode: "Zip-Code",
    FullAddress: "Full Address",
  };

  const [addressform, SetAddressForm] = useState({
    FullName: "",
    EmailId: "",
    Mobile: "",
    type: "",
    Street: "",
    Street2: "",
    Area: "",
    City: "",
    Zipcode: "",
    defaultAddress: false,
    FullAddress: "",
  });

  const [Error, SetError] = useState({});
  const [DeliveryAddressindex, SetDeliveryAddressIndex] = useState();

  const [IsLoading, setIsLoading] = useState(true);

  const [submitcall, Setsubmitcall] = useState(false);
  const [DeliveryAddress, SetDeliveryAddress] = useState({});

  // const stripe = useStripe()
  // const elements = useElements()

  const [ClientSecret, setClientSecret] = useState(null);
  const [product, setproduct] = useState([]);
  const [Shop, setShop] = useState({});
  const [Status, setStatus] = useState("add");
  const [disabledstatus, SetDisabledstatus] = useState({paynow:false})
  console.log("ClientSecret", ClientSecret, props.stripe);
  const [Total, setTotal] = useState({
    Taxes:
      state?.Products?.reduce(
        (acc, it) =>
          Number(acc) +
          (Number(it.ProductId.Tax) *
            Number(it.TotalPrice) *
            Number(it.Quantity)) /
            100,
        0
      ) ?? 0,
    Coupon: 0,
    Shipping: 0,
    ["PayAmount"]:
      state?.Products?.reduce(
        (acc, it) =>
          Number(acc) +
          (Number(it.ProductId.Tax) *
            Number(it.TotalPrice) *
            Number(it.Quantity)) /
            100,
        0
      ) + state?.TotalPrices?.PayAmount ?? 0,
  });
  const [addressselectstatus,SetAddressSelectStatus] = useState(true)
  const [show_map, setShow_map] = useState(false);

  console.log("state?.Products", state?.Products, state);
  useEffect(() => {
    let newtotal = {};
    if (!isEmpty(state?.TotalPrices)) {
      newtotal = { ...newtotal, ...state?.TotalPrices };
      // setTotal({...Total ,...state?.TotalPrices})
    }
    if (state?.Products.length > 0) {
      setproduct(state?.Products);
      newtotal.PayAmount = (
        state?.Products?.reduce((acc, it) => {
          console.log("slklfssjls111", acc, it);
          return (
            Number(acc) +
            (Number(it.ProductId.Tax) *
              Number(it.TotalPrice) *
              Number(it.Quantity)) /
              100
          );
        }, 0) + Number(state?.TotalPrices?.PayAmount)
      ).toFixed(2);
      newtotal.Taxes = state?.Products?.reduce((acc, it) => {
        console.log("slklfssjls", acc, it);
        return (
          Number(acc) +
          (Number(it.ProductId.Tax) *
            Number(it.TotalPrice) *
            Number(it.Quantity)) /
            100
        );
      }, 0);
      //  setTotal({...Total,...{
      //   ['PayAmount']:state?.Products?.reduce((acc,it)=>Number(acc)+Number(it.ProductId.Tax),0)+state?.TotalPrices?.PayAmount,
      //   ['Taxes']:state?.Products?.reduce((acc,it)=>Number(acc)+Number(it.ProductId.Tax),0)}})
    }
    // console.log("state?.Products?.reduce((acc,it)=>acc+it.Tax,0)",state?.Products?.reduce((acc,it)=>Number(acc)+Number(it.ProductId.Tax),0))
    if (!isEmpty(state?.ShopData)) {
      setShop(state?.ShopData);
    }
    setTotal({ ...Total, ...newtotal });
  }, [state?.Products]);

  useEffect(() => {
    if (
      Status &&
      DeliveryAddressindex &&
      !isEmpty(DeliveryAddress) &&
      !isEmpty(product) &&
      !isEmpty(Shop)
    ) {
      PayNow();
    }
  }, [Status, DeliveryAddressindex, !isEmpty(DeliveryAddress)]);

  const PayNow = async () => {
    let Resp = await axiosFile.StripeApi.Payment_Intent({
      currency: Shop?.CurrencyName,
      Price: Total,
      data: Status,
      id: ClientSecret?._id,
      UserId: payload?._id,
      cartid: product.map((it) => it._id),
      Product: product.map((it) => it.ProductId?._id),
      SellerId: Shop?.SellerId,
      ShopId: Shop?._id,
      OrderId: ClientSecret?.metadata,
      OrderType: Total?.Type,
      DeliveryAddress: DeliveryAddress?._id,
      Coupon: null,
      DeliveryTime: Total?.Time,
      Address: {
        EmailId: DeliveryAddress?.EmailId,
        // Doornumber: DeliveryAddress?.Doornumber,
        // Street: DeliveryAddress?.Street,
        // Street2: DeliveryAddress?.Street2,
        // State: DeliveryAddress?.Doornumber,
        // City: DeliveryAddress?.City,
        // Zipcode: DeliveryAddress?.Zipcode,
        // Country: DeliveryAddress?.Area,
        type: DeliveryAddress?.type,
        latlng: DeliveryAddress?.latlng,
        FullAddress: DeliveryAddress?.FullAddress,
        FullName :  DeliveryAddress?.FullName,
      },
    });
    setClientSecret(Resp.clientSecret);
    setIsLoading(false);
  };
console.log('lkdsdksjkfljshfksfs',DeliveryAddress)
  const EditClick = (address) => {
    setopenAddress("edit");
    SetError({});
    SetAddressForm({
      FullName: address.FullName,
      Street: address.Street,
      Area: address.Area,
      type: address.type,
      City: address.City,
      Zipcode: address.Zipcode,
      EmailId: address.EmailId,
      Mobile: address.Mobile,
      Street2: address.Street2,
      defaultAddress: address.defaultAddress,
      Addresslabel: address?.Addresslabel,
      Instructions: address?.Instructions,
      _id: address._id,
      latlng: address?.latlng,
      FullAddress: address?.FullAddress,
    });
  };

  const RemoveAddress = async (data) => {
    let text = "Do You Want to Delete this Address?";
    if (window.confirm(text)) {
      SetError({});
      let senddata = {
        Type: "remove",
        from: "buyer",
        Mobile: payload.Mobile,
        _id: payload._id,
        Address: { _id: data._id, defaultAddress: data.defaultAddress },
      };
      let Resp = await axiosFile.LoginReg(senddata);
      window.alert("Address Removed !");
    } else {
      window.alert("Cancelled !");
    }
  };

  const OnChange = (e) => {
    const { id, value, name } = e.target;

    if (name == "address") {
      if (!isEmpty(Error[id])) {
        SetError({ ...Error, ...{ [id]: "" } });
      }
      SetAddressForm({ ...addressform, ...{ [id]: value } });
    }
  };

  const Validate = () => {
    let error = {};
    Object.keys(addressform).map((val) => {
      if (isEmpty(addressform[val]) &&
        val !== "Street2" &&
        val != "FullName" &&
        val != "EmailId" &&
        val != "Mobile" && !isEmpty(errordisplay[val])
      ) {
        error[val] = errordisplay[val] + " is Required";
      }
    });

    return error;
  };

  const AddressSubmit = async () => {
    // setAddressInput("1")
    let error = Validate("address");
    console.log("jslkfjsjkfls", error);
    if (isEmpty(error)) {
      let testlan = await GetLatLngBasedOnAddress(addressform.FullAddress);
      if(isEmpty(testlan?.coordinates)||(!isEmpty(testlan?.coordinates)&&testlan?.coordinates?.every((val)=>val==0))){
        toast.error("Enter Valid Address");
        return SetError({
          FullAddress: "Enter Valid Address"
        })
      }
      let senddata = {};
      senddata.Address = addressform;
      senddata.Address.user_id = payload._id;
      senddata.Mobile = payload?.Mobile;
      senddata._id = payload?._id;
      senddata.Type = openAddress == "add" ? "saveaddress" : "editaddress";
      senddata.from = "buyer";

      let Update = await axiosFile.LoginReg(senddata);

      if (Update.success == "success") {
        toast.success(
          openAddress == "add" ? "Saved successfully" : "Updated Successfully",
          1000
        );
        SetAddressForm(initAddress);
        setopenAddress("");
      } else toast.error("Try-Again", 1000);
    } else {
      SetError(error);
      toast.error("Fix all validation issues", 1000);
    }
  };

  const SelectDown = (e, name) => {
    if (e) {
      SetAddressForm({ ...addressform, ...{ [name]: e } });
    }
  };

  const ApplyCouon = () => {
    let Coupon = 100;
    setStatus("update");
    setTotal({
      ...Total,
      ...{
        SubTotal: Number(Total.SubTotal) + Coupon,
        Payable: Number(Total.Payable) + Coupon,
        Coupon: Coupon,
      },
    });
  };

  /* Siva */
  useEffect(() => {
    if (!DeliveryAddressindex) {
      SetDeliveryAddressIndex(
        payload?.DeliveryAddress.findIndex((id) => id.defaultAddress) + 1
      );
      SetDeliveryAddress(
        payload?.DeliveryAddress.find((id) => id.defaultAddress)
      );
    } else {
      SetDeliveryAddress(payload?.DeliveryAddress[DeliveryAddressindex - 1]);
    }
  }, [payload?._id, DeliveryAddressindex]);
  /* Siva */

  const SelectAddress = useCallback(
    (place, Type) => {
      SetAddressForm({
        ...addressform,
        ...((place?.geometry?.location)
          ? { latlng: { type: "Point", coordinates: [place?.geometry?.location?.lat(), place?.geometry?.location?.lng()] } }
          : { latlng: { type: "Point", coordinates: [0, 0] } }),
        ...{ FullAddress: place?.formatted_address }
      });
    }, [addressform]);

  const Add = async (newadd, LatLng) => {
    if (newadd || (Address?.Address && isEmpty(Address?.Latlng))) {
      var Latlngs = await GetLatLngBasedOnAddress(Address?.Address ?? newadd);
      var Latlng = Latlngs?.coordinates;
    } else Latlng = Address?.Latlng ?? LatLng;
    if (LatLng || (Address?.Latlng && isEmpty(Address?.Address))) {
      var Addresses = await GetAddressBasedOnLatLng(
        Address?.Latlng?.[0] ?? LatLng?.[0],
        Address?.Latlng?.[1] ?? LatLng?.[1]
      );
    } else Addresses = Address?.Address ? Address?.Address : newadd;
    console.log("AddressAddress", Addresses, Address, LatLng);
  };

  const Address = useMemo(() => {
    return (
      <Row>
        {payload?.DeliveryAddress?.length > 0 ? (
          payload.DeliveryAddress.map((val, index) => {
            return (
              <Col
                className="my-4"
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
              >
                <div
                  className={
                    DeliveryAddressindex == index + 1
                      ? "ps_homeaddress active"
                      : "ps_homeaddress"
                  }
                  onClick={() => {
                    console.log("dsahdkjassadasdsa", index);
                    SetDeliveryAddressIndex(index + 1);
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="ps_homettl">{val.title}</p>
                    {DeliveryAddressindex == index + 1 ? (
                      <img
                        className="ps_greencheck"
                        src={require("../assets/images/icon/check-circle.png")}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="ps_addresstxt">
                    <p>Name:</p>
                    <b>{val?.FullName}</b>
                  </div>
                  <div className="ps_addresstxt">
                    <p>Address:</p>
                    <>
                      {
                        isEmpty(val?.FullAddress) ?
                          <b>{`${val.Street}${val.Street2 ? "," + val.Street2 : ""},${val.Area
                            },${val.City}-${val.Zipcode}`}</b>
                        :
                        <b>{val?.FullAddress}</b>
                    }
                    
                    </>
                    
                  </div>
                  <div className="ps_addresstxt">
                    <p>Phone Number:</p>
                    <b>{val?.Mobile}</b>
                  </div>
                  <div className="ps_addresstxt">
                    <p>Email Address:</p>
                    <b>{val?.EmailId}</b>
                  </div>
                  <div className="ps_editremovedef">
                    <div className="ps_editremove">
                      <p onClick={() => EditClick(val)}>Edit</p>
                      <p
                        className="ps_removetxt"
                        onClick={() => RemoveAddress(val)}
                      >
                        Remove
                      </p>
                    </div>
                    {val.defaultAddress && (
                      <p className="ps_defaddress">Default Address</p>
                    )}
                  </div>
                </div>
              </Col>
            );
          })
        ) : (
          <></>
        )}

        <>
        {console.log('ksjfshsklfks',openAddress)}
          {!openAddress ? (
            <div
              className="ps_addnewaddress mt-4"
              onClick={() => {
                setopenAddress("add");
                SetAddressForm(initAddress);
              }}
            >
              <img
                src={require("../assets/images/icon/plus_circle_outline.png")}
              />
              <p>Add New Address</p>
            </div>
          ) : (
            <>
              <div
                className="ps_addnewaddress ps_addnewcard ps_addnewcard_close"
                onClick={() => setopenAddress("")}
              >
                <p>{openAddress == "add" ? "Add New " : "Edit "} Address</p>
                <img src={require("../assets/images/icon/close_small.png")} />
              </div>

              <Row className="mt-4">
                {/* <Col
                  className="mb-3"
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">
                    Full Name <span>*</span>
                  </p>
                  <input
                    className="regpopy_cmninput"
                    type="text"
                    placeholder="Ex. john doe"
                    id="FullName"
                    name="address"
                    value={addressform?.FullName || ""}
                    onChange={(e) => OnChange(e)}
                  />
                  {console.log("dskjhdskfs", Error)}
                  {Error?.FullName && (
                    <span className="error-msg">{Error.FullName}</span>
                  )}
                </Col>
                <Col
                  className="mb-3"
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">Address Type</p>
                  {console.log("skjlksjfs", addressform)}
                  <Select
                    options={config.AddressType}
                    styles={stylesgraybg}
                    className="border_blue_select bnt_selec_mb custom_rct_slt"
                    classNamePrefix="react-select"
                    placeholder="Ex. home"
                    value={{
                      label: addressform?.type,
                      value: addressform?.type,
                    }}
                    id="type"
                    name="address"
                    onChange={(e) => {
                      SelectDown(e.value, "type");
                    }}
                    onInputChange={(e) => {
                      SelectDown(e, "type");
                    }}
                  />
                  {Error?.type && (
                    <span className="error-msg">{Error.type}</span>
                  )}
                </Col>
                <Col
                  className="mb-3"
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">
                    Email Address <span>*</span>
                  </p>
                  <input
                    className="regpopy_cmninput"
                    type="text"
                    placeholder="Ex. johndoe@gmail.com"
                    name="address"
                    id="EmailId"
                    value={addressform?.EmailId || ""}
                    onChange={(e) => OnChange(e)}
                  />
                  {Error?.EmailId && (
                    <span className="error-msg">{Error.EmailId}</span>
                  )}
                </Col>
                <Col
                  className="mb-3"
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">
                    Phone Number <span>*</span>
                  </p>
                  <input
                    className="regpopy_cmninput"
                    type="text"
                    placeholder="Ex. +1 515 516 0624"
                    name="address"
                    id="Mobile"
                    value={addressform?.Mobile || ""}
                    onChange={(e) => OnChange(e)}
                  />
                  {Error?.Mobile && (
                    <span className="error-msg">{Error.Mobile}</span>
                  )}
                </Col> */}
                {/* <Col
                  className="mb-3"
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">
                    Street Name <span>*</span>
                  </p>
                  <input
                    className="regpopy_cmninput"
                    type="text"
                    placeholder="Enter your house name and street name"
                    name="address"
                    id="Street"
                    value={addressform?.Street || ""}
                    onChange={(e) => OnChange(e)}
                  />
                  {Error?.Street && <span>{Error.Street}</span>}

                  <input
                    className="regpopy_cmninput"
                    type="text"
                    placeholder="Apartment, suit, unit, etc. (optional)"
                    name="address"
                    id="Street2"
                    value={addressform?.Street2 || ""}
                    onChange={(e) => OnChange(e)}
                  />
                </Col>
                <Col
                  className="mb-3"
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">
                    Country <span>*</span>
                  </p>
                  <input
                    className="regpopy_cmninput"
                    type="text"
                    placeholder="Ex. New york"
                    id="Area"
                    name="address"
                    value={addressform?.Area || ""}
                    onChange={(e) => OnChange(e)}
                  />
                  {Error?.Area && (
                    <span className="error-msg">{Error.Area}</span>
                  )}
                </Col>
                <Col
                  className="mb-3"
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">
                    City <span>*</span>
                  </p>
                  <input
                    className="regpopy_cmninput"
                    type="text"
                    placeholder="Ex. New york"
                    id="City"
                    name="address"
                    value={addressform?.City || ""}
                    onChange={(e) => OnChange(e)}
                  />
                  {Error?.City && (
                    <span className="error-msg">{Error.City}</span>
                  )}
                </Col>
                <Col
                  className="mb-3"
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">Zipcode</p>
                  <input
                    className="regpopy_cmninput"
                    type="text"
                    placeholder="Ex. 10101"
                    id="Zipcode"
                    name="address"
                    value={addressform?.Zipcode || ""}
                    onChange={(e) => OnChange(e)}
                  />
                  {Error?.Zipcode && (
                    <span className="error-msg">{Error.Zipcode}</span>
                  )}
                </Col> */}

                <Col
                  className="mb-3"
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">Enter Full Address<span>*  </span></p>
                  {console.log("skjlksjfs", addressform)}
                <div id="parent_comple" className="parent_comple parent_comple_payment">
                  <Autocomplete
                  autoCorrect={true}
                  className="regpopy_cmninput"
                  type="text"
                  placeholder="Enter your house name and street name"
                  name="address"
                  id="FullAddress"
                  value={addressform?.FullAddress || ""}
                  onChange={(e) => {changePositiion();OnChange(e)}}
                  apiKey={
                    process.env.geoapikey
                  }
                  onPlaceSelected={(place) =>{
                    SetAddressSelectStatus(!addressselectstatus);
                    SelectAddress(place, "FullAddress")
                  }
                  }
                  options={{
                    types: ["address"],
                  }}
                />
                </div>
              {Error?.FullAddress && (
                <span className="error-msg">{Error.FullAddress}</span>
              )}
                </Col>

                <Col
                  className="mb-3"
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">Address Type</p>
                  {console.log("skjlksjfs", addressform)}
                  <Select
                    options={config.AddressType}
                    styles={stylesgraybg}
                    className="border_blue_select bnt_selec_mb"
                    classNamePrefix="react-select"
                    placeholder="Ex. home"
                    value={{
                      label: addressform?.type,
                      value: addressform?.type,
                    }}
                    id="type"
                    name="address"
                    onChange={(e) => {
                      SelectDown(e.value, "type");
                    }}
                    onInputChange={(e) => {
                      SelectDown(e, "type");
                    }}
                  />
                  {Error?.type && (
                    <span className="error-msg">{Error.type}</span>
                  )}
                </Col>

                  <Col
                    className="mb-3 mt-2"
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <p className="regpopy_lable addnew_addr">
                      Choose Location by Clicking Here
                    </p>
                    <div
                      className="header_locateme_btn locate_just_start"
                      onClick={() => setShow_map(true)}
                    >
                      <img src={require("../assets/images/icon/Icon.png")} />
                      <p>Locate me</p>
                    </div>
                  </Col>
                <Col
                  className="mb-3"
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">
                    Instructions for delivery person
                  </p>
                  <textarea
                    className="regpopy_cmninput"
                    placeholder="Ex. Please knock instead of using the doorbell"
                    id="Instructions"
                    name="address"
                    value={addressform?.Instructions || ""}
                    onChange={(e) => OnChange(e)}
                  />

                  {Error?.Instructions && (
                    <span className="error-msg">{Error.Instructions}</span>
                  )}
                </Col>

                <Col
                  className="mb-3"
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <p className="regpopy_lable addnew_addr">Address label</p>
                  <input
                    className="regpopy_cmninput"
                    placeholder="Add a label Ex. school"
                    id="Addresslabel"
                    name="address"
                    value={addressform?.Addresslabel || ""}
                    onChange={(e) => OnChange(e)}
                  />

                  {Error?.Addresslabel && (
                    <span className="error-msg">{Error.Addresslabel}</span>
                  )}
                </Col>

                <div className="modal_checkoptions ps_defadcheck">
                  <Form.Check type="checkbox">
                    <Form.Check.Input
                      type="checkbox"
                      checked={addressform?.defaultAddress}
                      onChange={() =>
                        SetAddressForm({
                          ...addressform,
                          ...{
                            defaultAddress: !addressform.defaultAddress,
                          },
                        })
                      }
                    />
                    <Form.Check.Label className="ps_defaultaddr">
                      Default Address
                    </Form.Check.Label>
                  </Form.Check>
                </div>

                <Col xs={12} className="ps_saveaddrsbtnal">
                  <button
                    className="blue_btn ps_saveaddrsbtn"
                    onClick={() => AddressSubmit()}
                  >
                    {openAddress == "add" ? "Save" : "Edit"} Address
                  </button>
                </Col>
              </Row>
            </>
          )}
        </>
      </Row>
    );
  }, [addressform, openAddress, DeliveryAddressindex, Error]);

  const ProductList = useMemo(() => {
    {
      return (
        product?.length > 0 &&
        product?.map((item) => {
          return (
            <>
              <Row className="pc_rightwholedtls">
                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                  <div className="pc_rightimg">
                    <img
                      className="img-fluid pc_rightbur"
                      src={
                        item?.ProductId?.ProductImage
                          ? `${config.IMG_URL}/Product/${item?.ProductId?.ProductImage}`
                          : require("../assets/images/logo.png")
                      }
                    />
                  </div>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                  <div className="pc_rightttlprice">
                    <p className="pc_rightttl">
                      {item?.ProductId?.ProductName}
                    </p>
                    <p className="pc_rightprice">
                      {" "}
                      {Shop?.CurrencySymbol}{" "}
                      {Number(item?.Quantity) * Number(item?.TotalPrice)}
                    </p>
                  </div>

                  <div className="pc_spoonins">
                    {item?.AdditionalInfo.map((it) => {
                      return it.value ? (
                        <>
                          <div className="pc_rightfddtl pc_rightfddtlnew">
                            <img
                              className="cartoffcanva_spponicn"
                              src={require("../assets/images/icon/Vector.png")}
                            />
                            <p>{it?.value}</p>
                            <p>
                              {" "}
                              {it?.price ? (
                                <>
                                  {" "}
                                  -{Shop?.CurrencySymbol} {it?.price}
                                </>
                              ) : (
                                <></>
                              )}
                            </p>
                            <br />
                          </div>
                        </>
                      ) : (
                        <></>
                      );
                    })}
                  </div>

                  <p>Items: {Number(item?.Quantity)}</p>
                </Col>
              </Row>
              <hr className="pc_righthr" />
            </>
          );
        })
      );
    }
  }, [product, Shop]);

  /**lp */

  return (
    <>
      <Header />
      {console.log('lsjjlskjfslfs',DeliveryAddress)}

      {
        <>
          <Container className="my-5">
            <Row>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                <p className="cco_summary">Set Delivery Address</p>
                {Address}

                <div className="ps_paymentmethwhole mt-4">
                  <p className="cco_summary">Payment Method: Stripe</p>

                  <Form>
                    {/* <hr /> */}

                    <div className="mb-3 mt-3 ps_radio">
                      {IsLoading ? (
                        <>Loading.....</>
                      ) : (
                        ClientSecret &&
                        props.stripe && (
                          <Elements
                            stripe={props.stripe}
                            options={{
                              clientSecret: ClientSecret?.client_secret,
                              appearance: {
                                theme: "flat",
                                variables: { colorPrimaryText: "#262626" },
                              },
                            }}
                          >
                            <Payment
                              submitcall={submitcall}
                              Setsubmitcall={Setsubmitcall}
                              Datas={{
                                product: product,
                                DeliveryAddress: !isEmpty(DeliveryAddress)
                                  ? DeliveryAddress
                                  : payload.DeliveryAddress[
                                      DeliveryAddressindex - 1
                                    ],
                                Total: Total,
                              }}
                              SetDisabledstatus={SetDisabledstatus}
                            />
                          </Elements>
                        )
                      )}
                    </div>
                  </Form>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                <p className="cco_summary">Summary</p>
                {ProductList}

                {/* <div className="pc_applycoupon ">
                  <div className="pc_coupentxt" onClick={() => ApplyCouon()}>
                    <img
                      className="me-2"
                      src={require("../assets/images/icon/coupon1.png")}
                    />
                    <input className="couponcode_inpt" type="text" placeholder="Apply Coupon Code"/>
                  </div>
                  <button className="pc_applybtn" onClick={handleShowToken}>
                    Apply
                  </button>
                </div> */}
                <div className="pc_wholerightprice">
                  <div className="pc_rightpricingdtls">
                    <p>Subtotal</p>
                    <p className="pc_rightpriced">
                      +{Shop?.CurrencySymbol}{" "}
                      {Number_Decimal(Total?.CartValue, 2)}
                    </p>
                  </div>

                  {Total["SellerTipsInValue"] ? (
                    <div className="pc_rightpricingdtls">
                      <p>Seller Tips</p>
                      <p className="pc_rightpriced">
                        +{Shop?.CurrencySymbol}{" "}
                        {Number_Decimal(Total?.SellerTipsInValue, 2)}
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  {Total["DriverTipsInValue"] ? (
                    <div className="pc_rightpricingdtls">
                      <p>Driver Tips</p>
                      <p className="pc_rightpriced">
                        +{Shop?.CurrencySymbol}{" "}
                        {Number_Decimal(Total?.DriverTipsInValue, 2)}
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="pc_rightpricingdtls">
                    <p>Taxes</p>
                    <p className="pc_rightpriced">
                      {Shop?.CurrencySymbol} {Number_Decimal(Total?.Taxes, 2)}
                      {/* {(Total["Taxes"]).toFixed(2)} */}
                    </p>
                  </div>
                  {/* <div className="pc_rightpricingdtls">
                    <p>Coupon Code</p>
                    <p className="pc_rightpriced pc_greenvalue">
                      + {Shop?.CurrencySymbol}{" "}
                      {Total["Coupon"]}
                    </p>
                  </div> */}
                  {Total["DeliveryTimeCharges"] ? (
                    <div className="pc_rightpricingdtls">
                      <p>Shipping Charge</p>
                      <p className="pc_rightpriced">
                        +{Shop?.CurrencySymbol}{" "}
                        {Number_Decimal(Total?.DeliveryTimeCharges, 2)}
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* <div className="pc_rightpricingdtls">
                    <p>Shipping Charge</p>
                    <p className="pc_rightpriced">
                      {Shop?.CurrencySymbol}{" "}
                      {Total["Shipping"]}
                    </p>
                  </div> */}
                </div>
                <hr />
                <div className="pc_rightpricingdtls">
                  <p className="pc_payabaled">Payable Amount</p>
                  <p className="pc_rightpriced pc_totalpriced">
                    {Shop?.CurrencySymbol}
                    {Number_Decimal(Total?.PayAmount, 2)}
                  </p>
                </div>
              </Col>
              {ClientSecret?.client_secret ? (
                <div
                  className="w-100 text-centper"
                  onClick={() => Setsubmitcall(true)}
                >
                  <Link to="#">
                    <button className="blue_btn cco_proceedcheck paynow_btn" disabled={disabledstatus?.paynow}>
                      Pay Now
                    </button>
                  </Link>
                </div>
              ) : (
                <div
                  className="w-100 text-center"
                  aria-disabled={true}
                  title="Payment Not Completed Yet"
                >
                  <button
                    className="blue_btn cco_proceedcheck paynow_btn"
                    disabled={true}
                  >
                    Pay Now
                  </button>
                </div>
              )}
            </Row>
          </Container>

          {/* apply token offcanvas */}
          <Offcanvas
            show={showToken}
            placement="end"
            className="pc_couponoffcanvas"
            onHide={handleCloseToken}
          >
            {/* <Offcanvas.Header closeButton>
          <Offcanvas.Title>Apply Coupon</Offcanvas.Title>
        </Offcanvas.Header> */}
            <Offcanvas.Body>
              <div className="pc_wholeright_offcanva">
                <div>
                  <div className="pc_applyoffcanva_head">
                    <p>Apply Coupon</p>
                    <></>
                  </div>
                  <div className="pc_applycoupon pc_applyoffinp">
                    <div
                      className="pc_coupentxt pc_applyoffcanva"
                      onClick={() => ApplyCouon()}
                    >
                      <img
                        className="me-2"
                        src={require("../assets/images/icon/coupon1.png")}
                      />
                      {/* <p>Apply Coupon Code</p> */}
                      <input
                        className="couponcode_inpt"
                        type="text"
                        placeholder="Apply Coupon Code"
                      />
                    </div>
                    <button className="pc_applybtn" onClick={handleShowToken}>
                      Apply
                    </button>
                  </div>
                  <hr />

                  <div className="pc_aplofcavailable_coupons">
                    <p className="pc_canvaavailcoupons">Available Coupons</p>

                    <div className="pc_avail_trynow">
                      <div className="pc_trynowapply">
                        <button className="pc_yellowbtn">TRYNEW</button>
                        <p>APPLY</p>
                      </div>
                      <p className="pc_canvaoffer">
                        Get 60% discount Using PayPal wallet{" "}
                      </p>
                      <p className="pc_canvas_secondarytxt">
                        Maximum discount up to $10 on orders above $50
                      </p>
                      <p className="pc_canvas_secondarytxt">
                        Expires on: 30th September 2021 11:59 PM
                      </p>
                    </div>

                    <div className="pc_avail_trynow mt-3">
                      <div className="pc_trynowapply">
                        <button className="pc_yellowbtn">DELPICK20</button>
                        <p>APPLY</p>
                      </div>
                      <p className="pc_canvaoffer">
                        Get 10% discount Using PayPal wallet
                      </p>
                      <p className="pc_canvas_secondarytxt">
                        Maximum discount up to $10 on orders above $50
                      </p>
                      <p className="pc_canvas_secondarytxt">
                        Expires on: 30th September 2021 11:59 PM
                      </p>
                    </div>

                    <div className="pc_avail_trynow mt-3">
                      <div className="pc_trynowapply">
                        <button className="pc_yellowbtn">SALE50</button>
                        <p>APPLY</p>
                      </div>
                      <p className="pc_canvaoffer">
                        Get 50% discount Using PayPal wallet{" "}
                      </p>
                      <p className="pc_canvas_secondarytxt">
                        Use code SALE50 & get 50% off on orders above $150.
                      </p>
                      <p className="pc_canvas_secondarytxt">
                        Maximum discount: $100.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pc_applyoff_bottom">
                  <div>
                    <p className="pc_canvas_secondarytxt">Maximum savings:</p>
                    <p className="pc_canvaavailcoupons">$ 30.00</p>
                  </div>
                  <div className="pc_applyoff_btn">
                    <Link to="/paymentselect">
                      <button className="blue_btn pc_applybtn">Apply</button>
                    </Link>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      }
      {/* end of apply token offcanvas */}
      <Footer />
      {show_map && (
        <GMap
          show_map={show_map}
          Add={Add}
          setShow_map={setShow_map}
          SetAddress={(e) => {
            SetAddressForm({
              ...addressform,
              ...{FullAddress:e?.Address?.display},
              ...(e?.Latlng
                ? { latlng: { type: "Point", coordinates: e?.Latlng } }
                : { latlng: { type: "Point", coordinates: [0, 0] } }),
            });
          }}
          Address={Address}
        />
      )}
    </>
  );
}

export default PaymentSelect;
