import React, { useState ,useEffect} from 'react'
import { Col, Row } from 'react-bootstrap'

import config from '../actions/config';
import useAxiosFile from '../actions/useaxios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function FoodOrderCard(props) {
  
  const axiosFile = useAxiosFile()
  const { payload, token} = useSelector((state) => state.LoginReducer.User);
  const {UpdateCart, data } = props
  const [wishlistenabled, SetWishlistEnabled] = useState(true);
  const [Quantity , SetQuantity] = useState(data?.Quantity)
  const [Status , SetStatus] = useState('')
  const Val_id =  props?.data?.ProductId?.Wishlist?.find((item) => item?.ProductId ==  props?.data?.ProductId?._id)?._id
console.log("Val_id",Val_id,props?.data?.ProductId,props?.data?.ProductId?.Wishlist,props?.data?.ProductId?._id)
// useEffect(()=>{
//     if(Status){
//     UpdateCart(Status, data?.ProductId?._id,data?._id)
// }},[Quantity,Status])

useEffect(()=>{
  SetQuantity(data?.Quantity) 
},[data?.Quantity])

 const WishlistAction = async() => {

    if(wishlistenabled){
      SetWishlistEnabled(false)
      if(payload){
        let senddata = {
          SellerId: data?.ProductId?.SellerId,
          ProductId: data?.ProductId?._id,
          UserId: payload._id,
          Type: 'Product',
          _id:Val_id
        }
        let Resp = await axiosFile.WishListAction(senddata);
        if(Resp?.success == "success"){
          toast.success(Resp.msg,1000)
          props.GetCart(true)
        }
        else{
          toast.error(Resp.msg,1000)
        }
        
      }
      SetWishlistEnabled(true)
      return false
    }
    else return false
    
}

  return (
    <>
    <Row className='foc_whole'>
      {console.log('dksljkdsklfs',data)}
        <Col lg={3} md={3} xs={12}>
            <div className='foc_imgbg'>
            <img className='' src={ data?.ProductId?.ProductImage ? `${config.IMG_URL}/Product/${data?.ProductId?.ProductImage}` : require('../assets/images/logo.png')}/>
            </div>

        </Col>
        <Col lg={6} md={6} xs={12}>
            <h5 className=''>{data?.ProductId?.ProductName}</h5>
            <ul className='foc_cardul'>
            {data?.AdditionalInfo?.map(it=>{return(
             it.value ?<>  
             
                         <li>{it.value}
                          {it?.price ? ` - ${data?.ShopId?.CurrencySymbol} ${it.price}` :<></> } 
                          </li> 
                   
                    </>:
                    <></>

                    )})}
                    </ul><br/>
           
            <button onClick={()=>WishlistAction()} className='foc_wishlist'> {props?.data?.ProductId?.Wishlist?.length>0?"- Remove From Wishlist": " + Add to wishlist"}</button>
            
        </Col>
        <Col lg={2} md={2} xs={6} className='d-flex align-items-center'>
        <div className='cartoffcanva_incdec'>
        <div className="cartoffcanva_incdec">
          <button
            className="cartoffcanva_incdecbtn me-3"
            disabled={Number(Quantity) == 1}
            onClick={() =>{
              UpdateCart('remove', data?.ProductId?._id,data?._id)
              //   SetStatus('remove')
              //  SetQuantity(Quantity-1)
            }}
          >
            <p aria-disabled={Number(data?.Quantity) == 1}>-</p>
          </button>
          <p className="cartoffcanva_incdeccount">{Quantity}</p>
          <button
            className="cartoffcanva_incdecbtn ms-3"
            disabled={Number(Quantity) == Number(data?.ProductId?.Quantity)}
            onClick={() => {
              data?.ProductId?.Quantity > data?.Quantity&&
              UpdateCart('add', data?.ProductId?._id,data?._id)
                
              //   SetStatus('add')
              //  SetQuantity(Quantity+1)
             
            }}
          >
            <p aria-disabled={Number(data?.Quantity) == Number(data?.ProductId?.Quantity)}>+</p>
          </button>
        </div></div>
            
        </Col>
        <Col lg={1} md={1} xs={6}>
            <div className='foc_delprice'>
                <img className='cartoffcanva_delteicn' src={require('../assets/images/icon/delete.png')} onClick={()=>UpdateCart('delete',data?.ProductId?._id,data?._id)}/>
                <p className='foc_delpricedol'> {data.ShopId?.CurrencySymbol}{" "}{(Number(Quantity)*Number(data?.TotalPrice))}</p>
            </div>
            
            
            
        </Col>
    </Row>
    </>
  )
}

export default FoodOrderCard