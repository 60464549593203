import React, { useEffect, useState, Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Select from "react-select";
import { isEmpty } from "../actions/common";
import config from "../actions/config";
import useraxios from "../actions/useaxios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Newsletter from "../Components/Newsletter";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const axiosfile = useraxios();
  const [Error, SetError] = useState({});
  const [cat, setCatList] = useState();
  var [email, setEmail] = useState("");

  const navigate = useNavigate();
  const [users, setUsers] = useState({
    FirstName: "",
    LastName: "",
    EmailId: "",
    Category: "",
    Mobile: "",
    Message: "",
  });

  const { FirstName, LastName, EmailId, Category, Mobile, Message } = users;

  useEffect(() => {
    getCategory();
  }, []);

  const onInputchange = (e) => {
    SetError({});
    setUsers({ ...users, [e.target.name]: e.target.value });
  };
  const Validate = () => {
    let error = {};
    if (isEmpty(FirstName)) {
      error.FirstName = "Name Required";
    }
    if (isEmpty(LastName)) {
      error.LastName = "lastName Required";
    }
    if (isEmpty(Category)) {
      error.Category = "Category Required";
    }
    if (isEmpty(Message)) {
      error.Message = "Message Required";
    }
    if (!config.MOBILE.test(Mobile)) {
      error.Mobile = "Enter valid Number";
    }
    if (isEmpty(EmailId)) {
      error.EmailId = "Email Id Required";
    } else {
      if (!config.EMAIL.test(EmailId)) {
        error.EmailId = "Enter valid Email-Id";
      }
    }
    return error;
  };

  const FormSubmit = async () => {
    var valid = Validate();
    if (isEmpty(valid)) {
      let Resp = await axiosfile.Contactus(users);
      if (Resp?.success == "success") {
        toast.success("Sent successfully");
        setTimeout(() => {
          navigate("/");
        }, 800);
      } else {
        toast.success("Error Occured");
      }
    } else {
      SetError(valid);
      toast.error("Validation Failed");
    }
  };
  const getCategory = async () => {
    var resp = await axiosfile.getcategory();

    if (resp?.success == "success") {
      {
        resp?.data?.map((data, key) => {
          return (
            (data.value = data.Categoryname), (data.label = data.Categoryname)
          );
        });
      }
      setCatList(resp.data);
    }
  };
  const NewsLetter = async () => {
    const id = toast.loading("Subscribing...");

    let err = {};
    if (email == "") err.email = "Email Id Required";
    if (email && !config.EMAIL.test(email))
      err.email = "Invalid Email ID Format";
    SetError(err);
    //
    if (isEmpty(err)) {
      let resp = await axiosfile.Newsletter({ email: email });
      if (resp.success === "success") {
        toast.update(id, {
          render: "succeffully Subcribed for NewsLetter",
          type: "success",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        setEmail("");
      } else {
        toast.update(id, {
          render: "Already User",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetError({ email: resp.msg });
      }
    } else {
      toast.update(id, {
        render: err.email,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
    }
  };

  const options = [
    { value: "home", label: "Home" },
    { value: "office", label: "Office" },
    { value: "others", label: "Others" },
  ];

  const styles = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#e5555c"
          : isSelected
          ? "#e5555c"
          : isFocused
          ? "#e5555c"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 30,
      marginTop: "10px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "48px",
      borderRadius: 30,
      backgroundColor: "#F5F6F7",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "48px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      marginTop: "10px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  return (
    <>
      <Header />
      <Container fluid className="ab_bannerbg ">
        <h3 className="ab_bannerttl">Contact Us</h3>
      </Container>
      <Container fluid className="contact_wholedtlsban">
        <Container className="py-5">
          <p className="ss_mainttl text-center">Get In Touch With Us</p>
          <Row className="mt-4">
            <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">Full Name</p>
              <input
                className="regpopy_cmninput"
                name="FirstName"
                type="text"
                placeholder="Ex. john doe"
                onChange={(e) => onInputchange(e)}
              />
              {Error.FirstName && (
                <span className="text-danger img-file">{Error.FirstName}</span>
              )}
            </Col>
            <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">Last Name</p>
              <input
                className="regpopy_cmninput"
                name="LastName"
                type="text"
                placeholder="Ex. john doe"
                onChange={(e) => onInputchange(e)}
              />
              <span className="text-danger img-file">{Error.LastName}</span>
            </Col>
            <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">Email Addres</p>
              <input
                className="regpopy_cmninput"
                name="EmailId"
                type="text"
                placeholder="Ex. john doe"
                onChange={(e) => onInputchange(e)}
              />
              {Error.EmailId && (
                <span className="text-danger img-file">{Error.EmailId}</span>
              )}
            </Col>
            <Col className="mb-3" xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
              <p className="regpopy_lable addnew_addr">Mobile Number</p>
              <input
                className="regpopy_cmninput"
                name="Mobile"
                type="text"
                placeholder="Ex. john doe"
                onChange={(e) => onInputchange(e)}
                maxlength="10"
              />
              {Error.Mobile && (
                <span className="text-danger img-file">{Error.Mobile}</span>
              )}
            </Col>

            <Col
              className="mb-4"
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <p className="regpopy_lable addnew_addr">I Would like to</p>
              <Select
                options={cat}
                styles={styles}
                className="border_blue_select custom_rct_slt"
                classNamePrefix="react-select"
                placeholder="Ex. home"
                // menuIsOpen={true}
                onChange={(e) =>
                  setUsers({
                    ...users,
                    ...{ ["Category"]: e.label },
                  })
                }
              />
              {Error.Category && (
                <span className="text-danger img-file">{Error.Category}</span>
              )}
            </Col>
            <Col
              className="mb-4"
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <p className="regpopy_lable addnew_addr">Message</p>
              <textarea
                className="contact_textarea"
                placeholder="Write your message here.."
                id="w3review"
                name="Message"
                rows="3"
                cols="50"
                onChange={(e) => onInputchange(e)}
              ></textarea>
              {Error.Message && (
                <span className="text-danger img-file">{Error.Message}</span>
              )}
            </Col>
            <Col xs={12} className="contact_submitbtn">
              <button
                className="blue_btn ps_saveaddrsbtn"
                onClick={() => FormSubmit()}
              >
                Save Address
              </button>
            </Col>
          </Row>
        </Container>
      </Container>
      <Newsletter />
      <Footer />
    </>
  );
}

export default Contact;
