import React, { Component, useState, useEffect } from "react";
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { Container, Row, Col } from 'react-bootstrap'
import useraxios from "../actions/useaxios"
import config from '../actions/config'
import { isEmpty } from "../actions/common";

// import { LoadToast, updateToast } from '../actions/common'
import {toast} from 'react-toastify'

import Newsletter from "../Components/Newsletter";
function TermsandConditions() {
    const [Error, SetError] = useState({});

    var [email, setEmail] = useState('')
    console.log("emailssss", email);
    // useEffect(() =>{
    //     window.scrollTo(0,0);
    // },[])

    const axiosfile = useraxios();
    // const [cms,SetCms]=useState();

    // useEffect(() => {
    //     getcms();
    // }, []);


    const getcms = async () => {
        var value = termsvalidation()
        if (!isEmpty(value)) {
            
            console.log("value", value);
            SetError(value)
        }
        // let id = LoadToast()
        const id = toast.loading("Subscribing...");
        let err = {};
        if (email == '') err.email = "Email Id Required";
        if (email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
        SetError(err);
        console.log('fhbdfhbdf', email)
        if (isEmpty(err)) {
            var resp = await axiosfile.getCmsList({ email: email });
            console.log("grdeg", resp);
            // updateToast(id,resp.msg, resp.success)

            if (resp?.success == 'success'){
                toast.update(id, { render: "Successfully Subscribed ", type: "success", isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
            setEmail('');
        }
        else {
            toast.update(id, { render: "Already User", type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
            SetError({ email: resp.msg });
        }
    } else{
        toast.update(id ,{ render : err.email, type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
    }
}


const termsvalidation = () => {
    var Error = {};

    if(isEmpty(email.email)){
        Error.email="Email Id Required";
    }
  }

return (
    <>
        <Header />

        <Container fluid className='terms_conditions_bg '>
            <h3 className='ab_bannerttl'>Terms & Conditions</h3>
        </Container>

        

        <Newsletter/>

        <Footer />
    </>
)
}

export default TermsandConditions