import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Keyboard, Autoplay } from "swiper";
import Select from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Footer from "../Components/Footer";
import Header from "../Components/Header";
import config from "../actions/config";
import { DecryptData, isEmpty } from "../actions/common";
import Useaxiosfile from "../actions/useaxios";
import { toast } from "react-toastify";

function ReviewOrder() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const { token, payload } = useSelector((state) => state.LoginReducer.User);

  const axiosfile = Useaxiosfile();

  const [detailid, SetDetailid] = useState(location.search.split("?order=")[1]);
  const [Detail, SetDetail] = useState({});
  const [productoption, SetProductOption] = useState([]);
  const [Selected, SetSelected] = useState({});
  const [Error, SetError] = useState({});
  const [Review, SetReview] = useState([]);

  useEffect(() => {
    if (detailid) {
      OrderDetail();
    }
  }, [detailid]);

  const OrderDetail = async () => {
    let Resp = await axiosfile.GetOrderDetail({
      id: DecryptData(detailid),
      review: true,
    });

    SetDetail(Resp?.data?.Order);
    let arr = [];
    Resp?.data?.Order?.CartId?.map((val) => {
      arr.push({ label: val.ProductId.ProductName, value: val.ProductId._id });
    });
    SetProductOption(arr);
    SetReview(Resp?.data?.Review);
  };

  const SelectChange = async (e) => {
    SetError({});
    SetSelected({
      ...Selected,
      ...{ ProductId: e.value, Type: "Product", ProductName: e.label },
    });
  };

  const Validate = () => {
    let error = {};
    if (isEmpty(Selected.ProductId)) {
      error.Product = "Choose Product";
    }
    if (isEmpty(Selected.Comments)) {
      error.Review = "Enter Review";
    }
    return error;
  };

  const Submit = async () => {
    var error = Validate();
    if (isEmpty(error)) {
      let senddata = {
        ProductId: Selected.ProductId,
        Type: Selected.Type,
        Comments: Selected.Comments,
        type: "add",
        UserId: payload._id,
        Rating: "4",
        SellerId: Detail.SellerId,
      };
      let Resp = await axiosfile.ReviewAction(senddata);
      console.log("kjdhfjkshfjkf", Resp, Resp?.success);
      if (Resp?.success == "success") {
        SetSelected({});
        toast.success("Review Added Successfully", 1000);
        OrderDetail();
      } else {
        toast.error("Error Occured", 1000);
      }
    } else {
      SetError(error);
    }
  };

  const reviewProducts = [
    {
      img: require("../assets/images/items/McAlooTikkiBurger.png"),
      name: "McAloo Tikki Burger",
    },
    {
      img: require("../assets/images/items/DosaMasalaBurger.png"),
      name: "Dosa Masala Burger",
    },
    {
      img: require("../assets/images/items/SpicyChickenWrap.png"),
      name: "Spicy Chicken Wrap",
    },
  ];

  const reviewCards = [
    {
      title: "McAloo Tikki Burger",
      hint: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas interdum varius. Proin mattis ligula tristique varius laoreet vel eu sem.",
      img: require("../assets/images/uchihamadara.jpg"),
      name: "Simon Cowell",
    },
    {
      title: "Dosa Masala Burger",
      hint: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas interdum varius. Proin mattis ligula tristique varius laoreet vel eu sem.",
      img: require("../assets/images/uchihamadara.jpg"),
      name: "Julian",
    },
    {
      title: "Spicy Chicken Wrap",
      hint: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas interdum varius. Proin mattis ligula tristique varius laoreet vel eu sem.",
      img: require("../assets/images/uchihamadara.jpg"),
      name: "Julian",
    },
    {
      title: "Spicy Chicken Wrap",
      hint: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas interdum varius. Proin mattis ligula tristique varius laoreet vel eu sem.",
      img: require("../assets/images/uchihamadara.jpg"),
      name: "Julian",
    },
    {
      title: "Spicy Chicken Wrap",
      hint: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas interdum varius. Proin mattis ligula tristique varius laoreet vel eu sem.",
      img: require("../assets/images/uchihamadara.jpg"),
      name: "Julian",
    },
    {
      title: "Spicy Chicken Wrap",
      hint: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas interdum varius. Proin mattis ligula tristique varius laoreet vel eu sem.",
      img: require("../assets/images/uchihamadara.jpg"),
      name: "Julian",
    },
  ];

  const options = [
    { value: "chocolate", label: "McAloo Tikki Burger" },
    { value: "strawberry", label: "Dosa Masala Burger" },
    { value: "vanilla", label: "Spicy Chicken Wrap" },
    { value: "all", label: "All Products" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#A71D24"
          : isSelected
          ? "#A71D24"
          : isFocused
          ? "#A71D24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 30,
      marginTop: "10px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 30,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      marginTop: "10px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  return (
    <>
      <Header />
      <Container className="my-5">
        {console.log("ldjskjdsfs", Detail)}
        <p className="odp_lit_title">Orders</p>
        <p className="odp_lit_hint mt-2">
          We are attentive and respectful to each customer.
        </p>
        <Row>
          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
            <Row className="mt-4">
              {Detail?.CartId?.length > 0 &&
                Detail?.CartId?.map((val) => {
                  return (
                    <Col
                      className="mb-3"
                      xxl={4}
                      xl={4}
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <div className="foc_imgbg ro_review_orderbg">
                        <img
                          className="ro_reivewimg"
                          src={
                            val?.ProductId?.ProductImage &&
                            `${config.IMG_URL}/Product/${val?.ProductId?.ProductImage}`
                          }
                        />
                      </div>
                      <p className="ro_review_prodname">
                        {val?.ProductId?.ProductName}
                      </p>
                    </Col>
                  );
                })}
            </Row>

            <p className="cco_summary my-5">Reviews</p>

            <Swiper
              spaceBetween={15}
              grabCursor={true}
              keyboard={{
                enabled: true,
              }}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              modules={[Keyboard, Autoplay, Mousewheel]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                576: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
              className="mySwiper"
            >
              {Review?.length > 0 &&
                Review.map((val) => {
                  return (
                    <SwiperSlide>
                      <div className="ro_reviews_card">
                        <p className="ro_reviews_title">
                          {val?.ProductId?.ProductName}
                        </p>
                        <p className="ro_reviews_hint">{val.Comments}</p>
                        <div className="ro_review_imgname">
                          <img
                            className="ro_reviewers_cardprofile"
                            src={
                              isEmpty(val?.UserId?.Profile)
                                ? require("../assets/images/shopBig.jpg")
                                : `${config.IMG_URL}/user/${val?.UserId?._id}/Profile/${val?.UserId?.Profile}`
                            }
                          />
                          <p className="ro_reviewers_name">
                            {val?.UserId?.FirstName +
                              " " +
                              val?.UserId?.LastName}
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
            <p className="ro_reviewers_name">Select Item</p>
            <Select
              options={productoption}
              onChange={(e) => SelectChange(e)}
              styles={stylesgraybg}
              className="border_blue_select custom_rct_slt"
              classNamePrefix="react-select"
              placeholder="Select Product"
              value={{
                label: Selected?.ProductName,
                value: Selected?.ProductId,
              }}
            />
            {Error?.Product && <span>{Error?.Product}</span>}
            <p className="ro_reviewers_name mt-5">Write your review</p>

            <textarea
              className="contact_textarea"
              placeholder="Write your review"
              id="w3review"
              name="w3review"
              rows="23"
              cols="50"
              onChange={(e) =>
                SetSelected({ ...Selected, ...{ Comments: e.target.value } })
              }
              value={Selected?.Comments || ""}
            ></textarea>
            {Error?.Comments && <span>{Error?.Comments}</span>}

            <center>
              {/* <Link to="/store"> */}
              <button
                className="blue_btn to_continueorder mt-5"
                onClick={() => Submit()}
              >
                Submit
              </button>
              {/* </Link> */}
            </center>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default ReviewOrder;
