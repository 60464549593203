import React,{ Component, useState, useEffect } from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { toast } from "react-toastify";

// Import Swiper React components
import { isEmpty } from "../actions/common";
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Keyboard, Autoplay } from "swiper";
import config from "../actions/config"
import useraxios from "../actions/useaxios"
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import "swiper/css";
import ReviewCard from '../Components/ReviewCard';
import Newsletter from '../Components/Newsletter';



function About() {


    useEffect(() =>{
        window.scrollTo(0,0);
    },[])


    const axiosfile = useraxios();
    const [Cms, SetCms] = useState([])
    const [Reviews, setreview] = useState()
    var [email, setEmail] = useState('');
    var [Error, SetError] = useState({});
    
    const { payload, token } = useSelector(state => state.LoginReducer.User);
    

    useEffect(() => {
        review();
        getcms()
    }, []);

    const getcms = async () => {

        var resp = await axiosfile.getCmsList();
        
        if (resp?.success == 'success')
            SetCms(resp.data);
    }

    const review = async () => {
        var SendData = {
            type: "shop",
            limit: 5
        }
        var resp = await axiosfile.Getreview(SendData);
        
        if (resp?.sucess)
            setreview(resp?.data)
    }

    const NewsLetter = async () => {
        const id = toast.loading("Subscribing...");

        let err = {};
        if (email == '') err.email = "Email Id Required";
        if (email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
        SetError(err);
        
        if (isEmpty(err)) {
            let resp = await axiosfile.Newsletter({ email: email });
            if (resp.success === 'success') {
                toast.update(id, { render: "succeffully Subcribed for NewsLetter", type: "success", isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                setEmail('');
            }
            else {
                toast.update(id, { render: "Already User", type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                SetError({ email: resp.msg });
            }
        }
        else {
            toast.update(id, { render: err.email, type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
        }
    }

  return (
    <>
    <Header/>
    <Container fluid className='ab_bannerbg'>
        <h3 className='ab_bannerttl'>About Us</h3>


       
    </Container>
    <Container className='my-5'>
    {Cms?.map((data, key) => {
                
                return (
                    <>
    {data.slug == "Aboutus" ? <>
                            <p className='fm_banner_ttl'>{data?.question}</p>
                            <Row className='d-flex align-items-center mt-5'>
                                <Col className='fm_withlove_center' xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                    <img className='img-fluid' src={data.Image ? `${config.IMG_URL}/About/${data.Image}` : require('../assets/images/shopBig.jpg')} />
                                </Col>
                                <Col className='fm_withlove_center' xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                    <div className='fm_withlove_left'>

                                        <p className='fm_whatwe_hint' dangerouslySetInnerHTML={{__html:data?.answer}}></p>

                                        {/* <p className='fm_whatwe_hint mt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p> */}

                                        <button className='cmn_button' >Visit Us</button>
                                    </div>
                                </Col>

                            </Row>
                        </> : <></>}
                        </>
                )
                })}

        </Container>
        <Container fluid className='py-3 ab_reviewdtls'>
        <p className='fm_banner_ttl'>Our Reviews</p>

        <Swiper spaceBetween={15} 
        grabCursor={true}
        keyboard={{
        enabled: true,
        }}
        autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          
        modules={[Keyboard,Autoplay,Mousewheel]}
        breakpoints={{
                    320: {
                      slidesPerView: 0.5,
                    },
                    450:{
                        slidesPerView: 1,
                    },
                    500:{
                        slidesPerView: 1.5,
                    },
                    768: {
                      slidesPerView:2,
                    },
                    992: {
                        slidesPerView:2.5 ,
                      },
                      1200: {
                        slidesPerView:3.5 ,
                      }
                    
                  }} className="mySwiper">
                    {Reviews?.length > 0 && Reviews?.map((val) => {
                        
                        return (
                            <SwiperSlide>
                                <ReviewCard data={val} />
                            </SwiperSlide>

                        )
                    })}
        {/* <SwiperSlide>
            <ReviewCard/>
        </SwiperSlide>
        <SwiperSlide>
            <ReviewCard/>
        </SwiperSlide>
        <SwiperSlide>
            <ReviewCard/>
        </SwiperSlide>
        <SwiperSlide>
            <ReviewCard/>
        </SwiperSlide>
        <SwiperSlide>
            <ReviewCard/>
        </SwiperSlide> */}
      </Swiper>
        </Container>
        <Container className='my-5'>
            {Cms?.map((data, key) => {
                
                return (
                    <>
                        {data.slug == "Who We Serve" ?
                            <>
                                <p className='fm_banner_ttl'>{data?.question}</p>
                                <Row className='d-flex align-items-center mt-5'>
                                    <Col className='fm_withlove_center' xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <img className='img-fluid' src={data.Image ? `${config.IMG_URL}/About/${data.Image}` : require('../assets/images/shopBig.jpg')} />
                                    </Col>
                                    <Col className='fm_withlove_center' xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <div className='fm_withlove_left'>
                                            {/* <h5 className='ab_serve_subttl'>Mr.John Doe</h5> */}
                                            <p className='fm_whatwe_hint' dangerouslySetInnerHTML={{__html:data?.answer}}></p>

                                            {/* <p className='fm_whatwe_hint mt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p> */}

                                            {/* <button className='cmn_button'>Visit Us</button> */}
                                        </div>
                                    </Col>

                                </Row>
                            </> : <></>}
                        {data.slug == "Vendors" ?
                            <>
                                <Row className='d-flex align-items-center mt-4'>

                                    <Col className='fm_withlove_center' xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <div className='fm_withlove_left'>
                                            <h5 className='ab_serve_subttl'>{data?.question}</h5>
                                            <p className='fm_whatwe_hint' dangerouslySetInnerHTML={{__html:data?.answer}}></p>

                                            {/* <p className='fm_whatwe_hint mt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p> */}

                                            <button className='cmn_button'>Visit Us</button>
                                        </div>
                                    </Col>
                                    <Col className='fm_withlove_center' xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                        <img className='img-fluid' src={data.Image ? `${config.IMG_URL}/About/${data.Image}` : require('../assets/images/shopBig.jpg')} />
                                    </Col>

                                </Row>
                            </> : <></>}
                        <>{data.slug == "Drivers" ?
                            <Row className='d-flex align-items-center mt-4'>
                                <Col className='fm_withlove_center' xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                    <img className='img-fluid' src={data.Image ? `${config.IMG_URL}/About/${data.Image}` : require('../assets/images/shopBig.jpg')} />
                                </Col>
                                <Col className='fm_withlove_center' xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                    <div className='fm_withlove_left'>
                                        <h5 className='ab_serve_subttl'>{data?.question}</h5>
                                        <p className='fm_whatwe_hint' dangerouslySetInnerHTML={{__html:data?.answer}}></p>

                                        {/* <p className='fm_whatwe_hint mt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p> */}

                                        <button className='cmn_button'>Visit Us</button>
                                    </div>
                                </Col>

                            </Row> : <></>}
                        </>
                    </>
                    )
            })}
            </Container>
        <Newsletter/>

    
    <Footer/>

    </>
  )
}

export default About