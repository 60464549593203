import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useraxios from "../actions/useaxios";

import delpick from "../assets/images/delpick.png";
import delpickFooter from "../assets/images/delpicklogo.png";

function Footer() {
  const navigate = useNavigate();
  const { links } = useSelector((state) => state.LoginReducer);
  console.log("linksdaswwe", links);

  return (
    <>
      <Container fluid className="footer">
        <Row>
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
            {/* <img
              className="main_logo mb-3"
              src={require("../assets/images/logo.png")}
            /> */}
            <img className="main_logo mb-3" src={delpickFooter} />
            <p className="footer_mail">info@delpick.com</p>
            <p className="footer_cntct">515-516-00624</p>
            <p className="footer_cntct">
              40 North Altadena Drive Suit 213 Pasadena CA 91107
            </p>
            <div className="footer_dwnld_imgs">
              <img
                className="footer_apps_img me-3"
                src={require("../assets/images/icon/googleplay.png")}
              />
              <img
                className="footer_apps_img"
                src={require("../assets/images/icon/imacstore.png")}
              />
            </div>
          </Col>
          <Col xxl={8} xl={8} lg={8} md={6} sm={12} xs={12}>
            <Row>
              <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                <h5 className="footer_sec_title">Information</h5>
                <ul className="li_trans">
                  <li>
                    {" "}
                    <Link to="/savestore">Saved Store</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/">Blog</Link>
                  </li>

                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>

                  <li>
                    <Link to="/termsconditions">Terms & Conditions</Link>
                  </li>

                  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li>
                </ul>
              </Col>

              <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                <h5 className="footer_sec_title">Explore</h5>
                <ul className="li_trans">
                  <li>
                    <Link to="/faq">Get Help</Link>
                  </li>
                  <li>
                    <a
                      href={"https://jamske.maticz.in/seller/"}
                      target="_blank"
                    >
                      Add Your Restaurant
                    </a>
                  </li>
                  {/* <li>Sign Up To deliver</li> */}
                  <li>
                    {" "}
                    <Link to="/">Sign Up To deliver</Link>
                  </li>
                  <li>
                    <Link to="/">Save your First Order</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </Col>

              <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                <h5 className="footer_sec_title">Get Connected</h5>
                <p className="footer_social_desc">
                  Are we friends on social media, yet? Use the buttons below to
                  connect, then join my list to your right.
                </p>

                <div className="footer_social_ic">
                  {links?.map((mlink) => (
                    <>
                      {console.log("icon", mlink)}
                      {
                        <Link
                          to={mlink.link}
                          target="blank"
                          dangerouslySetInnerHTML={{ __html: mlink.icon }}
                        ></Link>
                      }
                    </>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid className="footer_bottom">
        <Row className="bottom_footer">
          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="bottom_footer_left">
              <p>Copyright © 2023-2024 All Right Reserved Delpick</p>
            </div>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="bottom_footer_logos">
              {/* <img className='bottom_footerimg' onClick={()=>navigate('/paymentselect')} src={require('../assets/images/icon/GooglePay.png')}/> */}
              <img
                className="bottom_footerimg"
                src={require("../assets/images/icon/Visa.png")}
              />
              <img
                className="bottom_footerimg"
                src={require("../assets/images/icon/Amex.png")}
              />
              <img
                className="bottom_footerimg"
                src={require("../assets/images/icon/Mastercard.png")}
              />
              <img
                className="bottom_footerimg"
                src={require("../assets/images/icon/Maestro.png")}
              />
              <img
                className="bottom_footerimg"
                src={require("../assets/images/icon/Stripe.png")}
              />
              {/* <img className='bottom_footerimg' src={require('../assets/images/icon/PayPal.png')}/> */}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Footer;
