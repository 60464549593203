import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Container, Form, InputGroup, Modal } from "react-bootstrap";
import Select from "react-select";
// import DatePicker from 'react-date-picker';

import config from "../actions/config";
import Useaxiosfile from "../actions/useaxios";
import { useSelector } from "react-redux";
import { isEmpty } from "../actions/common";
import { toast } from "react-toastify";

function Register() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { token, payload } = useSelector((state) => state.LoginReducer.User);
  const navigate = useNavigate();

  const axiosfile = Useaxiosfile();

  const [showGender, setShowGender] = useState(false);
  const [showDOB, setShowDOB] = useState(false);

  const [passwordText, setPasswordText] = useState("password");

  const initform = {
    FirstName: "",
    LastName: "",
    EmailId: "",
    Mobile: "",
    Password: "",
    Gender: "",
    // ConfirmPassword: '',
    Address: {
      type: "Home",
      Area: "",
      City: "",
      Street2: "",
      Street: "",

      FullName: "",
      EmailId: "",
      Mobile: "",
      Zipcode: "",
      State: "",
      defaultAddress: true,
    },
  };
  const initname = {
    FirstName: "First Name",
    LastName: "Last Name",
    EmailId: "Email-Id",
    Mobile: "Mobile Number",
    Password: "Password",
    ConfirmPassword: "Confirm-Password",
    Gender: "Gender",
  };

  const AddressName = {
    FullName: "Full Name",
    EmailId: "Emai-Id",
    Mobile: "Mobile Number",
    type: "Address Type",
    Street: "House Name / Flat no",
    Area: "Area",
    City: "City",
    Zipcode: "Zipcode",
    State: "State",
  };

  const [formdata, SetFormdata] = useState(initform);
  const [displayname, SetDisplayName] = useState(initname);
  const [addressname, SetAddressName] = useState(AddressName);
  const [Error, SetError] = useState({});

  const handleCloseGender = () => setShowGender(false);
  const handleShowGender = () => setShowGender(true);

  const handleCloseDOB = () => setShowDOB(false);
  const handleShowDOB = () => setShowDOB(true);

  // const [value, onChange] = useState(new Date());

  const optionsGender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "others", label: "Others" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#A71D24"
          : isSelected
          ? "#A71D24"
          : isFocused
          ? "#A71D24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid #232222 ",
      borderRadius: 30,
      marginTop: "10px",
      cursor: "pointer",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "44px",
      borderRadius: 30,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
      marginTop: "10px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const [buttonActive, setButtonActive] = useState(1);
  const OnChange = (e) => {
    if (e?.target) {
      const { name, value, id } = e.target;

      if (!isEmpty(Error[id])) {
        SetError({ ...Error, ...{ [id]: "" } });
      }

      if (name == "Address") {
        if (!isEmpty(Error[id])) {
          SetError({ ...Error, ...{ [id]: "" } });
        }
        SetFormdata({
          ...formdata,
          ...{ Address: { ...formdata.Address, ...{ [id]: value } } },
        });
      } else {
        if (["FirstName", "LastName", "EmailId", "Mobile"].includes(id)) {
          SetFormdata({
            ...formdata,
            ...{
              [id]: value,
              ["Address"]: {
                ...formdata["Address"],
                ...{
                  [id == "LastName" || id == "FirstName" ? "FullName" : id]:
                    id == "LastName"
                      ? formdata.FirstName + " " + value
                      : id == "FirstName"
                      ? value + " " + formdata.LastName
                      : value,
                },
              },
            },
          });
        } else SetFormdata({ ...formdata, ...{ [id]: value } });
      }
    } else if (typeof e == "string") {
      SetFormdata({
        ...formdata,
        ...{ Address: { ...formdata.Address, ...{ type: e } } },
      });
    }
  };

  const Validate = () => {
    let error = {};
    Object.keys(formdata).map((val) => {
      if (isEmpty(formdata[val])) {
        error[val] = displayname[val] + " is Required";
      } else {
        if (val == "Address") {
          Object.keys(formdata?.Address).map((addr) => {
            if (isEmpty(formdata.Address[addr]) && addressname[addr]) {
              error[addr] = addressname[addr] + " is Required";
            }
          });
        }
        if (val == "Mobile") {
          if (!config.NumOnly.test(formdata[val])) {
            error[val] = "Mobile Number Must be a Number";
          }
        }
        if (val == "EmailId") {
          if (!config.EMAIL.test(formdata[val])) {
            error[val] = "Enter Valid EmailId";
          }
        }
        if (val == "Password") {
          if (!config.PASSWORD.test(formdata[val])) {
            error[val] =
              "Password must contain alphanumeric, must contain 8 to 15 letters.";
          }
        }
      }
    });
    return error;
  };

  const Register = async () => {
    let error = Validate();

    if (isEmpty(error)) {
      let senddata = formdata;
      senddata.Type = "register";
      senddata.from = "buyer";
      var Resp = await axiosfile.LoginReg(formdata);

      if (Resp.success == "success") {
        navigate("/signin");
        //    siva

        toast.success("Registered Successfully", 1000);
      } else {
        toast.error("Error Occured", 1000);
      }
      // handleCloseModal()
    } else {
      SetError(error);
    }
  };

  return (
    <>
      <div className="regwhole_bg">
        <img
          className="singin_abs reg_abs"
          src={require("../assets/images/jamskew.png")}
          alt=""
        />
        <Container>
          <Row className="justify-content-center">
            <Col
              xxl={7}
              xsl={7}
              lg={7}
              md={10}
              sm={12}
              xs={12}
              className="reg_whole_dtls"
            >
              <div className="reg_top_dtls">
                <h6 className="reg_top_title">Register</h6>
                <p className="reg_skip_txt">
                  <Link to="/">skip</Link>
                </p>
              </div>
              <p className="reg_welcome_txt">
                Welcome. Let's Create Your <b>Delpick</b> Account.
              </p>
              <Row>
                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      First Name
                    </label>
                    <br />
                    <input
                      className="sigin_inputs"
                      type="text"
                      placeholder="Ex.John"
                      id="FirstName"
                      name="mobile"
                      onChange={(e) => OnChange(e)}
                    />
                    {Error?.FirstName && (
                      <span className="error-msg">{Error.FirstName}</span>
                    )}
                  </div>
                </Col>
                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      Last Name
                    </label>
                    <br />
                    <input
                      className="sigin_inputs"
                      type="text"
                      placeholder="Ex.Deo"
                      id="LastName"
                      name="LastName"
                      onChange={(e) => OnChange(e)}
                    />
                    {Error?.LastName && (
                      <span className="error-msg">{Error.LastName}</span>
                    )}
                  </div>
                </Col>

                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      Email Address
                    </label>
                    <br />
                    <input
                      className="sigin_inputs"
                      type="email"
                      placeholder="Ex.johndeo@gmail.com"
                      id="EmailId"
                      name="EmailId"
                      onChange={(e) => OnChange(e)}
                    />
                    {Error?.EmailId && (
                      <span className="error-msg">{Error.EmailId}</span>
                    )}
                  </div>
                </Col>

                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      Password
                    </label>
                    <br />
                    <div className="reg_passwordinps">
                      <input
                        className=""
                        type={passwordText == "password" ? "password" : "text"}
                        placeholder="Enter Password"
                        id="Password"
                        name="Password"
                        onChange={(e) => OnChange(e)}
                      />
                      <i
                        onClick={() =>
                          setPasswordText(
                            passwordText == "text" ? "password" : "text"
                          )
                        }
                        className={
                          passwordText == "password"
                            ? "fa-solid fa-eye"
                            : "fa-solid fa-eye-slash"
                        }
                      ></i>
                    </div>
                    {Error?.Password && (
                      <span className="error-msg">{Error.Password}</span>
                    )}
                  </div>
                </Col>

                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      Gender{" "}
                      <i
                        class="fa-solid fa-circle-exclamation reg_excla"
                        onClick={handleShowGender}
                      />
                    </label>
                    <br />
                    <Select
                      options={optionsGender}
                      onChange={(e) => {
                        SetError({});
                        SetFormdata({ ...formdata, ["Gender"]: e.value });
                      }}
                      styles={stylesgraybg}
                      className="border_blue_select"
                      classNamePrefix="react-select custom_rct_slt"
                      placeholder="Select a gender"
                      autoFocus={false}
                      autoComplete={false}
                    />
                    {Error?.Gender && (
                      <span className="error-msg reg_gendertop">
                        {Error.Gender}
                      </span>
                    )}
                  </div>
                </Col>

                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  {/* <div className='single_center'>
                    <label className='signin_labels' for="mobile">Date of Birth <i class="fa-solid fa-circle-exclamation reg_excla" onClick={handleShowDOB} /></label><br />
                    <input className='sigin_inputs' type="date" placeholder='Choose DOB' id="DateofBirth" name="DateofBirth" onChange={(e)=>OnChange(e)}/>
                    {/* <DatePicker onChange={onChange} value={value} /> */}
                  {/* { Error?.DateofBirth && <span>{Error.DateofBirth}</span>} */}
                  {/* </div> */}
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      Phone Number
                    </label>
                    <br />
                    {/* <input className='sigin_inputs' type="number" placeholder='123 456 7890' id="mobile" name="mobile"/> */}

                    <InputGroup className="sigin_inputs reg_inpgrp">
                      <InputGroup.Text id="basic-addon1">+1 |</InputGroup.Text>
                      <Form.Control
                        placeholder="123 456 7890"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        type="number"
                        id="Mobile"
                        onChange={(e) => OnChange(e)}
                      />
                    </InputGroup>
                    {Error?.Mobile && (
                      <span className="error-msg">{Error.Mobile}</span>
                    )}
                  </div>
                </Col>

                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      House no/ Flat no/Floor
                    </label>
                    <br />
                    <input
                      className="sigin_inputs"
                      type="text"
                      placeholder="No:12/345/2nd floor And Street"
                      id="Street"
                      name="Address"
                      onChange={(e) => OnChange(e)}
                    />
                    {Error?.Street && (
                      <span className="error-msg">{Error.Street}</span>
                    )}
                  </div>
                </Col>

                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      Society/Street name
                    </label>
                    <br />
                    <input
                      className="sigin_inputs"
                      type="text"
                      placeholder="Enter Street"
                      id="Street2"
                      name="Address"
                      onChange={(e) => OnChange(e)}
                    />
                    {Error?.Street2 && (
                      <span className="error-msg">{Error.Street2}</span>
                    )}
                  </div>
                </Col>

                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      City
                    </label>
                    <br />
                    <input
                      className="sigin_inputs"
                      type="text"
                      placeholder="Enter City"
                      id="City"
                      name="Address"
                      onChange={(e) => OnChange(e)}
                    />
                    {Error?.City && (
                      <span className="error-msg">{Error.City}</span>
                    )}
                  </div>
                </Col>

                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      Country{" "}
                    </label>
                    <br />
                    <input
                      className="sigin_inputs"
                      type="text"
                      placeholder="Enter Area"
                      id="Area"
                      name="Address"
                      onChange={(e) => OnChange(e)}
                    />
                    {Error?.Area && (
                      <span className="error-msg">{Error.Area}</span>
                    )}
                  </div>
                </Col>

                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      State
                    </label>
                    <br />
                    <input
                      className="sigin_inputs"
                      type="text"
                      placeholder="Enter State"
                      id="State"
                      name="Address"
                      onChange={(e) => OnChange(e)}
                    />
                    {Error?.State && (
                      <span className="error-msg">{Error.State}</span>
                    )}
                  </div>
                </Col>

                <Col xxl={6} xsl={6} lg={6} md={6} sm={6} xs={12}>
                  <div className="single_center">
                    <label className="signin_labels" for="mobile">
                      Zipcode
                    </label>
                    <br />
                    <input
                      className="sigin_inputs"
                      type="text"
                      placeholder="Enter Zipcode"
                      id="Zipcode"
                      name="Address"
                      onChange={(e) => OnChange(e)}
                    />
                    {Error?.Zipcode && (
                      <span className="error-msg">{Error.Zipcode}</span>
                    )}
                  </div>
                </Col>

                <Col
                  xxl={6}
                  xsl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="mt-4"
                >
                  <div className="single_center ">
                    <button
                      className={
                        buttonActive == 1
                          ? "regpopy_buttons active"
                          : "regpopy_buttons"
                      }
                      onClick={() => {
                        setButtonActive(1);
                        OnChange("Home");
                      }}
                    >
                      Home
                    </button>
                    <button
                      className={
                        buttonActive == 2
                          ? "regpopy_buttons active"
                          : "regpopy_buttons"
                      }
                      onClick={() => {
                        setButtonActive(2);
                        OnChange("Office");
                      }}
                    >
                      Office
                    </button>
                    <button
                      className={
                        buttonActive == 3
                          ? "regpopy_buttons active"
                          : "regpopy_buttons"
                      }
                      onClick={() => {
                        setButtonActive(3);
                        OnChange("Other");
                      }}
                    >
                      Other
                    </button>
                  </div>
                </Col>
              </Row>
              <div className="w-100 text-center">
                {/* <Link to='/'> */}
                <button
                  className="blue_btn register_btn"
                  onClick={() => Register()}
                >
                  Register
                </button>
                {/* </Link> */}
              </div>
              <p className="reg_bottomtxt">
                Already have an account?
                <span>
                  <Link to="/signin">Sign In</Link>
                </span>
              </p>
            </Col>
          </Row>

          {/* start of gender modal */}
          <Modal
            show={showGender}
            onHide={handleCloseGender}
            backdrop="static"
            keyboard={false}
            centered
            className="reg_gender_modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <p className="reg_modal_hintntxt">
                “We use your gender to make Delpick services more personal.
                Additionally, we can provide more relevant, tailored content you
                might be interested in, like ads as well as promotions,
                including but not limited to ,highly discounted and/or free
                products/services".
              </p>
            </Modal.Body>
          </Modal>
          {/* end of gender modal */}

          {/* start of DOB modal */}

          <Modal
            show={showDOB}
            onHide={handleCloseDOB}
            backdrop="static"
            keyboard={false}
            centered
            className="reg_gender_modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <p className="reg_modal_hintntxt reg_dobmodal_txt">
                1. Account security and personalization across Delpick services.
              </p>

              <p className="reg_modal_hintntxt reg_dobmodal_txt">
                2. Make sure you're old enough to buy certain products and
                services. For example, show/buy alcoholic beverages.
              </p>

              <p className="reg_modal_hintntxt reg_dobmodal_txt">
                3. Offer you highly discounted or free products and services on
                your birthday.
              </p>

              <p className="reg_modal_hintntxt reg_dobmodal_txt">
                4. Determine your age group for personalized recommendations and
                ads.
              </p>
            </Modal.Body>
          </Modal>
          {/* end of DOB modal */}
        </Container>
      </div>
    </>
  );
}

export default Register;
