import { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { Link } from "react-router-dom";
import GMap from "./Map/GMap";
import { toast } from "react-toastify";
import { isEmpty } from "../actions/common";
import { GetAddressBasedOnLatLng, GetLatLngBasedOnAddress } from "./Map/geocode";
import { useDispatch, useSelector } from "react-redux";
import { Data_Section } from "../redux/constants";
import { useEffect } from "react";

export default function MapInputPopup({ showMapInput, setShowMapInput, setplacechoosepopup }) {
  
  const dispatch = useDispatch()
  
  const [Address, SetAddress] = useState({});
  const [show_map, setShow_map] = useState(false);
  const [selectmap, SetSelectmap] = useState(false);

  
  // useEffect(() => {
  //   SetQuantity(val?.Quantity);
  // }, [val?.Quantity]);

  

  const changePositiion = ()=>
  {
    var addressInputElement =document.getElementById("parent_comple");
// addressInputElement.on('focus', function () {

setTimeout(() => {
  var pacContainer =document.querySelectorAll('.pac-container');
  for(var i=0;i<pacContainer.length;i++)
  {
    addressInputElement.appendChild(pacContainer[i]);

  }
  console.log(addressInputElement,pacContainer,"e;e");
  // addressInputElement.parentElement.append(pacContainer);
}, 100);



  // $(addressInputElement.parent()).append(pacContainer);
// })
  }



  const Add = async (newadd, LatLng) => {
    console.log("ljfjsfsjlsjlf", newadd, LatLng, Address);
    if (
      typeof newadd == "string" ||
      Address?.Address ||
      Address?.LatLng ||
      LatLng
    ) {
      if (newadd || (Address?.Address && isEmpty(Address?.Latlng))) {
        var Latlngs = await GetLatLngBasedOnAddress(
          (typeof Address?.Address == "string"
            ? Address?.Address
            : Address?.Address?.display) ?? newadd
        );
        console.log("dsjjkflksf", Latlngs);
        var Latlng = Latlngs?.coordinates;
      } else Latlng = Address?.Latlng ?? LatLng;
      if (LatLng || (Address?.Latlng && isEmpty(Address?.Address))) {
        var Addresses = await GetAddressBasedOnLatLng(
          Address?.Latlng?.[0] ?? LatLng?.[0],
          Address?.Latlng?.[1] ?? LatLng?.[1]
        );
      } else {
        Addresses = Address?.Address
          ? typeof Address?.Address == "string"
            ? Address?.Address
            : Address?.Address?.display
            ? Address?.Address?.display
            : newadd
          : newadd;
      }

      console.log("AddressAddress", Addresses, Address, LatLng);
      if (Addresses) localStorage.setItem("Address", JSON.stringify(Latlng));
      dispatch({
        type: Data_Section,
        Register_Section: {
          Address: JSON.stringify(Latlng),
          AddressInName: Addresses,
        },
      });
      setShowMapInput(!showMapInput)
      if(setplacechoosepopup){
        setplacechoosepopup(false)
      }
    } else {
      toast.error("Enter Or Choose Location to Add it");
    }
  };

  useEffect(() => {
    if (selectmap) {
      Add();
      SetSelectmap(false);
    }
  }, [selectmap]);


  console.log('dksjjfskfjskljgsgs',Address)
  useEffect(()=>{
    if(Address?.Address?.display){
      setShowMapInput(false)
    }
  },[Address])


  return (
    <Modal
      size="md"
      show={showMapInput}
      onHide={() => {setShowMapInput(!showMapInput)}}
      backdrop="static"
      keyboard={false}
      centered
      className="regpopy_modal"
    >
      {/* <Modal.Header className="regpopy_header" closeButton></Modal.Header> */}

      <Modal.Body className="d-flex flex-column align-items-center justify-content-center py-5 gap-4">
        <h5>Choose your location</h5>
        <div className="d-flex align-items-center header_bfrsignin_dtls header_bfrsignin_mob md_mapinput_mr_none">
          <div id="parent_comple" className="parent_comple_mao parent_comple d-flex align-items-center w-100 md_inpt_loc_wrap">
            <Autocomplete
              autoCorrect={true}
              className="header_bfrsignin_input md_input_fld"
              type="text"
              placeholder="Enter your location"
              id="StreetName"
              name="Address"
              value={
                (typeof Address?.Address == "string"
                  ? Address?.Address
                  : Address?.Address?.display) ?? ""
              }
              onChange={(e) => {
                changePositiion();
                (async () => {
                  SetAddress({ Address: e?.target?.value });
                })();
              }}
              apiKey={"AIzaSyDT9jQZ5BhdkR8dUwZFtdJKdi82PaBT_h8"}
              onPlaceSelected={(place) => {
                SetAddress({
                  ...Address,
                  ...{
                    Address: place?.formatted_address ?? place?.name,
                    Latlng: place?.geometry?.location
                      ? [
                          place?.geometry?.location?.lat(),
                          place?.geometry?.location?.lng(),
                        ]
                      : null,
                  },
                });
                Add(place?.formatted_address);;
              }}
              // types
              options={{
                types: ["address"],
              }}
              onError={(e) => console.log("dskfslsgsgrrfd", e)}
            />
            {!Address?.Address && (
              <div
                className="header_locateme_btn"
                onClick={() => setShow_map(true)}
              >
                <img src={require("../assets/images/icon/Icon.png")} />
                <p>Locate me</p>
              </div>
            )}
          </div>
          <button to={""} className="header_findfood bor_hover">
            Search
          </button>
        </div>

        {/* <Button className="md_map_sigin_btn">Sign in</Button> */}
      </Modal.Body>

      {show_map && (
        <GMap
          show_map={show_map}
          Add={Add}
          setShow_map={setShow_map}
          SetAddress={SetAddress}
          Address={Address}
          SetSelectmap={SetSelectmap}
        />
      )}
    </Modal>
  );
}
