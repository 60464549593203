import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import FoodCard from "../Components/FoodCard";
import useAxiosFile from "../actions/useaxios";
import { useSelector } from "react-redux";

const details = [
  {
    discountValue: "$0 Delivery Fees",
    brandName: "McDonalds",
    img: require("../assets/images/menu/c2.png"),
    shopName: "Food Shop",
  },
  {
    discountValue: "20% Discount on 1st order",
    brandName: "Doritos",
    img: require("../assets/images/menu/c3.png"),
    shopName: "Doritos Shop",
  },
  {
    discountValue: "50% Sale",
    brandName: "Dunkin",
    img: require("../assets/images/menu/c4.png"),
    shopName: "Donuts Shop",
  },
  {
    discountValue: "$10 Delivery Fees",
    brandName: "Breakfast bar",
    img: require("../assets/images/menu/c5.png"),
    shopName: "Bred Shop",
  },
  {
    discountValue: "50% Discount on 2nd order",
    brandName: "Snacks & coffeer",
    img: require("../assets/images/menu/c6.png"),
    shopName: "Coffee shop",
  },
  {
    discountValue: "50% Sale",
    brandName: "Fruits",
    img: require("../assets/images/menu/c7.png"),
    shopName: "Fruits shop",
  },
  {
    discountValue: "10% Weekend Offer",
    brandName: "Burger & Chips",
    img: require("../assets/images/menu/c8.png"),
    shopName: "Burger King",
  },
  {
    discountValue: "20% Discount on 1st order",
    brandName: "KFC Chicken",
    img: require("../assets/images/menu/c9.png"),
    shopName: "KFC",
  },
  {
    discountValue: "50% Sale",
    brandName: "Margarita Masala",
    img: require("../assets/images/menu/c10.png"),
    shopName: "Pizza Hut",
  },
];

function SavedStore() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { token, payload } = useSelector((state) => state.LoginReducer.User);

  const axiosFile = useAxiosFile();

  const [WishList, SetWishList] = useState({ page: 1, data: [], count: 0 });

  const GetWishList = async (page) => {
    let Resp = await axiosFile.GetMyWishList({
      UserId: payload?._id,
      Type: "Shop",
      page: page,
      limit: 5,
    });
    console.log("lskjdskljfksklfs", Resp);
    if (page == 1) {
      SetWishList({ page: 1, data: Resp?.data, count: Resp?.count });
    } else {
      SetWishList({
        ...WishList,
        ...{
          page: page,
          data: [...WishList.data, ...Resp?.data],
          count: Resp?.count,
        },
      });
    }
  };

  useState(() => {
    if (payload) {
      GetWishList(1);
    }
  }, [payload]);

  return (
    <>
      <Header />
      <div className="min_he_cont">
      <Container className="mt-5">
        <p className="ss_mainttl">Store Wishlist</p>
        <Row className="mt-3">
          {WishList?.data?.length > 0 ?
            WishList?.data?.map((val) => {
              return (
                <Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                  <FoodCard
                    details={val}
                    _id={val?._id}
                    list={WishList.data}
                    getwishlist={() => GetWishList(WishList.page)}
                    from={"savedshop"}
                  />
                </Col>
              );
            })
          :
          <div className="stire_nid_hwi">
            <div className="saved_bg_nine">
          <p className="mb-0">No Saved Stores</p>
          </div>
          </div>
          }
        </Row>
        {WishList?.count > WishList?.data?.length && (
          <div className="text-center">
            <button
              className="blue_btn odp_savebtn "
              onClick={() =>
                GetWishList((WishList?.page ? WishList?.page : 0) + 1)
              }
            >
              Load More
            </button>
          </div>
        )}
      </Container>
      </div>
      <Footer />
    </>
  );
}

export default SavedStore;
