import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";

import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Useaxiosfile from "../actions/useaxios";
import { DecryptData, EncryptData, isEmpty } from "../actions/common";
import config from "../actions/config";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

function ViewDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const { payload, token } = useSelector((state) => state.LoginReducer.User);

  const axiosfile = Useaxiosfile();

  const detailid = location.search.split("?order=")[1];
  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const [Detail, SetDetail] = useState({});

  const [Reason, SetReason] = useState("");
  const [error, SetError] = useState("");

  const handleCloseCancelOrder = () => setShowCancelOrder(false);
  const handleShowCancelOrder = () => setShowCancelOrder(true);

  useEffect(() => {
    OrderDetail();
  }, [detailid]);

  const OrderDetail = async () => {
    let Resp = await axiosfile.GetOrderDetail({
      id: DecryptData(detailid),
      UserId: payload?._id,
    });
    console.log(
      "dsahdjksdahasjkdhaskdasd",
      Resp?.data,
      Detail,
      DecryptData(detailid)
    );
    SetDetail(Resp?.data?.Order);
  };

  const Cancel = async () => {
    if (isEmpty(Reason)) {
      SetError("Enter Reason");
    } else {
      let Resp = await axiosfile.OrderAction({
        action: "cancel",
        OrderId: Detail._id,
      });

      if (Resp?.success == "success") {
        toast.error("Cancelled Successfully", 1000);
        handleCloseCancelOrder();
        navigate("/orderdetail");
      } else {
        toast.error("Try-Again", 1000);
      }
    }
  };

  return (
    <>
      <Header />
      <Container className="my-5">
        <p className="odp_lit_title">Orders</p>
        <p className="odp_lit_hint mt-2">
          We are attentive and respectful to each customer.
        </p>

        <Row className="mt-4">
          <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12} className="mb-4">
            {console.log("klcjvlksjsdlkds", Detail,isEmpty(Detail?.Address?.FullAddress))}
            {Detail?.CartId?.length > 0 &&
              Detail?.CartId.map((item) => {
                return (
                  <>
                    <Row className="pc_rightwholedtls">
                      <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}>
                        <div className="pc_rightimg">
                          <img
                            className="img-fluid pc_rightbur"
                            src={ isEmpty(item?.ProductId?.ProductImage) ? require("../assets/images/logo.png") : `${config.IMG_URL}/Product/${item?.ProductId?.ProductImage}`}
                          />
                        </div>
                      </Col>
                      <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                        <div className="pc_rightttlprice">
                          <p className="pc_rightttl">
                            {item?.ProductId?.ProductName}
                          </p>
                          <p className="pc_rightprice">
                            {" "}
                            {Detail?.ShopId?.CurrencySymbol}{" "}
                            {Number(item?.Quantity) * Number(item?.TotalPrice)}
                          </p>
                        </div>

                        <div className="cartoffcanva_spoons">
                          {item?.AdditionalInfo.map((it) => {
                            return it.value ? (
                              <>
                                <div className="pc_rightfddtl">
                                  <img
                                    className="cartoffcanva_spponicn"
                                    src={require("../assets/images/icon/Vector.png")}
                                  />
                                  <p>
                                    {it?.value.length > 12
                                      ? it?.value.slice(12).concat("...")
                                      : it?.value}
                                  </p>
                                  <p className="">
                                    {" "}
                                    {it?.price ? (
                                      <>
                                        {" "}
                                        -{Detail?.ShopId?.CurrencySymbol}{" "}
                                        {it?.price}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </p>
                                </div>
                              </>
                            ) : (
                              <></>
                            );
                          })}
                        </div>

                        <p>Items: {Number(item?.Quantity)}</p>
                      </Col>
                    </Row>
                    <hr className="pc_righthr" />
                  </>
                );
              })}
          </Col>

          <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
            <div className="vd_wholedtls d_new_flx">
              <div className="vd_singledtl wht_spc">
                <p className="vd_single_title">Order Number</p>
                <p className="vd_single_value">{Detail?.OrderNumber}</p>
              </div>
              <div className="vd_singledtl wht_spc">
                <p className="vd_single_title">Payment</p>
                <p className="vd_single_value">
                  {Detail?.TransactionHistory?.object?.payment_method_types[0]}
                </p>
              </div>
              <div className="vd_singledtl wht_spc">
                <p className="vd_single_title ">Ship From</p>
                <p className="vd_single_value">{Detail?.ShopId?.BrandName}</p>
              </div>
              <div className="vd_singledtl wht_spc">
                <p className="vd_single_title">Ship to</p>
                <p className="vd_single_value">
                  {Detail?.TransactionHistory?.object?.shipping?.name}
                </p>
              </div>
              <div className="vd_singledtl wht_spc">
                <p className="vd_single_title">Total amount</p>
                <p className="vd_single_value">
                  {Detail?.ShopId?.CurrencySymbol
                    ? Detail?.ShopId?.CurrencySymbol
                    : "$"}{" "}
                  {Detail?.Price?.PayAmount}
                </p>
              </div>
              {console.log("Detail?.Time", Detail?.Time)}
              <div className="vd_singledtl wht_spc">
                <p className="vd_single_title ">Order date</p>
                <p className="vd_single_value">
                  {Detail?.Time?.Paid &&
                    moment(new Date(Detail?.Time?.Paid)).format("DD MMMM YYYY")}
                </p>
              </div>
              <div className="vd_singledtl wht_spc">
                <p className="vd_single_title ">Order Time</p>
                <p className="vd_single_value">
                  {Detail?.Time?.Paid &&
                    moment(new Date(Detail?.Time?.Paid)).format("hh : mm a")}
                </p>
              </div>
            </div>
            {Detail?.OrderType == "deliver" ? (
              <>
                <p className="cco_summary mt-4">Delivery Address</p>
                <Row>
                  <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                    <div className="ps_addresstxt">
                      <p>Name:</p>
                      <b>
                        {Detail?.TransactionHistory?.object?.shipping?.name}
                      </b>
                    </div>
                    <div className="ps_addresstxt">
                      <p>Address:</p>
                      {
                        isEmpty(Detail?.Address?.FullAddress) ?
                        (<b>
                        {
                          Detail?.TransactionHistory?.object?.shipping?.address
                            ?.line1
                        }
                        ,{" "}
                        {
                          Detail?.TransactionHistory?.object?.shipping?.address
                            ?.line2
                        }
                        ,{" "}
                        {
                          Detail?.TransactionHistory?.object?.shipping?.address
                            ?.city
                        }{" "}
                        ,{" "}
                        {
                          Detail?.TransactionHistory?.object?.shipping?.address
                            ?.state
                        }{" "}
                        ,{" "}
                        {
                          Detail?.TransactionHistory?.object?.shipping?.address
                            ?.country
                        }{" "}
                        -{" "}
                        {
                          Detail?.TransactionHistory?.object?.shipping?.address
                            ?.postal_code
                        }
                      </b>)
                      :
                      <b>
                        {Detail?.Address?.FullAddress}
                      </b>  
                    }
                    </div>
                    <div className="ps_addresstxt">
                      <p>Phone Number:</p>
                      <b>
                        {Detail?.TransactionHistory?.object?.shipping?.phone}
                      </b>
                    </div>
                    {/* <div className='ps_addresstxt'>
                            <p>Email Address:</p>
                            <b>{Detail?.TransactionHistory?.object?.shipping?.}</b>
                        </div> */}
                    <div className="ps_addresstxt">
                      <p>Expected at:</p>
                      <b>
                        {moment(Detail?.DeliveryTime).format(
                          "DD / MM / YYYY HH:mm"
                        )}
                      </b>
                    </div>
                    {/* <div className='ps_editremovedef'>
                        <div className='ps_editremove'>
                        <p>Edit</p>
                        <p className='ps_removetxt'>Remove</p>
                        </div>
                        
                        </div> */}
                  </Col>
                  {!isEmpty(Detail?.Coupon) && (
                    <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                      <p className="cco_summary vd_coupeon_applied">
                        Coupon Applied
                      </p>

                      <div className="pc_avail_trynow">
                        <div className="pc_trynowapply">
                          <button className="pc_yellowbtn">
                            {Detail?.Coupon?.title}
                          </button>
                        </div>
                        <p className="pc_canvaoffer">
                          {Detail?.Coupon?.CouponDescription}
                        </p>
                        {Detail?.Coupon?.offersType == "Discount" ? (
                          <p className="pc_canvas_secondarytxt">
                            Maximum discount -{" "}
                            {Detail?.Coupon?.DiscountInPercentange} on orders
                            above {Detail?.Coupon?.CurrencySymbol}{" "}
                            {Detail?.Coupon?.MinimumBuy}
                          </p>
                        ) : Detail?.Coupon?.offersType == "Price" ? (
                          <p className="pc_canvas_secondarytxt">
                            Maximum discount - {Detail?.Coupon?.DiscountInPrice}{" "}
                            on orders above {Detail?.Coupon?.CurrencySymbol}{" "}
                            {Detail?.Coupon?.MinimumBuy}
                          </p>
                        ) : (
                          <p className="pc_canvas_secondarytxt">
                            Buy {Detail?.Coupon?.BuyProductId?.length} - Get{" "}
                            {Detail?.Coupon?.GetProductId?.length} on orders
                            above $50
                          </p>
                        )}
                        <p className="pc_canvas_secondarytxt">
                          Expires on:{" "}
                          {moment(Detail?.Coupon?.ExpireTime).format(
                            "DD MM YYYY HH:mm"
                          )}
                        </p>
                      </div>
                    </Col>
                  )}
                </Row>
              </>
            ) : (
              <>{Detail?.Address?.FullName}</>
            )}

            <div className="to_dual_btns vd_dual_btns">
              <Link className="to_continueshop_link" to="/">
                <button className="blue_btn to_continueorder me-3">
                  Continue Shopping
                </button>
              </Link>
              <Link className="to_continueshop_link" to="/detail/order">
                <button className="blue_btn to_continueorder me-3">
                  My Order
                </button>
              </Link>
            </div>
          </Col>
        </Row>

        {/* cancel order Modal */}
        <Modal
          show={showCancelOrder}
          onHide={handleCloseCancelOrder}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body>
            <p className="vd_cancel_modalttl">Cancel Order</p>
            <div className="vd_mdl_imgtxt">
              <img
                className="vd_mdl_cancelimg"
                src={require("../assets/images/icon/Cancel.png")}
              />
              <p className="vd_modal_cntnt">
                Are you sure you want to cancel this order?
              </p>
              <p className="vd_modal_lowercntnt">
                Your order is on the way. you cannot cancel now
              </p>
            </div>
            <p className="vd_mdl_smalltxt">Tell us Why</p>
            <textarea
              className="contact_textarea vd_modal_textarea"
              placeholder="Write a short suggestion "
              id="Reason"
              name="w3review"
              rows="3"
              cols="50"
              onChange={(e) => SetReason(e.target.value)}
            ></textarea>
            {error && <span>{error}</span>}
            <div className="to_dual_btns vd_dual_btns">
              <button
                className="blue_btn to_continueorder me-3"
                onClick={() => Cancel()}
              >
                Cancel Item
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* end of cancel order Modal */}
      </Container>
      <Footer />
    </>
  );
}

export default ViewDetails;
