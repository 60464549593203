import React, { useEffect, useState } from "react";

import ReactHorizontalDatePicker from "react-horizontal-strip-datepicker";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import {
  Datepicker,
  DatepickerEvent,
} from "@meinefinsternis/react-horizontal-date-picker";
import { isEmpty } from "../actions/common";

const DateChoose = (props) => {
  const [remainingday, setRemainingday] = useState(0);
  const [selecteday, SetSelectedDay] = useState(null);
  const [disabledates, SetDisabledDates] = useState([]);

  const onSelectedDay = (d) => {
    console.log("ljlkdgdgsgs", d[0]);
    props.clearTime();
    SetSelectedDay(
      selecteday == null ? d[0] : selecteday == d[1] ? d[0] : d[1]
    );
    props?.SetChoosenTime({
      ...props.ChoosenTime,
      ...{ day: selecteday == null ? d[0] : selecteday == d[1] ? d[0] : d[1] },
    });
  };

  useEffect(() => {
    // getRemanningDays();
    if (props?.ChoosenTime?.day) {
      SetSelectedDay(props?.ChoosenTime?.day);
    }
  }, []);

  const getRemanningDays = () => {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    console.log(time, "time");

    time.setDate(0);
    // alert(
    //   time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0
    // );
    console.log(
      "kshkshfskhfs",
      time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0
    );
    setRemainingday(
      time.getDate() > date.getDate()
        ? time.getDate() - date.getDate() + date.getDate()
        : 0
    );
  };

  useEffect(() => {
    if (props?.Validdays?.length > 0) {
      let invaliddays = [];
      for (let i = 0; i < 33; i++) {
        console.log(
          "dsjkhdsjkfs",
          new Date(new Date().setDate(new Date().getDate() + i))
        );
        let currdate = new Date().setDate(new Date().getDate() + i);
        if (!props?.Validdays?.includes(new Date(currdate).getDay())) {
          invaliddays.push(new Date(currdate));
        }
      }
      SetDisabledDates(invaliddays);
    }
  }, [props?.Validdays]);

  // useEffect(() => {
  //   if (selecteday) {
  //     props.GetTime(selecteday);
  //   }
  // }, [selecteday]);

  // const handleChange = (d: DatepickerEvent) => {
  //   const [startValue, endValue, rangeDates] = d;
  //   // setDate((prev) => ({ ...prev, endValue, startValue, rangeDates }));
  // };

  // let getClass = document.getElementsByClassName("_2E");
  // console.log("classnmae", getClass.ariaDisabled);
  // getClass.ariaDisabled = false;
  // var buttons = document.querySelectorAll("._2E");
  // buttons[0].setAttribute("aria-disabled", false);
  // buttons[1].setAttribute("aria-disabled", false);

  return (
    <>
      {/* <ReactHorizontalDatePicker
        className="newclasdate"
        selectedDay={onSelectedDay}
        enableScroll={true}
        // endDate={10}
        enableDays={remainingday + 2}
        color={"#987876"}
      /> */}
      <Datepicker
        className="newclasdate"
        onChange={onSelectedDay}
        startValue={selecteday}
        // endValue={new Date().setMonth(new Date().getMonth()+1,new Date().getDate()+1)}
        startDate={new Date()}
        endDate={new Date().setMonth(new Date().getMonth() + 1)}
        color={"#987876"}
        disabledDates={disabledates}
      />
      {console.log("lsjfksjfsfs", selecteday, props)}
    </>
  );
};

export default DateChoose;
