import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Container,
  Nav,
  Navbar,
  Offcanvas,
  Dropdown,
  DropdownButton,
  Card,
  Button,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import ReactSearchBox from "react-search-box";

import useAxiosFile from "../actions/useaxios";
import { Data_Section, LogOut } from "../redux/constants";
import { CartData } from "./CartData";
import { EncryptData, isEmpty } from "../actions/common";
import config from "../actions/config";
import SingInPopup from "./SingInPopup";
import Autocomplete from "react-google-autocomplete";
import {
  GetAddressBasedOnLatLng,
  GetLatLngBasedOnAddress,
} from "./Map/geocode";
import GMap from "./Map/GMap";
import { toast } from "react-toastify";

import delpickLogo from "../assets/images/delpicklogo.png";
import closeIcon from "../assets/svg/close.svg";
import MapInputPopup from "./MapInputPopup";

function Header(props) {
  console.log("kjlgsfkgjsfkg", props);
  const [show, setShow] = useState(false);
  const [show_sign, setShow_sign] = useState(false);
  const [show_map, setShow_map] = useState(false);
  const [selectmap, SetSelectmap] = useState(false);
  const [mobSearchBar, setMobileSearchBar] = useState(false);
  const [showMapInput, setShowMapInput] = useState(false);

  const handleCloseHam = () => setShow(false);
  const handleShowHam = () => setShow(true);

  const [showCart, setShowCart] = useState(false);
  const [cartdatas, SetCartDatas] = useState([]);
  const [total, SetTotal] = useState(0);
  const [ShowTotal, SetShowTotal] = useState(0);
  const [Load, SetLoad] = useState(false);
  const [UpdateBln, SetUpdateBln] = useState(false);
  const [Address, SetAddress] = useState({});
  const [SearchResult, SetSearchResult] = useState({ datatab: "", result: [] });
  const [Searchword, SetSearchWord] = useState("");
  // const [DeliveryType,SetDeliveryType] = useState('Delivery');
  const [LocationPopup,SetLocationPopup] = useState(true);

  const handleCloseCart = () => setShowCart(false);
  const handleShowCart = () => {
    setShowCart(true);
    GetCart();
  };

  const axiosFile = useAxiosFile();
  const dispatch = useDispatch();
  const { payload, token } = useSelector((state) => state.LoginReducer.User);
  const address = useSelector((state) => state.LoginReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef(null);

  console.log("autoComplete Address", address, Address, location);

  /**lp */
  const Logout = () => {
    let text = "Do You Want to Log-Out?";
    if (window.confirm(text)) {
      localStorage.clear();
      document.cookie =
        "token" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      dispatch({
        type: LogOut,
      });
      navigate("/");
      handleCloseHam();
    }
  };

  useEffect(() => {
    let LatLng = localStorage.getItem("Address");
    if (!isEmpty(LatLng)) {
      LatLng = JSON.parse(LatLng);
      SetAddress({
        Address: LatLng,
      });
      Add(null, LatLng);
      SetLocationPopup(false);
    }
    else{
      SetLocationPopup(true);
    }
  }, []);

  useEffect(() => {
    if (payload?._id) {
      GetCartLength();
      if (isEmpty(localStorage.getItem("Address"))) {
        var add = payload?.DeliveryAddress?.find((it) => it.defaultAddress);
        let newadd =
          (add?.DoorNumber ? add?.DoorNumber + " " + "," : "") +
          (add?.Street ? add?.Street + " " + "," : "") +
          (add?.Street2 ? add?.Street2 + " " + "," : "") +
          (add?.City ? add?.City + " " + "," : "") +
          (add?.Area ? add?.Area + " " + "," : "") +
          (add?.State ? add?.State + " " + "," : "") +
          (add?.Zipcode ? add?.Zipcode : "");
        if (newadd) {
          SetAddress({ Address: newadd });
          Add(newadd);
        }
      }
    }
  }, [props?.Getcart, payload?._id, token]);

  const Add = async (newadd, LatLng) => {
    console.log("ljfjsfsjlsjlf", newadd, LatLng, Address);
    if (
      typeof newadd == "string" ||
      Address?.Address ||
      Address?.LatLng ||
      LatLng
    ) {
      if (newadd || (Address?.Address && isEmpty(Address?.Latlng))) {
        var Latlngs = await GetLatLngBasedOnAddress(
          (typeof Address?.Address == "string"
            ? Address?.Address
            : Address?.Address?.display) ?? newadd
        );
        console.log("dsjjkflksf", Latlngs);
        var Latlng = Latlngs?.coordinates;
      } else Latlng = Address?.Latlng ?? LatLng;
      if (LatLng || (Address?.Latlng && isEmpty(Address?.Address))) {
        var Addresses = await GetAddressBasedOnLatLng(
          Address?.Latlng?.[0] ?? LatLng?.[0],
          Address?.Latlng?.[1] ?? LatLng?.[1]
        );
      } else {
        Addresses = Address?.Address
          ? typeof Address?.Address == "string"
            ? Address?.Address
            : Address?.Address?.display
            ? Address?.Address?.display
            : newadd
          : newadd;
      }

      console.log("AddressAddress", Addresses, Address, LatLng);
      if (Addresses) localStorage.setItem("Address", JSON.stringify(Latlng));
      dispatch({
        type: Data_Section,
        Register_Section: {
          Address: JSON.stringify(Latlng),
          AddressInName: Addresses,
        },
      });
    } else {
      toast.error("Enter Or Choose Location to Add it");
    }
  };

  const GetCartLength = async () => {
    let Resp = await axiosFile.GetCartLength({ UserId: payload._id });
    console.log("ksflskflsfs", Resp);
    SetShowTotal(!isEmpty(Resp.data) ? Resp?.data : 0);
  };
  const GetCart = async () => {
    let Resp = await axiosFile.GetCarts({ UserId: payload._id });
    let price = (Resp?.data?.shop ? Resp?.data?.shop : Resp?.data)?.reduce(
      (acc, it) => acc + Number(it.TotalPrice) * Number(it.Quantity),
      0
    );

    SetTotal(price);
    SetCartDatas(Resp?.data?.shop ? Resp?.data?.shop : Resp?.data);
  };
  const UpdateCart = async (type, ProductId, _id) => {
    SetUpdateBln(true);
    if (type == "delete" || type == "DeleteAll") {
      SetLoad(true);
    }
    let senddata = {
      action: "update_from_header",
      type: type,
      UserId: payload._id,
      ProductId: ProductId,
      CartId: _id,
      Quantity: 1,
    };
    let Resp = await axiosFile.CartAction(senddata);
    console.log("dljgksjg", _id, cartdatas, senddata, Resp);
    // SetTotal(cartdatas?.)
    if (Resp?.success == "success") {
      if (type == "delete") {
        SetLoad(false);
        SetCartDatas([]);
        SetCartDatas(cartdatas.filter((it) => it._id != _id));
        SetShowTotal(ShowTotal - 1);
      } else if (type == "DeleteAll") {
        SetLoad(false);
        SetCartDatas([]);
        SetShowTotal(0);
      } else {
        let ind = cartdatas.findIndex((it) => it._id == _id);
        console.log("cartdatas[ind]", cartdatas[ind]);
        // SetCartDatas([
        //   ...cartdatas.slice(0,ind),
        //   {
        //     ...cartdatas[ind],
        //     ...{
        //       Quantity:type ==  "add" ? Number(cartdatas[ind]?.Quantity)+1:Number(cartdatas[ind]?.Quantity)-1,
        //     }
        //   },
        //   ...cartdatas.slice(ind+1,cartdatas.length),

        // ])
      }
      if (props?.CartUpdate) {
        props?.CartUpdate("header");
      }
      GetCart();
    } else {
      toast.error(Resp?.msg, 1000);
    }
    SetUpdateBln(false);
  };
  console.log("SetCartDatas", cartdatas);
  useEffect(() => {
    let price = (cartdatas || [])?.reduce(
      (acc, it) => acc + Number(Number(it.TotalPrice) * Number(it.Quantity)),
      0
    );
    SetTotal(price);
  }, [UpdateBln]);

  const SearchData = async (e) => {
    let data = e?.target?.value;
    // let data = e;
    console.log("kdhjfdkfhdjfd", data);
    SetSearchWord(data);
    if (!isEmpty(data)) {
      let Resp = await axiosFile.GetSearchData({
        keyword: data,
        limit: 3,
        page: 1,
      });
      console.log("jksadhldflsdfs", Resp);
      setMobileSearchBar(true);
      let datatab = "";
      if (Resp?.data) {
        Object.keys(Resp?.data).map((val) => {
          if (Resp?.data?.[val]?.length > 0) {
            datatab = val;
          }
        });
      }
      SetSearchResult({
        datatab: datatab,
        result: [
          ...Resp?.data?.product,
          ...Resp?.data?.shop,
          ...Resp?.data?.category,
        ],
      });
    } else if (isEmpty(data)) {
      SetSearchResult({
        datatab: "",
        result: [],
      });
    }
  };

  const ViewPageRedirect = (e) => {
    console.log("kjldslkdsffd", e);
    if (e?.type == "category") {
      navigate("/store?Id=" + EncryptData(e._id));
    } else if (e?.type == "shop") {
      navigate("/categoryDetail/?Id=" + EncryptData(e._id));
    } else if (e?.type == "product") {
      navigate("/detail/?Id=" + EncryptData(e._id));
    }
  };

  const removeAddress = useCallback(() => {
    SetAddress({});
    localStorage.removeItem("Address");
    dispatch({
      type: Data_Section,
      Register_Section: {
        Address: null,
        AddressInName: null,
      },
    });
  }, [address?.Address]);
  console.log("kfdjfsljfsfs", SearchResult);

  const SearchClick = () => {
    navigate(
      "/search?keyword=" +
        EncryptData({ keyword: Searchword, defaulttab: SearchResult?.datatab })
    );
  };

  console.log("checkkkkkk", SearchResult);

  useEffect(()=>{
    if(props.placechoosepopup){
      SetLocationPopup(true);
    }
  },[props.placechoosepopup])

  const SearchHeader = useMemo(() => {
    return (
      <>
        <div className="header_search_manual">
          <i
            onClick={() => SearchClick()}
            class="fa-solid fa-magnifying-glass search_glass"
          />
          {/* <input
            type="text"
            value={Searchword}
            onChange={(e) => SearchData(e)}
          />
          {SearchResult?.result?.length > 0 &&
            SearchResult?.result?.map((val) => {
              return <p>{val?.value}</p>;
            })} */}

          {/* <ReactSearchBox
            placeholder="Search"
            value="john"
            data={SearchResult?.result}
            className="header_searcher"
            onChange={(e) => SearchData(e)}
            onSelect={(e) => ViewPageRedirect(e)}
          /> */}
        </div>
      </>
    );
  }, [SearchResult?.result, Searchword]);

  useEffect(() => {
    if (selectmap) {
      Add();
      SetSelectmap(false);
    }
  }, [selectmap]);

  useEffect(()=>{

  },payload)

  return (
    <>
      {["lg"].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="header">
          <Container fluid className="header_bot_bor">
            {/* hamburger offcanvas */}
            <i
              class="fa-solid fa-bars headerham_offcanva"
              onClick={handleShowHam}
            ></i>
            <Offcanvas
              show={show}
              className="mainoffcanva_whole"
              onHide={handleCloseHam}
            >
              <Offcanvas.Header className="mainoffcanva">
                <Offcanvas.Title className="offcanvamain_al">
                  <div className="offcanvamain_close">
                    <img
                      className="offcanva_clsblue"
                      onClick={handleCloseHam}
                      // src={require("../assets/images/icon/closeblue.png")}
                      src={closeIcon}
                    />
                  </div>
                  <div className="offcanvamain_logo">
                    <img className="offcanvamain_logoimg" src={delpickLogo} />
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="mainoffcanva_body">
                <div className="offcanvamain_list">
                  <ul>
                    <Link className="offcanvamain_link" to="/">
                      <li>
                        <div
                          className="offcanvamain_div"
                          onClick={() => handleCloseHam()}
                        >
                          <img
                            className="offcanvamain_logos"
                            src={require("../assets/images/icon/home.png")}
                          />
                          <p>Home</p>
                        </div>
                      </li>
                    </Link>
                    {payload && (
                      <>
                        <Link className="offcanvamain_link" to="/savestore">
                          <li>
                            <div className="offcanvamain_div">
                              <img
                                className="offcanvamain_logos"
                                src={require("../assets/images/icon/store.png")}
                              />
                              <p>Saved Stores</p>
                            </div>
                            {/* <p>4</p> */}
                          </li>
                        </Link>

                        <Link className="offcanvamain_link" to="/detail/order">
                          <li>
                            <div className="offcanvamain_div">
                              <img
                                className="offcanvamain_logos"
                                src={require("../assets/images/icon/myorders.png")}
                              />
                              <p>My Orders</p>
                            </div>
                            <p></p>
                          </li>
                        </Link>
                      </>
                    )}
                    <Link
                      className="offcanvamain_link"
                      to=""
                      target={"_blank"}
                      hrefLang={config.Seller}
                    >
                      <li>
                        <div
                          className="offcanvamain_div"
                          onClick={() => handleCloseHam()}
                        >
                          <img
                            className="offcanvamain_logos"
                            src={require("../assets/images/icon/joinjamske.png")}
                          />
                          <p>Join A Delpick</p>
                        </div>
                      </li>
                    </Link>

                    <Link
                      className="offcanvamain_link"
                      to=""
                      target={"_blank"}
                      hrefLang={config.Seller}
                    >
                      <li>
                        <div
                          className="offcanvamain_div"
                          onClick={() => handleCloseHam()}
                        >
                          <img
                            className="offcanvamain_logos"
                            src={require("../assets/images/icon/delivery.png")}
                          />
                          <p>Be A Delivery Partners</p>
                        </div>
                      </li>
                    </Link>

                    {payload && (
                      <li onClick={Logout}>
                        <div className="offcanvamain_div">
                          <img
                            className="offcanvamain_logos"
                            src={require("../assets/images/icon/logout1.png")}
                          />
                          <p>Log Out</p>
                        </div>
                      </li>
                    )}
                    <Link className="offcanvamain_link" to="/about">
                      <li onClick={() => handleCloseHam()}>
                        <p>About Us</p>
                      </li>
                    </Link>
                    <Link className="offcanvamain_link" to="/contact">
                      <li onClick={() => handleCloseHam()}>
                        <p>Contact Us</p>
                      </li>
                    </Link>
                    <Link className="offcanvamain_link" to="/termsconditions">
                      <li onClick={() => handleCloseHam()}>
                        <p>Terms & Conditions</p>
                      </li>
                    </Link>
                    <Link className="offcanvamain_link" to="/privacypolicy">
                      <li onClick={() => handleCloseHam()}>
                        <p>Privacy Policy</p>
                      </li>
                    </Link>
                    <Link className="offcanvamain_link" to="/faq">
                      <li onClick={() => handleCloseHam()}>
                        <p>FAQ</p>
                      </li>
                    </Link>

                    {/* Delivery Pickup Map */}
                    <li className="header_windowhide d-none ">
                      <DropdownButton
                        id="dropdown-basic"
                        className="header_type_dropdown headertype_mob"
                        title={address?.DeliveryType}
                        onSelect={(e) => {
                          dispatch({
                            type: Data_Section,
                            Register_Section: {
                              DeliveryType: e,
                            },
                          });
                        }}
                      >
                        <Dropdown.Item
                          className="header_del_drpitem"
                          eventKey="Delivery"
                        >
                          Delivery
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="header_del_drpitem"
                          eventKey="Pickup"
                        >
                          Pickup
                        </Dropdown.Item>
                      </DropdownButton>
                    </li>
                    {/* {console.log("asdsahdkadas",)} */}

                    {/* dropdown-1 */}
                    {!isEmpty(address?.AddressInName) && (
                      <li className="header_windowhide d-none ">
                        <Dropdown className="header_toaddr_dropdwn header_toadd_dropoffcanva">
                          <Dropdown.Toggle id="dropdown-basic">
                            To{" "}
                            <span>
                              {" "}
                              {String(address?.AddressInName)?.substring(0, 15)}
                            </span>
                          </Dropdown.Toggle>
                          {address?.AddressInName && (
                            <Dropdown.Menu className="drp_dwn_mt">
                              <Dropdown.Item
                                className="header_addr_drpitem"
                                href=""
                              >
                                <div className="address_brief">
                                  <div className="header_addr">
                                    <i class="fa-solid fa-house header_drphouse" />
                                    <p className="p_txt_wrp">
                                      {`${address?.AddressInName}`}
                                    </p>
                                    <i
                                      class={
                                        isEmpty(payload)
                                          ? "fa-regular fa-x"
                                          : "fa-regular fa-circle-check"
                                      }
                                      onClick={() => {
                                        removeAddress();
                                      }}
                                    ></i>
                                  </div>
                                  {!isEmpty(payload) ? (
                                    <HashLink to="/detail/address">
                                      <button className="blue_btn">
                                        Add address
                                      </button>
                                    </HashLink>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                      </li>
                    )}
                    <li className="header_windowhide loacateme_mob d-none">
                      {isEmpty(address?.AddressInName) && (
                        <div className="header_bfrsignin_dtls header_bfrsignin_mob">
                          <Autocomplete
                            autoCorrect={true}
                            className="header_bfrsignin_input"
                            type="text"
                            placeholder="Enter your location"
                            id="StreetName"
                            name="Address"
                            value={
                              (typeof Address?.Address == "string"
                                ? Address?.Address
                                : Address?.Address?.display) ?? ""
                            }
                            onChange={(e) => {
                              (async () => {
                                SetAddress({ Address: e?.target?.value });
                              })();
                            }}
                            apiKey={process.env.geoapikey}
                            onPlaceSelected={(place) => {
                              SetAddress({
                                ...Address,
                                ...{
                                  Address:
                                    place?.formatted_address ?? place?.name,
                                  Latlng: place?.geometry?.location
                                    ? [
                                        place?.geometry?.location?.lat(),
                                        place?.geometry?.location?.lng(),
                                      ]
                                    : null,
                                },
                              });
                              Add(place?.formatted_address);
                            }}
                            // types
                            options={{
                              types: ["address"],
                            }}
                            onError={(e) => console.log("dskfslsgsgrrfd", e)}
                          />
                          {!Address?.Address && (
                            <div
                              className="header_locateme_btn"
                              onClick={() => setShow_map(true)}
                            >
                              <img
                                src={require("../assets/images/icon/Icon.png")}
                              />
                              <p>Locate me</p>
                            </div>
                          )}

                          <button
                            to={""}
                            className="header_findfood "
                            onClick={Add}
                          >
                            Search
                          </button>
                        </div>
                      )}
                    </li>
                    <li className="d-none">
                      <Link
                        to={{ pathname: token ? "/detail/profile" : "" }}
                        onClick={() => !token && setShow_sign(true)}
                      >
                        <i class="fa-regular fa-user header_user header_user_offcanva" />{" "}
                      </Link>
                    </li>
                    {token &&
                      !location?.pathname?.includes("/paymentselect") && (
                        <li className="header_windowhide">
                          <div
                            className="header_cart header_cart_offcanva"
                            onClick={() => {
                              handleShowCart();
                              handleCloseHam();
                            }}
                          >
                            <i class="fa-solid fa-cart-shopping header_cart_ic"></i>
                            <p className="cart_count">{ShowTotal}</p>
                          </div>
                        </li>
                      )}
                  </ul>
                </div>
              </Offcanvas.Body>
            </Offcanvas>

            {/* <DropdownButton
              id="dropdown-basic"
              className="header_type_dropdown headertypedrop_mobhide"
              title={address?.DeliveryType}
              onSelect={(e) => {
                dispatch({
                  type: Data_Section,
                  Register_Section: {
                    DeliveryType: e,
                  },
                });
              }}
            >
              <Dropdown.Item className="header_del_drpitem" eventKey="Delivery">
                Delivery
              </Dropdown.Item>
              <Dropdown.Item className="header_del_drpitem" eventKey="Pickup">
                Pickup
              </Dropdown.Item>
            </DropdownButton> */}
            {console.log("sfsfsfssf", address)}

            {/* dropdown-2 */}

            {address?.AddressInName && (
              <Dropdown className="header_toaddr_dropdwn header_add_address cmn_drp_dwn">
                <Dropdown.Toggle id="dropdown-basic">
                  To{" "}
                  <span>
                    {`${
                      address?.AddressInName
                        ? String(address?.AddressInName)?.substring(0, 15)
                        : ""
                    }....`}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="custom_drp_dwn drp_dwn_mt py-0">
                  <Dropdown.Item className="header_addr_drpitem" href="">
                    <div className="address_brief">
                      <div className="header_addr">
                        <i class="fa-solid fa-house header_drphouse" />
                        <p className="header_addrdp p_txt_wrp">
                          {`${address?.AddressInName}`}
                        </p>
                        <i
                          class={"fa-regular fa-x"}
                          onClick={() => removeAddress()}
                        ></i>
                      </div>
                      {!isEmpty(payload) ? (
                        <HashLink to="/detail/address">
                          <button className="blue_btn">Add address</button>
                        </HashLink>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

            {isEmpty(address?.AddressInName) && (
              <>
                <div className="header_bfrsignin_dtls lct_min_wd">
                  <Autocomplete
                    autoCorrect={true}
                    className="header_bfrsignin_input"
                    type="text"
                    placeholder="Enter your location"
                    id="StreetName"
                    name="Address"
                    value={
                      (typeof Address?.Address == "string"
                        ? Address?.Address
                        : Address?.Address?.display) ?? ""
                    }
                    onChange={(e) => {
                      (async () => {
                        SetAddress({ Address: e?.target?.value });
                      })();
                    }}
                    apiKey={process.env.geoapikey}
                    onPlaceSelected={(place) => {
                      console.log("lsjdslfsfsfaa", place);
                      SetAddress({
                        ...Address,
                        ...{
                          Address: place?.formatted_address ?? place?.name,
                          Latlng: place?.geometry?.location
                            ? [
                                place?.geometry?.location?.lat(),
                                place?.geometry?.location?.lng(),
                              ]
                            : null,
                        },
                      });
                      Add(place?.formatted_address);
                    }}
                    // types
                    options={{
                      types: ["address"],
                    }}
                    onError={(e) => console.log("dskfslsgsgrrfd", e)}
                  />
                  {!Address?.Address && (
                    <div
                      className="header_locateme_btn"
                      onClick={() => setShow_map(true)}
                    >
                      <img src={require("../assets/images/icon/Icon.png")} />
                      <p>Locate me</p>
                    </div>
                  )}
                  <button
                    to={""}
                    // state={Address}

                    className="header_findfood "
                    onClick={Add}
                  >
                    Search
                  </button>
                </div>
              </>
            )}

            <div
              className={
                payload ? "header_logo_align" : "bfrsignin_headlogo_align"
              }
            >
              {/* start of map input popup */}

              {/* <Button onClick={() => setShowMapInput(true)}>Map Button</Button> */}

              <MapInputPopup
                showMapInput={LocationPopup}
                setShowMapInput={SetLocationPopup}
                show_sign={show_sign}
                setShow_sign={setShow_sign}
                setplacechoosepopup = {props.setplacechoosepopup}
              />

              {/* {show_sign && (
                <SingInPopup
                  show_sign={show_sign}
                  setShow_sign={setShow_sign}
                  Addtocart={() => console.log("nothing")}
                />
              )} */}

              {/* end of map input popup */}
              <Link to="/">
                {/* <img
                  className="main_logo header_mainlogo"
                  src={require("../assets/images/logo.png")}
                /> */}

                <img
                  className={
                    token
                      ? "main_logo header_mainlogo header_logo_cntr"
                      : "main_logo header_mainlogo header_logo_cntr main_logo_bfrauth"
                  }
                  src={delpickLogo}
                />
              </Link>
            </div>
            <Nav className="justify-content-end flex-grow-1"></Nav>
            <div className="header_search_manual d-none d-lg-flex align-items-center">
              <i
                onClick={() => SearchClick()}
                class="fa-solid fa-magnifying-glass search_glass icn_clr"
              />
              <Dropdown className="ms-2 dropdon_serach">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="drp_dwn_search"
                >
                  <input
                    type="text"
                    placeholder="Shop Name/Item"
                    value={Searchword}
                    onChange={(e) => SearchData(e)}
                  />
                </Dropdown.Toggle>
                {console.log("lfkdjkdlf", SearchResult)}
                {SearchResult?.result?.length > 0 && (
                  <Dropdown.Menu className="drp_dwn_menu">
                    {/* <Dropdown.Item href="">static one</Dropdown.Item>
                  <Dropdown.Item href="">static two</Dropdown.Item>
                  <Dropdown.Item href="">static three</Dropdown.Item> */}

                    {SearchResult?.result?.map((val) => {
                      return (
                        <Dropdown.Item
                          href="javascript:void(0)"
                          onClick={() => ViewPageRedirect(val)}
                        >
                          {val?.value}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                )}
              </Dropdown>
              {/* <input
                type="text"
                value={Searchword}
                onChange={(e) => SearchData(e)}
                placeholder="Search"
                className="cust_inpt ms-2"
              /> */}
              {/* {SearchResult?.result?.length > 0 &&
                SearchResult?.result?.map((val) => {
                  return <p>{val?.value}</p>;
                })} */}
              {console.log("jsdhfskjhfsd", SearchResult)}
              {/* <ReactSearchBox
                placeholder="Search"
                value="john"
                data={SearchResult?.result}
                className="header_searcher"
                onChange={(e) => SearchData(e)}
                onSelect={(e) => ViewPageRedirect(e)}
              /> */}
            </div>
            <div className="me-2 me-sm-3 d-lg-none ms-md-3">
              <Link
                to={{ pathname: token ? "/detail/profile" : "" }}
                onClick={() => !token && setShow_sign(true)}
              >
                <i class="fa-regular fa-user header_user header_user_offcanva" />{" "}
              </Link>
            </div>
            {/* <button onClick={()=>SearchClick()} type='button'>search</button> */}
            <i
              class="fa-solid fa-magnifying-glass search_glass search_glass_windowhide"
              onClick={() => setMobileSearchBar(true)}
            />
            <Link
              className="header_userlink"
              to={token ? "/detail/profile" : ""}
              onClick={() => {
                console.log("dsjdlkasjdsakdlas", token);
                !token && setShow_sign(true);
              }}
            >
              <i class="fa-regular fa-user header_user" />{" "}
            </Link>
            {token && !location?.pathname?.includes("/paymentselect") && (
              <div
                className="header_cart"
                onClick={() => {
                  handleShowCart();
                  handleCloseHam();
                }}
              >
                <i class="fa-solid fa-cart-shopping header_cart_ic"></i>
                <p className="cart_count">{ShowTotal}</p>
              </div>
            )}
            <Offcanvas
              show={showCart}
              placement="end"
              variant="end"
              onHide={handleCloseCart}
            >
              <Offcanvas.Body>
                <p className="cartoffcanva_subttl">Your cart </p>
                <div className="cartoffcanva_brand">
                  <h5>{cartdatas?.ShopId?.BrandName}</h5>
                  <img
                    onClick={handleCloseCart}
                    className="cartoffcanva_right"
                    src={require("../assets/images/icon/coolicon.png")}
                  />
                </div>

                {cartdatas?.length > 0 ? (
                  <>
                    <div className="cartoffcanva_checkal">
                      <Link
                        className="commmon_link cartoffcanva_bluecommonlink"
                        to="/cartcheckout"
                      >
                        <div className="cartoffcanva_bluecheckout">
                          <p>Checkout</p>
                          <p>
                            {" "}
                            {cartdatas[0]?.ShopId?.CurrencySymbol} {total}
                          </p>
                        </div>
                      </Link>
                      <img
                        className="cartoffcanva_delteicn"
                        src={require("../assets/images/icon/delete.png")}
                        onClick={() => {
                          UpdateCart("DeleteAll");
                        }}
                      />
                    </div>

                    {!Load &&
                      cartdatas?.map((val) => {
                        return (
                          <CartData
                            val={val}
                            UpdateCart={UpdateCart}
                            SetShowTotal={SetTotal}
                            ShowTotal={total}
                            SetCartDatas={SetCartDatas}
                            cartdatas={cartdatas}
                          />
                        );
                      })}
                  </>
                ) : (
                  <p>Your Cart is Empty.</p>
                )}
              </Offcanvas.Body>
            </Offcanvas>
            {/* end of Cart offcanvas */}
          </Container>

          <Container fluid className="d-lg-none mt-2 pb-2">
            <div className="d-sm-flex gap-3 align-items-center justify-content-sm-between wid_hund d-block ">
              {/* Delivery Pickup Map */}
              {/* <div className="header_windowhide">
                <DropdownButton
                  id="dropdown-basic"
                  className="header_type_dropdown headertype_mob txt_alg_srt"
                  title={address?.DeliveryType}
                  onSelect={(e) => {
                    dispatch({
                      type: Data_Section,
                      Register_Section: {
                        DeliveryType: e,
                      },
                    });
                  }}
                >
                  <Dropdown.Item
                    className="header_del_drpitem"
                    eventKey="Delivery"
                  >
                    Delivery
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="header_del_drpitem"
                    eventKey="Pickup"
                  >
                    Pickup
                  </Dropdown.Item>
                </DropdownButton>
              </div> */}
              {/* {console.log("asdsahdkadas",)} */}

              {/* dropdown-3 */}
              {!isEmpty(address?.AddressInName) && (
                <div className="header_windowhide unst_position">
                  <Dropdown className="header_toaddr_dropdwn header_toadd_dropoffcanva mar_zero mt-2 mt-sm-0">
                    <Dropdown.Toggle id="dropdown-basic">
                      To{" "}
                      <span>
                        {" "}
                        {String(address?.AddressInName)?.substring(0, 15)}
                      </span>
                    </Dropdown.Toggle>
                    {address?.AddressInName && (
                      <Dropdown.Menu className="drp_abv_sm drp_dwn_mt py-0">
                        <Dropdown.Item className="header_addr_drpitem" href="">
                          <div className="address_brief">
                            <div className="header_addr">
                              <i class="fa-solid fa-house header_drphouse" />
                              <p className="header_addrdp p_txt_wrp">
                                {`${address?.AddressInName}`}
                              </p>
                              <i
                                class={"fa-regular fa-x"}
                                onClick={() => {
                                  removeAddress();
                                }}
                              ></i>
                            </div>
                            {!isEmpty(payload) ? (
                              <HashLink to="/detail/address">
                                <button className="blue_btn">
                                  Add address
                                </button>
                              </HashLink>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                </div>
              )}
              {isEmpty(address?.AddressInName) && (
                <div className="header_windowhide loacateme_mob mt-3 mt-sm-0">
                  <div className="header_bfrsignin_dtls header_bfrsignin_mob mar_zero lct_min_wd ">
                    <Autocomplete
                      autoCorrect={true}
                      className="header_bfrsignin_input"
                      type="text"
                      placeholder="Enter your location"
                      id="StreetName"
                      name="Address"
                      value={
                        (typeof Address?.Address == "string"
                          ? Address?.Address
                          : Address?.Address?.display) ?? ""
                      }
                      onChange={(e) => {
                        (async () => {
                          SetAddress({ Address: e?.target?.value });
                        })();
                      }}
                      apiKey={process.env.geoapikey}
                      onPlaceSelected={(place) => {
                        console.log("lsjdslfsfsf", place);
                        SetAddress({
                          ...Address,
                          ...{
                            Address: place?.formatted_address ?? place?.name,
                            Latlng: place?.geometry?.location
                              ? [
                                  place?.geometry?.location?.lat(),
                                  place?.geometry?.location?.lng(),
                                ]
                              : null,
                          },
                        });
                        Add(place?.formatted_address);
                      }}
                      // types
                      options={{
                        types: ["address"],
                      }}
                      onError={(e) => console.log("dskfslsgsgrrfd", e)}
                    />
                    {!Address?.Address && (
                      <div
                        className="header_locateme_btn"
                        onClick={() => setShow_map(true)}
                      >
                        <img src={require("../assets/images/icon/Icon.png")} />
                        <p>Locate me</p>
                      </div>
                    )}
                    <button
                      to={""}
                      className="header_findfood srch_pads"
                      onClick={Add}
                    >
                      Search
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Container>
        </Navbar>
      ))}
      {mobSearchBar && (
        <div className="header_searchsmall">
          <div className="header_search_manual header_searchmanual_full">
            <div className="header_mobsearch_view">
              <i
                onClick={() => SearchClick()}
                class="fa-solid fa-magnifying-glass search_glass"
              />

              {/* <ReactSearchBox
                placeholder="Search"
                value="john"
                data={SearchResult?.result}
                className="header_searcher"
                onChange={(e) => SearchData(e)}
                onSelect={(e) => ViewPageRedirect(e)}
              /> */}
            </div>
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="drp_dwn_search"
              >
                <input
                  type="text"
                  placeholder="Shop name/Item"
                  value={Searchword}
                  onChange={(e) => SearchData(e)}
                />
                {console.log("dlkjfkdjgs", SearchResult)}
              </Dropdown.Toggle>
              {SearchResult?.result?.length > 0 && (
                <Dropdown.Menu className="drp_dwn_menu">
                  {/* <Dropdown.Item href="">static one</Dropdown.Item>
                <Dropdown.Item href="">static two</Dropdown.Item>
                <Dropdown.Item href="">static three</Dropdown.Item> */}

                  {SearchResult?.result?.map((val) => {
                    return (
                      <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={() => ViewPageRedirect(val)}
                      >
                        {val?.value}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              )}
            </Dropdown>
            <i
              class="fa-solid fa-xmark"
              onClick={() => setMobileSearchBar(false)}
            />
          </div>
        </div>
      )}

      {show_sign && (
        <SingInPopup
          show_sign={show_sign}
          setShow_sign={setShow_sign}
          Addtocart={() => console.log("nothing")}
        />
      )}

      {show_map && (
        <GMap
          show_map={show_map}
          Add={Add}
          setShow_map={setShow_map}
          SetAddress={SetAddress}
          Address={Address}
          SetSelectmap={SetSelectmap}
        />
      )}
    </>
  );
}

export default Header;
