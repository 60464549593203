import React, { useState, useEffect, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import Header from "../Components/Header";
import { Container } from "react-bootstrap";
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import Footer from "../Components/Footer";
import useraxios from "../../src/actions/useaxios";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import config from "../actions/config";
import {
  DecryptData,
  EncryptData,
  NumberOnly,
  isEmpty,
} from "../actions/common";
import { toast } from "react-toastify";
import SingInPopup from "../Components/SingInPopup";
function Detail() {
  const { payload, token } = useSelector((state) => state.LoginReducer.User);

  const axiosfile = useraxios();

  const location = useLocation();
  const navigate = useNavigate();

  const [productdetail, SetProductDetail] = useState({});
  const [updatecart, SetUpdateCart] = useState(1);
  const [showmain, Setshowmain] = useState(1);
  const [Quantity, SetQuantity] = useState(1);
  const [AdditionalInfo, SetAdditionalInfo] = useState([]);
  const [show_sign, setShow_sign] = useState(false);
  const [Error, setError] = useState({});
  const [nextpage, SetNextPage] = useState("");

  const [showInitial, setShowInitial] = useState("");

  const handleCloseInitial = () => setShowInitial(false);

  const ProductDetail = async (productid) => {
    let Resp = await axiosfile.GetProductDetail({ ProductId: productid });
    SetProductDetail(Resp?.data);
    console.log("Resp?.data", Resp?.data);
    Setshowmain(Resp?.data?.ProductImage);
  };
  useEffect(() => {
    if (location?.search) {
      var productid = location.search.split("?Id=")[1];

      ProductDetail(
        isEmpty(DecryptData(productid)) ? productid : DecryptData(productid)
      );
    }
  }, [location?.search]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const validation = () => {
    if (productdetail?.AdditionalInfo.length > 0) {
      var err = {};
      productdetail?.AdditionalInfo?.map((it) => {
        var isThere = AdditionalInfo?.filter((id) => id.question == it.Name);
        console.log("dsahdkjasdhsajksakhda", it, isThere);
        if (
          (it?.Required || it?.RequiredMultiple?.enable) &&
          isThere?.length == 0 && it?.Required!='false'
        ) {
          err[it.Name] = `Required ${[it.Name]} Menu`;
        }
        if (
          it?.RequiredMultiple?.enable &&
          Number(it?.RequiredMultiple?.min) > isThere?.length
        ) {
          err[it.Name] = `Select Minimum ${it?.RequiredMultiple?.min} ${[
            it.Name,
          ]}`;
        }
        if (
          it?.RequiredMultiple?.enable &&
          Number(it?.RequiredMultiple?.max) < isThere?.length
        ) {
          err[it.Name] = `Select only ${it?.RequiredMultiple?.max} ${[
            it.Name,
          ]}`;
        }
      });
      console.log("dsdasdasdasdas", err);
      if (!isEmpty(err)) {
        setError(err);
        return false;
      } else return true;
    } else return true;
  };
  console.log("Addtocart", payload);
  const Addtocart = async (from, redirectpage) => {
    redirectpage = redirectpage ?? nextpage;
    setError({});
    console.log(
      "sjfhsfgshfs",
      token || from,
      validation(),
      !isEmpty(Total),
      payload?._id
    );
    if (token || from) {
      if (validation()) {
        if (!isEmpty(Quantity)) {
          if (!isEmpty(Total)) {
            if (payload?._id) {
              let senddata = {
                ProductId: productdetail?._id,
                ShopId: productdetail?.ShopId,
                SellerId: productdetail?.SellerId,
                Quantity: Quantity,
                UserId: payload._id,
                Notes: productdetail?.Notes ? productdetail?.Notes : "",
                action: "add_from_info",
                TotalPrice: Total,
                AdditionalInfo,
              };
              let Resp = await axiosfile.CartAction(senddata);
              console.log("dsadljsakldsadsa", Resp);
              if (Resp?.success == "success") {
                SetUpdateCart(updatecart + 1);
                toast.success("Added To cart");
                if (redirectpage == "view") {
                  navigate(
                    "/categoryDetail/?Id=" +
                      EncryptData(productdetail?.ShopId?._id)
                  );
                } else {
                  navigate("/cartcheckout");
                }
              } else {
                console.log("Resp?.msg", Resp?.msg, Resp?.error);
                toast.error(Resp?.msg ?? Resp?.error);
                if (Resp?.error == "Remove Already Exist Shop From Cart") {
                  SetNextPage(redirectpage);
                  setShowInitial(Resp?.show);
                }
              }
            } else {
              // navigate("/signin", {
              //   state: {
              //     previouspage: location?.search
              //       ? location?.pathname + location?.search
              //       : location.pathname,
              //   },
              // });
            }
          } else {
            toast.error("Check price");
          }
        } else {
          toast.error("Quantity Must Not be Empty");
        }
      }
    } else {
      setShow_sign(true);
    }
  };

  const UpdateCart = async (type, ProductId, _id) => {
    let senddata = {
      action: "update_from_header",
      type: "DeleteAll",
      UserId: payload._id,
    };
    let Resp = await axiosfile.CartAction(senddata);
    if (Resp?.success == "success") {
      setShowInitial("");
      Addtocart();
    } else {
      toast.error("Remove Cart from Header");
    }
  };

  const Total = useMemo(() => {
    return productdetail?.Price
      ? Number(productdetail.Price) +
          Number(
            AdditionalInfo.length > 0
              ? AdditionalInfo.reduce((acc, it) => acc + Number(it.price), 0)
              : 0
          )
      : 0;
    // return  Number(productdetail?.Price ? productdetail?.Price :0 )
  }, [productdetail, AdditionalInfo, Quantity]);
  console.log("index>>>>>>>>>>>>", AdditionalInfo);

  const OnChange = (e, i, data, ind) => {
    const { id, value, name, checked } = e?.target;

    let index = AdditionalInfo.findIndex((it) => it.i == i && it.index == ind);
    console.log("index>>>>>>>>>>>>", checked, index, AdditionalInfo);
    SetAdditionalInfo(
      index != -1 && checked == false
        ? [
            ...AdditionalInfo.slice(0, index),
            ...AdditionalInfo.slice(index + 1, AdditionalInfo.length),
          ]
        : [
            ...AdditionalInfo,
            {
              price: data?.value,
              value: data?.key,
              question: name,
              type: "checkbox",
              index: ind,
              i,
            },
          ]
    );
  };

  return (
    <>
      {console.log("slksfkslfs", showmain, productdetail)}
      {!isEmpty(productdetail) && (
        <>
          {show_sign && (
            <SingInPopup
              show_sign={show_sign}
              setShow_sign={setShow_sign}
              Addtocart={Addtocart}
            />
          )}
          <Header Getcart={updatecart == 1 ? false : updatecart} />
          <Container className="my-5">
            <div className="foodorder_modal">
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="classi">
                    <div className="details_leftimg_holder d-flex align-items-center justify-content-center">
                      <img
                        className="details_left_img img_new_fit"
                        src={
                          showmain == productdetail?.ProductImage
                            ? productdetail?.ProductImage
                              ? `${config.IMG_URL}/Product/${productdetail?.ProductImage}`
                              : require("../assets/images/logo.png")
                            : showmain
                            ? `${config.IMG_URL}/Product/sub/${showmain}`
                            : require("../assets/images/logo.png")
                        }
                      />
                    </div>
                    <div className="foodmodal_demoimg_small">
                      {[
                        { ProductImageName: productdetail?.ProductImage },
                        ...productdetail?.ProductImageMultiple,
                      ]?.map((it) => {
                        return (
                          <img
                            onClick={() => Setshowmain(it.ProductImageName)}
                            className="foodmodal_imgsmall covered_cardimg me-2 covered_cardimg_new"
                            src={
                              it.ProductImageName == productdetail?.ProductImage
                                ? it.ProductImageName
                                  ? `${config.IMG_URL}/Product/${it.ProductImageName}`
                                  : require("../assets/images/logo.png")
                                : it.ProductImageName
                                ? `${config.IMG_URL}/Product/sub/${it.ProductImageName}`
                                : require("../assets/images/logo.png")
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                </Col>
                <Col
                  className="foodmodal_closerel foodmodal_closerel_tabv"
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <h5 className="foodmodal_title">
                    {productdetail?.ProductName}®
                  </h5>
                  <h5 className="foodmodal_title mt-2">
                    {productdetail?.ShopId?.CurrencySymbol}
                    {productdetail?.Price}
                  </h5>
                  <h5 className="foodmodal_subbold">
                    {/* Available - {productdetail?.Quantity} */}
                    <div className="cartoffcanva_incdec mt-2">
                      <div
                        className="cartoffcanva_incdecbtn oval_aligned me-3"
                        onClick={() =>
                          Quantity > 1 &&
                          productdetail.Quantity >= Quantity &&
                          SetQuantity(Quantity - 1)
                        }
                      >
                        <p aria-disabled={Number(Quantity) <= 1}>-</p>
                      </div>
                      <p className="cartoffcanva_incdeccount ">
                        <input
                          type="text"
                          className="input_max_wid"
                          value={Quantity}
                          onChange={(e) =>
                            productdetail.Quantity >=
                              Number(NumberOnly(e.target.value)) &&
                            SetQuantity(Number(NumberOnly(e.target.value)))
                          }
                        />
                      </p>
                      <div
                        className="cartoffcanva_incdecbtn oval_aligned ms-3"
                        onClick={() =>
                          productdetail.Quantity > Quantity &&
                          SetQuantity(Quantity + 1)
                        }
                      >
                        <p>+</p>
                      </div>
                    </div>
                  </h5>
                  <p className="foodmodal_subbold">About this item</p>
                  <p className="foodmodal_paratxt">
                    {productdetail?.ProductDescription}
                  </p>

                  <div className="modal_checkoptions">
                    <Form>
                      {productdetail?.AdditionalInfo?.map((it, i) => {
                        return (
                          <div className="mb-3">
                            <p className="foodmodal_subbold">{it.Name}</p>
                            {it.Options.map((its, index) => {
                              return (
                                <>
                                  <Form.Check
                                    id={it?._id}
                                    name={it?.Name}
                                    label={
                                      its.key +
                                      (isEmpty(its?.value)
                                        ? ""
                                        : " - " +
                                          (productdetail?.ShopId
                                            ?.CurrencySymbol +
                                            "" +
                                            its.value))
                                    }
                                    onChange={(e) =>
                                      OnChange(e, index, its, index)
                                    }
                                    type={"checkbox"}
                                  />
                                  <br />
                                </>
                              );
                            })}
                            <span className={"error-msg"}>
                              {Error?.[it.Name]}
                            </span>
                          </div>
                        );
                      })}
                    </Form>
                  </div>

                  {/* <p className="foodmodal_subbold add_note">Add Note</p>
                <input
                  className="foodmodal_input"
                  type="text"
                  placeholder="Write instructions here.You may be charged for extras."
                  onChange={(e) =>
                    SetProductDetail({
                      ...productdetail,
                      ...{ Notes: e.target.value },
                    })
                  }
                /> */}
                  <div className="foodmodal_price jtfy_near">
                    <h6>Total:</h6>
                    {console.log("djsggfsjfjsghfjsf", Total, Quantity)}
                    <h6 className="ms-3">
                      {productdetail?.ShopId?.CurrencySymbol} {Total * Quantity}
                    </h6>
                  </div>
                  <div className="row">
                    {" "}
                    <div className="col-sm-6 col-md-12 col-lg-6">
                      <button
                        className="foodmodal_cartbtn btn_wid_full"
                        onClick={() => Addtocart(undefined, "view")}
                      >
                        Add To Cart/Add another Item
                      </button>
                    </div>
                    <div className="col-sm-6 col-md-12 col-lg-6">
                      <button
                        className="foodmodal_cartbtn btn_wid_full"
                        onClick={() => Addtocart(undefined, "checkout")}
                      >
                        Add To Cart/CheckOut
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <Footer />

          {/* Initial Modal */}
          <Modal
            show={showInitial ? true : false}
            onHide={handleCloseInitial}
            backdrop="static"
            keyboard={false}
            className="cco_ordermodal"
            centered
          >
            <Modal.Header closeButton>
              <h6>Start a new order?</h6>
            </Modal.Header>
            <Modal.Body>
              <p className="emptybag_hint">
                Your bag contains items from <span>{showInitial}.</span>Empty
                your bag to start a new order.
              </p>
              <center>
                <button
                  className="blue_btn emptybag_btn"
                  onClick={() => UpdateCart()}
                >
                  Empty bag
                </button>
              </center>
            </Modal.Body>
          </Modal>
          {/* end of Initial Modal */}
        </>
      )}
    </>
  );
}

export default Detail;
