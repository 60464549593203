import { Route } from "react-router-dom";
import Protected from "./Protected";
import CartCheckout from "../Views/CartCheckout";
import PaymentSelect from "../Views/PaymentSelect";
import OrderConfirmed from "../Views/OrderConfirmed";
import OrderDetailsPage from "../Views/OrderDetailsPage";
import TrackOrder from "../Views/TrackOrder";
import ReviewOrder from "../Views/ReviewOrder";
import ViewDetails from "../Views/ViewDetails";
import ChangePassword from "../Views/ChangePassword";
import SavedStore from "../Views/SavedStore";

export const UserRoutes = (Auth, stripe, elements) => {
  return (
    <>
      <Route
        path="/cartcheckout"
        element={
          <Protected isSignedIn={Auth}>
            <CartCheckout />
          </Protected>
        }
      />
      <Route
        path="/paymentselect"
        element={
          <Protected isSignedIn={Auth}>
            <PaymentSelect stripe={stripe} />
          </Protected>
        }
      />
      <Route
        path="/orderconfirm"
        element={
          <Protected isSignedIn={Auth}>
            <OrderConfirmed />
          </Protected>
        }
      />
      <Route
        path="/savestore"
        element={
          <Protected isSignedIn={Auth}>
            <SavedStore />
          </Protected>
        }
      />
      <Route
        path="/detail"
        element={
          <Protected isSignedIn={Auth}>
            <OrderDetailsPage />
          </Protected>
        }
      >
        <Route path="order" element={<OrderDetailsPage />} />
        <Route path="address" element={<OrderDetailsPage />} />
        <Route path="payment" element={<OrderDetailsPage />} />
        <Route path="wishlist" element={<OrderDetailsPage />} />
        <Route path="profile" element={<OrderDetailsPage />} />
        <Route path="editaddress" element={<OrderDetailsPage />} />
        <Route path="addaddress" element={<OrderDetailsPage />} />
      </Route>

      <Route
        path="/trackorder"
        element={
          <Protected isSignedIn={Auth}>
            <TrackOrder />
          </Protected>
        }
      />

      <Route
        path="/revieworder"
        element={
          <Protected isSignedIn={Auth}>
            <ReviewOrder />
          </Protected>
        }
      />

      <Route
        path="/viewDetails"
        element={
          <Protected isSignedIn={Auth}>
            <ViewDetails />
          </Protected>
        }
      />
      <Route
        path="/changepw"
        element={
          <Protected isSignedIn={Auth}>
            <ChangePassword />
          </Protected>
        }
      />
    </>
  );
};
