import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useAxiosFile from "../actions/useaxios";
import { isEmpty } from "../actions/common";

function ForgotPassword() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { token, payload } = useSelector((state) => state.LoginReducer.User);
  const navigate = useNavigate();

  const [Mobile, SetMobile] = useState("");
  const [Error, SetError] = useState({});

  const axiosFile = useAxiosFile();
  const dispatch = useDispatch();

  const Validate = () => {
    let error = {};
    if (isEmpty(Mobile)) {
      error.Mobile = "Mobile Required";
    }
  };

  const Send = async () => {
    let error = Validate();
    if (isEmpty(error)) {
      let senddata = {
        Mobile: Mobile,
        Type: "forgot",
        from: "buyer",
      };
      let Resp = await axiosFile.LoginReg(senddata);

      if (Resp.success == "success") {
        dispatch({
          type: "Register_Section",
          Register_Section: {
            User: {
              token: Resp.token ? Resp.token : token,
            },
          },
        });
        navigate("/changepw", { state: { Mobile: Mobile } });
      }
    } else {
      SetError(error);
    }
  };

  return (
    <>
      <div className="singin_bg">
        <img
          className="singin_abs"
          src={require("../assets/images/jamskew.png")}
          alt=""
        />
        <div className="sigin_form">
          <div className="frgtpw_skip">
            <h5>Forgot Your password?</h5>
            <p className="sigin_skip">
              <Link to="/">Skip</Link>
            </p>
          </div>
          <p className="frgtpw_hint">
            Enter the mobile phone number associated with your Delpick account.
          </p>
          <div className="centering_sigin centering_frgtpw">
            <div className="single_center">
              <label className="signin_labels" for="mobile">
                Mobile Number
              </label>
              <br />
              <input
                className="sigin_inputs"
                type="number"
                placeholder="Enter your mobile number"
                id="mobile"
                name="mobile"
                onChange={(e) => SetMobile(e.target.value)}
              />
              {Error?.Mobile && <span>{Error.Mobile}</span>}
            </div>
          </div>

          <div className="siginin_btn_dtl">
            {/* <Link to="/changepw"> */}
            <button className="blue_btn send_btn" onClick={() => Send()}>
              Send
            </button>
            {/* </Link> */}
          </div>
          <p className="reg_bottomtxt">
            Do you remember it?{" "}
            <span>
              <Link to="/signin">Log In</Link>
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
