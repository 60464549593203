import React, { Component, useState, useEffect } from "react";
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { Container, Row, Col } from 'react-bootstrap'
import useraxios from "../actions/useaxios"
import {toast} from 'react-toastify'
import config from '../actions/config'
import { isEmpty } from "../actions/common";

import Newsletter from "../Components/Newsletter";
function PrivacyPolicy() {
    var [email,setEmail] = useState('');
    console.log("emadffvderfg", email);

    const [Error, SetError] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const axiosfile = useraxios();
    // const [cms, SetCms] = useState();
    const [userpaneldata, setUserpaneldata] = useState()
    console.log("sdv", userpaneldata);
    // useEffect(() => {
    //     getcmsList();
    // }, []);


    const getcmsList = async () => {
        var value = privacyvalidation()
        if (!isEmpty(value)) {
            
            console.log("value", value);
            SetError(value)
        }
        const id = toast.loading("Subscribing...");
        let err = {};
        if (email == '') err.email = "Email Id Required";
        if (email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
        SetError(err);
        console.log('fhbdfhbdf', email)
        if (isEmpty(err)) {

            var resp = await axiosfile.getCmsList({email:email});

                if (resp?.success == 'success') {
                    toast.update(id, { render: "Successfully Subscribed", type: "success", isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                    setEmail('');
                }
                else {
                    toast.update(id, { render: "Already User", type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                    SetError({ email: resp.msg });
                }
        } else {
            toast.update(id, { render: err.email, type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
        }
    }


    useEffect(() => {
        showuserpanel()
    }, [])
    // const showuserpanel = async () => {

    //     var res = await axiosfile.showuserpanel({ page: "home" })
    //     console.log("qwrtw", res?.data.data);
    //     setUserpaneldata(res?.data.data)
    // }
    const showuserpanel = async () => {
        var obj = {}
        var res = await axiosfile.showuserpanel({ page: "privacy" })
        console.log('kgjkldfjglkfsd', res)
        res?.data.data.map((mappeddata) => {
            console.log("qwrtw", mappeddata);
            obj[mappeddata?.page] = mappeddata

        })
        setUserpaneldata(obj)
    }
    const privacyvalidation = () => {
        var Error = {};

        if(isEmpty(email.email)){
            Error.email="Email Id Required";
        }
      }
    console.log("rtyhrtdhuytju", userpaneldata);
    return (
        <>
            <Header />
            <Container fluid className='privacypolicy_bannerbg '>
                <h3 className='ab_bannerttl'>{userpaneldata?.privacy?.question}</h3>


            </Container>
            <Container fluid className='pp_whole_dtls'>
                <p className='ab_bannerttl'><div dangerouslySetInnerHTML={{ __html: userpaneldata?.privacy?.answer }}></div></p>
                {/* {cms?.map((data, key) => {
                
                return (
                    <>
    {data.slug == "Privacy Policy" ? <>
        <p>Lorem Ipsum is simply dummy text</p>
        <hr/>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>

        <h6>{data?.question}</h6>
        <p dangerouslySetInnerHTML={{__html:data?.answer}}></p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>

        <h6>02.  Lorem Ipsum</h6>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>

        <h6>03.  Lorem Ipsum</h6>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>

        <ul>
            <li>
                <p><span>Lorem Ipsum: </span> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p></li>
                <li>
                <p><span>Lorem Ipsum: </span> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p></li>
                <li>
                <p><span>Lorem Ipsum: </span> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p></li>
                <li>
                <p><span>Lorem Ipsum: </span> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p>
            </li>
        </ul>

        <h6>04.  Lorem Ipsum</h6>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.</p>

</> : <></>}
                        </>
                )
                })} */}


    </Container>

    <Newsletter/>
    <Footer/>

    </>
  )
}

export default PrivacyPolicy