import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import React from "react";

// export default function Geocode() {
  setDefaults({
    // key: "AIzaSyB3t3YIfX-GVP16C8ZM_efQLUVuv5r2WSo", // Your API key here.
    key: "AIzaSyBtlmYERzInVggsVmyh_VO2DFDDQpbXmzU", // Your API key here.
    language: "en", // Default language for responses.
  });

export const GetLatLngBasedOnAddress = async (address) => {
  // Get latitude & longitude from address.
  console.log("results", address)
  try {
    var { results } = await fromAddress(address)
    console.log("results", results, results?.[0]?.geometry?.location)
    // coordinates: [ -73.97, 40.77 ] 
    return { type: "Point", coordinates: [results?.[0]?.geometry?.location?.lat, results?.[0]?.geometry?.location?.lng] }
  }
  catch (e) {
    console.log('GetLatLngBasedOnAddress', e)
    return { type: "Point", coordinates: [0, 0] }
  }
};
  export const GetAddressBasedOnLatLng = async(lat,lng,from) => {
   console.log("resultsaaaa",lat,lng,from)
    try{
   var {results}  = await fromLatLng(lat,lng)
   console.log("resultssafdsdfds",results[0])
  
   var add = {},
   stret = {};
 var data = results[0].address_components.map((it) => {
   console.log("add>>>>>>>>>>>>>", it);
   if (it.types.includes("street_number")) {
    add.Doornumber = it.long_name;
   }
   if (it.types.includes("route")) {
    add.Street = it.long_name;
   }
   if (it.types.includes("locality")) {
     stret.Street2 = it.long_name;
   }
   if (it.types.includes("administrative_area_level_2")) {
     add.City = it.long_name;
   }
   if (it.types.includes("administrative_area_level_3")) {
     add.City = it.long_name;
   }
   if (it.types.includes("administrative_area_level_1")) {
     add.State = it.long_name;
   }
   if (it.types.includes("country")) {
     add.Area = it.long_name;
   }
   if (it.types.includes("postal_code")||it.types.includes("post_code")) {
     add.Zipcode = it.long_name;
   }

   return add;
 });
 console.log("resultsaaaaendd",add,from,results[0].formatted_address)
    return (from == "sign" ? {Address:add,display:results[0].formatted_address} : results[0].formatted_address)
  }
catch(e) {
  console.log('resultsaaaaerror',e)
  return false}

  };
  //   return {
//     GetLatLngBasedOnAddress
// };
// }
