import { combineReducers } from "redux";
import { Data_Section, Initial_Register, LogOut } from "./constants";

const Initial_State = {
  App_Inside: false,
  links: null,
  currency: [],
  Categorys: [],

  User: {
    token: "",
    payload: null,
    logincheck: false,
  },
  initialRegister: {
    show: true,
  },
  Address: null,
  AddressInName: null,
  DeliveryType: "Delivery",
};

function LoginReducer(state = Initial_State, action) {
  console.log("Register_Section", action);
  switch (action.type) {
    case Data_Section:
      return {
        ...state,
        ...action.Register_Section,
      };
    case Initial_Register:
      return {
        ...state,
        ...action.initialRegister,
      };
    case LogOut:
      return Initial_State;
    default:
      return Initial_State;
  }
}

const JamskeApp = combineReducers({ LoginReducer: LoginReducer });

export default JamskeApp;
