import React from "react";
import { Route } from "react-router-dom";

import GroceryCategory from "../Views/GroceryCategory";
import CategoryDetails from "../Views/CategoryDetails";
import About from "../Views/About";
import Contact from "../Views/Contact";
import JoinAJamske from "../Views/JoinAJamske";
import Faq from "../Views/Faq";
import TermsandConditions from "../Views/TermsandConditions";
import PrivacyPolicy from "../Views/PrivacyPolicy";
import Home from "../Views/Home";

import Viewcategory from "../../src/Views/Categoryview";

import Detail from "../Views/Detail";
import Search from "../Views/SearchPage";

export const PublicRoutes = () => {
  return (
    <>
      <Route path="/" element={<Home />} />
      <Route path="/store" element={<GroceryCategory />} />
      <Route path="/categoryDetail" element={<CategoryDetails />} />
      <Route path="/detail" element={<Detail />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/joinjamske" element={<JoinAJamske />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/termsconditions" element={<TermsandConditions />} />
      <Route path="/viewcategory" element={<Viewcategory />} />
      <Route path="/search" element={<Search />} />
    </>
  );
};
