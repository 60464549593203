import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import config from "../actions/config";
import { useEffect } from "react";
import { DateTimeForm, DecryptData, EncryptData } from "../actions/common";
import useAxiosFile from "../actions/useaxios";
import { toast } from "react-toastify";


// import noticon from '../assets/svg'

function OrdersCard(props) {
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const encryptid = EncryptData(props?.details?._id);
  const axiosFile = useAxiosFile();

  console.log("skjfjkslfsfs", payload, props);
  const navigate = useNavigate();

  const ReOrder = async () => {
    let Resp = await axiosFile.Reorder({ OrderId: props?.details?._id });
    console.log('lksjflksjfklsf',Resp)
    if (Resp?.success == "success") {
      toast.success("Product Added to Cart", 1000);
      props?.updatecart();
    } else {
      toast.error(Resp?.msg, 1000);
    }
  };

  return (
    <>
      <Container>
        <Row className="ocard_fullwhole">
          <Col xxl={3} xl={3} lg={3} md={12} sm={12} xs={12}>
            <div
              className="foc_imgbg orders_bg"
              onClick={() =>
                navigate(
                  `/categoryDetail/?Id=${EncryptData(
                    props?.details?.ShopId?._id
                  )}`
                )
              }
            >
              <img
                className="img-fluid"
                src={`${config.IMG_URL}/shop/${props.details?.ShopId?.BrandImage}`}
              />
            </div>
          </Col>
          <Col xxl={9} xl={9} lg={9} md={12} sm={12} xs={12}>
            <div className="foc_wholedtls">
              <div className="foc_leftorder_dtls d_blc">
                <div className="foc_ordernum w_fl pe-2">
                  <p className="foc_orderttl">Order Number</p>
                  <p className="foc_orderval wrd_brk_all">
                    {props?.details?.OrderNumber}
                  </p>
                </div>
                <div className="foc_ordernum alg_itm_end mt-3 mt-sm-0 pe-2">
                  <p className="foc_orderttl">Payment</p>
                  <p className="foc_orderval">
                    {
                      props?.details?.TransactionHistory?.object
                        ?.payment_method_types[0]
                    }
                  </p>
                </div>
                <div className="foc_ordernum foc_ordernum_mobtop pe-2">
                  <p className="foc_orderttl">Ship to</p>
                  <p className="foc_orderval">
                    {payload?.FirstName + " " + payload?.LastName}
                  </p>
                </div>

                <div className="foc_ordernum foc_ordernum_mobtop pe-2">
                  <p className="foc_orderttl">Total amount</p>
                  <p className="foc_orderval">
                    {props?.details?.ShopId?.CurrencySymbol?props?.details?.ShopId?.CurrencySymbol:'$'}{" "}
                    {props?.details?.Price?.PayAmount}
                  </p>
                </div>
              </div>
              <Link
                to={`/viewDetails?order=${encryptid}`}
                className="foc_viewdtl_blue"
              >
                View Details
              </Link>
            </div>
            <div className="foc_bottomdtls">
              <div className="foc_bottombtns">
                {props?.details?.Status == "paid" ? (
                  <button className="blue_btn foc_trackbtn">
                    {"Not Yet Dispatched"}
                  </button>
                ) : (
                  <>
                    <Link
                      to={
                        (props?.details?.Status == "completed"||props.details.Status == "returned")
                          ? "#"
                          : `/trackorder?order=${encryptid}`
                      }
                    >
                      <button
                        onClick={() => {
                          (props?.details?.Status == "completed"||props.details.Status == "returned") && ReOrder();
                        }}
                        className="blue_btn foc_trackbtn"
                      >
                        {(props?.details?.Status == "completed"||props.details.Status == "returned")
                          ? "Reorder"
                          : "Track"}
                      </button>
                    </Link>
                    {
                      (!props?.details?.Status?.includes("cancel") && !props?.details?.Status?.includes("returned")) &&
                      <Link
                      to={
                        props?.details?.Status == "completed" &&
                        `/revieworder?order=${encryptid}`
                      }
                    >
                      <button
                        className="secondary_btn foc_cancelordr"
                        onClick={() => {
                          props?.details?.Status != "completed" &&
                            props?.details?.Status != "cancelbyseller" &&
                            props?.cancelOrder(true, props?.details);
                        }}
                      >
                        {(props?.details?.Status == "completed" ||
                        props?.details?.Status == "cancelbyseller" ||
                        props.details.Status == "returned") 
                        
                          ? "Review Order"
                          : "Cancel Order"}
                      </button>
                    </Link>}
                  </>
                )}
              </div>
              <div className="ocard_exclawhole">
                <p className="ocard_exclatxt">
                  {props?.details?.Status == "completed"
                    ? `Delivered on ${DateTimeForm(props?.details?.Time?.completed)}`
                    : props.details.Status == "cancelbyuser" ||
                      props.details.Status == "cancelbyseller"
                    ? `Cancel on ${DateTimeForm(
                        props?.details?.Time?.cancelbyuser
                          ? props?.details?.Time?.cancelbyuser
                          : props?.details?.Time?.cancelbyseller
                      )}`
                    : props.details.Status == "approvebyseller"
                    ? `Order Approved on ${DateTimeForm(
                        props?.details?.Time?.ApprovedBySeller
                      )}`
                    : props.details.Status == "dispatch"
                    ? `Order is ready at ${DateTimeForm(
                        props?.details?.Time?.Dispatch
                      )}`
                    : props.details.Status == "preparing"
                    ? `Preparation started at ${DateTimeForm(
                        props?.details?.Time?.preparing
                      )}`
                    : props.details.Status == "shipping"
                    ? `In transit on ${DateTimeForm(
                        props?.details?.Time?.Pickup
                      )}`
                    : props.details.Status == "paid"
                    ? `Paid at ${DateTimeForm(props?.details?.Time?.Paid)}`
                    :(
                      props.details.Status == "returned" ?
                      `Returned at ${DateTimeForm(props?.details?.Time?.Paid)}` :
                      `Delivery at ${DateTimeForm(
                        props?.details?.Time?.DeliveryTime
                      )}`
                    )}
                </p>
                <img
                  className="foc_excla"
                  src={
                    props?.details?.Status == "completed"
                      ? require("../assets/images/icon/delivered.png")
                      : (props.details.Status == "cancelled"||props.details.Status == "returned")
                      ? require("../assets/svg/closered.svg").default
                      : require("../assets/svg/iblack.svg").default
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OrdersCard;
