import React from 'react'
import {Card} from 'react-bootstrap';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import config from '../actions/config'
function ReviewCard({data}) {
  
  return (
    <>
    <Card className='ab_reviewcard'> 
      
      <Card.Body>
        <div className='rc_cardwhole'>
        
        <img className='rc_cardimg' src={data.Profile? `${config?.IMG_URL}/shop/${data?.Profile}`:require('../assets/images/uchihamadara.jpg')}/>
        <div>
        <p className='rc_desctxt'> {data?.Comments}</p>
        <Stack spacing={1}>
      <Rating name="half-rating" defaultValue={data?.Rating} precision={data?.Rating} />
      
    </Stack>
    <p className='rc_reviewername'>{data?.FirstName} <span>Seller</span></p>
    </div>
        </div>
      </Card.Body>
    </Card>
    </>
  )
}

export default ReviewCard