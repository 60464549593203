import React, { useState } from "react";
import { toast } from "react-toastify";
import { isEmpty } from "../actions/common";
import config from "../actions/config";
import useAxiosFile from "../actions/useaxios";
import { Card, Col, Container, Row } from "react-bootstrap";

export default function Newsletter() {
  var [email, setEmail] = useState("");

  console.log("email_email", email);
  const axiosfile = useAxiosFile();

  const [Error, SetError] = useState({});
  const NewsLetter = async () => {
    var value = cmsvalidation();
    if (!isEmpty(value)) {
      console.log("value", value);
      SetError(value);
    }
    const id = toast.loading("Subscribing...");
    let err = {};
    if (email == "") err.email = "Email Id Required";
    if (email && !config.EMAIL.test(email))
      err.email = "Invalid Email ID Format";
    SetError(err);
    console.log("fhbdfhbdf", email);
    if (isEmpty(err)) {
      let resp = await axiosfile.NewsletterS({ email: email });
      if (resp.success === "success") {
        toast.update(id, {
          render: "Successfully Subscribed for NewsLetter",
          type: "success",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        setEmail("");
      } else {
        toast.update(id, {
          render: "Already User",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetError({ email: resp.msg });
      }
    } else {
      toast.update(id, {
        render: err.email,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
    }
  };
  const cmsvalidation = () => {
    var Error = {};

    if (isEmpty(email.email)) {
      Error.email = "Email Id Required";
    }
  };

  return (
    <>
      {/* <Container fluid className="fm_subscribe_dtl">
        <Container>
          <Row>
            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
              <div className="fm_subs_left">
                <h5 className="fm_subs_head">
                  SUBSCRIBE TO OUR <br className="fm_subs_cmnbr" /> NEWSLETTER
                </h5>
                <p className="fm_subs_hint">
                  Be The First To Hear About New Launches, Exclusive Offers.
                </p>
              </div>
            </Col>
            <Col
              xxl={8}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              className="d-flex "
            >
              <div className="fm_subs_right  position-relative">
                <div className="w-100 me-4 position-relative">
                  <input
                    type="email"
                    className="fm_subs_mailinput "
                    placeholder="Your Email Address"
                    value={"" || email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <span id="email-error " className="error-msg ">
                    {Error && Error.email}
                  </span>
                </div>

                <button className="fm_mailsub_btn" onClick={NewsLetter}>
                  SUBSCRIBE
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container> */}
      {/* <Container fluid className="fm_subscribe_dtl">
        <Container>
          <Row>
            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
              <div className="fm_subs_left">
                <h5 className="fm_subs_head">
                  SUBSCRIBE TO OUR <br className="fm_subs_cmnbr" /> NEWSLETTER
                </h5>
                <p className="fm_subs_hint">
                  Be The First To Hear About New Launches, Exclusive Offers.
                </p>
              </div>
            </Col>
            <Col
              xxl={8}
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              className="d-flex flex-column justify-content-center"
            >
              <div className="fm_subs_right hgt_unst position-relative">
                <div className="w-100 me-4 position-relative">
                  <input
                    type="email"
                    className="fm_subs_mailinput wid_fl"
                    placeholder="Your Email Address"
                    value={"" || email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <span
                    id="email-error "
                    className="error-msg spn_txt_st mt_zero position-absolute"
                  >
                    {Error && Error.email}
                  </span>
                </div>

                <button className="fm_mailsub_btn" onClick={NewsLetter}>
                  SUBSCRIBE
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container> */}
      <Container fluid className="px-0 ">
        <Card className="newslrt_card">
          <Card.Body className="p-5">
            <Row className="align-items-center">
              <Col xs={12} md={6} lg={5} xl={4}>
                <div className="fm_subs_head">
                  <h5 className="fm_subs_head">SUBSCRIBE TO OUR </h5>
                  <h5 className="fm_subs_head">NEWSLETTER</h5>
                  <p className="fm_subs_hint">
                    Be The First To Hear About New Launches, Exclusive Offers.
                  </p>
                </div>
              </Col>
              <Col
                xs={12}
                md={6}
                lg={7}
                xl={8}
                className="mt-4 mt-sm-5 mt-md-0"
              >
                <div className="d-flex align-items-center flex-column flex-lg-row">
                  <div className=" me-lg-4 position-relative w-100 inpt_par_ht">
                    <input
                      type="email"
                      className=""
                      placeholder="Your Email Address"
                      value={"" || email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                    <span
                      id="email-error "
                      className="error-msg spn_txt_st mt_zero position-absolute"
                    >
                      {Error && Error.email}
                    </span>
                  </div>

                  <button
                    className="fm_mailsub_btn btn_mb_zero mt-5 mt-lg-0"
                    onClick={NewsLetter}
                  >
                    SUBSCRIBE
                  </button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}
